import {
	Component,
	OnInit,
	Input,
	ChangeDetectorRef,
	Optional,
	EventEmitter,
	OnDestroy,
	ViewChild,
	AfterViewInit,
} from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { BehaviorSubject, Subscription } from 'rxjs';
import { GridSettings } from '../../_models/grid-settings.interface';
import { process, State } from '@progress/kendo-data-query';
import { PageChangeEvent } from '@progress/kendo-angular-grid';

import { filter } from 'rxjs/operators';
import Swal from 'sweetalert2';

import _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/data.service';
import { DashboardService } from '../../services/dashboard.service';
import { KendoSettingsService } from '../../services/kendo-settings.service';
import { SignalRCoreService } from '../../services/signalr-core.service';
import { GseService } from '../../services/gse.service';
import { Global } from '../../_constants/global.variables';
import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';
import * as $ from 'jquery';
import { IGlobal } from '../../_models/global.model';
import { KendoGridParentComponent } from '../kendo-grid-parent/kendo-grid-parent.component';
import { IWidgetSignalRGroupObject } from '../../_models/signalr-widget-group.model';
import { ITag } from '../../_models/tag.model';
import { MapsService } from '../../services/maps.service';
import { ITagNamePrefixSubject } from '../../_models/tag-name-prefix-subject.model';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

declare const google: any;

@Component({
	selector: 'lib-locate-all-gse-widget',
	templateUrl: './locate-all-gse-widget.component.html',
	styleUrls: ['./locate-all-gse-widget.component.scss'],
})
export class LocateAllGseWidgetComponent implements AfterViewInit, OnInit, OnDestroy {
	@ViewChild('tagDataGrid') tagDataGrid: KendoGridParentComponent;

	widgetGroupSettings: IWidgetSignalRGroupObject;

	@ViewChild('locateAllGSEGenericMapChild') locateAllGSEGenericMapChild: any;

	@Optional() @Input() widgetObject: any;
	private route$: any;
	tagOptions: { tagName: string; standardObservationId: number, percent?: boolean }[];
	private centerLatitude: number = 30.3092613;
	private centerLongitude: number = -95.4679269;
	private zoomLevel: number = 18;
	fullDataCache$: BehaviorSubject<false>;

	public mapOptions: any;
	markers: Array<any>;
	defaultLeftWidth = 25;
	defaultRightWidth = 75;
	private swalWithBootstrapButtons: any;

	private checkAlarmNotificationsInterval;

	gseIdList = [];
	gse: any;
	gseForMap: any;
	public mapDiv: string;
	newHeight: any;
	oldHeight: any;
	newWidth: any;
	oldWidth: any;
	tagGraphSingleModalSubscription: any;
	evaluateLocateRedraw: NodeJS.Timeout;
	isDataLoading: boolean;
	locateMap: any;
	childMapReadyToLoad: boolean = false;
	private componentName: string = 'locate-all-gse: ';
	public global: IGlobal = Global;
	@Input() public dashboardTimeZoneChanged: EventEmitter<any>;
	dashboardTimeZoneChangedSubscription: Subscription;
	timeZoneType: any;

	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: 'and',
				filters: [],
			},
			take: 15,
		},

		columnsConfig: [
			{
				title: "Last Refresh",
				field: "lastRefreshTime",
				filterable: true,
				_width: 100,
			},
			{
				field: 'stringPathForAssetImage',
				title: 'Asset Image',
				filterable: false,
				_width: 100,
			},
			{
				field: 'stringPathForAssetUserDefinedAlarm',
				title: 'User Defined Faults',
				filterable: false,
				_width: 100,
			},
			{
				field: 'Name',
				title: 'Name',
				filterable: true,
				_width: 300,
			},
			{
				field: 'Site.Name',
				title: 'Site',
				filterable: true,
				_width: 120,
			},
			{
				field: 'AssetTypeName',
				title: 'Asset Type',
				filterable: true,
				_width: 100,
			},
			{
				field: 'OwnerOrganizationName',
				title: 'Owner',
				filterable: true,
				_width: 150,
			},
			{
				field: 'ManufacturerOrganizationName',
				title: 'Manufacturer',
				filterable: true,
				_width: 250,
			},

		],
	};
	signalRSubscription: any;
	gseFiltered: Array<any>;
	fullScreen: boolean;
	fullDataCacheSubscription: any;
	gseSummaryModalSubscription: any;
	pageSize: number;
	updateAssetImageSubscription: any;
	zonesAndZonesInstances: any;
	isWidgetInView: any = true;
	widgetIsInViewSubscription: any;
	toggleSubscription: any;
	signalRTagUpdateSubscription: any;
	signalRTagUpdateByAssetSubscription: Subscription;
	split1Percent: any;
	split2Percent: any;
	isTableReadyForLoading: boolean = false;

	// Fleet
	allFleetAssets: Array<any>;
	public guid: string;
	public tagNamePrefixes: string;

	constructor(
		private route: ActivatedRoute,
		private dataService: DataService,
		public dialog: MatDialog,
		private dashboardService: DashboardService,
		private kendoSettingsService: KendoSettingsService,
		private ref: ChangeDetectorRef,
		private signalRCore: SignalRCoreService,
		public gseService: GseService,
		private toastr: ToastrService,
		private router: Router,
		private mapsService: MapsService
	) {
		this.swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: 'btn btn-success',
				cancelButton: 'btn btn-danger',
			},
			buttonsStyling: false,
		});
	}

	//started here instead of ngoninit beacuse we are trying to locate the dom element for the map and it isn't there fast enough if started on ngoninit
	ngAfterViewInit() {
		this.mapsService.loadGoogleMaps().then(() => {
			if (!Global.FullDataCacheExists) {
				this.fullDataCacheSubscription =
					this.dataService.fullDataCacheExists$.subscribe((data: any) => {
						if (data === true) {
							this.initialize();
							this.fullDataCacheSubscription.unsubscribe();
						}
					});
			} else {
				this.dataService.GetFleetAssets().subscribe((data: any) => {
					Global.User.DebugMode && console.log("GetFleetAssets", data);
					this.allFleetAssets = data;	// all the Fleet assets
					this.initialize();
				});

			}
		});

	}

	ngOnInit(): void {
		this.guid = this.dataService.guid();

		if (this.dashboardTimeZoneChanged) { //handler for time zone changes
			this.dashboardTimeZoneChangedSubscription = this.dashboardTimeZoneChanged.subscribe((data) => {
				console.log(data);
				let foundWidgetWithSameWidgetId = data.find((widgetThatWasChanged) => {
					return widgetThatWasChanged.WidgetId === this.widgetObject.WidgetId;
				});

				if (!_.isNil(foundWidgetWithSameWidgetId)) {
					console.log("Widget Time Zone Changed");
					this.childMapReadyToLoad = true;
					this.locateAllGSEGenericMapChild?.drawMap();
				}
			});
		}
	}

	toggleZonesOnMap() {
		this.locateAllGSEGenericMapChild.toggleZonesOnMap();
	}

	checkAlarmNotifications() {

		this.gse.forEach(asset => {

			let userAlert = this.dataService.cache.emailTagUsers.find((a) =>
								a.UserId == Global.User.currentUser.Id &&
								(a.CriticalAssetId == asset.Id || a.AssetId == asset.Id)
							);

			asset.alertNotification = userAlert == undefined ? false : true;


			// set the title with alert info
			if(userAlert != undefined) {

				let title = userAlert.CriticalAssetId == asset.Id ? "Critical " : "";
				title += "Alert notifications set for " + asset.Name;
				let alertObject = this.GetAlertNotificationDetails(userAlert);

				if(alertObject.Email == true && alertObject.TextMessage == true) {
					title += " email and text delivery";
				}
				else {
					title += alertObject.Email == true ? " email delivery" : "";
					title += alertObject.TextMessage == true ? " text delivery" : "";
				}

				title += " on " + alertObject.DaysSelected;
				title += " during "+ alertObject.HoursSelected;

				asset.alertNotificationTitle = title;
			}
			else {
				asset.alertNotificationTitle = "Click to set alert management settings.";
			}


		});

	}


	GetAlertNotificationDetails(userAlert) : any {

		let alertObject: any = {
			siteId: 0,
			siteName: '',
			gateSystemId: 0,
			gateSystemName: '',
			category: '',
			Email: 0,
		};


		// delivery
		alertObject.Email = userAlert.Email == 1 ? true : false;
		alertObject.TextMessage = userAlert.TextMessage == 1 ? true : false;

		// set days
		if(userAlert.SendAllDays == 1) {
			alertObject.DaysSelected = "all days";
		}
		else {
			alertObject.DaysSelected = "select days";
		}


		// calc work hours
		let hourToStart = userAlert.UserTimeZoneOffset + 8;

		// set hours
		if(userAlert.SendAllHours == 1) {
			alertObject.HoursSelected = "all Hours";
		}
		else {
			alertObject.HoursSelected = "select Hours";
		}

		return alertObject;
	}


	finishInitializeGrids() {
		//if(this.widgetObject?.WidgetSiteId || this.widgetObject?.SiteList) {
			let alarmColumn: any =
				{
					field: 'alertNotification',
					title: 'Fault',
					filterable: true,
					filter: 'boolean',
					_width: 60,
					hidden: false,
					minResizableWidth: 60,
				};
			this.gridSettings.columnsConfig.push(alarmColumn);
		//}
	}

	initialize() {
		let statement =
			'API.GetWidgetTabSettings @WidgetId = ' +
			this.widgetObject.WidgetId;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			console.log('data', data);
			this.widgetObject.SelectedTabIndex = 0;
			if (data.length > 0) {
				this.widgetObject.tabSettings = data;
				this.split1Percent =
					this.widgetObject.tabSettings[0].SplitPanel1Percent;
				this.split2Percent =
					this.widgetObject.tabSettings[0].SplitPanel2Percent;
			} else {
				this.split1Percent = Global.isMobile ? 50 : 25;
				this.split2Percent = Global.isMobile ? 50 : 75;
			}
			if (this.widgetObject !== undefined) {
				if (_.isNil(this.widgetObject.SiteList) && _.isNil(this.widgetObject.VocationalSettingsJSON)) {		// Sites and Fleet
					this.isDataLoading = false;
					return;
				}
				this.widgetIsInViewSubscription =
					this.dashboardService.widgetInViewChanged$.subscribe(
						(data: any) => {
							if (data.widgetId === this.widgetObject.WidgetId) {
								this.isWidgetInView = data.isInView;
							}
						}
					);

				this.toggleSubscription =
					this.dataService.toggleGeofencesForLocateAllGSEChanged$.subscribe(
						(widgetObject: any) => {
							if (
								widgetObject.WidgetId ===
								this.widgetObject.WidgetId
							) {
								this.locateAllGSEGenericMapChild.toggleZonesOnMap();
							}
						}
					);
			}

			setTimeout(() => {
				this.updateAssetImageSubscription =
					this.gseService.updateAssetImageEmitter$.subscribe(
						(asset) => {
							let indexInControllerAssets =
								this.gseFiltered.findIndex(
									(assetInControllerList) => {
										return (
											assetInControllerList.Id ===
											asset.Id
										);
									}
								);
							if (indexInControllerAssets !== -1) {
								this.gseFiltered[
									indexInControllerAssets
								].AssetModelId = asset.AssetModelId;
							}
						}
					);
				this.isDataLoading = true;
				this.tagOptions =  [
					{tagName: "Last Refresh Time", standardObservationId: 79870}, 
					{tagName: "Drive Mode", standardObservationId: 19127}, 
					{tagName: "Speedometer", standardObservationId: 77417}, 
					{tagName: "Fuel Level", standardObservationId: 19141, 'percent': true}, 
					{tagName: "Def Level", standardObservationId: 19229, 'percent': true}, 
					{tagName: "Soot Load %", standardObservationId: 63843, 'percent': true}
				];


				this.gridSettings = {
					state: {
						skip: 0,
						filter: {
							logic: 'and',
							filters: [],
						},
						take: 15,
					},

					columnsConfig: [
						{
							field: 'stringPathForAssetImage',
							title: 'Asset Image',
							filterable: false,
							_width: 100,
						},
						{
							field: 'stringPathForAssetUserDefinedAlarm',
							title: 'User Defined Faults',
							filterable: false,
							_width: 100,
						},
						{
							field: 'Name',
							title: 'Name',
							filterable: true,
							_width: 300,
						},
						{
							field: this.widgetObject.VocationalSettingsJSON ? "FleetName" : "Site.Name",
							title: this.widgetObject.VocationalSettingsJSON ? "Fleet" : "Site",
							filterable: true,
							_width: 120,
						},
						{
							field: 'AssetTypeName',
							title: 'Asset Type',
							filterable: true,
							_width: 100,
						},
						{
							field: 'OwnerOrganizationName',
							title: 'Owner',
							filterable: true,
							_width: 150,
						},
						{
							field: 'ManufacturerOrganizationName',
							title: 'Manufacturer',
							filterable: true,
							_width: 250,
						},
						{
							field: 'speed',
							title: 'Speed',
							filterable: true,
							_width: 120,
						},
						{
							field: 'speedLimit',
							title: 'Speed Limit',
							filterable: true,
							_width: 120,
						},
					],
				};

				this.finishInitializeGrids();

				if (this.widgetObject !== undefined) {
					this.mapDiv =
						'locateAllGSEMap' + this.widgetObject.WidgetId;
				} else {
					this.mapDiv = 'locateAllGSEMap';
				}

				//new code
				this.evaluateUserAndDeclareGSE();

				this.gse.forEach((gse: any) => {
					this.gseIdList.push(gse.Id.toString());
					gse.FleetName = this.widgetObject.FleetName;
				});

				this.checkAlarmNotifications();
				this.checkAlarmNotificationsInterval = setInterval(() => {
					this.checkAlarmNotifications();
				}, 10000);

				this.gseIdList.join(',');
				let stringList = this.gseIdList.join(',');
				this.dataService
					.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
						stringList
					)
					.subscribe((data: any) => {
						this.tagNamePrefixes =  _.uniq(
							this.gse.map(
								(a: any) =>
									this.dataService.cache.assetsObject[a.Id]
										.TagNamePrefix
							)
						).join();

						this.buildMap(true);
						this.getSignalRUpdates();
					});
			}, 500);
		});
	}

	getSignalRUpdates() {
		let tagNamePrefixesString = this.tagNamePrefixes;
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;


		this.signalRCore.joinGroups();


		if (this.widgetObject && this.widgetObject.WidgetId !== undefined) {
			this.widgetGroupSettings = {
				WidgetId: this.widgetObject.WidgetId,
				GroupList: tagNamePrefixesString,
				IsPopup: false,
			};
		} else {
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: tagNamePrefixesString,
				IsPopup: true,
			};
		}

		Global.User.DebugMode && console.log(this.componentName + ": widgetGroupSettings = %O", this.widgetGroupSettings);

		this.dataService
		.createSubjectAndSubscribe({ Id: this.guid,
									WidgetName: "Locate All GSE",
									TagNamePrefix: tagNamePrefixesString.split(",")
		 })
		.then((data) => {
			//subscribe to existing subject
			Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);

			 var activeSubject = this.dataService.returnCorrectActiveSubject(this.guid); 
			
			     Global.User.DebugMode && console.log(this.componentName + "active subjects: %O", activeSubject);
			
			
			activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
				//console.log(this.componentName + "Updating tag we care about: %O", tag);
				this.locateAllGSEGenericMapChild?.updateMapData(tag);
			});
		});
	}

	getTagValue(assetObject, observationId, needObject?) {
		let tag = assetObject.Tags.find(
			(tag) => tag.JBTStandardObservationId === observationId
		);
		if (tag == undefined) {
			return null;
		} else if (needObject === undefined) {
			return `${tag.JBTStandardObservation.Name}: ${tag.Value}`;
		} else if (needObject === true) {
			return tag;
		}
	}

	evaluateUserAndDeclareGSE() {
		const allGSEAssetIds = [86292, 86293, 86294, 86295, 86296, 86297, 86313, 86314];
		if (Global.User.isAdmin) {
			this.gse = this.dataService.cache.assets.filter((asset) => {
				return (
					allGSEAssetIds.includes(asset.AssetTypeId) &&
					asset.Site !== undefined
				);
			});
		} else {
			this.gse = this.dataService.cache.assets.filter((asset) => {
				return (
					allGSEAssetIds.includes(asset.AssetTypeId) &&
					asset.Site !== undefined &&
					Global.User.currentUser.Security.Aggregate.Collections.SiteIds.includes(
						asset.Site.Id
					)
				);
			});
		}

		if (this.widgetObject) {
			if (!_.isNil(this.widgetObject.SiteList)) {
				if (typeof this.widgetObject.SiteList === 'string') {
					this.widgetObject.SiteList =
						this.widgetObject.SiteList.split(',').map((item) => {
							return parseInt(item);
						});
				}

				this.gse = this.gse.filter((asset) => {
					return this.widgetObject.SiteList.includes(
						parseInt(asset.Site?.Id)
					);
				});
			}
			else if(!_.isNil(this.widgetObject.VocationalSettingsJSON)) {		//  Fleet
				// filter just the assets for this Fleet
				var VocationalSettings = JSON.parse(this.widgetObject.VocationalSettingsJSON);
				console.log("VocationalSettings ", VocationalSettings)

				let fleetId = VocationalSettings.id;

				let myFleetAssets = this.allFleetAssets.filter((asset) => {
						return asset.FleetId === fleetId;
					}
				);

				this.widgetObject.FleetName = myFleetAssets.length > 0 ? myFleetAssets[0].FleetName : "";


				myFleetAssets.forEach((gse: any) => {
					this.gseIdList.push(gse.Id);
				});

				this.gse = this.dataService.cache.assets.filter((asset) => {
					return (
						//asset.Id == 3150851
						this.gseIdList.includes(asset.Id)
					);
				});

				console.log("assets for Fleet", this.gse);


			}


		}
	}

	openTagGraphSingle(passedTagObject): void {
		let tagObject = this.getValue(
			passedTagObject.gse,
			passedTagObject.observationId,
			true
		);
		if (tagObject === null) {
			this.swalWithBootstrapButtons
				.fire({
					title: 'Error',
					text: "Tag Object Doesn't Exist",
					showCancelButton: false,
					confirmButtonText: 'Ok',
					reverseButtons: false,
				})
				.then(() => {});
		} else {
			// Global.User.DebugMode && console.log(widgetObject);
			const tagGraphSingleModal = this.dialog.open(
				DialogModalParentComponent,
				{
					width: '95%',
					height: '80%',
					data: {
						graphId: uuidv4(),
						TagId: tagObject.TagId,
						widgetNameDisplay: 'Tag Graph',
						WidgetName: 'tag-graph',
						isDisplayDataLive: true,
						preventFromAddingToDashboard:
							this.widgetObject !== undefined ? false : true,
						timeZoneType:
							this.dashboardService.determineTimeZoneType(
								this.widgetObject
							),
					},

					maxWidth: '100vw',
					maxHeight: '100vh',
				}
			);
			this.tagGraphSingleModalSubscription = tagGraphSingleModal
				.afterClosed()
				.subscribe((result) => {
					this.tagGraphSingleModalSubscription.unsubscribe();
				});
		}
	}

	getValue(element, observationId, needObject) {
		let tag = element.Tags.find(
			(element) => element.JBTStandardObservationId === observationId
		);
		if (tag === undefined) {
			return null;
		} else if (needObject === undefined) {
			return tag.Value;
		} else if (needObject === true) {
			return tag;
		}
	}

	showAssetOnMap(asset) {
		this.locateAllGSEGenericMapChild.showAssetOnMap(asset);
	}

	saveGridSettings(event) {
		if (this.widgetObject !== undefined) {
			this.kendoSettingsService
				.saveGridSettings(
					[
						{
							gridObject: this.tagDataGrid.kendoGridParent,
							gridState: event.state,
						},
					],
					this.widgetObject.WidgetId
				)
				.then((data: any) => {
					console.log(data);
					this.widgetObject.WidgetKendoUIJson = data;
				});
		}
	}

	public onRightClickSelect({ dataItem, item }) {
		if (item === 'Open Summary for Asset') {
			this.openSummaryWidget({
				dataItem: dataItem,
				shouldShowMap: false,
			});
		} else if (item === 'Show Location of Asset') {
			this.openSummaryWidget({
				dataItem: dataItem,
				shouldShowMap: true,
			});
		} else if (item === 'Open Punchout Screen for Asset'){
			const whereToGoUrl = '/layout/assets/' + dataItem.Id;
			this.router.navigate([whereToGoUrl]);
		}
	}

	openSummaryWidget(event): void {
		const gseSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '100%' : '60%',
			height: Global.isMobile ? '90%' : '80%',
			data: {
				graphId: uuidv4(),
				widgetObject: event.dataItem,
				shouldShowMap: event.shouldShowMap,
				siteName: event.dataItem.WidgetSiteName,
				gseName: event.dataItem.Name,
				widgetNameDisplay: 'Fleet Asset Summary',
				WidgetName: 'GSE-Summary',
				parentWidget: this.widgetObject,
			},

			maxWidth: '100vw',
			maxHeight: '100vh',
		});
		this.gseSummaryModalSubscription = gseSummaryModal
			.afterClosed()
			.subscribe((result) => {
				this.gseSummaryModalSubscription.unsubscribe();
			});
	}

	buildMap(useDatabaseState: boolean) {
		console.log(this.locateMap);

		this.gse = this.gse.filter((asset) => {
			return (
				asset.Tags.find((t) => t.JBTStandardObservationId == 54260) !==
					undefined &&
				asset.Tags.find((t) => t.JBTStandardObservationId == 54261) !==
					undefined &&
				asset.Name !== undefined
			);
		});
		if (
			this.widgetObject !== undefined &&
			this.widgetObject.WidgetKendoUIJson &&
			useDatabaseState === true
		) {
			let jsonObjectParsed =
				this.kendoSettingsService.parseReturnedSettingsForDates(
					this.widgetObject.WidgetKendoUIJson
				);
			//the first item in the array is the gridSettings for the first tab of data for this widget.

			let returnedParsedObject = jsonObjectParsed[0];
			//We pass it into the function in the kendo service to compare what has been saved vs the template declaration of columns to make sure they get the lastest updates.
			this.gridSettings = this.mapGridSettings(
				this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
					this.gridSettings,
					returnedParsedObject
				)
			);
			this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(
				[this.gridSettings.columnsConfig],
				this.widgetObject.WidgetId
			);
		}
		this.isTableReadyForLoading = true;
		setTimeout(() => {
			this.gse.forEach((asset) => {
				let evaluationObject: any =
					this.gseService.determineAssetImageFromAssetTypeForTable(
						asset
					);
				let evaluationObjectForUserDefinedAlarms: any =
					this.gseService.determineUserDefinedAlarmsImageFromAssetTypeForTable(
						asset
					);
				if (
					evaluationObject?.icon !== undefined
				) {
					asset.stringPathForAssetImage = evaluationObject.icon.url;
				} else {
					asset.stringPathForAssetImage = '';
				}
				if (
					evaluationObjectForUserDefinedAlarms?.alarmType !== '' &&
					evaluationObjectForUserDefinedAlarms?.icon !== undefined
				) {
					asset.stringPathForAssetUserDefinedAlarm =
						evaluationObjectForUserDefinedAlarms.icon.url;
				} else {
					asset.stringPathForAssetUserDefinedAlarm = '';
				}
				
			});
			if (this.tagDataGrid) {
				console.log(this);
				this.tagDataGrid.gridDataSubject.next(this.gse);
				this.gseForMap = this.tagDataGrid.processedRawDataForGridWithoutPaging.data;
			}

			this.childMapReadyToLoad = false;
			this.childMapReadyToLoad = true;
			this.isDataLoading = false;
			this.finishBuildingMap();
		}, 500);
	}

	finishBuildingMap() {
		setTimeout(() => {
			this.locateAllGSEGenericMapChild?.drawMap();

			if (this.widgetObject !== undefined) {
				let mapContainer = $(
					'#locateResizeContainer' + this.widgetObject.WidgetId
				).height();
				$('#regularMap' + this.widgetObject.WidgetId).height(
					mapContainer - 5
				);
			} else {
				let mapContainer = $('#locateResizeContainer').height();
				$('#regularMap').height(mapContainer - 5);
			}
		}, 500);
	}

	public mapGridSettings(gridSettings: GridSettings) {
		const state = gridSettings.state;
		let emptyArray: any = [];
		return {
			state,
			columnsConfig: gridSettings.columnsConfig.sort(
				(a, b) => a.orderIndex - b.orderIndex
			),
			gridData:
				this.dataService.cache !== undefined
					? process(this.gse, state)
					: emptyArray,
		};
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + ": ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);

		if (this.checkAlarmNotificationsInterval) {
			clearInterval(this.checkAlarmNotificationsInterval);
		}
	}

	onResized(event) {
		this.pageSize = ((event.newRect.height - 120) / 36) * 3;

		this.newHeight = event.newRect.height;
		this.oldHeight = event.oldHeight;
		this.newWidth = event.newRect.width;
		this.oldWidth = event.oldWidth;
		this.evaluateRedraw();
	}

	evaluateRedraw() {
		if (this.widgetObject !== undefined) {
			let cardHeight = $('#card-whole').height();
			if (this.newHeight !== this.oldHeight) {
				$('.map-container' + this.widgetObject.WidgetId).height(
					this.newHeight - 5
				);
				$('#regularMap' + this.widgetObject.WidgetId).height(
					this.newHeight - 5
				);
			}
		} else {
			let cardHeight = $('#card-whole').height();
			if (this.newHeight !== this.oldHeight) {
				$('.map-container').height(this.newHeight - 5);
				$('#regularMap').height(this.newHeight - 5);
			}
		}
	}
}
