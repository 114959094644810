import { Component, OnInit, Input, NgZone } from "@angular/core";


import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';

import Swal from 'sweetalert2';
import { DataService } from '../../services/data.service';
import { SignalRCoreService } from '../../services/signalr-core.service';
import { DashboardService } from '../../services/dashboard.service';
import { Global } from '../../_constants/global.variables';
import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';
import _ from 'lodash';
import { ClipboardService } from 'ngx-clipboard';
import { IGlobal } from '../../_models/global.model';
import { UtilityService } from '../../services/utility.service';
import { ITag } from "../../_models/tag.model";
import { ITagNamePrefixSubject } from "../../_models/tag-name-prefix-subject.model";
import { v4 as uuidv4 } from 'uuid';

@Component({
	selector: 'lib-pca-quick-view',
	templateUrl: './pca-quick-view.component.html',
	styleUrls: ['./pca-quick-view.component.scss'],
})
export class PcaQuickViewComponent implements OnInit {
	@Input() parentAsset: any;
	@Input() parentTagIds: any;
	@Input() widgetObject: any;
	fullDataCacheSubscription: any;
	theme: string;
	assetTags: any;
	isDataLoading: boolean;
	widgetGroupSettings: any;
	tagGraphSingleModalSubscription: Subscription;
	private swalWithBootstrapButtons: any;
	public label = {
		visible: false,
	};
	public progressStyles: { [key: string]: any } = {
		background: 'darkgrey',
	};

	public global: IGlobal = Global;
	public viewHeatModule = false;
	public componentName: string = "pca-quick-view: ";

	public compressor1HeadPressure = [3848]; //Compressor 1 Head Pressure
	public compressor1Status = [3967]; // Compressor 1 Status
	public compressor1SuctionPressure = [3912]; //Compressor 1 Suction Pressure
	public expansionValve1 = [13917]; //Expansion Valve 1
	public hotGas1 = [56880]; //Hot Gas 1
	public suctionTemp1 = [15458]; //Suction Temp 1
	public superHeat1 = [56891]; //Super Heat 1
	public heat1Status = [4704]; //Heat 1

	public compressor2HeadPressure = [3909]; //Compressor 2 Head Pressure
	public compressor2Status = [3598]; // Compressor 2 Status
	public compressor2SuctionPressure = [3913]; //Compressor 2 Suction Pressure
	public expansionValve2 = [13933]; //Expansion Valve 2
	public hotGas2 = [12576]; //Hot Gas 2
	public suctionTemp2 = [15397]; //Suction Temp 2
	public superHeat2 = [56892]; //Super Heat 2
	public heat2Status = [3883]; //Heat 2

	public compressor3HeadPressure = [3910]; //Compressor 3 Head Pressure
	public compressor3Status = [3968]; // Compressor 3 Status
	public compressor3SuctionPressure = [1872]; //Compressor 3 Suction Pressure
	public expansionValve3 = [13901]; //Expansion Valve 3
	public hotGas3 = [56896]; //Hot Gas 3
	public suctionTemp3 = [56895]; //Suction Temp 3
	public superHeat3 = [56893]; //Super Heat 3
	public heat3Status = [3884]; //Heat 3

	public compressor4HeadPressure = [12542]; //Compressor 4 Head Pressure
	public compressor4Status = [3969]; // Compressor 4 Status
	public compressor4SuctionPressure = [56746]; //Compressor 4 Suction Pressure
	public expansionValve4 = [13860]; //Expansion Valve 4
	public hotGas4 = [56577]; //Hot Gas 4
	public suctionTemp4 = [56749]; //Suction Temp 4
	public superHeat4 = [56894]; //Super Heat 4
	public heat4Status = [3885]; //Heat 4

	public compressor5HeadPressure = [57450]; //Compressor 5 Head Pressure
	public compressor5Status = [57452]; // Compressor 5 Status
	public compressor5SuctionPressure = [57454]; //Compressor 5 Suction Pressure
	public expansionValve5 = [57456]; //Expansion Valve 5
	public hotGas5 = [57458]; //Hot Gas 5
	public suctionTemp5 = [57460]; //Suction Temp 5
	public superHeat5 = [57462]; //Super Heat 5
	public heat5Status = [12539]; //Heat 5

	public compressor6HeadPressure = [57451]; //Compressor 6 Head Pressure
	public compressor6Status = [57453]; // Compressor 6 Status
	public compressor6SuctionPressure = [57455]; //Compressor 6 Suction Pressure
	public expansionValve6 = [57457]; //Expansion Valve 6
	public hotGas6 = [57459]; //Hot Gas 6
	public suctionTemp6 = [57461]; //Suction Temp 6
	public superHeat6 = [57463]; //Super Heat 6

	public condenserFan1 = [3818]; //Condenser Fan 1
	public condenserFan1Speed = [13827]; //Condenser Fan 1 Percentage
	public condenserFan2 = [3787]; //Condenser Fan 2
	public condenserFan2Speed = [13813]; //Condenser Fan 2 Percentage
	public condenserFan3 = [3845]; //Condenser Fan 3
	public condenserFan3Speed = [13981]; //Condenser Fan 3 Percentage
	public condenserFan4 = [56733]; //Condenser Fan 4
	public condenserFan4Speed = [13872]; //Condenser Fan 4 Percentage
	public condenserFan5 = [57464]; //Condenser Fan 5
	public condenserFan5Speed = [57466]; //Condenser Fan 5 Percentage
	public condenserFan6 = [57465]; //Condenser Fan 6
	public condenserFan6Speed = [57467]; //Condenser Fan 6 Percentage

	public ambientHumidity = [2263]; // Ambient Relative Humidity (rh %)
	public ambTemp = [4084]; // Ambient Temp
	public blowerSpeed = [2857]; // Blower Percentage
	public bridgeMode = [15220]; //Bridge Mode Selection
	public dischargeTemp = [2736]; // Discharge Temp
	public dischMode = [15215]; // disch Mode
	public dischPressure = [56565]; // Discharge Pressure
	public operationMode = [15219]; // Operation Mode
	public overnightMode = [3846]; // Overnight Mode
	public unitCurrent = [2257]; // Unit Current
	public unitStatus = [12374]; // Unit Status
	public deltaT = [57471]; //DeltaT- Abs diff between Amb & Disch Temp
	public bridgeAirTemp = [1835]; //Bridge Air Temp
	runtimeUpdateInterval: NodeJS.Timeout;

	private allStdIds = [
		3848, 3967, 3912, 13917, 56880, 15458, 56891, 3909, 3598, 3913, 13933,
		12576, 15397, 56892, 3910, 3968, 1872, 13901, 56896, 56895, 56893,
		12542, 3969, 56746, 13860, 56577, 56749, 56894, 57450, 57452, 57454,
		57456, 57458, 57460, 57462, 57451, 57453, 57455, 57457, 57459, 57461,
		57463, 3818, 13827, 3787, 13813, 3845, 13981, 56733, 13872, 57464,
		57466, 57465, 57467, 2263, 4084, 2857, 15220, 2736, 15215, 56565, 15219,
		3846, 2257, 12374, 57471, 1835, 4704, 3883, 3884, 3885, 12539,
	];
	colorChangedSubscription: any;
	cacheJbtStandardObsLookup: any;
	cacheTagLookup: any;
	signalRTagUpdateSubscription: any;
	subscriptions: Subscription[] = [];
	tooltipInformationObject: any = {};
	signalRAssetGroupUpdateSubscription: any;
	public guid: string;

	constructor(
		public dataService: DataService,
		private signalRCore: SignalRCoreService,
		public dialog: MatDialog,
		private dashboardService: DashboardService,
		private clipboardApi: ClipboardService,
		private utilityService: UtilityService,
		private zone: NgZone
	) {
		this.swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: 'btn btn-success',
				cancelButton: 'btn btn-danger',
			},
			buttonsStyling: false,
		});
	}

	ngOnInit() {
		this.guid = this.dataService.guid();
		this.isDataLoading = true;
		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						if (Global.Theme === 'light') {
							this.theme = 'light';
						} else if (Global.Theme === 'dark') {
							this.theme = 'dark';
						}
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			if (Global.Theme === 'light') {
				this.theme = 'light';
			} else if (Global.Theme === 'dark') {
				this.theme = 'dark';
			}
			this.initialize();
		}

		this.colorChangedSubscription =
			this.dataService.colorChanged$.subscribe((theme: any) => {
				this.theme = theme;
			});

		
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + "ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);

		if (this.colorChangedSubscription) {
			this.colorChangedSubscription.unsubscribe();
		}
		if (this.runtimeUpdateInterval) {
			clearInterval(this.runtimeUpdateInterval);
		}
		if (this.tagGraphSingleModalSubscription !== undefined) {
			this.tagGraphSingleModalSubscription.unsubscribe();
		}
	}

	switchView(view: any) {
		this.viewHeatModule = view;
	}
	// [matTooltipPosition]="'above'" [matTooltip]="tooltipInformation('dischargeTemp')"
	tooltipInformation(controllerName) {
		this.tooltipInformationObject = {
			title: '',
			TagId: '',
			TagName: '',
			ListOfStdIdsUsed: this[controllerName],
			StdId: '',
			StdName: '',
			Value: '',
			ValueWhenActive: '',
			Datetime: ''
		};
		if (Global.User.DebugMode) {
			let foundTag = this.assetTags.find((t) =>
				this[controllerName].includes(
					parseInt(t.JBTStandardObservationId)
				)
			);

			if (foundTag !== undefined) {
				var actualTag = this.dataService.cache.tagsObject[foundTag.TagId];
				this.tooltipInformationObject = {
					title: foundTag.Name,
					TagId: foundTag.TagId,
					TagName: foundTag.TagName,
					ListOfStdIdsUsed: this[controllerName],
					StdId: foundTag.JBTStandardObservationId + ": " + foundTag.Name,
					StdName: foundTag.Name,
					Value: foundTag.Value,
					ValueWhenActive: foundTag.ValueWhenActive,
					Datetime: actualTag.JBTStandardObservationId == 12374 ? actualTag.DateOfLastChangedValueInMS : actualTag.DateInMilliseconds //-- Unit On should have the date/time when the unit was initially turned on, not the latest date/time. --Kirk T. Sherer, October 16, 2024.
				};

				// return (
				// 	'TagId: ' +
				// 	foundTag.TagId +
				// 	'TagName: ' +
				// 	foundTag.TagName +
				// 	' JBTSTDID: ' +
				// 	foundTag.JBTStandardObservationId +
				// 	' JBTStdName: ' +
				// 	foundTag.Name +
				// 	' Value: ' +
				// 	foundTag.Value +
				// 	' ValueWhenActive: ' +
				// 	foundTag.ValueWhenActive
				// );
			}
		}

		// let foundTag = this.assetTags.find(t => {
		// 	return this.[controllerName] === true;
		// })
		// if (Global.User.DebugMode) {
		// 	for (const key in this
		// 		.ObservationIdMappingObjectForTrueFalseValues) {
		// 		if (key == controllerName) {
		// 			let foundTag = this.assetTags.find((t) =>
		// 				this.ObservationIdMappingObjectForTrueFalseValues[
		// 					key
		// 				].includes(parseInt(t.JBTStandardObservationId))
		// 			);
		// 			if (foundTag !== undefined) {
		// 				return (
		// 					'TagId: ' +
		// 					foundTag.TagId +
		// 					'TagName: ' +
		// 					foundTag.TagName +
		// 					' JBTSTDID: ' +
		// 					foundTag.JBTStandardObservationId +
		// 					' JBTStdName: ' +
		// 					foundTag.Name +
		// 					' Value: ' +
		// 					foundTag.Value +
		// 					' ValueWhenActive: ' +
		// 					foundTag.ValueWhenActive
		// 				);
		// 			}
		// 		}
		// 	}

		// 	for (const key in this
		// 		.ObservationIdMappingObjectForParseFloatValues) {
		// 		if (key == controllerName) {
		// 			let foundTag = this.assetTags.find((t) =>
		// 				this.ObservationIdMappingObjectForParseFloatValues[
		// 					key
		// 				].includes(parseInt(t.JBTStandardObservationId))
		// 			);
		// 			if (foundTag !== undefined) {
		// 				return (
		// 					'TagId: ' +
		// 					foundTag.TagId +
		// 					'TagName: ' +
		// 					foundTag.TagName +
		// 					' JBTSTDID: ' +
		// 					foundTag.JBTStandardObservationId +
		// 					' JBTStdName: ' +
		// 					foundTag.Name +
		// 					' Value: ' +
		// 					foundTag.Value +
		// 					' ValueWhenActive: ' +
		// 					foundTag.ValueWhenActive
		// 				);
		// 			}
		// 		}
		// 	}
		// }
	}

	onRightClick(event, controllerName) {
		console.log('right click');
		event.preventDefault();
		if (this.tooltipInformationObject.TagId !== '') {
			let stringToReturn =
				'TagId: ' +
				this.tooltipInformationObject.TagId +
				' TagName: ' +
				this.tooltipInformationObject.TagName +
				' ListOfStdIdsUsed: ' +
				this.tooltipInformationObject.ListOfStdIdsUsed +
				' StdId: ' +
				this.tooltipInformationObject.StdId + ': ' + this.tooltipInformationObject.StdName +
				' Datetime: ' +
				this.tooltipInformationObject.Datetime +
				' Value: ' +
				this.tooltipInformationObject.Value +
				' ValueWhenActive: ' +
				this.tooltipInformationObject.ValueWhenActive;
			this.clipboardApi.copyFromContent(stringToReturn);
			this.utilityService.showToastMessageShared({
				type: 'success',
				message: 'Text Copied to Clipboard.',
				title: 'Copied',
			});
		}
	}

	initialize() {
		console.log('Getting tags....');
		this.cacheJbtStandardObsLookup =
			this.dataService.cache.jbtStandardObservationsObject;
		this.cacheTagLookup = this.dataService.cache.tagsObject;
		this.dataService
			.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
				this.parentAsset.Id.toString(),
				false,
				this.allStdIds.toString()
			)
			.subscribe((data) => {
				Global.User.DebugMode &&
					console.log(
						': dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds data = %O',
						data
					);
				this.dataForDisplay(data);
				this.isDataLoading = false;
				this.getSignalRUpdates();
			});
	}

	returnBackgroundColorBasedOffValue(value: any) {
		let progressStylesBasedOffValue: { [key: string]: any } = {
			background: '',
		};
		if (value < 36) progressStylesBasedOffValue.background = 'darkgrey';
		else if (value >= 36 && value <= 650)
			progressStylesBasedOffValue.background = 'limegreen';
		else if (value > 650) progressStylesBasedOffValue.background = 'red';
		return progressStylesBasedOffValue;
	}

	dataForDisplay(data: any) {
		this.assetTags = data.map((t) => ({
			TagId: t.Id != undefined ? t.Id : t.TagId,
			Name:
				t.JBTStandardObservation?.Name != undefined
					? t.JBTStandardObservation?.Name
					: this.cacheJbtStandardObsLookup[
							parseInt(t.JBTStandardObservationId)
					  ]?.Name != undefined
					? this.cacheJbtStandardObsLookup[
							parseInt(t.JBTStandardObservationId)
					  ]?.Name
					: this.cacheTagLookup[t.Id != undefined ? t.Id : t.TagId]
							?.ShortTagName,
			JBTStandardObservationId: parseInt(t.JBTStandardObservationId),
			JavascriptDate: new Date(t.DateInMilliseconds),
			Value: t.Value != undefined ? t.Value : t.LastObservationTextValue,
			ValueWhenActive: t.ValueWhenActive,
			TagName: t.Name,
		}));

		let UnitStatus = this.assetTags.find((t) =>
			this.unitStatus.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.UnitStatus =
			this.evaluateTagValueVsValueWhenActive(UnitStatus);

		let BridgeMode = this.assetTags.find((t) =>
			this.bridgeMode.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.BridgeMode =
			this.evaluateTagValueVsValueWhenActive(BridgeMode);
		if (this.assetTags.BridgeMode != undefined)
			this.assetTags.OutputStatus =
				this.assetTags.UnitStatus && this.assetTags.BridgeMode
					? 'Bridge'
					: 'Aircraft';

		let DischargeTemp = this.assetTags.find((t) =>
			this.dischargeTemp.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.DischargeTemp =
			this.evaluateTagValueForParseFloat(DischargeTemp);
		let AmbTemp = this.assetTags.find((t) =>
			this.ambTemp.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.AmbTemp = this.evaluateTagValueForParseFloat(AmbTemp);
		let DeltaT = this.assetTags.find((t) =>
			this.deltaT.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.DeltaT = this.evaluateTagValueForParseFloat(DeltaT);

		let AmbientHumidity = this.assetTags.find((t) =>
			this.ambientHumidity.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.AmbientHumidity =
			this.evaluateTagValueForParseFloat(AmbientHumidity);
		let DischMode = this.assetTags.find((t) =>
			this.dischMode.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.DischMode =
			DischMode == undefined
				? undefined
				: DischMode.Value == '1'
				? 'Commuter'
				: DischMode.Value == '2'
				? 'Narrow'
				: DischMode.Value == '3'
				? 'Wide'
				: DischMode.Value == '4'
				? 'Jumbo'
				: 'Aircraft';
		let OperationMode = this.assetTags.find((t) =>
			this.operationMode.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.OperationMode =
			OperationMode == undefined
				? undefined
				: OperationMode.Value == '1'
				? 'Auto'
				: OperationMode.Value == '2'
				? 'Cool'
				: OperationMode.Value == '3'
				? 'Vent'
				: OperationMode.Value == '4'
				? 'Heat'
				: 'Auto';
		this.viewHeatModule =
			this.assetTags.OperationMode != 'Heat' ? false : true;
		let OvernightMode = this.assetTags.find((t) =>
			this.overnightMode.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.OvernightMode =
			OvernightMode == undefined
				? undefined
				: OvernightMode.Value == '1'
				? 'On'
				: 'Off';
		let UnitCurrent = this.assetTags.find((t) =>
			this.unitCurrent.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.UnitCurrent =
			this.evaluateTagValueForParseFloat(UnitCurrent);
		let DischPressure = this.assetTags.find((t) =>
			this.dischPressure.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.DischPressure =
			this.evaluateTagValueForParseFloat(DischPressure);
		let BlowerSpeed = this.assetTags.find((t) =>
			this.blowerSpeed.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.BlowerSpeed =
			this.evaluateTagValueForParseFloat(BlowerSpeed);
		let BridgeAirTemp = this.assetTags.find((t) =>
			this.bridgeAirTemp.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.BridgeAirTemp =
			this.evaluateTagValueForParseFloat(BridgeAirTemp);

		//Condensor Fans
		let CondenserFan1Status = this.assetTags.find((t) =>
			this.condenserFan1.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.CondenserFan1Status =
			this.evaluateTagValueVsValueWhenActive(CondenserFan1Status);

		let CondenserFan1Speed = this.assetTags.find((t) =>
			this.condenserFan1Speed.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		this.assetTags.CondenserFan1Speed =
			this.evaluateTagValueForParseFloat(CondenserFan1Speed);

		let CondenserFan2Status = this.assetTags.find((t) =>
			this.condenserFan2.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.CondenserFan2Status =
			this.evaluateTagValueVsValueWhenActive(CondenserFan2Status);

		let CondenserFan2Speed = this.assetTags.find((t) =>
			this.condenserFan2Speed.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		this.assetTags.CondenserFan2Speed =
			this.evaluateTagValueForParseFloat(CondenserFan2Speed);

		let CondenserFan3Status = this.assetTags.find((t) =>
			this.condenserFan3.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.CondenserFan3Status =
			this.evaluateTagValueVsValueWhenActive(CondenserFan3Status);

		let CondenserFan3Speed = this.assetTags.find((t) =>
			this.condenserFan3Speed.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		this.assetTags.CondenserFan3Speed =
			this.evaluateTagValueForParseFloat(CondenserFan3Speed);

		let CondenserFan4Status = this.assetTags.find((t) =>
			this.condenserFan4.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.CondenserFan4Status =
			this.evaluateTagValueVsValueWhenActive(CondenserFan4Status);

		let CondenserFan4Speed = this.assetTags.find((t) =>
			this.condenserFan4Speed.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		this.assetTags.CondenserFan4Speed =
			this.evaluateTagValueForParseFloat(CondenserFan4Speed);

		let CondenserFan5Status = this.assetTags.find((t) =>
			this.condenserFan5.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.CondenserFan5Status =
			this.evaluateTagValueVsValueWhenActive(CondenserFan5Status);

		let CondenserFan5Speed = this.assetTags.find((t) =>
			this.condenserFan5Speed.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		this.assetTags.CondenserFan5Speed =
			this.evaluateTagValueForParseFloat(CondenserFan5Speed);

		let CondenserFan6Status = this.assetTags.find((t) =>
			this.condenserFan6.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.CondenserFan6Status =
			this.evaluateTagValueVsValueWhenActive(CondenserFan6Status);

		let CondenserFan6Speed = this.assetTags.find((t) =>
			this.condenserFan6Speed.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		this.assetTags.CondenserFan6Speed =
			this.evaluateTagValueForParseFloat(CondenserFan6Speed);

		//Suction Temp
		let SuctionTemp1 = this.assetTags.find((t) =>
			this.suctionTemp1.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.SuctionTemp1 =
			this.evaluateTagValueForParseFloat(SuctionTemp1);
		let SuctionTemp2 = this.assetTags.find((t) =>
			this.suctionTemp2.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.SuctionTemp2 =
			this.evaluateTagValueForParseFloat(SuctionTemp2);
		let SuctionTemp3 = this.assetTags.find((t) =>
			this.suctionTemp3.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.SuctionTemp3 =
			this.evaluateTagValueForParseFloat(SuctionTemp3);
		let SuctionTemp4 = this.assetTags.find((t) =>
			this.suctionTemp4.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.SuctionTemp4 =
			this.evaluateTagValueForParseFloat(SuctionTemp4);
		let SuctionTemp5 = this.assetTags.find((t) =>
			this.suctionTemp5.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.SuctionTemp5 =
			this.evaluateTagValueForParseFloat(SuctionTemp5);
		let SuctionTemp6 = this.assetTags.find((t) =>
			this.suctionTemp6.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.SuctionTemp6 =
			this.evaluateTagValueForParseFloat(SuctionTemp6);

		//Hot Gas
		let HotGas1 = this.assetTags.find((t) =>
			this.hotGas1.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.HotGas1 = this.evaluateTagValueForParseFloat(HotGas1);
		let HotGas2 = this.assetTags.find((t) =>
			this.hotGas2.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.HotGas2 = this.evaluateTagValueForParseFloat(HotGas2);
		let HotGas3 = this.assetTags.find((t) =>
			this.hotGas3.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.HotGas3 = this.evaluateTagValueForParseFloat(HotGas3);
		let HotGas4 = this.assetTags.find((t) =>
			this.hotGas4.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.HotGas4 = this.evaluateTagValueForParseFloat(HotGas4);
		let HotGas5 = this.assetTags.find((t) =>
			this.hotGas5.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.HotGas5 = this.evaluateTagValueForParseFloat(HotGas5);
		let HotGas6 = this.assetTags.find((t) =>
			this.hotGas6.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.HotGas6 = this.evaluateTagValueForParseFloat(HotGas6);

		//Expansion Valve
		let ExpansionValve1 = this.assetTags.find((t) =>
			this.expansionValve1.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.ExpansionValve1 =
			this.evaluateTagValueForParseFloat(ExpansionValve1);
		let ExpansionValve2 = this.assetTags.find((t) =>
			this.expansionValve2.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.ExpansionValve2 =
			this.evaluateTagValueForParseFloat(ExpansionValve2);
		let ExpansionValve3 = this.assetTags.find((t) =>
			this.expansionValve3.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.ExpansionValve3 =
			this.evaluateTagValueForParseFloat(ExpansionValve3);
		let ExpansionValve4 = this.assetTags.find((t) =>
			this.expansionValve4.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.ExpansionValve4 =
			this.evaluateTagValueForParseFloat(ExpansionValve4);
		let ExpansionValve5 = this.assetTags.find((t) =>
			this.expansionValve5.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.ExpansionValve5 =
			this.evaluateTagValueForParseFloat(ExpansionValve5);
		let ExpansionValve6 = this.assetTags.find((t) =>
			this.expansionValve6.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.ExpansionValve6 =
			this.evaluateTagValueForParseFloat(ExpansionValve6);

		//Super Heat
		let SuperHeat1 = this.assetTags.find((t) =>
			this.superHeat1.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.SuperHeat1 =
			this.evaluateTagValueForParseFloat(SuperHeat1);
		let SuperHeat2 = this.assetTags.find((t) =>
			this.superHeat2.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.SuperHeat2 =
			this.evaluateTagValueForParseFloat(SuperHeat2);
		let SuperHeat3 = this.assetTags.find((t) =>
			this.superHeat3.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.SuperHeat3 =
			this.evaluateTagValueForParseFloat(SuperHeat3);
		let SuperHeat4 = this.assetTags.find((t) =>
			this.superHeat4.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.SuperHeat4 =
			this.evaluateTagValueForParseFloat(SuperHeat4);
		let SuperHeat5 = this.assetTags.find((t) =>
			this.superHeat5.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.SuperHeat5 =
			this.evaluateTagValueForParseFloat(SuperHeat5);
		let SuperHeat6 = this.assetTags.find((t) =>
			this.superHeat6.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.SuperHeat6 =
			this.evaluateTagValueForParseFloat(SuperHeat6);

		//Get Run Time Data if the Unit is On
		if (this.assetTags.UnitStatus) {
			this.getRuntimeData();
			this.assetTags.Unit = 'RUNNING';
			this.runtimeUpdateInterval = setInterval(() => {
				// Update the runtime values every minute
				this.updateRuntimes();
			}, 100);
		} else {
			this.assetTags.Unit = 'OFF';
			this.assetTags.RunTime = ' ';
		}

		this.createSparkLineChartForDischTemp(this.parentAsset.Id);

		//Compressor Pressures
		let compressor1HeadPressure = this.assetTags.find((t) =>
			this.compressor1HeadPressure.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		if (compressor1HeadPressure != undefined) {
			this.assetTags.Compressor1HeadPressure = parseFloat(
				compressor1HeadPressure.Value
			).toFixed(1);
			this.assetTags.Compressor1HeadPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor1HeadPressure
				);
		} else this.assetTags.Compressor1HeadPressure = undefined;

		let compressor1SuctionPressure = this.assetTags.find((t) =>
			this.compressor1SuctionPressure.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		if (compressor1SuctionPressure != undefined) {
			this.assetTags.Compressor1SuctionPressure = parseFloat(
				compressor1SuctionPressure.Value
			).toFixed(1);
			this.assetTags.Compressor1SuctionPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor1SuctionPressure
				);
		} else this.assetTags.Compressor1SuctionPressure = undefined;

		let compressor2HeadPressure = this.assetTags.find((t) =>
			this.compressor2HeadPressure.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		if (compressor2HeadPressure != undefined) {
			this.assetTags.Compressor2HeadPressure = parseFloat(
				compressor2HeadPressure.Value
			).toFixed(1);
			this.assetTags.Compressor2HeadPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor2HeadPressure
				);
		} else this.assetTags.Compressor2HeadPressure = undefined;

		let compressor2SuctionPressure = this.assetTags.find((t) =>
			this.compressor2SuctionPressure.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		if (compressor2SuctionPressure != undefined) {
			this.assetTags.Compressor2SuctionPressure = parseFloat(
				compressor2SuctionPressure.Value
			).toFixed(1);
			this.assetTags.Compressor2SuctionPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor2SuctionPressure
				);
		} else this.assetTags.Compressor2SuctionPressure = undefined;

		let compressor3HeadPressure = this.assetTags.find((t) =>
			this.compressor3HeadPressure.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		if (compressor3HeadPressure != undefined) {
			this.assetTags.Compressor3HeadPressure = parseFloat(
				compressor3HeadPressure.Value
			).toFixed(1);
			this.assetTags.Compressor3HeadPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor3HeadPressure
				);
		} else this.assetTags.Compressor3HeadPressure = undefined;

		let compressor3SuctionPressure = this.assetTags.find((t) =>
			this.compressor3SuctionPressure.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		if (compressor3SuctionPressure != undefined) {
			this.assetTags.Compressor3SuctionPressure = parseFloat(
				compressor3SuctionPressure.Value
			).toFixed(1);
			this.assetTags.Compressor3SuctionPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor3SuctionPressure
				);
		} else this.assetTags.Compressor3SuctionPressure = undefined;

		let compressor4HeadPressure = this.assetTags.find((t) =>
			this.compressor4HeadPressure.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		if (compressor4HeadPressure != undefined) {
			this.assetTags.Compressor4HeadPressure = parseFloat(
				compressor4HeadPressure.Value
			).toFixed(1);
			this.assetTags.Compressor4HeadPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor4HeadPressure
				);
		} else this.assetTags.Compressor4HeadPressure = undefined;

		let compressor4SuctionPressure = this.assetTags.find((t) =>
			this.compressor4SuctionPressure.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		if (compressor4SuctionPressure != undefined) {
			this.assetTags.Compressor4SuctionPressure = parseFloat(
				compressor4SuctionPressure.Value
			).toFixed(1);
			this.assetTags.Compressor4SuctionPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor4SuctionPressure
				);
		} else this.assetTags.Compressor4SuctionPressure = undefined;

		let compressor5HeadPressure = this.assetTags.find((t) =>
			this.compressor5HeadPressure.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		if (compressor5HeadPressure != undefined) {
			this.assetTags.Compressor5HeadPressure = parseFloat(
				compressor5HeadPressure.Value
			).toFixed(1);
			this.assetTags.Compressor5HeadPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor5HeadPressure
				);
		} else this.assetTags.Compressor5HeadPressure = undefined;

		let compressor5SuctionPressure = this.assetTags.find((t) =>
			this.compressor5SuctionPressure.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		if (compressor5SuctionPressure != undefined) {
			this.assetTags.Compressor5SuctionPressure = parseFloat(
				compressor5SuctionPressure.Value
			).toFixed(1);
			this.assetTags.Compressor5SuctionPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor5SuctionPressure
				);
		} else this.assetTags.Compressor5SuctionPressure = undefined;

		let compressor6HeadPressure = this.assetTags.find((t) =>
			this.compressor6HeadPressure.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		if (compressor5HeadPressure != undefined) {
			this.assetTags.Compressor6HeadPressure = parseFloat(
				compressor6HeadPressure.Value
			).toFixed(1);
			this.assetTags.Compressor6HeadPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor6HeadPressure
				);
		} else this.assetTags.Compressor6HeadPressure = undefined;

		let compressor6SuctionPressure = this.assetTags.find((t) =>
			this.compressor6SuctionPressure.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		if (compressor6SuctionPressure != undefined) {
			this.assetTags.Compressor6SuctionPressure = parseFloat(
				compressor6SuctionPressure.Value
			).toFixed(1);
			this.assetTags.Compressor6SuctionPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor6SuctionPressure
				);
		} else this.assetTags.Compressor6SuctionPressure = undefined;

		//HeatStatus
		let Heat1Status = this.assetTags.find((t) =>
			this.heat1Status.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.Heat1Status =
			Heat1Status == undefined
				? undefined
				: Heat1Status.Value == '1'
				? true
				: false;

		let Heat2Status = this.assetTags.find((t) =>
			this.heat2Status.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.Heat2Status =
			Heat2Status == undefined
				? undefined
				: Heat2Status.Value == '1'
				? true
				: false;

		let Heat3Status = this.assetTags.find((t) =>
			this.heat3Status.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.Heat3Status =
			Heat3Status == undefined
				? undefined
				: Heat3Status.Value == '1'
				? true
				: false;

		let Heat4Status = this.assetTags.find((t) =>
			this.heat4Status.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.Heat4Status =
			Heat4Status == undefined
				? undefined
				: Heat4Status.Value == '1'
				? true
				: false;

		let Heat5Status = this.assetTags.find((t) =>
			this.heat5Status.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.Heat5Status =
			Heat5Status == undefined
				? undefined
				: Heat5Status.Value == '1'
				? true
				: false;

		//Compressor Status
		let Compressor1Status = this.assetTags.find((t) =>
			this.compressor1Status.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		this.assetTags.Compressor1Status =
			Compressor1Status == undefined
				? this.assetTags.Compressor1SuctionPressure !== undefined ||
				  this.assetTags.Compressor1HeadPressure !== undefined
					? false
					: undefined
				: Compressor1Status.Value == '1'
				? true
				: false;
		let Compressor2Status = this.assetTags.find((t) =>
			this.compressor2Status.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		this.assetTags.Compressor2Status =
			Compressor2Status == undefined
				? this.assetTags.Compressor2SuctionPressure !== undefined ||
				  this.assetTags.Compressor2HeadPressure !== undefined
					? false
					: undefined
				: Compressor2Status.Value == '1'
				? true
				: false;
		let Compressor3Status = this.assetTags.find((t) =>
			this.compressor3Status.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		this.assetTags.Compressor3Status =
			Compressor3Status == undefined
				? this.assetTags.Compressor3SuctionPressure !== undefined ||
				  this.assetTags.Compressor3HeadPressure !== undefined
					? false
					: undefined
				: Compressor3Status.Value == '1'
				? true
				: false;
		let Compressor4Status = this.assetTags.find((t) =>
			this.compressor4Status.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		this.assetTags.Compressor4Status =
			Compressor4Status == undefined
				? this.assetTags.Compressor4SuctionPressure !== undefined ||
				  this.assetTags.Compressor4HeadPressure !== undefined
					? false
					: undefined
				: Compressor4Status.Value == '1'
				? true
				: false;
		let Compressor5Status = this.assetTags.find((t) =>
			this.compressor5Status.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		this.assetTags.Compressor5Status =
			Compressor5Status == undefined
				? this.assetTags.Compressor5SuctionPressure !== undefined ||
				  this.assetTags.Compressor5HeadPressure !== undefined
					? false
					: undefined
				: Compressor5Status.Value == '1'
				? true
				: false;
		let Compressor6Status = this.assetTags.find((t) =>
			this.compressor6Status.includes(
				parseInt(t.JBTStandardObservationId)
			)
		);
		this.assetTags.Compressor6Status =
			Compressor6Status == undefined
				? this.assetTags.Compressor6SuctionPressure !== undefined ||
				  this.assetTags.Compressor6HeadPressure !== undefined
					? false
					: undefined
				: Compressor6Status.Value == '1'
				? true
				: false;
	}

	dataUpdate(dataUpdateTagObj: any) {
		//Update Unit Status
		if (
			this.unitStatus.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		) {
			this.assetTags.UnitStatus =
				dataUpdateTagObj.Value == '1' ? true : false;
			if (this.assetTags.BridgeMode != undefined)
				this.assetTags.OutputStatus =
					this.assetTags.UnitStatus && this.assetTags.BridgeMode
						? 'Bridge'
						: 'Aircraft';
			if (this.assetTags.UnitStatus) {
				this.getRuntimeData();
				this.assetTags.Unit = 'RUNNING';
				this.runtimeUpdateInterval = setInterval(() => {
					// Update the runtime values every minute
					this.updateRuntimes();
				}, 100);
			} else {
				this.assetTags.Unit = 'OFF';
				this.assetTags.RunTime = ' ';
			}
		}

		//Update SparkLineChart For DischTemp
		if (
			this.dischargeTemp.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		) {
			this.assetTags.DischargeTemp =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
			let color = this.assetTags.pcaDischTempChart[0].color;
			let tempArray = this.assetTags.pcaDischTempChart[0].data.slice(0);
			let chartDataPoint = {
				y: dataUpdateTagObj.Value,
				obsTimeFormatted: moment(
					dataUpdateTagObj.DateInMilliseconds
				).format('HH:mm:ss'),
				obsTimeMS: dataUpdateTagObj.DateInMilliseconds,
			};
			tempArray.push(chartDataPoint);
			this.assetTags.pcaDischTempChart = [
				{ field: 'y', width: 1.5, color: color, data: tempArray },
			];
		}
		if (
			this.bridgeMode.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		) {
			this.assetTags.BridgeMode =
				this.evaluateTagValueVsValueWhenActive(dataUpdateTagObj);
			this.assetTags.OutputStatus =
				this.assetTags.UnitStatus && this.assetTags.BridgeMode
					? 'Bridge'
					: 'Aircraft';
		}
		if (
			this.ambTemp.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.AmbTemp =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.deltaT.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.DeltaT =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.ambientHumidity.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.AmbientHumidity =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.dischMode.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.DischMode =
				dataUpdateTagObj.Value == '1'
					? 'Commuter'
					: dataUpdateTagObj.Value == '2'
					? 'Narrow'
					: dataUpdateTagObj.Value == '3'
					? 'Wide'
					: dataUpdateTagObj.Value == '4'
					? 'Jumbo'
					: 'Aircraft';
		if (
			this.operationMode.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.OperationMode =
				dataUpdateTagObj.Value == '1'
					? 'Auto'
					: dataUpdateTagObj.Value == '2'
					? 'Cool'
					: dataUpdateTagObj.Value == '3'
					? 'Vent'
					: dataUpdateTagObj.Value == '4'
					? 'Heat'
					: 'Auto';
		if (
			this.overnightMode.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.OvernightMode =
				dataUpdateTagObj.Value == '1' ? 'On' : 'Off';
		if (
			this.unitCurrent.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.UnitCurrent =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.dischPressure.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.DischPressure =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.blowerSpeed.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.BlowerSpeed =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.bridgeAirTemp.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.BridgeAirTemp =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		//Update Haet Status
		if (
			this.heat1Status.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.Heat1Status =
				this.evaluateTagValueVsValueWhenActive(dataUpdateTagObj);
		if (
			this.heat2Status.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.Heat2Status =
				this.evaluateTagValueVsValueWhenActive(dataUpdateTagObj);
		if (
			this.heat3Status.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.Heat3Status =
				this.evaluateTagValueVsValueWhenActive(dataUpdateTagObj);
		if (
			this.heat4Status.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.Heat4Status =
				this.evaluateTagValueVsValueWhenActive(dataUpdateTagObj);
		if (
			this.heat5Status.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.Heat5Status =
				this.evaluateTagValueVsValueWhenActive(dataUpdateTagObj);
		//Update Compressor Status
		if (
			this.compressor1Status.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.Compressor1Status =
				this.evaluateTagValueVsValueWhenActive(dataUpdateTagObj);
		if (
			this.compressor2Status.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.Compressor2Status =
				this.evaluateTagValueVsValueWhenActive(dataUpdateTagObj);
		if (
			this.compressor3Status.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.Compressor3Status =
				this.evaluateTagValueVsValueWhenActive(dataUpdateTagObj);
		if (
			this.compressor4Status.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.Compressor4Status =
				this.evaluateTagValueVsValueWhenActive(dataUpdateTagObj);
		if (
			this.compressor5Status.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.Compressor5Status =
				this.evaluateTagValueVsValueWhenActive(dataUpdateTagObj);
		if (
			this.compressor6Status.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.Compressor6Status =
				this.evaluateTagValueVsValueWhenActive(dataUpdateTagObj);
		//Update Condensor Fans
		if (
			this.condenserFan1.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.CondenserFan1Status =
				this.evaluateTagValueVsValueWhenActive(dataUpdateTagObj);
		if (
			this.condenserFan1Speed.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.CondenserFan1Speed =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.condenserFan2.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.CondenserFan2Status =
				this.evaluateTagValueVsValueWhenActive(dataUpdateTagObj);
		if (
			this.condenserFan2Speed.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.CondenserFan2Speed =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.condenserFan3.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.CondenserFan3Status =
				this.evaluateTagValueVsValueWhenActive(dataUpdateTagObj);
		if (
			this.condenserFan3Speed.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.CondenserFan3Speed =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.condenserFan4.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.CondenserFan4Status =
				this.evaluateTagValueVsValueWhenActive(dataUpdateTagObj);
		if (
			this.condenserFan4Speed.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.CondenserFan4Speed =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.condenserFan5.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.CondenserFan5Status =
				this.evaluateTagValueVsValueWhenActive(dataUpdateTagObj);
		if (
			this.condenserFan5Speed.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.CondenserFan5Speed =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.condenserFan6.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.CondenserFan6Status =
				this.evaluateTagValueVsValueWhenActive(dataUpdateTagObj);
		if (
			this.condenserFan6Speed.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.CondenserFan6Speed =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		//Update Suction Temp
		if (
			this.suctionTemp1.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.SuctionTemp1 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.suctionTemp2.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.SuctionTemp2 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.suctionTemp3.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.SuctionTemp3 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.suctionTemp4.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.SuctionTemp4 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.suctionTemp5.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.SuctionTemp5 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.suctionTemp6.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.SuctionTemp6 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		//Update Hot Gas
		if (
			this.hotGas1.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.HotGas1 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.hotGas2.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.HotGas2 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.hotGas3.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.HotGas3 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.hotGas4.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.HotGas4 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.hotGas5.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.HotGas5 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.hotGas6.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.HotGas6 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		//Update Expansion Valve
		if (
			this.expansionValve1.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.ExpansionValve1 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.expansionValve2.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.ExpansionValve2 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.expansionValve3.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.ExpansionValve3 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.expansionValve4.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.ExpansionValve4 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.expansionValve5.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.ExpansionValve5 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.expansionValve6.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.ExpansionValve6 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		//Update Super Heat
		if (
			this.superHeat1.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.SuperHeat1 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.superHeat2.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.SuperHeat2 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.superHeat3.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.SuperHeat3 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.superHeat4.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.SuperHeat4 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.superHeat5.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.SuperHeat5 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		if (
			this.superHeat6.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		)
			this.assetTags.SuperHeat6 =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
		//Update Pressures
		if (
			this.compressor1HeadPressure.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		) {
			this.assetTags.Compressor1HeadPressure =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
			this.assetTags.Compressor1HeadPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor1HeadPressure
				);
		}
		if (
			this.compressor1SuctionPressure.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		) {
			this.assetTags.Compressor1SuctionPressure =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
			this.assetTags.Compressor1SuctionPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor1SuctionPressure
				);
		}
		if (
			this.compressor2HeadPressure.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		) {
			this.assetTags.Compressor2HeadPressure =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
			this.assetTags.Compressor2HeadPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor2HeadPressure
				);
		}
		if (
			this.compressor2SuctionPressure.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		) {
			this.assetTags.Compressor2SuctionPressure =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
			this.assetTags.Compressor2SuctionPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor2SuctionPressure
				);
		}
		if (
			this.compressor3HeadPressure.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		) {
			this.assetTags.Compressor3HeadPressure =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
			this.assetTags.Compressor3HeadPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor3HeadPressure
				);
		}
		if (
			this.compressor3SuctionPressure.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		) {
			this.assetTags.Compressor3SuctionPressure =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
			this.assetTags.Compressor3SuctionPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor3SuctionPressure
				);
		}
		if (
			this.compressor4HeadPressure.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		) {
			this.assetTags.Compressor4HeadPressure =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
			this.assetTags.Compressor4HeadPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor4HeadPressure
				);
		}
		if (
			this.compressor4SuctionPressure.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		) {
			this.assetTags.Compressor4SuctionPressure =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
			this.assetTags.Compressor4SuctionPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor4SuctionPressure
				);
		}
		if (
			this.compressor5HeadPressure.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		) {
			this.assetTags.Compressor5HeadPressure =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
			this.assetTags.Compressor5HeadPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor5HeadPressure
				);
		}
		if (
			this.compressor5SuctionPressure.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		) {
			this.assetTags.Compressor5SuctionPressure =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
			this.assetTags.Compressor5SuctionPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor5SuctionPressure
				);
		}
		if (
			this.compressor6HeadPressure.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		) {
			this.assetTags.Compressor6HeadPressure =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
			this.assetTags.Compressor6HeadPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor6HeadPressure
				);
		}
		if (
			this.compressor6SuctionPressure.includes(
				parseInt(dataUpdateTagObj.JBTStandardObservationId)
			)
		) {
			this.assetTags.Compressor6SuctionPressure =
				this.evaluateTagValueForParseFloat(dataUpdateTagObj);
			this.assetTags.Compressor6SuctionPressureColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.Compressor6SuctionPressure
				);
		}
	}

	evaluateTagValueVsValueWhenActive(tagObject) {
		if (_.isNil(tagObject)) {
			return false;
		} else if (!_.isNil(tagObject.ValueWhenActive)) {
			if (tagObject.Value == tagObject.ValueWhenActive) {
				return true;
			} else {
				return false;
			}
		} else {
			if (tagObject.Value == 1) {
				return true;
			} else {
				return false;
			}
		}
	}

	evaluateTagValueForParseFloat(tagObject) {
		if (_.isNil(tagObject)) {
			return '-';
		} else {
			return parseFloat(tagObject.Value).toFixed(1);
		}
	}

	openTagGraphSingle(observationIdArray): void {
		let tagObject = this.assetTags.find((t) =>
			observationIdArray.includes(parseInt(t.JBTStandardObservationId))
		);
		if (tagObject === undefined) {
			this.swalWithBootstrapButtons
				.fire({
					title: 'Error',
					text: "Tag Object Doesn't Exist",
					showCancelButton: false,
					confirmButtonText: 'Ok',
					reverseButtons: false,
				})
				.then(() => {});
		} else {
			if (this.widgetObject == undefined) {
				this.widgetObject = tagObject;
				this.widgetObject.TimeZoneId = null;
			}

			const tagGraphSingleModal = this.dialog.open(
				DialogModalParentComponent,
				{
					width: Global.isMobile ? '90%' : '60%',
					height: Global.isMobile ? '90%' : '80%',
					data: {
						graphId: uuidv4(),
						TagId: tagObject.TagId,
						widgetNameDisplay: 'Tag Graph',
						WidgetName: 'tag-graph',
						isDisplayDataLive: true,
						timeZoneType:
							this.dashboardService.determineTimeZoneType(
								this.widgetObject
							),
					},

					maxWidth: '100vw',
					maxHeight: '100vh',
				}
			);
			this.tagGraphSingleModalSubscription = tagGraphSingleModal
				.afterClosed()
				.subscribe((result) => {
					Global.User.DebugMode &&
						console.log('The modal was closed');
					this.tagGraphSingleModalSubscription.unsubscribe();
				});
		}
	}

	createSparkLineChartForDischTemp(assetId: any) {
		// Get Chart Data
		let statement = `GetSparklineDataByAssetAndJBTStandardObId @AssetIdList = '${assetId}', @JBTStandardObId = '${2736}'`;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			let assetData = data.filter((d) => d.AssetId == assetId);

			this.assetTags.pcaDischTempChart = [
				{
					field: 'y',
					width: 1.5,
					color:
						this.assetTags.UnitStatus != undefined &&
						this.assetTags.UnitStatus
							? 'limegreen'
							: 'darkgrey',
					data:
						assetData.length > 0
							? assetData.map((a) => ({
									y: parseFloat(
										parseFloat(a.TextValue).toFixed(1)
									),
									obsTimeFormatted: moment(a.DateInMS).format(
										'HH:mm:ss'
									),
									obsTimeMS: a.DateInMS,
							  }))
							: [],
				},
			];
		});
	}

	getRuntimeData() {
		var UnitOnTag: ITag = this.dataService.cache.assetsObject[this.parentAsset.Id].Tags.firstOrDefault((tag:ITag)=> { return tag.JBTStandardObservationId == 12374 });
		if (UnitOnTag == null) {
			// Get Runtime Data
			let statement = `GSGetRuntimeForAssetsByAssetId @assetId = '${this.parentAsset.Id}'`;
			this.dataService.SQLActionAsPromise(statement).then((data: any) => {
				Global.User.DebugMode &&
					console.log(statement + ' data = %O', data);
				this.assetTags.RunTime =
					data != undefined
						? Math.abs(
								moment
									.utc()
									.diff(moment.utc(data[0].UnitOnDate), 'minutes')
						) + ' min'
						: '';
				this.assetTags.PCAOnDate =
					data != undefined ? data[0].UnitOnDate : '';
			});
		}
		else {
			if (UnitOnTag.Value == 1) {
				//console.log("current UTC date: " + moment(new Date()) + ", moment.utc(): " + moment.utc() + ", Unit On Tag JavascriptDate: " + UnitOnTag.JavascriptDate + ", moment.utc(UnitOn.JavascriptDate): " + moment.utc(UnitOnTag.SiteDateFull));
				//this.assetTags.RunTime = Math.abs(moment.utc().diff(moment.utc(UnitOnTag.JavascriptDate), 'minutes')) + ' min';
				this.assetTags.RunTime = Math.abs(UnitOnTag.DurationInMS() / 60000).toFixed(2) + ' min';
				this.assetTags.PCAOnDate = UnitOnTag.DateOfLastChangedValueInMS;
			}
			else {
				this.assetTags.RunTime = "";
				this.assetTags.PCAOnDate = "";
			}
		}
	}

	updateRuntimes() {
		if (this.assetTags.UnitStatus) {
			var UnitOnTag: ITag = this.dataService.cache.assetsObject[this.parentAsset.Id].Tags.firstOrDefault((tag:ITag)=> { return tag.JBTStandardObservationId == 12374 });
			if (UnitOnTag != undefined) {
				this.assetTags.RunTime = Math.abs(UnitOnTag.DurationInMS() / 60000).toFixed(2) + ' min';
			}
		}
			// this.assetTags.RunTime =
			// 	Math.abs(
			// 		moment
			// 			.utc()
			// 			.diff(moment.utc(this.assetTags.PCAOnDate), 'minutes')
			// 	) + ' min';
	}

	getSignalRUpdates() {
		let assetObjectInCache =
			this.dataService.cache.assetsObject[this.parentAsset.Id];

		let tagNamePrefixesString = assetObjectInCache.TagNamePrefix;
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;




		
		this.signalRCore.joinGroups();

		if (this.widgetObject && this.widgetObject.WidgetId !== undefined) {
			this.widgetGroupSettings = {
				WidgetId: this.widgetObject.WidgetId,
				GroupList: tagNamePrefixesString,
				IsPopup: false
			};
		} else {
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: tagNamePrefixesString,
				IsPopup: true,
			};
		}

		Global.User.DebugMode && console.log(this.componentName + ": widgetGroupSettings = %O", this.widgetGroupSettings);

		this.dataService
			.createSubjectAndSubscribe({ Id: this.guid, 
										 WidgetName: "PCA Quick View", 
										 TagNamePrefix: [tagNamePrefixesString]  })
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);

				 var activeSubject = this.dataService.returnCorrectActiveSubject(this.guid); 
			
			     Global.User.DebugMode && console.log(this.componentName + "active subjects: %O", activeSubject);
			
			
				activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
					//console.log(this.componentName + "Updating tag we care about: %O", tag);
					this.dataUpdate(tag);
				});
			});
	}

}
