import { Component, OnInit, OnDestroy, Input, ViewChild, EventEmitter, ChangeDetectorRef, ViewEncapsulation, NgZone, AfterViewInit, ElementRef } from "@angular/core";

import { GridSettings } from "../../_models/grid-settings.interface";
import { filter } from "rxjs/operators";
import * as moment from "moment";
import _ from "lodash";
import { SassHelperComponent } from "../../_helpers/sass-helper/sass-helper.component";

import { Subscription } from "rxjs";
import { DataService } from "../../services/data.service";
import { SignalRCoreService } from "../../services/signalr-core.service";
import { DashboardService } from "../../services/dashboard.service";
import { UtilityService } from "../../services/utility.service";
import { Global } from "../../_constants/global.variables";
import * as $ from 'jquery';
import { IAsset } from "../../_models/asset.model";
import { IGlobal } from '../../_models/global.model';
import { ITag } from "../../_models/tag.model";
import { IObservation } from "../../_models/observation.model";

import {Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { animate, style, transition, trigger } from "@angular/animations";
import { DialogModalParentComponent } from "../dialog-modal-parent/dialog-modal-parent.component";
import { v4 as uuidv4 } from 'uuid';

declare var Highcharts: any;

export interface PerfectTurnDialogData {
	name: string;
	turn: any;
	pbb: any;
	gpu: any;
	gpuon: any;
	pca: any;
	siteAirUnitName: any;
	currentTurn: any;
	timers: any;
	dock: any;
	state: any;
	theme: any;
	headerLabel: any;
	headerValue: any;
	assetState: any;
	tags: any;
	widgetObject: any,
  }

  @Component({
	  selector: 'perfect-turn-popup-dialog',
	  templateUrl: './perfect-turn-popup-dialog.html',
	  styleUrls: ['./perfect-turn-popup-dialog.component.scss'],
	})
	export class PerfectTurnPopupDialogComponent {
	  private swalWithBootstrapButtons: any;
	  tagGraphSingleModalSubscription: Subscription;
	  constructor(
		  private elem: ElementRef,
		  public dialog: MatDialog,
		  private dashboardService: DashboardService,
		public dialogRef: MatDialogRef<PerfectTurnPopupDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: PerfectTurnDialogData) {}

		  ngOnInit() {
			  console.log("dialog data=", this.data);
		  }
	  openTagGraphSingle(observationIdArray): void {
		  let jbtStandardObsId = parseInt(observationIdArray);
		  let tagId = this.data.tags[jbtStandardObsId]?.Id;
		  if (tagId === undefined) {
			  this.swalWithBootstrapButtons
				  .fire({
					  title: 'Error',
					  text: "Tag Object Doesn't Exist",
					  showCancelButton: false,
					  confirmButtonText: 'Ok',
					  reverseButtons: false,
				  })
				  .then(() => { });
		  } else {
			  var bounds = this.elem.nativeElement.getBoundingClientRect();

			  let widgetObject = this.data.widgetObject;
			  const tagGraphSingleModal = this.dialog.open(
				  DialogModalParentComponent,
				  {
					  width: Global.isMobile ? '30%' : '30%',
					  height: Global.isMobile ? '30%' : '30%',
					  data: {
						graphId: uuidv4(),
						  TagId: tagId,
						  widgetNameDisplay: 'Tag Graph',
						  WidgetName: 'tag-graph',
						  isDisplayDataLive: true,
						  timeZoneType:
							  this.dashboardService.determineTimeZoneType(
								  widgetObject
							  ),
					  },

					  maxWidth: '100vw',
					  maxHeight: '100vh',
					  position: { left: bounds.left+'px', top: bounds.bottom+'px' },
				  }
			  );
			  this.tagGraphSingleModalSubscription = tagGraphSingleModal
				  .afterClosed()
				  .subscribe((result) => {
					  Global.User.DebugMode &&
						  console.log('The modal was closed');
					  this.tagGraphSingleModalSubscription.unsubscribe();
				  });
		  }
	  }

	  onCancel() {
		  this.dialogRef.close();
	  }
	}

