import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, AfterViewInit, Optional, ViewChild, ViewEncapsulation, OnInit } from "@angular/core";
import _ from "lodash";

import { GridSettings } from "../../_models/grid-settings.interface";
import { process, State } from "@progress/kendo-data-query";

import { GridDataResult, DataStateChangeEvent, PageChangeEvent, RowClassArgs } from "@progress/kendo-angular-grid";

import { filter } from "rxjs/operators";
import { Subscription } from "rxjs";
import { DataService } from "../../services/data.service";
import { DashboardService } from "../../services/dashboard.service";
import { KendoSettingsService } from "../../services/kendo-settings.service";
import { SignalRCoreService } from "../../services/signalr-core.service";
import { Global } from "../../_constants/global.variables";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { KendoGridParentComponent } from "../kendo-grid-parent/kendo-grid-parent.component";
import { ITag } from "../../_models/tag.model";
import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';
import { MatDialog } from '@angular/material/dialog';
import { IWidgetSignalRGroupObject } from "../../_models/signalr-widget-group.model";
import { ITagNamePrefixSubject } from "../../_models/tag-name-prefix-subject.model";
import { v4 as uuidv4 } from 'uuid';

@Component({
	encapsulation: ViewEncapsulation.None,
	selector: 'lib-raw-tag-data-for-asset',
	templateUrl: './raw-tag-data-for-asset.component.html',
	styleUrls: ['./raw-tag-data-for-asset.component.scss'],
})
export class RawTagDataForAssetComponent implements AfterViewInit, OnInit, OnDestroy {
	@ViewChild('tagDataGrid') tagDataGrid: KendoGridParentComponent;
	@Optional() @Input() widgetObject: any;
	@Optional() @Input() dataObject: any;
	@ViewChild('eventDataRowForCalculation')
	eventDataRowForCalculation: ElementRef;
	@Input() private dashboardTimeZoneChanged: EventEmitter<any>;
	eventDataRowHeight: any = 47;
	eventDataPageSize: number;
	assetTagIds: any;
	isLoading: boolean = true;
	timeZoneType: any;

	theme: string;
	assetTags: any;
	fullDataCacheSubscription: Subscription;
	signalRTagUpdateSubscription: Subscription;
	widgetIsInViewSubscription: Subscription;
	widgetGroupSettings: IWidgetSignalRGroupObject;

	dashboardTimeZoneChangedSubscription: Subscription;
	private componentName: string = 'asset-raw-tag-data: ';
	public gridContextMenuItems = ['Quick Trend of Tag'];

	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: 'and',
				filters: [],
			},
			take: 15,
		},
		//Tag Id, UTC, JBT Std Obs Id, JBt Std Obs Name, Last Obs Id, Qual
		columnsConfig: [
			{
				field: 'chart',
				title: 'Chart',
				filterable: false,
				_width: 75,
				hidden: false,
			},
			{
				field: 'TagId',
				title: 'Tag Id',
				_width: 100,
				filterable: true,
			},
			{
				field: 'TagName',
				title: 'Name',
				_width: 150,
				filterable: true,
			},
			{
				field: 'Value',
				title: 'Value',
				filter: 'numeric',
				_width: 150,
				filterable: true,
			},
			{
				field: 'ValueWhenActive',
				title: 'ValueWhenActive',
				filter: 'numeric',
				_width: 150,
				filterable: true,
			},
			{
				field: 'JBTStandardObservationId',
				title: 'JBT Std Obs Id',
				_width: 150,
				filterable: true,
			},
			{
				field: 'ShortTagName',
				title: 'JBT Std Obs Name',
				_width: 150,
				filterable: true,
			},

			{
				field: 'JavascriptDate',
				title: 'Date',
				filterable: true,
				_width: 150,
				filter: 'date',
			},
			{
				field: 'SiteLocalJavascriptDate',
				title: 'Date',
				filterable: true,
				filter: 'date',
				_width: 150,
			},
			{
				field: 'UTCJavascriptDate',
				title: 'Date',
				filterable: true,
				filter: 'date',
				_width: 150,
			},
			{
				field: 'RedisKeyName',
				title: 'Redis Key',
				filterable: true,
				_width: 150,
			},
			{
				field: 'Quality',
				title: 'Qual',
				filterable: true,
				_width: 100,
			},
			{
				field: 'Severity',
				title: 'Severity',
				filterable: true,
				_width: 130,
			},
			{
				field: 'Favorite',
				title: 'Favorite',
				filterable: true,
				filter: 'boolean',
				_width: 150,
			},
		],
	};
	tagGraphSingleModalSubscription: any;
	public guid: string;

	constructor(
		public dataService: DataService,
		private dashboardService: DashboardService,
		private kendoSettingsService: KendoSettingsService,
		private ref: ChangeDetectorRef,
		public signalRCore: SignalRCoreService,
		public dialog: MatDialog
	) {}

	ngAfterViewInit() {
		this.isLoading = true;
		if (!_.isNil(this.widgetObject.WidgetAssetId) || !_.isNil(this.widgetObject.VocationalSettingsJSON)) {

			if(!_.isNil(this.widgetObject.VocationalSettingsJSON)) {
				// get the asset id
				var VocationalSettings = JSON.parse(this.widgetObject.VocationalSettingsJSON);
				this.widgetObject.WidgetAssetId = VocationalSettings.child?.id;

				let fleetId = VocationalSettings.id;

				this.widgetObject.WidgetAssetId = VocationalSettings.child.id;
				this.widgetObject.WidgetAssetName = this.dataService.cache.assetsObject[this.widgetObject.WidgetAssetId].Name;

				this.dataService.GetFleets().subscribe((data: any) => {
					if(data.length > 0) {
						let myFleet = data.find((fleet) => {
							return ( fleet.FleetId === fleetId );
						});

						this.widgetObject.FleetName = myFleet?.FleetName;
					}
				});
			}

			if (Global.FullDataCacheExists === false) {
				this.fullDataCacheSubscription =
					this.dataService.fullDataCacheExists$.subscribe(
						(data: any) => {
							if (data === true) {
								this.theme = Global.Theme;
								this.initialize();
								this.fullDataCacheSubscription.unsubscribe();
							}
						}
					);
			} else {
				this.theme = Global.Theme;
				this.initialize();
			}
		} else {
			this.isLoading = false;
			return;
		}

		if (this.dashboardTimeZoneChanged) {
			this.dashboardTimeZoneChangedSubscription =
				this.dashboardTimeZoneChanged.subscribe((data) => {
					console.log(data);
					let foundWidgetWithSameWidgetId = data.find(
						(widgetThatWasChanged) => {
							return (
								widgetThatWasChanged.WidgetId ===
								this.widgetObject.WidgetId
							);
						}
					);

					if (!_.isNil(foundWidgetWithSameWidgetId)) {
						console.log('Widget Time Zone Changed');
						this.timeZoneType =
							this.dashboardService.determineTimeZoneType(
								this.widgetObject
							);

						const localDateCol =
							this.gridSettings.columnsConfig.find(
								(col) => col.field == 'JavascriptDate'
							);
						const siteDateCol =
							this.gridSettings.columnsConfig.find(
								(col) => col.field == 'SiteLocalJavascriptDate'
							);
						const utcDateCol = this.gridSettings.columnsConfig.find(
							(col) => col.field == 'UTCJavascriptDate'
						);

						localDateCol.hidden = this.timeZoneType !== 'User Time';
						siteDateCol.hidden = this.timeZoneType !== 'Site Time';
						utcDateCol.hidden = this.timeZoneType !== 'UTC Time';
						localDateCol.includeInChooser = !localDateCol.hidden;
						siteDateCol.includeInChooser = !siteDateCol.hidden;
						utcDateCol.includeInChooser = !utcDateCol.hidden;
					}
				});
		}
	}

	ngOnInit() {
		this.guid = this.dataService.guid();
	}

	initialize() {
		this.dataService
			.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventory(
				this.widgetObject.WidgetAssetId
			)
			.subscribe((data: any) => {

				this.isLoading = false;

				if (this.tagDataGrid) {
					this.tagDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[
						this.widgetObject.WidgetAssetId
					].Tags);
				}

				this.widgetObject.isDisplayDataLive = true;
				this.finishInitializingWidget();
			});
	}

	saveGridSettings() {
		if (this.widgetObject !== undefined) {
			this.kendoSettingsService
				.saveGridSettings(
					[
						{
							gridObject: this.tagDataGrid.kendoGridParent,
							gridState: this.gridSettings.state,
						},
					],
					this.widgetObject.WidgetId
				)
				.then((data: any) => {
					console.log(data);
					this.widgetObject.WidgetKendoUIJson = data;
				});
		}
	}

	getSignalRUpdates() {

		let assetObjectInCache = this.dataService.cache.assetsObject[this.widgetObject.WidgetAssetId];
		let tagNamePrefixesString = assetObjectInCache.TagNamePrefix;
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;

		
		this.signalRCore.joinGroups();

		if (this.widgetObject && this.widgetObject.WidgetId !== undefined) {
			this.widgetGroupSettings = {
				WidgetId: this.widgetObject.WidgetId,
				GroupList: tagNamePrefixesString,
				IsPopup: false
			};
		} else {
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: tagNamePrefixesString,
				IsPopup: true,
			};
		}
		Global.User.DebugMode && console.log(this.componentName + "this.widgetGroupSettings = %O", this.widgetGroupSettings);
		this.dataService
			.createSubjectAndSubscribe({ Id: this.guid, 
										 WidgetName: (this.dataObject != null ? this.dataObject.WidgetName : this.widgetObject.WidgetName), 
										 TagNamePrefix: [ tagNamePrefixesString ]  })
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);

				 var activeSubject = this.dataService.returnCorrectActiveSubject(this.guid); 
			
			     Global.User.DebugMode && console.log(this.componentName + "active subjects: %O", activeSubject);
			
			
				activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
					//console.log(this.componentName + "Updating tag we care about: %O", tag);
					this.updateGridData();
				});
			});
	}

	updateGridData() {
		if (this.tagDataGrid) {
			this.tagDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[
				this.widgetObject.WidgetAssetId
			].Tags);
		}
	}

	onResized(event) {
		this.eventDataPageSize = Math.floor(
			((event.newRect.height - 120) / this.eventDataRowHeight) * 3
		);
	}

	finishInitializingWidget() {
		this.widgetIsInViewSubscription =
			this.dashboardService.widgetInViewChanged$.subscribe(
				(data: any) => {
					if (data.widgetId === this.widgetObject.WidgetId) {
						if (this.eventDataRowForCalculation !== undefined) {
							this.eventDataRowHeight =
								this.eventDataRowForCalculation.nativeElement.getBoundingClientRect().height;
						}
						// this.isWidgetInView = data.isInView;
					}
				}
			);
		this.isLoading = false;


		if (this.widgetObject.WidgetKendoUIJson) {
			let jsonObjectParsed =
				this.kendoSettingsService.parseReturnedSettingsForDates(
					this.widgetObject.WidgetKendoUIJson
				);
			//the first item in the array is the gridSettings for the first tab of data for GSE-Overview

			let returnedParsedObject = jsonObjectParsed[0];
			//We pass it into the function in the kendo service to compare what has been saved vs the template declaration of columns to make sure they get the lastest updates.
			this.gridSettings = this.mapGridSettings(
				this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
					this.gridSettings,
					returnedParsedObject
				)
			);
			if (this.tagDataGrid) {
				this.tagDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[
					this.widgetObject.WidgetAssetId
				].Tags);
			}
			this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(
				[this.gridSettings.columnsConfig],
				this.widgetObject.WidgetId
			);
		} else {
			if (this.tagDataGrid) {
				this.tagDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[
					this.widgetObject.WidgetAssetId
				].Tags);
			}
		}
		this.timeZoneType = this.dashboardService.determineTimeZoneType(
			this.widgetObject
		);
		const localDateCol = this.gridSettings.columnsConfig.find(
			(col) => col.field == 'JavascriptDate'
		);
		const siteDateCol = this.gridSettings.columnsConfig.find(
			(col) => col.field == 'SiteLocalJavascriptDate'
		);

		const utcDateCol = this.gridSettings.columnsConfig.find(
			(col) => col.field == 'UTCJavascriptDate'
		);

		localDateCol.hidden = this.timeZoneType !== 'User Time';
		siteDateCol.hidden = this.timeZoneType !== 'Site Time';
		utcDateCol.hidden = this.timeZoneType !== 'UTC Time';
		localDateCol.includeInChooser = !localDateCol.hidden;
		siteDateCol.includeInChooser = !siteDateCol.hidden;
		utcDateCol.includeInChooser = !utcDateCol.hidden;

		this.getSignalRUpdates();
	}

	public mapGridSettings(gridSettings: GridSettings) {
		const state = gridSettings.state;
		let emptyArray: any = [];
		return {
			state,
			columnsConfig: gridSettings.columnsConfig.sort(
				(a, b) => a.orderIndex - b.orderIndex
			),
			gridData:
				this.dataService.cache !== undefined
					? process(this.dataService.cache.assetsObject[
					this.widgetObject.WidgetAssetId
				].Tags, state)
					: emptyArray,
		};
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + ": ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);

		this.fullDataCacheSubscription &&
			this.fullDataCacheSubscription.unsubscribe();
		this.dashboardTimeZoneChangedSubscription &&
			this.dashboardTimeZoneChangedSubscription.unsubscribe();

		this.widgetIsInViewSubscription &&
			this.widgetIsInViewSubscription.unsubscribe();
	}

	public onRightClickSelect({ dataItem, item }): void {
		if (item === 'Quick Trend of Tag') {
			const tagGraphSingleModal = this.dialog.open(
				DialogModalParentComponent,
				{
					width: '95%',
					height: '80%',
					data: {
						graphId: uuidv4(),
						TagId: dataItem.TagId,
						widgetNameDisplay: 'Tag Graph',
						WidgetName: 'tag-graph',
						isDisplayDataLive: true,
						timeZoneType:
							this.dashboardService.determineTimeZoneType(
								this.widgetObject
							),
					},
					maxWidth: '100vw',
					maxHeight: '100vh',
				}
			);
			this.tagGraphSingleModalSubscription = tagGraphSingleModal
				.afterClosed()
				.subscribe((result) => {
					this.tagGraphSingleModalSubscription.unsubscribe();
				});
		}
	}
}
