import { Component, AfterViewInit, Input, EventEmitter, ViewChild, ElementRef, ViewChildren } from "@angular/core";
import _ from "lodash";
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { TacticalDashboardOverviewGaugeComponent } from '../../components/tactical-dashboard-overview-gauge/tactical-dashboard-overview-gauge.component';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/data.service';
import { DashboardService } from '../../services/dashboard.service';
import { Global } from '../../_constants/global.variables';
import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';
import { UtilityService } from '../../services/utility.service';
import { IGlobal } from "../../_models/global.model";
import { TacticalWidgetSummaryTilePopupComponent } from '../../components/tactical-widget-summary-tile-popup/tactical-widget-summary-tile-popup.component';
import { TourService } from 'ngx-ui-tour-md-menu';
import { v4 as uuidv4 } from 'uuid';

@Component({
	selector: 'lib-gse-assets-in-use',
	templateUrl: './gse-assets-in-use.component.html',
	styleUrls: ['./gse-assets-in-use.component.scss'],
})
export class GseAssetsInUseComponent implements AfterViewInit {
	public graphTypes: string[] = [
		'area',
		'bar',
		'column',
		'donut',
		'horizontalWaterfall',
		'line',
		'verticalArea',
		'verticalLine',
		'waterfall',
	];

	public graphType = this.graphTypes[0];
	public graphShow: boolean = true;
	@ViewChildren('seriesItems')
	public series: any;

	@ViewChild('chart')
	public chart: any;

	@ViewChild('childOverviewGauge')
	childOverviewGauge: TacticalDashboardOverviewGaugeComponent;
	public chartDataIsReady: boolean = false;
	public isCollapsed = false;
	@Input() widgetObject: any;
	@Input() private widgetResizedEvent: EventEmitter<any>;
	public global: IGlobal = Global;

	h1TitlePercent: number;
	componentSubtitle: String = '';
	percentCalculated: boolean = false;
	updateControllerInterval: NodeJS.Timeout;
	lastUpdatedDate: Date;
	displayTimeAgoMessage: any;
	@Input() private dashboardTimeScopeChanged: EventEmitter<any>;
	@Input() private dashboardUpdateIntervalChanged: EventEmitter<any>;
	dashboardUpdateIntervalSubscription: any;
	secondsAgoInterval: NodeJS.Timeout;
	fullDataCacheSubscription: any;
	dialogModalData: any;
	chartData: any;
	noDataForSite: boolean = false;
	chartBackgroundColor: string = '#27304C';
	chartLabelColors: string = 'white';
	gridLineColor: string = 'white';
	axisItemColor: string = 'white';
	colorChangedSubscription: any;
	theme: string;
	trendDirection: string;
	summaryArray = [];
	summaryArray1 = [];
	sparklineData = [];
	fontSize1 = 'medium';
	fontSize2 = 'small';
	cardClass1 = 'card-tile-lg';
	cardClass2 = 'card-tile-md';
	cardWideClass2 = 'card-wide-tile-lg';
	gridNumberTitleClass = 'grid-number-title-md';
	holeSize = 30;
	holeCenterTextClass = 'donut-center-text-md';
	donutClass = 'donut-md';
	headerGridTitle = 'grid-title-lg';
	cardTileCenterTitleClass = 'grid-card-title-center-sm';

	widgetResizedSubscription: any;
	widgetNavigateToFirstTabSubscription: Subscription;
	widgetToggleLegendHiddenSubscription: Subscription;

	public label = {
		visible: false,
	};
	public progressStyles: { [key: string]: any } = {
		background: 'limegreen',
	};

	barChartData: any[] = [
		{ Status: 10 },
		{ Status: 20 },
		{ Status: 200 },
		{ Status: 200 },
	];
	public userDefinedAlarmObservationIds: any = [
		56442, 56443, 56444, 56445, 56446, 56447, 56448, 56449, 56450, 56451,
		56452, 56453, 56454, 56455, 56456, 56457, 56458, 56459, 56460, 56461,
	];
	displayNoUpdateIntervalMessage: boolean;
	updateInterval: any;
	isLoading: boolean;
	public tab: any;
	public widgetTabs: any = [
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Summary',
			rendered: true,
			tooltip:
				'Summary of assets in use out of the total amount of assets within the selected scope.Click on the info to navigate to corresponding tab for details.',
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Assets In Use Current Time',
			rendered: false,
			graphType: this.graphTypes[5],
			tooltip:
				'Detailed line chart showing within the current scope settings (Site, asset type) the amount of Assets in Use at the time this report was generated over the last 7 days. ',
			downloadableData: true,
			groupable: true,
			groupColumns: [{ field: 'AssetName' }],
			tableStructure: [
				{
					field: 'DateOfObservationDateObject',
					title: 'Date',
					filter: 'date',
					_width: 300,
					formatOption: 'shortDate',
				},

				{
					field: 'AssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'ObservationBoolean',
					title: 'In Use',
					_width: 100,
				},
			],
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Total Minutes On For Day',
			rendered: false,
			graphType: this.graphTypes[5],
			tooltip:
				'Detailed line chart showing within the current scope settings (Site, asset type) the total amount of time Assets were in use for each day of the trend along with the amount of times they were powered on.',
			downloadableData: true,
			groupable: true,
			groupColumns: [{ field: 'AssetName' }],
			legendVisible: true,
			tableStructure: [
				{
					field: 'DateOfObservationDateObject',
					title: 'Date',
					filter: 'date',
					_width: 300,
					formatOption: 'shortDate',
				},

				{
					field: 'AssetName',
					title: 'Asset Name',
					_width: 300,
				},

				{
					field: 'PowerOnCount',
					title: 'Power On Count',
					_width: 300,
				},
				{
					field: 'PowerOnDurationMinutes',
					title: 'Power On Duration Minutes',
					_width: 300,
				},
			],
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Active Time Stacked',
			rendered: false,
			graphType: this.graphTypes[2],
			tooltip:
				'Stacked Column Chart showing minutes used and what percent of time the unit spent idle/driving/operating.',
			downloadableData: true,
			groupable: true,
			legendVisible: true,
			groupColumns: [{ field: 'AssetName' }],
			tableStructure: [
				{
					field: 'StartDate',
					title: 'Date',
					filter: 'date',
					_width: 50,
				},

				{
					field: 'AssetName',
					title: 'Asset Name',
					_width: 100,
				},
				{
					field: 'TotalActiveMinutes',
					title: 'Total Active (Min)',
					_width: 50,
				},
				{
					field: 'IdleDurationInMinutes',
					title: 'Idle Time (Min)',
					_width: 50,
				},
				{
					field: 'IdlePercent',
					title: 'Idle Percent',
					_width: 50,
				},
				{
					field: 'DriveDurationInMinutes',
					title: 'Drive Time (Min)',
					_width: 50,
				},
				{
					field: 'DrivePercent',
					title: 'Drive Percent',
					_width: 50,
				},
				{
					field: 'OperateDurationInMinutes',
					title: 'Operate Time (Min)',
					_width: 50,
				},
				{
					field: 'OperatePercent',
					title: 'Operate Percent',
					_width: 50,
				},
			],
		},
	];

	parentContainerSize: any;
	selectedMatTabLabel: string;
	widgetTabsChartConfigurations: any;
	widgetTabConfigurationsFinishedMapping: boolean = false;
	totalMinutesOnForDayChartDataIsReady: boolean = false;
	totalMinutesOnForDayChart: GroupResult[];
	displayNoTimeScopeMessage: boolean = false;
	dashboardTimeScopeChangedSubscription: any;
	timeoutToExpire: NodeJS.Timeout;
	selectedTabIndex: number = 0;
	WidgetTabSettings: any = [];
	public progressBackground: any;
	public timeScopeObject: any;
	public options: any = [];
	public optionsSettingsOnly: any = [];
	public navigationOpened: boolean = false;
	contentHeight: any;
	public percentAssetsInUse = [];

	ConditionalShadingRecord: any;
	percentTextColor: any = [];
	conditionalColorPercentAmount: any = [];
	Fleet: any;
	assetIdList: string;

	constructor(
		public dataService: DataService,
		private dashboardService: DashboardService,
		private dialog: MatDialog,
		public toastr: ToastrService,
		private utilityService: UtilityService,
		private elem: ElementRef,
		public tourService: TourService,
	) {}

	changeGraphType(event) {
		let i = this.selectedTabIndex;
		this.widgetTabs[i].graphType = event;
		let existingRecord = this.widgetTabsChartConfigurations.find(
			(record) => {
				return (record.WidgetTabIndex = i);
			}
		);

		let statement =
			'API.TacticalDashboardAddOrUpdateWidgetChartIndexRecord @widgetId = ' +
			this.widgetObject.WidgetId +
			', @userId = ' +
			Global.User.currentUser.Id +
			', @chartTypeId = ' +
			event.id +
			', @widgetTabIndex = ' +
			i;
		if (existingRecord !== undefined) {
			statement = statement + ', @id = ' + existingRecord.Id;
		}
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.widgetTabsChartConfigurations = data;
			this.utilityService.showToastMessageShared({
				type: 'success',
				message: 'Chart type changed to ' + event.properName,
			});
			//   this.toastr.success("Chart type changed to " + event.properName);
		});
	}

	public toggleLegendChange(event: any) {
		this.toggleLegendHidden(this.tab, this.selectedTabIndex);
	}

	CreateTheme(theme: string) {
		if (theme === 'light') {
			this.theme = 'light';
			this.chartBackgroundColor = 'white';
			this.chartLabelColors = 'grey';
			this.gridLineColor = 'grey';
			this.axisItemColor = 'grey';
		} else {
			this.theme = 'dark';
			this.chartBackgroundColor = '#27304C';
			this.chartLabelColors = 'white';
			this.gridLineColor = 'white';
			this.axisItemColor = 'white';
		}
	}
	public labelContent(e: any): string {
		return e.category + ' - ' + e.value;
	}

	ngOnInit() {
		this.tab = this.widgetTabs[0];
		this.updateInterval =
			this.dashboardService.determineProperTacticalDashboardUpdateIntervalForWidget(
				this.widgetObject
			);
		this.timeScopeObject =
		this.dashboardService.determineProperTimeScopeForWidget({
			widgetObject: this.widgetObject,
			UTCConfiguration: false,
		});
		this.buildNavigationOptionsArray();
	}

	ngAfterViewInit() {
		var bounds = this.elem.nativeElement.getBoundingClientRect();
		this.cardResize(bounds);

		if(Global.isMobile == true) {
			this.contentHeight = 'calc(100% - 20px)';
		}
		else {
			this.contentHeight = 'calc(100% - 120px)';
		}

		let statement =
			'API.GetWidgetTabSettings @WidgetId = ' +
			this.widgetObject.WidgetId;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.WidgetTabSettings = data;
			this.WidgetTabSettings.forEach((tabSetting) => {
				this.widgetTabs[tabSetting.TabIndex].legendVisible =
					!tabSetting.IsLegendHidden;
			});
			Global.User.DebugMode && console.log(this);
		});

		this.ConditionalShadingRecord = this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
			return p.WidgetId == this.widgetObject.WidgetId;
		});

		if (this.ConditionalShadingRecord.length > 0) {
			this.ConditionalShadingRecord.sort((a, b) => parseFloat(a.Value) - parseFloat(b.Value));
		}

		if (this.widgetResizedEvent) {
			this.widgetResizedSubscription = this.widgetResizedEvent.subscribe((data) => {
				if (this.widgetObject.WidgetId == data.item.WidgetId) {
					this.cardResize(data);
					this.fontResize(data);
				}
			});
		}
		this.widgetNavigateToFirstTabSubscription = this.dashboardService._widgetNavigateToFirstTab.subscribe((widgetObject: any) => {
			if (widgetObject && parseInt(this.widgetObject.WidgetId) === parseInt(widgetObject.WidgetId)) {
				this.selectedTabIndex = 0
			}
		});

		this.widgetToggleLegendHiddenSubscription = this.dashboardService._widgetToggleLegendHidden.subscribe((widgetObject: any) => {
			if (widgetObject && parseInt(this.widgetObject.WidgetId) === parseInt(widgetObject.WidgetId)) {
				this.toggleLegendHidden(this.tab ,this.selectedTabIndex);
			}
		});

		this.isLoading = true;
		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			this.initialize();
		}

		this.colorChangedSubscription =
			this.dataService.colorChanged$.subscribe((data: any) => {
				if (data === 'light') {
					this.CreateTheme('light');
				} else {
					this.CreateTheme('dark');
				}
			});
		if (Global.Theme === 'light') {
			this.CreateTheme('light');
		} else {
			this.CreateTheme('dark');
		}

		if (this.dashboardUpdateIntervalChanged) {
			this.dashboardUpdateIntervalSubscription =
				this.dashboardUpdateIntervalChanged.subscribe((data) => {
					Global.User.DebugMode && console.log(data);
					let foundWidgetWithSameWidgetId = data.find(
						(widgetThatWasChanged) => {
							return (
								widgetThatWasChanged.WidgetId ===
								this.widgetObject.WidgetId
							);
						}
					);

					if (!_.isNil(foundWidgetWithSameWidgetId)) {
						//if data (which is list of widgets that had the time zone changed as an array of widgets includes a widget with this widget id, we can assume this widget needs to be updated. needs to initialize with new selected time zone in mind. )
						this.initialize();
					}
				});
		}
		if (this.dashboardTimeScopeChanged) {
			this.dashboardTimeScopeChangedSubscription =
				this.dashboardTimeScopeChanged.subscribe((data) => {
					let foundWidgetWithSameWidgetId = data.find(
						(widgetThatWasChanged) => {
							return (
								widgetThatWasChanged.WidgetId ===
								this.widgetObject.WidgetId
							);
						}
					);

					if (!_.isNil(foundWidgetWithSameWidgetId)) {
						Global.User.DebugMode && console.log('Widget Time Zone Changed');
						this.initialize();
					}
				});
		}
		// this.chartDataIsReady = true;
		this.tab = this.widgetTabs[0];
	}

	fontResize(data: any) {

		if (data.height < 400 || data.width < 400) {
			this.fontSize1 = 'small';
			this.fontSize2 = 'x-small';
		}

		else if ((data.height > 400 && data.height < 500) || (data.width > 400 && data.width < 600)) {
			this.fontSize1 = 'medium';
			this.fontSize2 = 'small';
		}

		else if ((data.height > 500 && data.height < 700) || (data.width > 600 && data.width < 800)) {
			this.fontSize1 = 'large';
			this.fontSize2 = 'medium';
		}
		else if (data.height > 700 || data.width > 800) {
			this.fontSize1 = 'x-large';
			this.fontSize2 = 'large';
	  }
	}



	openConditionalShadingSettingsPopup(tileNumber) {
		let tileConditionalShadingRecord =
			this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
				return p.WidgetId == this.widgetObject.WidgetId && p.SummaryTileIndex == tileNumber;
			});
		if(tileConditionalShadingRecord.length == 0) {
			tileConditionalShadingRecord.WidgetId = this.widgetObject.WidgetId;
			tileConditionalShadingRecord.SummaryTileIndex = tileNumber;
		}

		var DialogRef = this.dialog.open(TacticalWidgetSummaryTilePopupComponent, {
			width: '50%',
			height: '40%',
			data: tileConditionalShadingRecord,
		});


		DialogRef.afterClosed().subscribe((result) => {
			let tileConditionalShadingRecordLatest =
				this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
					return p.WidgetId == this.widgetObject.WidgetId;
				});
			this.ConditionalShadingRecord = [];
			tileConditionalShadingRecordLatest.forEach((r) => {
				this.ConditionalShadingRecord.push(r);
			});

			this.ConditionalShadingRecord.sort((a, b) => parseFloat(a.Value) - parseFloat(b.Value));
			this.ConditionalColorSummaryTiles();

		});

	}


		private ConditionalColorSummaryTiles() {
			this.percentTextColor[1] = "lightseagreen";

			this.conditionalColorPercentAmount[1] = this.summaryArray[2] * 100;


			if (this.ConditionalShadingRecord.length > 0) {
				this.ConditionalShadingRecord.forEach((r) => {

					switch (r.Operator) {
						case 'Is greater than' :
							if(this.conditionalColorPercentAmount[r.SummaryTileIndex] > r.Value) {
								this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
							}
							break;
						case 'Is less than' :
							if(this.conditionalColorPercentAmount[r.SummaryTileIndex] < r.Value) {
								this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
							}
							break;
						case 'Is equal to' :
							var conditionalColorPercentAmountRounded = Math.round(this.conditionalColorPercentAmount[r.SummaryTileIndex]);
							if(conditionalColorPercentAmountRounded == r.Value) {
								this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
							}
							break;
						case 'Is not equal to' :
							var conditionalColorPercentAmountRounded = Math.round(this.conditionalColorPercentAmount[r.SummaryTileIndex]);
							if(conditionalColorPercentAmountRounded != r.Value) {
								this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
							}
							break;
					}
				});
			}

			this.percentAssetsInUse = [
				{
					kind: "assets in use",
					share: this.summaryArray[2],
					color: this.percentTextColor[1],
				},
				{
					kind: "other",
					share: 1 - this.summaryArray[2],
					color: "grey",
				},
			];

		}


	showChartAsPopup(tab) {
		var popupData;
		var field;
		var canIterate;
		var titleXAxis;
		var categoryField;
		var stacked = false;
		var stackedSeriesValues = [];

		if (tab.textLabel == 'Assets In Use Current Time') {
			field = 'CountOfPoweredOn';
			canIterate = false;
			titleXAxis = 'Total Assets In Use For Day';
			categoryField = 'DateOfObservationDateObject';
		}
		else if (tab.textLabel == 'Total Minutes On For Day') {
			field = 'PowerOnDurationMinutes';
			canIterate = true;
			titleXAxis = 'Total Minutes On For Day';
			categoryField = 'DateOfObservationDateObject';
		}
		else if (tab.textLabel == 'Active Time Stacked') {
			field = 'IdleDurationInMinutes';
			canIterate = false;
			titleXAxis = 'Total Active Time By Asset (Minutes)';
			categoryField = 'value';
			stacked = true;
			stackedSeriesValues = [
				{
					name: "Idle",
					field: 'IdleDurationInMinutes',
					color: '#4BB543',
				},
				{
					name: "Drive",
					field: 'DriveDurationInMinutes',
					color: '#b5434b',
				},
				{
					name: "Operate",
					field: 'OperateDurationInMinutes',
					color: '#434bb5',
				},
			];
			
		}

		popupData = {
			categoryField: categoryField,
			field: field,
			popupTitle: 'Raw Chart: ' + tab.textLabel,
			titleXAxis: titleXAxis,
			chartTitle: tab.textLabel,
			WidgetName: 'Kendo-Chart',
			chartData: tab.chartData,
			canCreateDashboardWidgetFromPopup: false,
			graphType: tab.graphType?.name,
			legendVisible: tab.legendVisible,
			canIterate: canIterate,
			stack: stacked,
			stackedSeriesValues: stackedSeriesValues,
		}
		const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '90%' : '90%',
			height: Global.isMobile ? '90%' : '90%',
			data: popupData,
			maxWidth: '100vw',
			maxHeight: '100vh',
		});
	}

	cardResize(data: any) {

		if (data.height < 400 || data.width < 400) {
			this.cardClass1 = 'card-tile-md';
			this.cardClass2 = 'card-tile-xs';
			this.cardWideClass2 = 'card-wide-tile-sm';

			this.gridNumberTitleClass = 'grid-number-title-md';

			this.holeSize = 55;
			this.holeCenterTextClass = 'donut-center-text-xs';
			this.donutClass = 'donut-sm';

			this.cardTileCenterTitleClass = 'grid-card-title-center-xs';

		}

		else if ((data.height > 400 && data.height < 500) || (data.width > 400 && data.width < 600)) {
			this.cardClass1 = 'card-tile-md';
			this.cardClass2 = 'card-tile-sm';
			this.cardWideClass2 = 'card-wide-tile-md';

			this.gridNumberTitleClass = 'grid-number-title-md';

			this.holeSize = 60;
			this.holeCenterTextClass = 'donut-center-text-sm';
			this.donutClass = 'donut-sm';

			this.cardTileCenterTitleClass = 'grid-card-title-center-sm';

		}

		else if ((data.height > 500 && data.height < 700) || (data.width > 600 && data.width < 800)) {
			this.cardClass1 = 'card-tile-md';
			this.cardClass2 = 'card-tile-sm';
			this.cardWideClass2 = 'card-wide-tile-md';

			this.gridNumberTitleClass = 'grid-number-title-md';

			this.holeSize = 60;
			this.holeCenterTextClass = 'donut-center-text-sm';
			this.donutClass = 'donut-sm';

			this.cardTileCenterTitleClass = 'grid-card-title-center-sm';

		}
		else if (data.height > 700 || data.width > 800) {
			this.cardClass1 = 'card-tile-lg';
			this.cardClass2 = 'card-tile-md';
			this.cardWideClass2 = 'card-wide-tile-lg';

			this.gridNumberTitleClass = 'grid-number-title-lg';

			this.holeSize = 60;
			this.holeCenterTextClass = 'donut-center-text-sm';
			this.donutClass = 'donut-sm';

			this.cardTileCenterTitleClass = 'grid-card-title-center-md';

		}

		if (data.width < 400) {
			this.headerGridTitle = 'grid-title-sm';
		}
		else if (data.width > 400 && data.width < 600) {
			this.headerGridTitle = 'grid-title-sm';
		}
		else if (data.width > 600 && data.width < 800) {
			this.headerGridTitle = 'grid-title-lg';
		}
		else if (data.width > 800) {
			this.headerGridTitle = 'grid-title-lg';
		}

	}
	startIntervals() {
		this.updateInterval =
			this.dashboardService.determineProperTacticalDashboardUpdateIntervalForWidget(
				this.widgetObject
			);

		if (this.updateInterval === undefined) {
			this.isLoading = false;
			this.displayNoUpdateIntervalMessage = true;
			return;
		} else {
			this.isLoading = true;
			this.displayNoUpdateIntervalMessage = false;
		}
		let intervalUpdate = this.updateInterval.UpdateInterval * 60000;
		if (this.secondsAgoInterval !== undefined) {
			clearInterval(this.secondsAgoInterval);
			this.secondsAgoInterval = undefined;
		}
		this.retrieveData();

		this.secondsAgoInterval = setInterval(() => {
			if (this.lastUpdatedDate !== undefined) {
				this.displayTimeAgoMessage =
					this.dashboardService.calculateTimeAgo(
						this.lastUpdatedDate
					);
				this.widgetObject.displayTimeAgoMessage =
					this.displayTimeAgoMessage;
			}
		}, 1000);
		if (this.updateControllerInterval !== undefined) {
			clearInterval(this.updateControllerInterval);
			this.updateControllerInterval = undefined;
		}
		this.updateControllerInterval = setInterval(() => {
			this.retrieveData();
		}, intervalUpdate);
	}

	destroyIntervals() {
		if (this.updateControllerInterval) {
			clearInterval(this.updateControllerInterval);
			this.updateControllerInterval = undefined;
		}
		if (this.secondsAgoInterval) {
			clearInterval(this.secondsAgoInterval);
			this.secondsAgoInterval = undefined;
		}
	}

	initialize() {
		if (_.isNil(this.widgetObject.SiteList) && _.isNil(this.widgetObject.VocationalSettingsJSON)) {	// added fleet settings as an option
			this.isLoading = false;
			return;
		}

		// Fleet
		//
		if (!_.isNil(this.widgetObject.VocationalSettingsJSON)) {
			//this.widgetObject.WidgetAssetTypeId = 3;

			var VocationalSettings = JSON.parse(this.widgetObject.VocationalSettingsJSON);
			Global.User.DebugMode && console.log("VocationalSettings ", VocationalSettings)

			let fleetId = VocationalSettings.id;
			this.widgetObject.WidgetAssetTypeId = VocationalSettings.child.id;

			let assetType = this.dataService.cache.assetTypes.find(
				(assetType) => {
					return assetType.Id == VocationalSettings.child.id;
				}
			);
			this.widgetObject.WidgetAssetTypeName = assetType?.Name;


			this.dataService.GetFleets().subscribe((data: any) => {
				Global.User.DebugMode && console.log("GetFleets data", data);
				this.Fleet = data.find((f) => {
					return f.FleetId === fleetId;
				}
				);
				Global.User.DebugMode && console.log("Fleet", this.Fleet);
			});

			this.dataService.GetFleetAssets(fleetId).subscribe((data: any) => {
				Global.User.DebugMode && console.log("GetFleetAssets data", data);

				let assetIdListArray = new Array();
				data.forEach(record => {
					assetIdListArray.push(record.AssetId);
				});
				this.assetIdList = assetIdListArray.toString();

				Global.User.DebugMode && console.log("asset Id List", this.assetIdList);
			});

		}
		let statement =
			'API.TacticalDashboardsRetrieveAllRecordsForWidgetId @widgetId = ' +
			this.widgetObject.WidgetId +
			', @userId = ' +
			Global.User.currentUser.Id;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.widgetTabsChartConfigurations = data;
			this.widgetTabs.forEach((tab, index) => {
				if (this.widgetTabsChartConfigurations.length > 0) {
					let foundWidgetTabConfiguration =
						this.widgetTabsChartConfigurations.find(
							(configurationrecord) => {
								return (
									configurationrecord.WidgetTabIndex === index
								);
							}
						);
					if (foundWidgetTabConfiguration !== undefined) {
						tab.graphType = Global.tacticalDashboardGraphTypes.find(
							(graphType) =>
								graphType.id ==
								foundWidgetTabConfiguration.ChartTypeId
						);
					}
				}
			});
			this.widgetTabConfigurationsFinishedMapping = true;

			this.selectedTabIndex =
				this.widgetObject.SelectedTabIndex !== undefined
					? this.widgetObject.SelectedTabIndex
					: 0;
			this.selectedMatTabLabel =
				this.widgetTabs[
					this.widgetObject.SelectedTabIndex !== undefined
						? this.widgetObject.SelectedTabIndex
						: 0
				].textLabel;
			this.widgetTabs[
				this.widgetObject.SelectedTabIndex !== undefined
					? this.widgetObject.SelectedTabIndex
					: 0
			].tabHasBeenLoaded = true;
			this.tab = this.widgetTabs[this.selectedTabIndex];
			this.startIntervals();
		});
	}

	showDialogModalPopupGrid(tab) {
		const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '100%' : '60%',
			height: Global.isMobile ? '90%' : '80%',
			data: {
				graphId: uuidv4(),
				groupable: tab.groupable,
				groupColumns: tab.groupable === true ? tab.groupColumns : [],
				popupTitle: 'Raw Data: ' + tab.textLabel,
				WidgetName: 'Kendo-Grid',
				tableStructure: tab.tableStructure,
				tableData: tab.tableData,
				canCreateDashboardWidgetFromPopup: false,
			},
			maxWidth: '100vw',
			maxHeight: '100vh',
		});
	}

	retrieveData() {
		this.timeScopeObject =
		this.dashboardService.determineProperTimeScopeForWidget({
			widgetObject: this.widgetObject,
			UTCConfiguration: false,
		});
		if (this.timeScopeObject === undefined) {
			this.displayNoTimeScopeMessage = true;
			this.isLoading = false;
		} else {
			this.displayNoTimeScopeMessage = false;
			if (
				this.widgetTabs[0].tabHasBeenLoaded === true ||
				this.widgetTabs[2].tabHasBeenLoaded === true
			) {
				this.widgetTabs[0].isLoading = this.widgetTabs[2].isLoading =
					true;

				this.totalMinutesOnForDayChartDataIsReady = false;
				let secondstatement =
					'API.TacticalDashboard_Asset_PoweredOn_Duration ' +
					'@startDateTimeMS=' +
					this.timeScopeObject.queryStartDate +
					', @endDateTimeMS= ' +
					this.timeScopeObject.queryEndDate +
					" , @accessToken='" +
					Global.User.currentUser.ODataAccessToken +
					"'";
				if (_.isNil(this.assetIdList) && !_.isNil(this.widgetObject.SiteList)) {
					secondstatement =
						secondstatement +
						", @siteIdList = '" +
						this.widgetObject.SiteList +
						"'";
				}
				if (
					!_.isNil(this.widgetObject.WidgetAssetTypeId) &&
					this.widgetObject.WidgetAssetTypeId !== 0
				) {
					secondstatement =
						secondstatement +
						', @assetTypeId = ' +
						this.widgetObject.WidgetAssetTypeId;
				}
				if (!_.isNil(this.assetIdList)) {
					secondstatement = secondstatement + ", @assetIdList = '" + this.assetIdList + "'";
				}
				this.dataService
					.SQLActionAsPromise(secondstatement)
					.then((data: any) => {
						Global.User.DebugMode && console.log(data);
						if (data.length === 0) {
							this.widgetTabs[2].tableData = data;
							this.widgetTabs[2].chartData = data;
							this.widgetTabs[2].isLoading = false;
							this.widgetTabs[0].isLoading = false;
						} else {
							data.forEach((record) => {
								record.DateOfObservationDateObject = new Date(
									record.DateOfObservation
								);
								if (record.PowerOnDurationMinutes !== null) {
									record.PowerOnDurationMinutes =
										parseInt(
											record.PowerOnDurationMinutes
										) / 60;

									record.AssetOnDurationMinutes = Math.round(
										record.PowerOnDurationMinutes
									);
								} else {
									record.AssetOnDurationMinutes = 0;
								}
							});
							this.widgetTabs[2].tableData = data;
							this.widgetTabs[2].chartData = groupBy(data, [
								{ field: 'AssetName' },
							]) as GroupResult[];
							if (this.widgetTabs[2].chartData.length > 0) {
								this.widgetTabs[2].chartData.push({
									value: 'Toggle All Series',
								});
							}
							let sortByAssetOnDurationMinutes = data;
							sortByAssetOnDurationMinutes.sort(
								(a, b) =>
									b.AssetOnDurationMinutes -
									a.AssetOnDurationMinutes
							);
							this.summaryArray1[0] =
								sortByAssetOnDurationMinutes[0].AssetOnDurationMinutes; //Highest AssetOnDuration Minutes
							this.summaryArray1[1] =
								sortByAssetOnDurationMinutes[0].DateOfObservation; //Highest AssetOnDuration DateOfObservation
							this.summaryArray1[2] =
								sortByAssetOnDurationMinutes[0].AssetName; //Highest AssetOnDuration Asset Name

							this.widgetTabs[2].chartDataIsReady = true;
							this.widgetTabs[0].isLoading =
								this.widgetTabs[2].isLoading = false;
						}
					});
			}

			if (
				this.widgetTabs[0].tabHasBeenLoaded === true ||
				this.widgetTabs[1].tabHasBeenLoaded === true
			) {
				this.widgetTabs[0].isLoading = this.widgetTabs[1].isLoading =
					true;
				let statement =
					"API.TacticalDashboard_AssetStatus_PoweredOn_ForSpecificMomentInTime @accessToken = '" +
					Global.User.currentUser.ODataAccessToken +
					"', @startDateTimeMS=" +
					this.timeScopeObject.queryStartDate +
					', @endDateTimeMS= ' +
					this.timeScopeObject.queryEndDate;
				if (_.isNil(this.assetIdList) && !_.isNil(this.widgetObject.SiteList)) {
					statement =
						statement +
						", @siteIdList = '" +
						this.widgetObject.SiteList +
						"'";
				}
				if (
					!_.isNil(this.widgetObject.WidgetAssetTypeId) &&
					this.widgetObject.WidgetAssetTypeId !== 0
				) {
					statement =
						statement +
						', @assetTypeId = ' +
						this.widgetObject.WidgetAssetTypeId;
				}
				if (!_.isNil(this.assetIdList)) {
					statement = statement + ", @assetIdList = '" + this.assetIdList + "'";
				}
				Global.User.DebugMode && console.log(this.widgetObject);
				Global.User.DebugMode && console.log(statement);
				this.dataService
					.SQLActionAsPromise(statement)
					.then((data: any) => {
						Global.User.DebugMode && console.log(data);
						if (data.length === 0) {
							this.widgetTabs[0].isLoading =
								this.widgetTabs[1].isLoading = false;
							this.widgetTabs[1].tableData = [];
							this.widgetTabs[1].chartData = [];
						} else {
							data.forEach((record) => {
								record.ObservationValue = parseInt(
									record.ObservationTextValue
								);
							});
							this.chartData = _.uniqBy(data, (e: any) => {
								return e.DateOfObservation;
							});

							// this.h1Number =
							// 	this.chartData[
							// 		this.chartData.length - 1
							// 	].CountOfPoweredOn;
							// this.totalAssetLength =
							// 	this.chartData[
							// 		this.chartData.length - 1
							// 	].CountOfAssets;
							// if (this.h1Number === 0) {
							// 	this.h4Percent = 0;
							// } else {
							// 	this.h4Percent = parseFloat(
							// 		(
							// 			(this.h1Number /
							// 				this.totalAssetLength) *
							// 			100
							// 		).toFixed(1)
							// 	);
							// }
							Global.User.DebugMode && console.log(this.chartData);
							this.percentCalculated = true;

							// this.trendDirection =
							// 	this.chartData.length >= 1
							// 		? 'even'
							// 		: this.h1Number >
							// 		  this.chartData[this.chartData.length - 2]
							// 				.CountOfPoweredOn
							// 		? 'up'
							// 		: this.h1Number <
							// 		  this.chartData[this.chartData.length - 2]
							// 				.CountOfPoweredOn
							// 		? 'down'
							// 		: this.h1Number ===
							// 		  this.chartData[this.chartData.length - 2]
							// 				.CountOfPoweredOn
							// 		? 'even'
							// 		: '';
							// this.gaugeDisplayData = {
							// 	h1Number: this.h1Number,
							// 	h4Percent: this.h4Percent,
							// 	totalAssetLength: this.totalAssetLength,
							// };
							data.forEach((record) => {
								record.DateOfObservationDateObject = new Date(
									record.DateOfObservation
								);
								if (
									parseInt(record.ObservationTextValue) === 0
								) {
									record.ObservationBoolean = false;
								} else if (
									parseInt(record.ObservationTextValue) === 1
								) {
									record.ObservationBoolean = true;
								}
							});

							let groupedData = groupBy(data, [
								{ field: 'DateOfObservation' },
							]) as GroupResult[];
							if (groupedData.length > 0) {
								this.widgetTabs[0].tableData =
									groupedData[groupedData.length - 1].items;
							}
							this.widgetTabs[1].tableData = data;
							this.widgetTabs[1].chartData = _.uniqBy(
								data,
								(e: any) => {
									return e.DateOfObservation;
								}
							);

							this.summaryArray[0] =
								this.chartData[
									this.chartData.length - 1
								].CountOfAssets; // Count Of Assets
							this.summaryArray[1] =
								this.chartData[
									this.chartData.length - 1
								].CountOfPoweredOn; // Count Of PoweredOn
							if (this.summaryArray[1] === 0) {
								this.summaryArray[2] = 0.0; // Percent Usage
							} else {

								this.summaryArray[2] = this.summaryArray[1] / this.summaryArray[0]; //Percent Usage
							}

							this.ConditionalColorSummaryTiles();

							let sortByCountOfPoweredOn = this.chartData;
							sortByCountOfPoweredOn.sort(
								(a, b) =>
									b.CountOfPoweredOn - a.CountOfPoweredOn
							);
							this.summaryArray[3] =
								sortByCountOfPoweredOn[0].CountOfPoweredOn; // Highest Count of Powered On
							this.summaryArray[4] =
								sortByCountOfPoweredOn[0].DateOfObservation; //Day with Highest Count of Powered On

							let sortbyDateDesc = this.chartData;
							sortbyDateDesc.sort(
								(a, b) =>
									new Date(b.DateOfObservation).getTime() -
									new Date(a.DateOfObservation).getTime()
							);
							this.createSparkLineChart(sortbyDateDesc); //Sparkline Data

							this.widgetTabs[1].chartDataIsReady = true;
							this.widgetTabs[0].isLoading =
								this.widgetTabs[1].isLoading = false;
						}
					});
			}

			//
			//
			//
			if (
				this.widgetTabs[0].tabHasBeenLoaded === true ||
				this.widgetTabs[3].tabHasBeenLoaded === true
			) {
				this.widgetTabs[0].isLoading = this.widgetTabs[3].isLoading =
					true;

				this.totalMinutesOnForDayChartDataIsReady = false;
				let Equipment_Active_Timestatement =
					'API.TacticalDashboard_Equipment_Active_Time ' +
					'@startDateTimeMS=' +
					this.timeScopeObject.queryStartDate +
					', @endDateTimeMS= ' +
					this.timeScopeObject.queryEndDate +
					" , @accessToken='" +
					Global.User.currentUser.ODataAccessToken +
					"'";
				if (_.isNil(this.assetIdList) && !_.isNil(this.widgetObject.SiteList)) {
					Equipment_Active_Timestatement =
						Equipment_Active_Timestatement +
						", @siteIdList = '" +
						this.widgetObject.SiteList +
						"'";
				}
				if (
					!_.isNil(this.widgetObject.WidgetAssetTypeId) &&
					this.widgetObject.WidgetAssetTypeId !== 0
				) {
					Equipment_Active_Timestatement =
						Equipment_Active_Timestatement +
						', @assetTypeId = ' +
						this.widgetObject.WidgetAssetTypeId;
				}
				if (!_.isNil(this.assetIdList)) {
					Equipment_Active_Timestatement = Equipment_Active_Timestatement + ", @assetIdList = '" + this.assetIdList + "'";
				}
				this.dataService
					.SQLActionAsPromise(Equipment_Active_Timestatement)
					.then((data: any) => {
						Global.User.DebugMode && console.log(data);
						if (data.length === 0) {
							this.widgetTabs[3].tableData = data;
							this.widgetTabs[3].chartData = data;
							this.widgetTabs[2].isLoading = false;
							this.widgetTabs[0].isLoading = false;
						} else {
							var dataForTable = [];
							data = data.filter(d=>d.AssetName!=undefined);

							//data = data.filter(d=>d.AssetName=='C30i22052'); // TEST 1 ASSET

							data.forEach((record) => {
								//
								// record calculations for table data
								//
								record.DurationInMinutes = Math.round(record.DurationInSeconds / 60);
								record.UTCDate = record.StartDate.toUTCString();

								var foundItem = dataForTable.find((d:any) =>  d.AssetName == record.AssetName && d.StartDateMS == record.StartDateMS);
								if(foundItem == undefined) {
									foundItem = record;
									dataForTable.push(record);
								}

								if(record.DurationInMinutes==undefined) {
									record.DurationInMinutes = 0;
								}
								switch (record.SimpleName) {
									case "IDLE_TIME":
									//console.log("IDLE_TIME.");
									foundItem.IdleDurationInMinutes = record.DurationInMinutes;
									break;
									case "DRIVE_TIME":
									//console.log("DRIVE_TIME.");
									foundItem.DriveDurationInMinutes = record.DurationInMinutes;
									break;
									case "OPERATE_TIME":
									//console.log("OPERATE_TIME.");
									foundItem.OperateDurationInMinutes = record.DurationInMinutes;
									break;
							
									default:
										Global.User.DebugMode && console.log("Error");
								}
								
								

								// record.DateOfObservationDateObject = new Date(
								// 	record.DateOfObservation
								// );
								// if (record.PowerOnDurationMinutes !== null) {
								// 	record.PowerOnDurationMinutes =
								// 		parseInt(
								// 			record.PowerOnDurationMinutes
								// 		) / 60;

								// 	record.AssetOnDurationMinutes = Math.round(
								// 		record.PowerOnDurationMinutes
								// 	);
								// } else {
								// 	record.AssetOnDurationMinutes = 0;
								// }
							});

							dataForTable.forEach((tableEntry:any) => {
								if(tableEntry.IdleDurationInMinutes==undefined) {
									tableEntry.IdleDurationInMinutes = 0;
								}
								if(tableEntry.DriveDurationInMinutes==undefined) {
									tableEntry.DriveDurationInMinutes = 0;
								}
								if(tableEntry.OperateDurationInMinutes==undefined) {
									tableEntry.OperateDurationInMinutes = 0;
								}
								tableEntry.TotalActiveMinutes = tableEntry.IdleDurationInMinutes + tableEntry.DriveDurationInMinutes + tableEntry.OperateDurationInMinutes;
								tableEntry.IdlePercent = Math.round(tableEntry.IdleDurationInMinutes / tableEntry.TotalActiveMinutes * 100);
								tableEntry.DrivePercent = Math.round(tableEntry.DriveDurationInMinutes / tableEntry.TotalActiveMinutes * 100);
								tableEntry.OperatePercent = Math.round(tableEntry.OperateDurationInMinutes / tableEntry.TotalActiveMinutes * 100);
							});

							dataForTable = dataForTable.filter(d=>d.TotalActiveMinutes!=0);

							Global.User.DebugMode && console.log("dataForTable: ", dataForTable);

							this.widgetTabs[3].tableData = dataForTable;

							let assetAggregated = groupBy(data, [
								{ field: 'AssetName' },
							]) as GroupResult[];

							assetAggregated.forEach((group:any) => {
								//group.idleLength = group.items.length;
								group.IdleDurationInSeconds = 0;
								group.DriveDurationInSeconds = 0;
								group.OperateDurationInSeconds = 0;
								//DRIVE_TIME //item.JBTStandardObservationId === '19131'
								const driveitemscount = group.items.forEach((item:any)=> {
									//console.log("item", item);
									//console.log("item DurationInSeconds", item.DurationInSeconds);
									switch (item.SimpleName) {
										case "IDLE_TIME":
										//console.log("IDLE_TIME.");
										group.IdleDurationInSeconds += item.DurationInSeconds > 0 ? item.DurationInSeconds : 0;
										break;
										case "DRIVE_TIME":
										//console.log("DRIVE_TIME.");
										group.DriveDurationInSeconds += item.DurationInSeconds > 0 ? item.DurationInSeconds : 0;
										break;
										case "OPERATE_TIME":
										//console.log("OPERATE_TIME.");
										group.OperateDurationInSeconds += item.DurationInSeconds > 0 ? item.DurationInSeconds : 0;
										break;
								
										default:
											Global.User.DebugMode && console.log("Error");
									}
									// if(item.DurationInSeconds > 0) {
									// 	group.DurationInSeconds += item.DurationInSeconds;
									// }
								
								});

								//console.log("group DurationInSeconds", group.DurationInSeconds);

								group.IdleDurationInMinutes = Math.round(group.IdleDurationInSeconds / 60);
								group.DriveDurationInMinutes = Math.round(group.DriveDurationInSeconds / 60);
								group.OperateDurationInMinutes = Math.round(group.OperateDurationInSeconds / 60);

								group.TotalActiveMinutes = group.IdleDurationInMinutes + group.DriveDurationInMinutes + group.OperateDurationInMinutes;
								group.IdlePercent = Math.round(group.IdleDurationInMinutes / group.TotalActiveMinutes * 100);
								group.DrivePercent = Math.round(group.DriveDurationInMinutes / group.TotalActiveMinutes * 100);
								group.OperatePercent = Math.round(group.OperateDurationInMinutes / group.TotalActiveMinutes * 100);
							});

							assetAggregated.forEach((element:any) => {
								element.tooltip = [];
								element.tooltip[0] = element.value + ": " + element.IdleDurationInMinutes + " minutes (" + element.IdlePercent + "%)";
								element.tooltip[1] = element.value + ": " + element.DriveDurationInMinutes + " minutes (" + element.DrivePercent + "%)";
								element.tooltip[2] = element.value + ": " + element.OperateDurationInMinutes + " minutes (" + element.OperatePercent + "%)";
							});

							this.widgetTabs[3].chartData = assetAggregated;

							// if (this.widgetTabs[3].chartData.length > 0) {
							// 	this.widgetTabs[3].chartData.push({
							// 		value: 'Toggle All Series',
							// 	});
							// }

							// let sortByAssetOnDurationMinutes = data;
							// sortByAssetOnDurationMinutes.sort(
							// 	(a, b) =>
							// 		b.AssetOnDurationMinutes -
							// 		a.AssetOnDurationMinutes
							// );

							//
							// add to summary tab
							//

							// this.summaryArray1[0] =
							// 	sortByAssetOnDurationMinutes[0].AssetOnDurationMinutes; //Highest AssetOnDuration Minutes
							// this.summaryArray1[1] =
							// 	sortByAssetOnDurationMinutes[0].DateOfObservation; //Highest AssetOnDuration DateOfObservation
							// this.summaryArray1[2] =
							// 	sortByAssetOnDurationMinutes[0].AssetName; //Highest AssetOnDuration Asset Name

							this.widgetTabs[3].chartDataIsReady = true;
							this.widgetTabs[0].isLoading =
								this.widgetTabs[3].isLoading = false;
						}
					});
			}


			this.lastUpdatedDate = new Date();

			this.isLoading = false;

			var bounds = this.elem.nativeElement.getBoundingClientRect();
			this.cardResize(bounds);
		}
	}

	createSparkLineChart(chartData: any) {
		this.sparklineData = [
			{
				field: 'y',
				width: 0.75,
				color: 'limegreen',
				data: chartData.map((a) => ({
					y: a.CountOfPoweredOn,
					countOfAssets: a.CountOfAssets,
					dateOfObs: a.DateOfObservation,
				})),
			},
		];
	}

	toggleLegendHidden(tab, index) {
		tab.legendVisible = !tab.legendVisible;
		let foundSetting = this.WidgetTabSettings.find((widgetTabSetting) => {
			return widgetTabSetting.TabIndex === index;
		});

		let statement =
			'API.EditWidgetTabSettings @WidgetId = ' +
			this.widgetObject.WidgetId +
			', @TabIndex = ' +
			index +
			', @IsHidden = ' +
			!tab.legendVisible;
		if (!_.isNil(foundSetting)) {
			statement = statement + ', @Id = ' + foundSetting.Id;
		}
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.WidgetTabSettings = data;
			this.WidgetTabSettings.forEach((tabSetting) => {
				this.widgetTabs[tabSetting.TabIndex].legendVisible =
					!tabSetting.IsLegendHidden;
			});
		});
	}

	animationRedraw() {
		// if (this.selectedMatTabLabel === "Map") {
		// 	this.loadCorrectTabData(true)
		// }
	}

	navigateToTab(index, textLabel) {
		this.tab = this.widgetTabs[index];
		if (this.tab.graphType != undefined && this.tab.graphType.name == undefined)
			this.tab.graphType = Global.tacticalDashboardGraphTypes.find((graphType) => graphType.name == this.tab.graphType);


		clearTimeout(this.timeoutToExpire);
		this.selectedMatTabLabel = textLabel;
		this.selectedTabIndex = index;
		if (this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded === false) {
			this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded = true;
			this.destroyIntervals();
			this.startIntervals();
		}
		this.timeoutToExpire = setTimeout(() => {
			this.widgetObject.SelectedTabIndex = this.selectedTabIndex;
			if (this.widgetObject.RememberSelectedTabIndex) {
				let returnedWidgetAsArray =
					this.dataService.updateTacticalDashboardWidgetRecord(
						this.widgetObject
					);
			}

		}, 5000);
	}

	private buildNavigationOptionsArray() {
		var service = this;

		var navigationWidth = Global.isMobile ? "165px" : "155px";

		service.options = [
			{
				id: 1,
				width: navigationWidth,
				name: "Navigation",
				children: [
					{
						id: 10,
						width: navigationWidth,
						name: "Summary",
						action: () => { service.navigateToTab(0, "Summary");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Assets In Use Current Time",
						action: () => { service.navigateToTab(1, "Assets In Use Current Time");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Total Mins On For Day",
						action: () => { service.navigateToTab(2, "Total Minutes On For Day");  this.navigationOpened=false; }
					},

					{
						id: 10,
						width: navigationWidth,
						name: "Active Time Stacked",
						action: () => { service.navigateToTab(3, "Active Time Stacked");  this.navigationOpened=false; }
					},

					// {
					// 	id: 11,
					// 	width: navigationWidth,
					// 	name: "Raw Data",
					// 	children: [
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Assets In Use Current Time",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[1]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Total Minutes On For Day",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[2]);  this.navigationOpened=false; }
					// 		},

					// 	],


					// }

				],
				root: true,
				opened: false
			},
			{
				id: 2,
				width: navigationWidth,
				name: "Settings",
				children: [
					{
						id: 10,
						width: navigationWidth,
						name: "Site",
						action: () => { service.widgetObject.editWidgetSettings(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Edit Name",
						action: () => { service.widgetObject.editWidgetName(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Reload",
						action: () => { service.dashboardService._reloadedWidget.next(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Delete",
						action: () => { service.widgetObject.deleteFunction();  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Timescope",
						action: () => { service.widgetObject.editTimeScopeSettings(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Interval",
						action: () => { service.widgetObject.editIntervalSettings(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Legend",
						action: () => { service.toggleLegendHidden(service.tab, service.selectedTabIndex);  this.navigationOpened=false; }
					},

					{
						id: 23,		// id has to match id in toggle method below
						width: navigationWidth,
						name: service.widgetObject.RememberSelectedTabIndex == 0 ? "Remember Nav" : "Forget Nav",
						action: () => { service.widgetObject.invertRememberingOfSelectedTabIndex(service.widgetObject); this.toggleRememberingOfSelectedTabIndexName(); this.navigationOpened=false; }
					},

				],
				root: true,
				opened: false
			}
		];

		let optionsDesktop = [
			{
				id: 22,		// id has to match id in toggle method below
				width: navigationWidth,
				name: service.widgetObject.dragEnabled ? "Disable Drag" : "Allow Drag",
				action: () => { service.widgetObject.invertDraggableSetting(service.widgetObject); this.toggleDragName(); this.navigationOpened=false;}
			},
			{
				id: 21,		// id has to match id in toggle method below
				width: navigationWidth,
				name: service.widgetObject.resizeEnabled ? "Disable Resize" : "Allow Resize",
				action: () => { service.widgetObject.invertResizeSetting(service.widgetObject); this.toggleResizeName(); this.navigationOpened=false;}
			},
		];

		if(Global.isMobile == false) {
			service.options[1].children = service.options[1].children.concat(optionsDesktop);
		}

		service.optionsSettingsOnly = service.options.where((item: any) => { return item.id == 2 }).toArray(); //- Settings tab only when no Site selected.

		Global.User.DebugMode && console.log("options built", service.options);

	}


	public editWidgetSettings() {
		this.widgetObject.editWidgetSettings(this.widgetObject)
	}

	public refreshWidget() {
		this.dashboardService._reloadedWidget.next(this.widgetObject);
	}

	public changeWidgetInterval() {
		this.widgetObject.editIntervalSettings(this.widgetObject);
	}

	public changeWidgetTimescope() {
		this.widgetObject.editTimeScopeSettings(this.widgetObject);
	}


	private toggleRememberingOfSelectedTabIndexName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item: any) => { return item.id == 2 }).firstOrDefault();
		var rememberTabSetting = settings.children.where((item: any) => { return item.id == 23 }).firstOrDefault();

		rememberTabSetting.name = this.widgetObject.RememberSelectedTabIndex == 0 ? "Remember Nav" : "Forget Nav";

	}

	private toggleDragName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item: any) => { return item.id == 2 }).firstOrDefault();
		var dragSetting = settings.children.where((item: any) => { return item.id == 22 }).firstOrDefault();

		dragSetting.name = this.widgetObject.dragEnabled ? "Disable Drag" : "Enable Drag";

	}

	private toggleResizeName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item: any) => { return item.id == 2 }).firstOrDefault();
		var resizeSetting = settings.children.where((item: any) => { return item.id == 21 }).firstOrDefault();

		resizeSetting.name = this.widgetObject.resizeEnabled ? "Disable Resize" : "Allow Resize";

	}

	public checkNavigation(opened: any) {
		Global.User.DebugMode && console.log("checkNavigation opened = %O", opened);
		this.navigationOpened = opened;
		this.isLoading = false;
	}

	public checkSelectedItem(selected: any) {
		Global.User.DebugMode && console.log("checkSelectedItem invoked. selected = %O", selected);
	}

	public openCloseNavigation() {
		Global.User.DebugMode && console.log("openCloseNavigation invoked. navigationOpened current status: = " + this.navigationOpened);
		this.navigationOpened = !this.navigationOpened; //--whatever it was set to, set it to the opposite.
		Global.User.DebugMode && console.log("openCloseNavigation invoked. navigationOpened changed status: = " + this.navigationOpened);
	}

	onResized(event) {
		// this.tagDataPageSize = Math.floor(((event.newRect.height - 120) / this.tagDataRowHeight) * 3);
		// this.eventDataPageSize = Math.floor(((event.newRect.height - 120) / this.eventDataRowHeight) * 3);
		this.parentContainerSize = event.newRect.height;
		if (this.childOverviewGauge !== undefined) {
			this.childOverviewGauge.reFlow();
		}

		// Global.User.DebugMode && console.log("gse-summary: pageSize = " + this.tagDataPageSize);
		// this.newHeight = event.newRect.height;
		// this.oldHeight = event.oldHeight;
		// this.newWidth = event.newRect.width;
		// this.oldWidth = event.oldWidth;
	}

	ngOnDestroy() {
		this.destroyIntervals();
	}

	public toggleSeries(item: any): void {
		let i = item.series.index;

		this.series._results[i].toggleVisibility();
	}

	public toggleAllSeries(e) {
		if (e.text === 'Toggle All Series') {
			this.series._results.forEach((series, index) => {
				this.series._results[index].toggleVisibility();
			});
		}
	}

	initializeTour(): void {
		this.tourService.initialize([
			{
				anchorId: this.widgetObject.WidgetId + '-1',
				content: 'Here you can click on the Site, Update Interval and Timescope areas to change the settings for this widget',
				title: 'Settings',
			},
			{
				anchorId: this.widgetObject.WidgetId + '-2',
				content: 'Navigate to different tabs & Widget Settings',
				title: 'Navigation',
			},
			{
				anchorId: this.widgetObject.WidgetId + '-3',
				content: 'Click on various tiles for more information',
				title: 'Summary Info',
			},
		]);

		this.tourService.start();
	}
}
