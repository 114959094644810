import { Component, OnInit, OnDestroy, Inject, ViewChild, AfterViewInit, Input, Output, EventEmitter } from "@angular/core";

import { Subscription, BehaviorSubject, Subject } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { ISignalRNotification } from "../../_models/signalr-notification.model";

import { DomSanitizer, SafeResourceUrl, SafeUrl } from "@angular/platform-browser";

import { DateTimeQuestion } from "../../_models/dynamic-fields/questions/question-datetime";
import { SliderQuestion } from "../../_models/dynamic-fields/questions/question-slider";
import { QuestionBase } from "../../_models/dynamic-fields/questions/question-base";
import { HttpClient } from "@angular/common/http";
import * as moment from "moment";
import { BsCurrentDateViewComponent } from "ngx-bootstrap/datepicker/themes/bs/bs-current-date-view.component";
import { MatDialog } from "@angular/material/dialog";

import _ from "lodash";
import { DataService } from "../../services/data.service";
import { SignalRCoreService } from "../../services/signalr-core.service";
import { DisplaySetupService } from "../../services/display-setup.service";
import { UtilityService } from "../../services/utility.service";
import { DashboardService } from "../../services/dashboard.service";
import { Global } from "../../_constants/global.variables";
import { DialogModalParentComponent } from "../dialog-modal-parent/dialog-modal-parent.component";
import { v4 as uuidv4 } from 'uuid';
import * as $ from 'jquery';
import { IGlobal } from '../../_models/global.model';
import { IWidgetSignalRGroupObject } from "../../_models/signalr-widget-group.model";

@Component({
	selector: 'lib-site-camera-view',
	templateUrl: './site-camera-view.component.html',
	styleUrls: ['./site-camera-view.component.scss'],
})
export class SiteCameraViewComponent
	implements OnInit, OnDestroy, AfterViewInit
{
	@ViewChild('drawer') drawer;
	@Input() autoFocus: boolean;
	@Input() widgetObject: any;
	@Input() fixedTopGap: number;
	@Input() fixedInViewport: boolean;
	@Input() fixedBottomGap: number;
	@Input() disableRipple: boolean;
	@Input() multiple: boolean;

	public subscriptions: Subscription[] = [];
	tagGraphSingleModalSubscription: Subscription;
	public currentSelectedWidget: any;
	public cameras: any;
	public cameraSource: any;
	public widgetSiteId: number;
	public widgetSite: any;
	public widgetSiteName: string;

	private newHeight: number;
	private oldHeight: number;
	private newWidth: number;
	private oldWidth: number;
	private timerId: any;

	public selectedCamera: any;
	public selectedCameraId: number;
	public previouslySelectedCameraId: number;

	public cameraModified$: any;
	public cameraAdded$: any;
	public selectedCamera$: any;
	public siteChange$: any;

	public headingExtraTitle: string;
	public service: any;
	public camInOurList: any;
	private fullDataCacheExists: boolean = false;
	public isLoading: boolean = true;
	public fullDataCacheExists$: any;
	public showIframe: boolean = false;
	private currentSiteId: number;
	public camerasExistForThisSite: boolean = false;
	public historicalQuestions: QuestionBase<any>[];
	historicalQuestionsFieldsAndValues: any;
	cameraObj: any;
	showHistoricalForm: boolean = false;
	executeFormOptions: any;
	startedViewingLiveCameraDateTime: any;
	startDateTime: any;
	public dateTimeFormat = 'MM/dd/yyyy HH:mm';
	durationHours: number;
	durationMinutes: number;
	public checked = false;
	public global: IGlobal = Global;
	public componentName: string = 'site-camera-view: ';

	public currentUserHasElevatedPrivilege: boolean = false;

	assetsForTheSite: any;
	assetIds: any;
	private autoLevelAlarm = [57434, 56295];
	public noAccessToThisWidget: boolean = false;

	@Output() dashboardWidgetDeleted = new EventEmitter<Object>();
	fullDataCacheSubscription: Subscription;
	signalRTagUpdateSubscription: any;
	widgetGroupSettings: IWidgetSignalRGroupObject;
	assetIdsAsNumbers: any;
	sidenavToggle: Subscription;
	hideIFrame: boolean;
	hideIFrameTimeout: NodeJS.Timeout;

	constructor(
		private http: HttpClient,
		private dataService: DataService,
		private signalRCore: SignalRCoreService,
		private displaySetupService: DisplaySetupService,
		private sanitizer: DomSanitizer,
		private utilityService: UtilityService,
		public dialog: MatDialog,
		private dashboardService: DashboardService,
		private domSanitizer: DomSanitizer
	) {
		this.service = this;
	}

	ngOnInit() {
		this.sidenavToggle = this.dataService.sidenavToggle$.subscribe(
			(obj: any) => {
				Global.User.DebugMode &&
					console.log(
						this.componentName + 'WidgetId: ' + obj.widget.WidgetId
					);
				if (obj.widget.WidgetId == this.widgetObject.WidgetId) {
					this.drawer.toggle();
				}
			}
		);
		this.isLoading = true;
		this.selectedCamera$ = new BehaviorSubject(this.selectedCameraId);

		var service = this;
		Global.User.DebugMode &&
			console.log(
				this.componentName +
					'Global.FullDataCacheExists = ' +
					Global.FullDataCacheExists
			);

		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			Global.User.DebugMode &&
				console.log(
					this.componentName +
						'going to initialize since cache exists...'
				);
			this.initialize();
		}

		this.cameraModified$ = this.signalRCore.broadcastMessages$.pipe(
			filter((msg: ISignalRNotification) => {
				return msg.code == 'Camera Modified';
			})
		);

		this.cameraModified$.subscribe(
			(data: any) => {
				Global.User.DebugMode &&
					console.log(
						this.componentName + 'cameraModified$ = %O',
						data
					);
				Global.User.DebugMode &&
					console.log(
						this.componentName +
							'Camera Modified Event - Camera = %O',
						data
					);
				//See if this is our camera
				if (this.cameras && this.cameras.length > 0) {
					var camInOurList: any = this.cameras.first(
						(c: any) => c.Id == data.Id
					);
					if (camInOurList) {
						this.camInOurList.SelectorButtonLabel =
							data.SelectorButtonLabel;
					}
				}
			},
			(err: Error) => console.error(`Error from cameraModified$: ${err}`)
		);

		this.cameraAdded$ = this.signalRCore.broadcastMessages$.pipe(
			filter((msg: ISignalRNotification) => {
				return msg.code == 'Camera Added';
			})
		);

		this.cameraAdded$.subscribe(
			(data: any) => {
				Global.User.DebugMode &&
					console.log(this.componentName + 'cameraAdded$ = %O', data);
				Global.User.DebugMode &&
					console.log(
						this.componentName + 'Camera Added Event - Camera = %O',
						data
					);

				if (
					!this.selectedCamera &&
					data.AssignedSiteId == this.widgetObject.WidgetSiteId
				) {
					this.dataService
						.SQLActionAsPromise('API.Widget_GetCameraById ' + data)
						.then((camera: any) => {
							this.service.selectedCamera = camera;
						});
				}
			},
			(err: Error) => console.error(`Error from cameraAdded$: ${err}`)
		);

		//Start watching for selected camera Id changes
		this.selectedCamera$.subscribe(
			(cameraId: any) => {
				try {
					var selectedCamera: any = service.cameras.first(
						(c: any) => c.Id == cameraId
					);
					this.switchToNewlySelectedCamera(selectedCamera);
				} catch (error) {}
			},
			(err: Error) => console.error(`Error with selectedCamera$: ${err}`)
		);

		//Start watching for change in widget SiteId...
		this.siteChange$ = new BehaviorSubject(this.widgetObject.WidgetSiteId);
		this.siteChange$.subscribe(
			(siteId: any) => {
				if (
					this.fullDataCacheExists &&
					siteId &&
					this.service.widgetSiteId != siteId
				) {
					var currentSite =
						this.dataService.cache.sitesObject[siteId];
					this.currentUserHasElevatedPrivilege =
						Global.User.isAdmin ||
						Global.User.Privilege[currentSite.Name].Write == true;
					this.service.widgetSite = currentSite;
					this.service.widgetSiteId = siteId;
					this.service.widgetSiteName = currentSite.Name;
					Global.User.DebugMode &&
						console.log(
							this.componentName +
								'widget site changed: widgetSite = %O',
							this.service.widgetSite
						);
					this.service.saveWidgetObject('SiteId', siteId);
					this.service.getAllCamerasForSite(siteId);
				}
			},
			(err: Error) => console.error(`Error with siteChange$: ${err}`)
		);
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + 'ngOnDestroy invoked...');
		if (!_.isNil(this.widgetGroupSettings)) {
			//this.signalRCore.leaveParentSystemGroupsThroughSignalR(this.widgetGroupSettings);
		}
		this.cameraAdded$ && this.cameraAdded$.unsubscribe();
		this.cameraModified$ && this.cameraModified$.unsubscribe();
		this.selectedCamera$ && this.selectedCamera$.unsubscribe();
		this.siteChange$ && this.siteChange$.unsubscribe();
		if (this.signalRTagUpdateSubscription) {
			this.signalRTagUpdateSubscription.unsubscribe();
		}
		if (this.tagGraphSingleModalSubscription !== undefined) {
			this.tagGraphSingleModalSubscription.unsubscribe();
		}
	}

	initialize() {
		Global.User.DebugMode &&
			console.log(this.componentName + 'initialize invoked...');
		var service = this;

		//-- don't need to continue listening for Global.FullDataCacheExists change, so unsubscribe if observable exists. --Kirk T. Sherer, March 25, 2020.
		if (this.fullDataCacheExists$) {
			Global.User.DebugMode &&
				console.log(
					this.componentName +
						'unsubscribing to fullDataCacheExists$...'
				);
			this.fullDataCacheExists$.unsubscribe();
		}

		this.fullDataCacheExists = true;
		Global.User.DebugMode &&
			console.log(this.componentName + 'siteCameraView invoked');

		Global.User.DebugMode &&
			console.log(
				this.componentName + 'this.widgetObject = %O',
				this.widgetObject
			);
		var widget: any = this.widgetObject;
		if (widget.WidgetSiteId) {
			this.noAccessToThisWidget =
				Global.User.PermittedSites.firstOrDefault((site: any) => {
					return site.Id == widget.WidgetSiteId;
				}) == undefined;
			if (!this.noAccessToThisWidget) {
				this.widgetSite =
					this.dataService.cache.sitesObject[widget.WidgetSiteId];
				Global.User.DebugMode &&
					console.log(
						this.componentName + 'this.widgetSite = %O',
						this.widgetSite
					);

				this.widgetSiteName = this.widgetSite && this.widgetSite.Name;
				Global.User.DebugMode &&
					console.log(
						this.componentName +
							'this.widgetSiteName = ' +
							this.widgetSiteName
					);
				this.getAllCamerasForSite(widget.WidgetSiteId);
				service.displaySetupService.setWidgetPanelBodyDimensions(
					widget.WidgetId
				);
				this.widgetObject.isDisplayDataLive = true;
			} else {
				this.isLoading = false;
			}
		} else {
			this.isLoading = false;
		}
	}

	ngAfterViewInit() {
		this.drawer.toggle();
	}

	onResized(event: any) {
		if (this.hideIFrameTimeout) {
			clearTimeout(this.hideIFrameTimeout);
		}
		this.hideIFrame = true;
		this.newHeight = event.newRect.height;
		this.oldHeight = event.oldHeight;
		this.newWidth = event.newRect.width;
		this.oldWidth = event.oldWidth;
		this.hideIFrameTimeout = setTimeout(() => {
			this.hideIFrame = false;
		}, 1000);
	}

	openSettingsIfNoSiteAndCloseIfSiteIsPresent() {
		console.log('Opening settings vm.widgetSite = %O', this.widgetSite);
		if (!this.widgetSite) {
			this.dataService.currentSelectedWidget = this.currentSelectedWidget;
			Global.User.DebugMode &&
				console.log(
					this.componentName +
						"Should be going to Widget Settings since the widget site doesn't exist..."
				);
		}
	}

	changeCamera(camera: any) {
		this.startedViewingLiveCameraDateTime = new Date();
		if (Global.isMobile) {
			camera.trustedSourceURL = this.getSafeResourceUrl(camera);
			camera.sanitizedUrl = this.getSafeUrl(camera);
		} else {
			camera.trustedSourceURL =
				this.sanitizer.bypassSecurityTrustResourceUrl(
					camera.HLSSourceURL +
						'&accessToken=' +
						encodeURIComponent(
							Global.User.currentUser.ODataAccessToken
						) + '&timestamp=' + new Date().getTime()
				);
		}
		this.switchToNewlySelectedCamera(camera);
	}

	getSafeResourceUrl(
		camera: any,
		dateTime1?: any,
		dateTime2?: any
	): SafeResourceUrl {
		var url = this.buildCameraUrl(camera, dateTime1, dateTime2);
		return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
	}

	buildCameraUrl(camera: any, dateTime1?: any, dateTime2?: any): string {
		var sourceURL = camera.HLSSourceURL;
		if (Global.isMobile) {
			//-- if this is an iOS device, you have to use the /playlist instead of /play.
			sourceURL = sourceURL.replace('iopspro', 'iopsmobile');
		}
		var url =
			sourceURL +
			'&accessToken=' +
			encodeURIComponent(Global.User.currentUser.ODataAccessToken);
		if (dateTime1 != undefined && dateTime2 != undefined) {
			url +=
				'&Time1=' +
				dateTime1 +
				'&Time2=' +
				dateTime2 +
				'&SegmentLength=60&timestamp=' + new Date().getTime()
		}
		camera.sourceUrl = url;
		return url;
	}

	getSafeUrl(camera: any, dateTime1?: any, dateTime2?: any): SafeUrl {
		var url = this.buildCameraUrl(camera, dateTime1, dateTime2);
		return this.domSanitizer.bypassSecurityTrustUrl(url);
	}

	//Dynamic form for user to pick startDate,endDate & view historic Download/Playback
	// historicalVideo(camera: any){

	// 	this.historicalQuestionsFieldsAndValues= [];

	// 	var startDateTimeField = new DateTimeQuestion({
	// 		key: "startDateTime",
	// 		label: "Start Date/Time",
	// 		order: 1,
	// 	});
	// 	this.historicalQuestionsFieldsAndValues.push(startDateTimeField);

	// 	var endDateTimeField = new DateTimeQuestion({
	// 		key: "endDateTime",
	// 		label: "End Date/Time",
	// 		order: 2,
	// 	});
	// 	this.historicalQuestionsFieldsAndValues.push(endDateTimeField);

	// 	var downloadField = new SliderQuestion({
	// 		key: "downloadField",
	// 		label: "Download Video",
	// 		order: 3,
	// 	});
	// 	this.historicalQuestionsFieldsAndValues.push(downloadField);

	// 	this.historicalQuestions = this.historicalQuestionsFieldsAndValues;

	// 	this.executeFormOptions = {
	// 		submitButtonText: "Play Video",
	// 	};
	// 	//Show the dynamic form
	// 	this.showHistoricalForm = true;
	// 	this.cameraObj = camera;
	// }

	// submitParametersAndExecute(submittedValues:any){

	// 	let dateTimeNow = new Date();
	// 	var submittedValuesObject = JSON.parse(submittedValues);
	// 	//Format startDate and endDate picked by user in the form to check gainst certain conditions
	// 	let startDateFormatted = new Date(submittedValuesObject['startDateTime']);
	// 	let endDateFormatted = new Date(submittedValuesObject['endDateTime']);

	// 	let diffInMilliSeconds = Math.abs(Date.parse(submittedValuesObject['endDateTime']) - Date.parse(submittedValuesObject['startDateTime']));
	// 	let hours = Math.floor((diffInMilliSeconds / 1000 / 60 / 60) % 60);
	// 	let minutes = Math.floor((diffInMilliSeconds / 1000 / 60) % 60);

	// 	//Conditions to check against user selected startDate and EndDate in the form
	// 	if (hours > 5 && minutes > 0) {
	// 		this.utilityService.showAlert("Please select a time range less than five (5) hours.");
	// 	} else if (startDateFormatted.getDate() < (dateTimeNow.getDate() - 7)) {
	// 		this.utilityService.showAlert("Please select a start date within the last seven (7) Days");
	// 	} else if (endDateFormatted.getDate() < startDateFormatted.getDate()) {
	// 		this.utilityService.showAlert("Please select an End Date beyond the Start Date");
	// 	} else if (endDateFormatted > dateTimeNow) {
	// 		this.utilityService.showAlert("Please select an End Date/Time less than Current Date/Time");
	// 	} else if (diffInMilliSeconds < 60000) {
	// 		this.utilityService.showAlert("Please select at least 1 minute of Playback/Download");
	// 	} else {
	// 		//If user selected Download and PlayVideo in the form
	// 		if (submittedValuesObject['downloadField'] == true) {
	// 			this.downloadVideo(this.cameraObj, submittedValuesObject['startDateTime'], submittedValuesObject['endDateTime']);
	// 		}
	// 		this.playVideo(this.cameraObj, submittedValuesObject['startDateTime'], submittedValuesObject['endDateTime']);
	// 	}
	// }

	showDateTimeForm(camera: any) {
		this.startDateTime = new Date();
		this.startDateTime.setMinutes(this.startDateTime.getMinutes() - 30);
		this.durationHours = 0;
		this.durationMinutes = 30;
		//Show the form
		this.showHistoricalForm = true;
		this.cameraObj = camera;
	}

	executeDateTimeForm() {
		let currentDateTime = new Date();
		let startHours = this.startDateTime.getHours();
		let startMinutes = this.startDateTime.getMinutes();
		//Set End Date/Time to Start Date/Time plus the Duration(Hours + Minutes)
		let endDateTime = new Date(
			this.startDateTime.getFullYear(),
			this.startDateTime.getMonth(),
			this.startDateTime.getDate(),
			startHours + this.durationHours,
			startMinutes + this.durationMinutes
		);

		let startDateFormatted = this.startDateTime.toString();
		let endDateFormatted = endDateTime.toString();

		let diffInMilliSeconds = Math.abs(
			Date.parse(endDateFormatted) - Date.parse(startDateFormatted)
		);
		let hours = diffInMilliSeconds / (1000 * 60 * 60);

		let sevenDays = new Date();
		sevenDays.setDate(currentDateTime.getDate() - 7);

		//Conditions to check against user selected startDate and Duration in the form
		if (this.startDateTime < sevenDays) {
			this.utilityService.showAlert(
				'Please select a start date within the last seven (7) Days'
			);
		} else if (hours > 5) {
			this.utilityService.showAlert(
				'Please select a time range less than five (5) hours'
			);
		} else if (currentDateTime < endDateTime) {
			this.utilityService.showAlert(
				'Please select a Duration Range in the past'
			);
		} else if (diffInMilliSeconds < 60000) {
			this.utilityService.showAlert(
				'Please select at least 1 minute of Playback/Download'
			);
		} else {
			//If user selected Download and PlayVideo in the form
			if (this.checked == true) {
				this.downloadVideo(
					this.cameraObj,
					this.startDateTime,
					endDateTime,
					true
				);
			}
			this.playVideo(this.cameraObj, this.startDateTime, endDateTime);
		}
	}

	downloadVideo(
		camera: any,
		startDate?: any,
		endDate?: any,
		historicDownload?: boolean
	) {
		if (!startDate) {
			//-- If we didn't come in here with a startDate parameter, that means the user clicked the 'Download' button, meaning they want to download the current LIVE video from the point they started viewing it.
			//-- if that's less than 60 seconds, then we'll get 60 seconds prior to the date we started watching the live video. --Kirk T. Sherer, March 23, 2021.

			let currentDateTime = new Date();
			let diffInMilliSeconds = Math.abs(
				Date.parse(currentDateTime.toString()) -
					Date.parse(this.startedViewingLiveCameraDateTime.toString())
			);
			let minutes = diffInMilliSeconds / (1000 * 60);

			if (
				this.utilityService.DateTimeInMilliseconds(currentDateTime) -
					this.utilityService.DateTimeInMilliseconds(
						this.startedViewingLiveCameraDateTime
					) <
				60000
			) {
				//-- it's been less than a minute since we started viewing the current live camera feed.  Make the start date 60 minutes earlier than the startedViewingLiveCameraDateTime.
				this.startedViewingLiveCameraDateTime.setMinutes(
					this.startedViewingLiveCameraDateTime.getMinutes() - 60
				);
			} else if (minutes > 90) {
				//-- if its more than 90 minutes since viewing live cam ,default to only 90 mins of download
				this.startedViewingLiveCameraDateTime.setMinutes(
					this.startedViewingLiveCameraDateTime.getMinutes() - 90
				);
			}
			startDate = this.startedViewingLiveCameraDateTime;
			endDate = currentDateTime;
		}

		//-- converting startDate and endDate into milliseconds for URL being used for download, regardless of whether we're downloading a live video feed or one from the historical form. --Kirk T. Sherer, March 23, 2021.

		let downloadVideoStartDateInMilliseconds =
			this.utilityService.DateTimeInMilliseconds(startDate);
		let downloadVideoEndDateInMilliseconds =
			this.utilityService.DateTimeInMilliseconds(endDate);

		if (historicDownload)
			//8 days of the 60 segment length -- Notes from Grant
			var url =
				(Global.isMobile
					? Global.Video.serverMobileDownloadUrl
					: Global.Video.serverDownloadUrl) +
				'?CameraId=' +
				camera.Id +
				'&accessToken=' +
				encodeURIComponent(Global.User.currentUser.ODataAccessToken) +
				'&Time1=' +
				downloadVideoStartDateInMilliseconds +
				'&Time2=' +
				downloadVideoEndDateInMilliseconds +
				'&SegmentLength=60&Delivery=download';
		// last 90 minutes of the 3 segment length -- Notes from Grant
		else
			var url =
				(Global.isMobile
					? Global.Video.serverMobileDownloadUrl
					: Global.Video.serverDownloadUrl) +
				'?CameraId=' +
				camera.Id +
				'&accessToken=' +
				encodeURIComponent(Global.User.currentUser.ODataAccessToken) +
				'&Time1=' +
				downloadVideoStartDateInMilliseconds +
				'&Time2=' +
				downloadVideoEndDateInMilliseconds +
				'&SegmentLength=3&Delivery=download';

		this.http.get(url, { responseType: 'blob' }).subscribe((data: any) => {
			const a = document.createElement('a');
			const objectUrl = URL.createObjectURL(data);
			a.href = objectUrl;
			a.download =
				'Camera' +
				' ' +
				camera.Name +
				' ' +
				moment(startDate).format('YYYY/MM/DD HH:mm') +
				' to ' +
				moment(endDate).format('YYYY/MM/DD HH:mm') +
				'.mp4';
			a.click();
			URL.revokeObjectURL(objectUrl);
		}),
			(error: Error) => console.error(`Error in download: ${error}`),
			() => {
				console.log('Download process completed.');
				this.utilityService.showToastMessageShared({
					type: 'success',
					message: 'Your requested video has been downloaded.',
				});
			};
	}

	playVideo(camera: any, playVideoStartDate: any, playVideoEndDate: any) {
		// PlayVideo startDate and endDate to milliseconds for playback URL
		let playVideoStartDateInMilliseconds =
			this.utilityService.DateTimeInMilliseconds(playVideoStartDate);
		let playVideoEndDateInMilliseconds =
			this.utilityService.DateTimeInMilliseconds(playVideoEndDate);
		if (Global.isMobile) {
			camera.trustedSourceURL = this.getSafeResourceUrl(
				camera,
				playVideoStartDateInMilliseconds,
				playVideoEndDateInMilliseconds
			);
			camera.sanitizedUrl = this.getSafeUrl(
				camera,
				playVideoStartDateInMilliseconds,
				playVideoEndDateInMilliseconds
			);
		} else {
			camera.trustedSourceURL =
				this.sanitizer.bypassSecurityTrustResourceUrl(
					camera.HLSSourceURL +
						'&accessToken=' +
						encodeURIComponent(
							Global.User.currentUser.ODataAccessToken
						) +
						'&Time1=' +
						playVideoStartDateInMilliseconds +
						'&Time2=' +
						playVideoEndDateInMilliseconds +
						'&SegmentLength=60&timestamp=' + new Date().getTime()
				);
		}

		this.switchToNewlySelectedCamera(camera);
	}

	switchToNewlySelectedCamera(camera: any) {
		if (camera) {
			this.showHistoricalForm = false; //hide the Dynamic datetime form and Play Video
			this.widgetObject.SelectedCameraId = camera.Id;
			this.selectedCamera = camera;
			this.selectedCameraId = camera.Id;
			//Save the selected Camera to Widget Record -- JI -- 08/25/2021
			this.service.saveWidgetObject('SelectedCameraId', camera.Id);

			setTimeout(() => {
				this.showIframe = true;
			}, 200);
		}
	}

	getAllCamerasForSite(siteId: number) {
		this.isLoading = true;
		Global.User.DebugMode && console.log('getAllCamerasForSite invoked...');
		var service = this;
		if (siteId && service.widgetObject.WidgetSiteId != this.currentSiteId) {
			if (
				service.widgetObject.WidgetSiteId != this.currentSiteId &&
				this.currentSiteId
			) {
				//-- only update this behavior subject observable because we did originally have a siteId defined earlier...
				service.siteChange$.next(siteId);
			}

			this.dataService
				.SQLActionAsPromise('API.GetCamerasBySiteId ' + siteId)
				.then(
					(data: any) => {
						Global.User.DebugMode &&
							console.log(
								'API.GetCamerasBySiteId results: %O',
								data
							);
						//service.cameras = data.sort((a: any, b: any) => { return a.Ordinal == b.Ordinal ? 1 : -1 });
						service.cameras = data;
						service.cameras.every((camera: any) => {
							if (Global.isMobile) {
								camera.trustedSourceURL =
									this.getSafeResourceUrl(camera);
								camera.sanitizedUrl = this.getSafeUrl(camera);
							} else {
								camera.trustedSourceURL =
									this.sanitizer.bypassSecurityTrustResourceUrl(
										camera.HLSSourceURL +
											'&accessToken=' +
											encodeURIComponent(
												Global.User.currentUser
													.ODataAccessToken
											) + '&timestamp=' + new Date().getTime()
									);
							}

							return true;
						});

						Global.User.DebugMode &&
							console.log('siteCameras = %O', service.cameras);
						if (service.cameras && service.cameras.length > 0) {
							this.getAutoLevelAlarm(
								service.widgetObject.WidgetSiteId
							);
							this.camerasExistForThisSite = true;
							//If the widget already has a selected camera Id, then find the camera in the set of camera entities retrieved from the database and set it as the selected camera.
							Global.User.DebugMode &&
								console.log(
									'service.widgetObject = %O',
									service.widgetObject
								);
							if (service.widgetObject.SelectedCameraId) {
								var cameras = service.cameras;
								service.selectedCamera = cameras
									.where(
										(c: any) =>
											c.Id ==
											service.widgetObject
												.SelectedCameraId
									)
									.firstOrDefault();
								if (service.selectedCamera == null) {
									service.selectedCamera = cameras.first(); //-- just get the first camera since the selected camera no longer exists. --Kirk T. Sherer, January 11, 2021.
								}
							} else {
								service.selectedCamera =
									service.cameras.first();
								this.service.saveWidgetObject(
									'SelectedCameraId',
									service.selectedCamera.Id
								);
							}

							if (service.selectedCamera == null) {
								service.selectedCamera =
									service.cameras.first();
							}

							Global.User.DebugMode &&
								console.log(
									'service.selectedCamera = %O',
									service.selectedCamera
								);

							service.selectedCameraId =
								service.selectedCamera.Id;
							this.selectedCamera$.next(service.selectedCameraId);
							this.startedViewingLiveCameraDateTime = new Date();

							if (service.selectedCamera) {
								// the trusted source URL will be streaming the video, so no need to connect to SignalR here for frame transmission.
								Global.User.DebugMode &&
									console.log(
										'trusted source URL should be displaying the video now...'
									);
								service.showIframe = true;
							} else {
								service.cameraSource = null;
							}

							service.headingExtraTitle =
								this.service.getHeadingExtraTitle();
						} else {
							Global.User.DebugMode &&
								console.log('There are no cameras');
							service.isLoading = false;
							this.camerasExistForThisSite = false;
							service.cameraSource = null;
							service.selectedCamera = null;
							service.selectedCameraId = null;
						}

						this.currentSiteId = siteId; //-- need to update the current site Id since we're determining whether to go get the cameras again based on this value. --Kirk T. Sherer, July 24, 2020.
						this.isLoading = false;
					},
					(err: Error) =>
						console.error(
							`Error in retrieving cameras for ${siteId}... ${err}`
						)
				);
		}
	}

	getAutoLevelAlarm(siteId: any) {
		if (!this.noAccessToThisWidget) {
			//Get info for Potential AutoLevel Not Deployed Alarm
			this.assetsForTheSite = this.dataService.cache.assets
				.filter((a) => a.SiteId == siteId)
				.filter((a) => ['PBB'].includes(a.Name))
				.filter(
					(a) =>
						this.dataService.cache.systemsObject[a.ParentSystemId]
							.Assets.length > 0
				)
				.map((a) => ({
					Id: a.Id,
				}));

			Global.User.DebugMode &&
				console.log('this.widgetSite = %O', this.assetsForTheSite);

			this.assetIds = this.assetsForTheSite
				.map((a) => a.Id.toString())
				.join(',');
			this.assetIdsAsNumbers = this.assetsForTheSite.map((a) => a.Id);
			this.dataService
				.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
					this.assetIds,
					false,
					this.autoLevelAlarm.toString()
				)
				.subscribe((data) => {
					Global.User.DebugMode &&
						console.log(
							': dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds data = %O',
							data
						);
					//Assign PotentialAutoLevelNotDeployedAlarm to the Camera Object
					if (data != null && data.length > 0) {
						this.cameras.every((camera: any) => {
							let potentialAutoLevelNotDeployedAlarmTag = data
								.where(
									(c: any) =>
										c.GateName == camera.SystemName &&
										c.JBTStandardObservationId == 57434
								)
								.firstOrDefault();
							if (potentialAutoLevelNotDeployedAlarmTag != null) {
								camera.potentialAutoLevelNotDeployedAlarm =
									potentialAutoLevelNotDeployedAlarmTag.LastObservationTextValue ==
									'1'
										? true
										: false;
								camera.potentialAutoLevelNotDeployedAlarmTagId =
									potentialAutoLevelNotDeployedAlarmTag.Id;
							}
							let autoLevelMLAlarmTag = data
								.where(
									(c: any) =>
										c.GateName == camera.SystemName &&
										c.JBTStandardObservationId == 56295
								)
								.firstOrDefault();
							if (autoLevelMLAlarmTag != null) {
								camera.autoLevelMLAlarm =
									autoLevelMLAlarmTag.LastObservationTextValue ==
									'1'
										? true
										: false;
								camera.autoLevelMLAlarmTagId =
									autoLevelMLAlarmTag.Id;
							}
							camera.autoLevelAlarmStatus =
								camera.potentialAutoLevelNotDeployedAlarm ||
								camera.autoLevelMLAlarm;
							return true;
						});
					}
				});
		}
	}

	openTagGraphSingle(tagId): void {
		const tagGraphSingleModal = this.dialog.open(
			DialogModalParentComponent,
			{
				width: '80%',
				height: '70%',
				data: {
					graphId: uuidv4(),
					TagId: tagId,
					widgetNameDisplay: 'Tag Graph',
					WidgetName: 'tag-graph',
					isDisplayDataLive: true,
					timeZoneType: this.dashboardService.determineTimeZoneType(
						this.widgetObject
					),
				},

				maxWidth: '100vw',
				maxHeight: '100vh',
			}
		);
		this.tagGraphSingleModalSubscription = tagGraphSingleModal
			.afterClosed()
			.subscribe((result) => {
				Global.User.DebugMode && console.log('The modal was closed');
				this.tagGraphSingleModalSubscription.unsubscribe();
			});
	}

	getHeadingExtraTitle() {
		if (this.widgetSite) {
			return ' - ' + this.widgetSite.Name;
		}
	}

	widgetWasDeleted(event: boolean) {
		this.dashboardWidgetDeleted.emit(this.widgetObject);
	}

	saveWidgetObject(fieldName: string, fieldValue: string) {
		this.dataService
			.SQLActionAsPromise(
				'API.Widget_UpdateRecordByIdAndFieldName ' +
					this.widgetObject.WidgetId +
					", '" +
					fieldName +
					"', '" +
					fieldValue +
					"'"
			)
			.then((data: any) => {
				Global.User.DebugMode && console.log('data updated: %O', data);
			});
	}
}
