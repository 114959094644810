import {
	Component,
	Input,
	ViewEncapsulation,
	EventEmitter,
	Output,
	ViewChild,
	ElementRef,
	OnDestroy,
	AfterViewInit,
	Optional,
	NgZone,
	OnInit,
} from '@angular/core';
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
	MatDialog,
} from '@angular/material/dialog';
import _ from 'lodash';
import { process, State } from '@progress/kendo-data-query';
import { GridSettings } from '../../_models/grid-settings.interface';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DataService } from '../../services/data.service';
import { SignalRCoreService } from '../../services/signalr-core.service';
import { UtilityService } from '../../services/utility.service';
import { Global } from '../../_constants/global.variables';
import { DashboardService } from '../../services/dashboard.service';
import { KendoGridParentComponent } from '../kendo-grid-parent/kendo-grid-parent.component';
import { IWidgetSignalRGroupObject } from '../../_models/signalr-widget-group.model';
import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';
import { ITag } from '../../_models/tag.model';
import { ITagNamePrefixSubject } from '../../_models/tag-name-prefix-subject.model';
import { v4 as uuidv4 } from 'uuid';

@Component({
	encapsulation: ViewEncapsulation.None,
	selector: 'lib-last-thousand-grid',
	templateUrl: './last-thousand-grid.component.html',
	styleUrls: ['./last-thousand-grid.component.scss'],
})
export class LastThousandGridComponent implements AfterViewInit, OnInit, OnDestroy {
	@Input() @Optional() widgetObject: any;
	@Input() configuration: any;
	@Input() gridSettings: GridSettings;
	@Input() parentContainerSize: any;

	@Output() callSave: any = new EventEmitter<any>();
	@Output() trendBreadcrumbsEvent: any = new EventEmitter<any>();
	@ViewChild('kendoGrid') kendoGrid;
	@ViewChild('calculatedDataRow') calculatedDataRow: ElementRef;
	@ViewChild('tagDataGrid') tagDataGrid: KendoGridParentComponent;
	gridData = [];
	emptyRecordsMessage: String = 'No Data';
	rowHeight = 26;
	isDataLoading: boolean = true;
	eventDataPageSize: number;
	componentName: string = 'last-1000-grid-tab: ';
	fullDataCacheSubscription: Subscription;
	timeZoneType: any;
	widgetGroupSettings: IWidgetSignalRGroupObject;
	public gridContextMenuItems: Array<string> = [];
	tagGraphSingleModalSubscription: Subscription;
	timeScopeObject: { queryStartDate: any; queryEndDate: any; };
	public guid: string;

	constructor(
		public dataService: DataService,
		private signalRCore: SignalRCoreService,
		private utilityService: UtilityService,
		private dashboardService: DashboardService,
		public dialog: MatDialog,
		private zone: NgZone
	) {
		this.allData = this.allData.bind(this);
	}

	ngAfterViewInit() {
		setTimeout(() => {
			if (!Global.FullDataCacheExists) {
				this.fullDataCacheSubscription =
					this.dataService.fullDataCacheExists$.subscribe(
						(data: any) => {
							if (data === true) {
								this.initialize();
								this.fullDataCacheSubscription.unsubscribe();
							}
						}
					);
			} else {
				this.initialize();
			}
		}, 500);


	}

	ngOnInit() {
		this.guid = this.dataService.guid();
		
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + "ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);

		if (this.tagGraphSingleModalSubscription) {
			this.tagGraphSingleModalSubscription.unsubscribe();
		}
	}

	public onRightClickSelect({ dataItem, item }): void {
		if (item === 'Quick Trend of Tag') {
			const tagGraphSingleModal = this.dialog.open(
				DialogModalParentComponent,
				{
					width: '95%',
					height: '80%',
					data: {
						graphId: uuidv4(),
						TagIds: [dataItem.TagId],
						widgetNameDisplay: 'Tag Graph',
						WidgetName: 'tag-graph',
						isDisplayDataLive: true,
						timeZoneType:
							this.dashboardService.determineTimeZoneType(
								this.widgetObject
							),
					},
					maxWidth: '100vw',
					maxHeight: '100vh',
				}
			);
			this.tagGraphSingleModalSubscription = tagGraphSingleModal
				.afterClosed()
				.subscribe((result) => {
					this.tagGraphSingleModalSubscription.unsubscribe();
				});
		}
		else if (item === 'Trend Using Playback - 5 Min Before' || item === 'Trend Using Playback - 2 Hr Before') {
			this.trendBreadcrumbsEvent.emit({dataItem: dataItem, selection: item});
		}
	}

	initialize() {
		this.gridContextMenuItems = ['Quick Trend of Tag'];
		if (this.configuration.gseOnly) {
			//if row name has speeding?
			this.gridContextMenuItems.push('Trend Using Playback - 5 Min Before');
			this.gridContextMenuItems.push('Trend Using Playback - 2 Hr Before');
		}


		this.timeZoneType = this.dashboardService.determineTimeZoneType(
			this.widgetObject
		);
		const localDateCol = this.gridSettings.columnsConfig.find(
			(col) => col.field == 'JavascriptDate'
		);
		const siteDateCol = this.gridSettings.columnsConfig.find(
			(col) => col.field == 'SiteLocalJavascriptDate'
		);
		const utcDateCol = this.gridSettings.columnsConfig.find(
			(col) => col.field == 'UTCJavascriptDate'
		);


		localDateCol.hidden = this.timeZoneType !== 'User Time';
		siteDateCol.hidden = this.timeZoneType !== 'Site Time';
		utcDateCol.hidden = this.timeZoneType !== 'UTC Time';
		localDateCol.includeInChooser = !localDateCol.hidden;
		siteDateCol.includeInChooser = !siteDateCol.hidden;
		utcDateCol.includeInChooser = !utcDateCol.hidden;

		if (this.configuration.alarmsOnly) {
			const localEndDateCol = this.gridSettings.columnsConfig.find(
				(col) => col.field == 'JavascriptEndDate'
			);
			const siteEndDateCol = this.gridSettings.columnsConfig.find(
				(col) => col.field == 'SiteLocalJavascriptEndDate'
			);
			const utcEndDateCol = this.gridSettings.columnsConfig.find(
				(col) => col.field == 'UTCJavascriptEndDate'
			);
			localEndDateCol.hidden = this.timeZoneType !== 'User Time';
			siteEndDateCol.hidden = this.timeZoneType !== 'Site Time';
			utcEndDateCol.hidden = this.timeZoneType !== 'UTC Time';
			localEndDateCol.includeInChooser = !localEndDateCol.hidden;
			siteEndDateCol.includeInChooser = !siteEndDateCol.hidden;
			utcEndDateCol.includeInChooser = !utcEndDateCol.hidden;
		}

		this.eventDataPageSize = Math.floor(
			((this.parentContainerSize - 120) / this.rowHeight) * 3
		);
		this.isDataLoading = true;
		this.emptyRecordsMessage = 'Loading Data...';
		let statement;
		let assetId = this.configuration.assetId ? this.configuration.assetId : this.widgetObject.WidgetAssetId;
		//Historic Faults
		if (this.configuration.alarmsOnly && this.configuration.gseOnly && !this.configuration.alertsOnly) {
			this.timeScopeObject =
				this.dashboardService.determineProperTimeScopeForWidget({
					widgetObject: this.widgetObject,
					UTCConfiguration: false,
				});
			
			this.timeScopeObject.queryStartDate = (this.timeScopeObject.queryStartDate).toString().contains('/') ? this.convertDateTimeStringToMs(this.timeScopeObject.queryStartDate): this.timeScopeObject.queryStartDate;
			this.timeScopeObject.queryEndDate = (this.timeScopeObject.queryEndDate).toString().contains('/') ? this.convertDateTimeStringToMs(this.timeScopeObject.queryEndDate) : this.timeScopeObject.queryEndDate;
			
			statement = 'API.GetHistoricAlarmsByAssetId @assetId = ' +
			assetId +
			' , @startDateTimeMS = ' +
			this.timeScopeObject.queryStartDate +
			' , @endDateTimeMS = ' +
			this.timeScopeObject.queryEndDate;
		}
		//Historic Alerts
		else if (this.configuration.alarmsOnly && this.configuration.gseOnly && this.configuration.alertsOnly) {
			this.timeScopeObject =
				this.dashboardService.determineProperTimeScopeForWidget({
					widgetObject: this.widgetObject,
					UTCConfiguration: false,
				});

				this.timeScopeObject.queryStartDate = (this.timeScopeObject.queryStartDate).toString().contains('/') ? this.convertDateTimeStringToMs(this.timeScopeObject.queryStartDate): this.timeScopeObject.queryStartDate;
				this.timeScopeObject.queryEndDate = (this.timeScopeObject.queryEndDate).toString().contains('/') ? this.convertDateTimeStringToMs(this.timeScopeObject.queryEndDate) : this.timeScopeObject.queryEndDate;
					
				statement = 'API.GetHistoricAlertsByAssetId @assetId = ' +
				assetId +
				' , @startDateTimeMS = ' +
				this.timeScopeObject.queryStartDate +
				' , @endDateTimeMS = ' +
				this.timeScopeObject.queryEndDate;
		}

		else {
			statement = !this.configuration.alarmsOnly
			? `API.GetLast1000ObservationsForAsset @assetId = ${
				assetId
			  }, @alarmsOnly = ${0}`
			: `API.GetLast1000AlarmsByAssetId @assetId = ${
				assetId
		  	  }`; // Get last 1000 Events or Alarms Data
		}

		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			Global.User.DebugMode &&
				!this.configuration.alarmsOnly &&
				console.log(
					this.componentName +
						': GetLast1000ObservationsForAsset data = %O',
					data
				);
			Global.User.DebugMode &&
				this.configuration.alarmsOnly &&
				console.log(
					this.componentName +
					': GetAlarmsByAssetId data = %O',
					data
				);
			this.dataService
				.UpdateAnyMissingAssetOrJBTStandardObservationForTagsInDataCacheObject(
					data
				)
				.then((response: any) => {
					this.gridData = data.map((d) => ({
						TagId: d.T,
						JBTStandardObservationId: d.J,
						Name: this.dataService.cache.tagsObject[d.T]
							?.JBTStandardObservation?.Name
							? this.dataService.cache.tagsObject[d.T]
									?.JBTStandardObservation?.Name
							: this.dataService.cache.tagsObject[d.T]
									?.ShortTagName,
						JavascriptDate: new Date(d.D),
						SiteLocalJavascriptDate:
							this.utilityService.convertFromUtcToLocalToSite(
								d.D,
								this.configuration.siteUTCTimeOffset
							),
						UTCJavascriptDate:
							this.utilityService.convertFromUtcToLocalToSite(
								d.D,
								0
							),
						JavascriptEndDate: d.E == 0 ? null : new Date(d.E),
						SiteLocalJavascriptEndDate: d.E == 0 ? null :
							this.utilityService.convertFromUtcToLocalToSite(
								d.E,
								this.configuration.siteUTCTimeOffset
							),
						UTCJavascriptEndDate: d.E == 0 ? null :
							this.utilityService.convertFromUtcToLocalToSite(
								d.E,
								0
							),
						Value: d.V,
						ValueWhenActive:
							this.dataService.cache.tagsObject[d.T]
								?.ValueWhenActive,
						EffectiveSeverityLevelId: this.dataService.cache.tagsObject[d.T]?.EffectiveSeverityLevelId,
						Severity: this.dataService.cache.tagsObject[d.T]?.EffectiveSeverityLevelId ?
								  	this.dataService.cache.jbtStandardObservationSeverityLevelsObject[this.dataService.cache.tagsObject[d.T].EffectiveSeverityLevelId].Name :
							 			'Informational',
						Duration: Math.round(d.DurationMS/1000),
						DurationinMins: Math.round(d.DurationMS / 60000),
						UserDateFull: new Date(d.D),
						UTCDateFull: this.utilityService.convertMillisecondsToDateWithTimezoneOffset(d.D, 0),
						SiteDateFull: this.utilityService.convertMillisecondsToDateWithTimezoneOffset(d.D, this.configuration.siteUTCTimeOffset),
						// Get UTC TimeZone offset then call utility service to convert the utc milliseconds to site date

					}));
					this.isDataLoading = false;
					if (this.configuration.alarmsOnly) {
						// this.gridData.unshift(...this.configuration.currentAlarms);
						this.emptyRecordsMessage = 'No recent active alarms.';
					} else {
						this.emptyRecordsMessage = 'No recent events.';
					}
					if (this.tagDataGrid) {
						this.tagDataGrid.gridDataSubject.next(this.gridData);
					}
					this.getSignalRUpdates();
				});

			setTimeout(() => {
				console.log(this.configuration);
				this.saveState();
			}, 1000 * 5);
		});
	}

	getSignalRUpdates() {
		let assetId = this.configuration.assetId ? this.configuration.assetId : this.widgetObject.WidgetAssetId;
		let assetObjectInCache = this.dataService.cache.assetsObject[assetId];
		let tagNamePrefixesString = assetObjectInCache.TagNamePrefix;
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;

		
		this.signalRCore.joinGroups();

		if (this.widgetObject && this.widgetObject.WidgetId !== undefined) {
			this.widgetGroupSettings = {
				WidgetId: this.widgetObject.WidgetId,
				GroupList: tagNamePrefixesString,
				IsPopup: false
			};
		} else {
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: tagNamePrefixesString,
				IsPopup: true,
			};
		}
		Global.User.DebugMode && console.log(this.componentName + "this.widgetGroupSettings = %O", this.widgetGroupSettings);

		this.dataService
			.createSubjectAndSubscribe({ Id: this.guid, 
										 WidgetName: this.widgetObject?.WidgetName != null ? this.widgetObject?.WidgetName : "Last 1,000 Grid" , 
										 TagNamePrefix: [tagNamePrefixesString]  })
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);

				 var activeSubject = this.dataService.returnCorrectActiveSubject(this.guid); 
			
			     Global.User.DebugMode && console.log(this.componentName + "active subjects: %O", activeSubject);
			
			
				activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
					//console.log(this.componentName + "Updating tag we care about: %O", tag);
					var tagWeCareAbout = this.configuration.assetTagIds.firstOrDefault((tagId:any) => { return tagId == tag.Id });
					if (tagWeCareAbout != null) {
						this.updateGridData(tag);
					}
				});
			});
	}

	updateGridData(tagObj: any) {
		
		let newTag = {
			TagId: tagObj.Id != undefined ? tagObj.Id : tagObj.TagId,
			Name:
				tagObj.JBTStandardObservation?.Name != undefined
					? tagObj.JBTStandardObservation?.Name
					: this.dataService.cache.jbtStandardObservationsObject[
							tagObj.JBTStandardObservationId
					  ]?.Name != undefined
					? this.dataService.cache.jbtStandardObservationsObject[
							tagObj.JBTStandardObservationId
					  ]?.Name
					: this.dataService.cache.tagsObject[
							tagObj.Id != undefined ? tagObj.Id : tagObj.TagId
					  ]?.ShortTagName,
			JavascriptDate: tagObj.JavascriptDate,
			SiteLocalJavascriptDate: tagObj.SiteLocalJavascriptDate,
			UTCJavascriptDate: tagObj.UTCJavascriptDate,
			Value:
				tagObj.Value != undefined
					? tagObj.Value
					: tagObj.LastObservationTextValue,
			ValueWhenActive: tagObj.ValueWhenActive,
			EffectiveSeverityLevelId: this.dataService.cache.tagsObject[tagObj.T]?.EffectiveSeverityLevelId,
			Severity: this.dataService.cache.tagsObject[tagObj.T]?.EffectiveSeverityLevelId ?
								  	this.dataService.cache.jbtStandardObservationSeverityLevelsObject[this.dataService.cache.tagsObject[tagObj.T].EffectiveSeverityLevelId].Name :
							 			'Informational',
		};

		if (
			(!this.configuration.alarmsOnly &&
				(newTag.Severity == 'Warning' ||
					newTag.Severity == 'Informational')) ||
			(this.configuration.alarmsOnly &&
				(newTag.ValueWhenActive == newTag.Value ||
					(!_.isNil(newTag.ValueWhenActive) &&
						newTag.Value == '1')) &&
				(newTag.Severity == 'Critical' || newTag.Severity == 'Alarm'))
		) {
			this.gridData.push(newTag);

			if (this.configuration.gridInView) {
				// this.gridData.unshift(newTag);
				if (this.tagDataGrid) {
					this.tagDataGrid.gridDataSubject.next(this.gridData);
				}
			}
		}
	}

	public updateDataAfterTabSwitch() {
		if (this.tagDataGrid) {
			this.tagDataGrid.gridDataSubject.next(this.gridData);
		}
	}

	// declare callback functions (onChange, onColumnresize etc)

	saveState() {
		// if (this.configuration.isFromWidget) { // don't save grid settings if pca summary is a popup (no widgetId) check isFromWidget
		this.callSave.next(this.componentName);
		console.log(this.configuration.gridSettings);

		// }
	}

	public allData(): ExcelExportData {
		console.log(this);
		const result: ExcelExportData = {
			data: process(this.gridData, {
				group: this.gridSettings.state.group,
				sort: this.gridSettings.state.sort,
			}).data,
			group: this.gridSettings.state.group,
		};

		return result;
	}

	convertDateTimeStringToMs(dateTimeString: string): number {
		const date = new Date(dateTimeString);
		return date.getTime();
	  }
}
