import { Component, AfterViewInit, Input, EventEmitter, ViewChild, ElementRef, ViewChildren, OnInit } from "@angular/core";
import _ from "lodash";
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { TacticalDashboardOverviewGaugeComponent } from '../../components/tactical-dashboard-overview-gauge/tactical-dashboard-overview-gauge.component';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/data.service';
import { DashboardService } from '../../services/dashboard.service';
import { Global } from '../../_constants/global.variables';
import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';
import moment from "moment";
import { IGlobal } from "../../_models/global.model";
import { UtilityService } from "../../services/utility.service";
import { TacticalWidgetSummaryTilePopupComponent } from '../../components/tactical-widget-summary-tile-popup/tactical-widget-summary-tile-popup.component';
import { TourService } from 'ngx-ui-tour-md-menu';
import { v4 as uuidv4 } from 'uuid';

@Component({
	selector: 'lib-tactical-gstop-five-alarms',
	templateUrl: './tactical-gstop-five-alarms.component.html',
	styleUrls: ['./tactical-gstop-five-alarms.component.scss'],
})
export class TacticalGstopFiveAlarmsComponent implements AfterViewInit, OnInit {
	public graphTypes: string[] = ['area', 'bar', 'column', 'donut', 'horizontalWaterfall', 'line', 'verticalArea', 'verticalLine', 'waterfall'];
	public graphShow: boolean = true;

	@ViewChildren('seriesItems')
	public series: any;

	@Input() private dashboardTimeScopeChanged: EventEmitter<any>;
	@Input() private widgetResizedEvent: EventEmitter<any>;
	@ViewChild('childOverviewGauge')
	childOverviewGauge: TacticalDashboardOverviewGaugeComponent;
	public chartDataIsReady: boolean = false;
	public isCollapsed = false;
	public global: IGlobal = Global;

	@Input() widgetObject: any;
	h1TitlePercent: number;
	componentSubtitle: String = '';
	percentCalculated: boolean = false;
	h1Number: number = undefined;
	h4Percent: number = undefined;
	updateControllerInterval: NodeJS.Timeout;
	totalAssetLength: any;
	lastUpdatedDate: Date;
	displayTimeAgoMessage: any;
	@Input() private dashboardUpdateIntervalChanged: EventEmitter<any>;
	dashboardUpdateIntervalSubscription: any;
	secondsAgoInterval: NodeJS.Timeout;
	fullDataCacheSubscription: any;
	dialogModalData: any;
	chartData: any;
	noDataForSite: boolean = false;
	chartBackgroundColor: string = '#27304C';
	chartLabelColors: string = 'white';
	gridLineColor: string = 'white';
	axisItemColor: string = 'white';
	colorChangedSubscription: any;
	theme: string;
	summaryArray = [];
	sparklineData = [];
	headerGridTitle = 'grid-title-lg';
	fontSize1 = 'medium';
	fontSize2 = 'small';
	// cardClass1 = 'card-tile-lg';
	// cardClass2 = 'card-tile-md';
	// cardWideClass2 = 'card-wide-tile-lg';
	// gridNumberTitleClass = 'grid-number-title-lg';
	contentHeight: any;
	holeSize = 60;
	holeCenterTextClass = 'donut-center-text-lg';
	donutClass = 'donut-lg';
	cardTileCenterTitleClass = 'grid-card-title-center-sm';
	cardTileCenterDonutTitleClass = 'grid-card-title-center-md';
	cardWideTileCenterTitleClass = 'grid-wide-card-title-center-sm';
	widgetResizedSubscription: any;
	widgetNavigateToFirstTabSubscription: Subscription;
	widgetToggleLegendHiddenSubscription: Subscription;

	barChartData: any[] = [
		{ Status: 10 },
		{ Status: 20 },
		{ Status: 200 },
		{ Status: 200 },
	];
	public userDefinedAlarmObservationIds: any = [
		56442, 56443, 56444, 56445, 56446, 56447, 56448, 56449, 56450, 56451,
		56452, 56453, 56454, 56455, 56456, 56457, 56458, 56459, 56460, 56461,
	];
	displayNoUpdateIntervalMessage: boolean;
	updateInterval: any;
	timeScopeObject: any;

	isLoading: boolean;

	public tab: any;
	public widgetTabs: any = [
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Summary',
			tooltip:
				'Summary of the Top Five  Faults.Click on the info to navigate to corresponding tab for details.',
		}, {
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Top  Faults',
			graphType: this.graphTypes[1],
			downloadableData: true,
			tooltip:
				'Bar chart showing the top 5 fault types that have occurred with the selected configuration. ',
			groupable: true,
			groupColumns: [
				{ field: 'timesAlarmOccurred', dir: 'desc' },
				{ field: 'AlarmName', dir: 'asc' },
			],
			tableStructure: [
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},
				{
					field: 'GateName',
					title: 'Gate Name',
					_width: 300,
				},
				{
					field: 'ProperAssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'AlarmName',
					title: 'Fault Name',
					_width: 300,
				},
				{
					field: 'timesAlarmOccurred',
					title: 'Times Fault Occurred',
					_width: 300,
				},
			],
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Top  Faults Historical',
			legendVisible: true,
			graphType: this.graphTypes[0],
			tooltip:
				'Line chart showing the top 5 fault types that have occurred over the historical timescope selected.',
			downloadableData: true,
			chartDataIsReady: false,
			groupable: true,
			groupColumns: [
				{ field: 'DateOfObservation' },
				{ field: 'AlarmName' },
			],
			tableStructure: [
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},
				{
					field: 'GateName',
					title: 'Gate Name',
					_width: 300,
				},
				{
					field: 'ProperAssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'AlarmName',
					title: 'Fault Name',
					_width: 300,
				},
				{
					field: 'DurationInSeconds',
					title: 'Duration in Seconds',
					_width: 200,
				},
				{
					field: 'DurationInMinutes',
					title: 'Duration in Minutes',
					_width: 200,
				},
			],
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Top  Faults by Asset',
			graphType: this.graphTypes[1],
			downloadableData: true,
			tooltip:
				'Bar chart showing the faults for the timescope broken down by asset.',
			groupable: true,
			groupColumns: [{ field: 'ProperAssetName' }],
			tableStructure: [
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},
				{
					field: 'GateName',
					title: 'Gate Name',
					_width: 300,
				},
				{
					field: 'ProperAssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'AlarmName',
					title: 'Fault Name',
					_width: 300,
				},
				{
					field: 'DurationInSeconds',
					title: 'Duration in Seconds',
					_width: 200,
				},
				{
					field: 'DurationInMinutes',
					title: 'Duration in Minutes',
					_width: 200,
				},
			],
		},
		// {
		//     isLoading: false,
		//     tabHasBeenLoaded: false,
		//     textLabel: "Total Time in ComsLoss For Day",
		//     tooltip: "Line chart showing total time each asset has been in ComsLoss status over the timescope period.",
		//     downloadableData: true,
		//     chartDataIsReady: false,
		//     groupable: true,
		//     groupColumns: ['ProperAssetName'],
		//     legendVisible: true,
		//     tableStructure: [
		//         {
		//             field: "DateOfObservationDateObject",
		//             title: "Date",
		//             filter: 'date',
		//             _width: 300,
		//             formatOption: 'shortDate'
		//         },
		//         {
		//             field: "SiteName",
		//             title: "Site",
		//             _width: 100
		//         },
		//         {
		//             field: "GateName",
		//             title: "Gate Name",
		//             _width: 300
		//         },
		//         {
		//             field: "ProperAssetName",
		//             title: "Asset Name",
		//             _width: 300,
		//         },
		//         {
		//             field: "AlarmCount",
		//             title: "Alarm Count",
		//             _width: 300
		//         },
		//         {
		//             field: "AlarmDurationSeconds",
		//             title: "Duration in Seconds",
		//             _width: 300
		//         },

		//     ],
		// },
		// {
		//     isLoading: false,
		//     tabHasBeenLoaded: false,
		//     textLabel: "Detailed Alarm Report",
		//     tooltip: "Donut chart showing total time each asset has been in alarm status as a collection of time over the time scope period.",
		//     downloadableData: true,
		//     chartDataIsReady: false,
		//     groupable: true,
		//     legendVisible: true,
		//     groupColumns: ['ProperAssetName'],
		//     tableStructure: [
		//         {
		//             field: "DateOfObservationDateObject",
		//             title: "Date",
		//             filter: 'date',
		//             _width: 300,
		//             formatOption: 'shortDate'
		//         },
		//         {
		//             field: "SiteName",
		//             title: "Site",
		//             _width: 100
		//         },
		//         {
		//             field: "GateName",
		//             title: "Gate Name",
		//             _width: 300
		//         },
		//         {
		//             field: "ProperAssetName",
		//             title: "Asset Name",
		//             _width: 300,
		//         },
		//         {
		//             field: "AlarmName",
		//             title: "Alarm Name",
		//             _width: 300
		//         },
		//         {
		//             field: "DurationInSeconds",
		//             title: "Duration in Seconds",
		//             _width: 300
		//         },
		//         {
		//             field: "DurationInMinutes",
		//             title: "Duration in Minutes",
		//             _width: 300
		//         },
		//         {
		//             field: "AlarmStartDate",
		//             title: "Alarm Start Date",
		//             filter: 'date',
		//             _width: 300,
		//             // formatOption: 'shortDate'
		//         },
		//         {
		//             field: "AlarmEndDate",
		//             title: "Alarm End Date",
		//             filter: 'date',
		//             _width: 300,
		//             // formatOption: 'shortDate'
		//         }

		//     ],
		// },
	];

	gaugeDisplayData: any;
	parentContainerSize: any;
	selectedMatTabLabel: string;
	displayNoTimeScopeMessage: boolean = false;
	dashboardTimeScopeChangedSubscription: any;
	timeoutToExpire: NodeJS.Timeout;
	selectedTabIndex: number = 0;
	widgetTabsChartConfigurations: any;
	widgetTabConfigurationsFinishedMapping: boolean = false;
	WidgetTabSettings: any = [];

	public percentTop5Alarms: number;
	public countOfTop5Alarms;
	public totalCountAlarms;
	public progressBackground: any;

	public options: any = [];
	public optionsSettingsOnly: any = [];
	public navigationOpened: boolean = false;

	public percentTop5AlarmsData = [
		{
		  kind: "top 5",
		  share: 0.7,
		  color: "lightseagreen",
		},
		{
			kind: "other",
			share: 0.3,
			color: "grey",
		},
	];

	ConditionalShadingRecord: any;
	percentTextColor: any = [];
	conditionalColorPercentAmount: any = [];

	constructor(
		public dataService: DataService,
		private dashboardService: DashboardService,
		private dialog: MatDialog,
		private utilityService: UtilityService,
		private elem: ElementRef,
		public tourService: TourService,
	) {
	}

	CreateTheme(theme: string) {
		if (theme === 'light') {
			this.theme = 'light';
			this.chartBackgroundColor = 'white';
			this.chartLabelColors = 'grey';
			this.gridLineColor = 'grey';
			this.axisItemColor = 'grey';
		} else {
			this.theme = 'dark';
			this.chartBackgroundColor = '#27304C';
			this.chartLabelColors = 'white';
			this.gridLineColor = 'white';
			this.axisItemColor = 'white';
		}
	}


	ngOnInit() {

		this.buildNavigationOptionsArray();

	}
	ngAfterViewInit() {

		var bounds = this.elem.nativeElement.getBoundingClientRect();
		this.cardResize(bounds);

		if(Global.isMobile == true) {
			this.contentHeight = 'calc(100% - 20px)';
		}
		else {
			this.contentHeight = 'calc(100% - 120px)';
		}

		let statement =
			'API.GetWidgetTabSettings @WidgetId = ' +
			this.widgetObject.WidgetId;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.WidgetTabSettings = data;
			this.WidgetTabSettings.forEach((tabSetting) => {
				this.widgetTabs[tabSetting.TabIndex].legendVisible =
					!tabSetting.IsLegendHidden;
			});

			Global.User.DebugMode && console.log(this);
		});

		this.ConditionalShadingRecord = this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
			return p.WidgetId == this.widgetObject.WidgetId && p.SummaryTileIndex == 1;
		});

		if (this.ConditionalShadingRecord.length > 0) {
			this.ConditionalShadingRecord.sort((a, b) => parseFloat(a.Value) - parseFloat(b.Value));
		}


		if (this.widgetResizedEvent) {
			this.widgetResizedSubscription = this.widgetResizedEvent.subscribe((data) => {
				if (this.widgetObject.WidgetId == data.item.WidgetId) {
					this.cardResize(data);
					this.fontResize(data);
				}
			});
		}

		this.widgetNavigateToFirstTabSubscription = this.dashboardService._widgetNavigateToFirstTab.subscribe((widgetObject: any) => {
			if (widgetObject && parseInt(this.widgetObject.WidgetId) === parseInt(widgetObject.WidgetId)) {
				this.selectedTabIndex = 0
			}
		});

		this.widgetToggleLegendHiddenSubscription = this.dashboardService._widgetToggleLegendHidden.subscribe((widgetObject: any) => {
			if (widgetObject && parseInt(this.widgetObject.WidgetId) === parseInt(widgetObject.WidgetId)) {
				this.toggleLegendHidden(this.tab ,this.selectedTabIndex);
			}
		});

		this.isLoading = true;
		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			this.initialize();
		}

		this.colorChangedSubscription =
			this.dataService.colorChanged$.subscribe((data: any) => {
				if (data === 'light') {
					this.CreateTheme('light');
				} else {
					this.CreateTheme('dark');
				}
			});
		if (Global.Theme === 'light') {
			this.CreateTheme('light');
		} else {
			this.CreateTheme('dark');
		}

		if (this.dashboardUpdateIntervalChanged) {
			this.dashboardUpdateIntervalSubscription =
				this.dashboardUpdateIntervalChanged.subscribe((data) => {
					Global.User.DebugMode && console.log(data);
					let foundWidgetWithSameWidgetId = data.find(
						(widgetThatWasChanged) => {
							return (
								widgetThatWasChanged.WidgetId ===
								this.widgetObject.WidgetId
							);
						}
					);

					if (!_.isNil(foundWidgetWithSameWidgetId)) {
						//if data (which is list of widgets that had the time zone changed as an array of widgets includes a widget with this widget id, we can assume this widget needs to be updated. needs to initialize with new selected time zone in mind. )
						this.initialize();
					}
				});
		}
		if (this.dashboardTimeScopeChanged) {
			this.dashboardTimeScopeChangedSubscription =
				this.dashboardTimeScopeChanged.subscribe((data) => {
					let foundWidgetWithSameWidgetId = data.find(
						(widgetThatWasChanged) => {
							return (
								widgetThatWasChanged.WidgetId ===
								this.widgetObject.WidgetId
							);
						}
					);

					if (!_.isNil(foundWidgetWithSameWidgetId)) {
						Global.User.DebugMode && console.log('Widget Time Zone Changed');
						this.initialize();
					}
				});
		}
		// this.chartDataIsReady = true;
		this.tab = this.widgetTabs[0];
	}



	openConditionalShadingSettingsPopup(tileNumber) {
		let tileConditionalShadingRecord =
			this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
				return p.WidgetId == this.widgetObject.WidgetId && p.SummaryTileIndex == tileNumber;
			});
		if(tileConditionalShadingRecord.length == 0) {
			tileConditionalShadingRecord.WidgetId = this.widgetObject.WidgetId;
			tileConditionalShadingRecord.SummaryTileIndex = tileNumber;
		}

		var DialogRef = this.dialog.open(TacticalWidgetSummaryTilePopupComponent, {
			width: '50%',
			height: '40%',
			data: tileConditionalShadingRecord,
		});


		DialogRef.afterClosed().subscribe((result) => {
			let tileConditionalShadingRecordLatest =
				this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
					return p.WidgetId == this.widgetObject.WidgetId;
				});
			this.ConditionalShadingRecord = [];
			tileConditionalShadingRecordLatest.forEach((r) => {
				this.ConditionalShadingRecord.push(r);
			});

			this.ConditionalShadingRecord.sort((a, b) => parseFloat(a.Value) - parseFloat(b.Value));
			this.ConditionalColorSummaryTiles();

		});

	}

	fontResize(data: any) {

		if (data.height < 400 || data.width < 400) {
			this.fontSize1 = 'small';
			this.fontSize2 = 'x-small';
		}

		else if ((data.height > 400 && data.height < 500) || (data.width > 400 && data.width < 600)) {
			this.fontSize1 = 'medium';
			this.fontSize2 = 'small';
		}

		else if ((data.height > 500 && data.height < 700) || (data.width > 600 && data.width < 800)) {
			this.fontSize1 = 'large';
			this.fontSize2 = 'medium';
		}
		else if (data.height > 700 || data.width > 800) {
			this.fontSize1 = 'x-large';
			this.fontSize2 = 'large';
	  }
  }


	cardResize(data: any) {

		if (data.height < 440 || data.width < 400) {
			// this.cardClass1 = 'card-tile-md';
			// this.cardClass2 = 'card-tile-md';
			// this.cardWideClass2 = 'card-wide-tile-sm';

			// this.gridNumberTitleClass = 'grid-number-title-md';

			this.holeSize = 55;
			this.holeCenterTextClass = 'donut-center-text-xs';
			this.donutClass = 'donut-sm';

			this.cardTileCenterTitleClass = 'grid-card-title-center-sm';
			this.cardTileCenterDonutTitleClass = 'grid-card-title-center-md';
			this.cardWideTileCenterTitleClass = 'grid-wide-card-title-center-xs';

		}

		else if ((data.height > 440 && data.height < 500) || (data.width > 400 && data.width < 600)) {
			// this.cardClass1 = 'card-tile-md';
			// this.cardClass2 = 'card-tile-md';
			// this.cardWideClass2 = 'card-wide-tile-sm';

			// this.gridNumberTitleClass = 'grid-number-title-md';

			this.holeSize = 60;
			this.holeCenterTextClass = 'donut-center-text-sm';
			this.donutClass = 'donut-sm';

			this.cardTileCenterTitleClass = 'grid-card-title-center-md';
			this.cardTileCenterDonutTitleClass = 'grid-card-title-center-md';
			this.cardWideTileCenterTitleClass = 'grid-wide-card-title-center-md';

		}

		else if ((data.height > 500 && data.height < 700) || (data.width > 600 && data.width < 800)) {
			// this.cardClass1 = 'card-tile-md';
			// this.cardClass2 = 'card-tile-md';
			// this.cardWideClass2 = 'card-wide-tile-sm';

			// this.gridNumberTitleClass = 'grid-number-title-md';

			this.holeSize = 60;
			this.holeCenterTextClass = 'donut-center-text-sm';
			this.donutClass = 'donut-sm';

			this.cardTileCenterTitleClass = 'grid-card-title-center-md';
			this.cardTileCenterDonutTitleClass = 'grid-card-title-center-md';
			this.cardWideTileCenterTitleClass = 'grid-wide-card-title-center-md';

		}
		else if (data.height > 700 || data.width > 800) {
			// this.cardClass1 = 'card-tile-md';
			// this.cardClass2 = 'card-tile-md';
			// this.cardWideClass2 = 'card-wide-tile-sm';

			// this.gridNumberTitleClass = 'grid-number-title-md';

			this.holeSize = 60;
			this.holeCenterTextClass = 'donut-center-text-sm';
			this.donutClass = 'donut-sm';

			this.cardTileCenterTitleClass = 'grid-card-title-center-md';
			this.cardTileCenterDonutTitleClass = 'grid-card-title-center-md';
			this.cardWideTileCenterTitleClass = 'grid-wide-card-title-center-md';

		}

		if (data.width < 400) {
			this.headerGridTitle = 'grid-title-sm';
		}
		else if (data.width > 400 && data.width < 600) {
			this.headerGridTitle = 'grid-title-sm';
		}
		else if (data.width > 600 && data.width < 800) {
			this.headerGridTitle = 'grid-title-lg';
		}
		else if (data.width > 800) {
			this.headerGridTitle = 'grid-title-lg';
		}

	}


	startIntervals() {
		this.updateInterval =
			this.dashboardService.determineProperTacticalDashboardUpdateIntervalForWidget(
				this.widgetObject
			);

		if (this.updateInterval === undefined) {
			this.isLoading = false;
			this.displayNoUpdateIntervalMessage = true;
			return;
		} else {
			this.isLoading = true;
			this.displayNoUpdateIntervalMessage = false;
		}
		let intervalUpdate = this.updateInterval.UpdateInterval * 60000;
		if (this.secondsAgoInterval !== undefined) {
			clearInterval(this.secondsAgoInterval);
			this.secondsAgoInterval = undefined;
		}

		this.retrieveData();

		this.secondsAgoInterval = setInterval(() => {
			if (this.lastUpdatedDate !== undefined) {
				this.displayTimeAgoMessage =
					this.dashboardService.calculateTimeAgo(
						this.lastUpdatedDate
					);
				this.widgetObject.displayTimeAgoMessage =
					this.displayTimeAgoMessage;
			}
		}, 1000);
		if (this.updateControllerInterval !== undefined) {
			clearInterval(this.updateControllerInterval);
			this.updateControllerInterval = undefined;
		}
		this.updateControllerInterval = setInterval(() => {
			this.retrieveData();
		}, intervalUpdate);
	}

	destroyIntervals() {
		if (this.updateControllerInterval) {
			clearInterval(this.updateControllerInterval);
			this.updateControllerInterval = undefined;
		}
		if (this.secondsAgoInterval) {
			clearInterval(this.secondsAgoInterval);
			this.secondsAgoInterval = undefined;
		}
	}

	initialize() {

		if (_.isNil(this.widgetObject.SiteList)) {
			this.isLoading = false;
			return;
		}
		let statement =
			'API.TacticalDashboardsRetrieveAllRecordsForWidgetId @widgetId = ' +
			this.widgetObject.WidgetId +
			', @userId = ' +
			Global.User.currentUser.Id;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.widgetTabsChartConfigurations = data;
			this.widgetTabs.forEach((tab, index) => {
				if (this.widgetTabsChartConfigurations.length > 0) {
					let foundWidgetTabConfiguration =
						this.widgetTabsChartConfigurations.find(
							(configurationrecord) => {
								return (
									configurationrecord.WidgetTabIndex === index
								);
							}
						);
					if (foundWidgetTabConfiguration !== undefined) {
						tab.graphType = Global.tacticalDashboardGraphTypes.find(
							(graphType) =>
								graphType.id ==
								foundWidgetTabConfiguration.ChartTypeId
						);
					}
				}
			});
			this.widgetTabConfigurationsFinishedMapping = true;

			this.selectedTabIndex =
				this.widgetObject.SelectedTabIndex !== undefined
					? this.widgetObject.SelectedTabIndex
					: 0;
			this.selectedMatTabLabel =
				this.widgetTabs[
					this.widgetObject.SelectedTabIndex !== undefined
						? this.widgetObject.SelectedTabIndex
						: 0
				].textLabel;
			this.widgetTabs[
				this.widgetObject.SelectedTabIndex !== undefined
					? this.widgetObject.SelectedTabIndex
					: 0
			].tabHasBeenLoaded = true;

			this.tab = this.widgetTabs[this.selectedTabIndex];

			this.startIntervals();
		});
	}

	public toggleLegendChange(event: any) {
		this.toggleLegendHidden(this.tab, this.selectedTabIndex);
	}

	changeGraphType(event) {
		let i = this.selectedTabIndex;
		this.widgetTabs[i].graphType = event;
		let existingRecord = this.widgetTabsChartConfigurations.find(
			(record) => {
				return record.WidgetTabIndex === i;
			}
		);

		let statement =
			'API.TacticalDashboardAddOrUpdateWidgetChartIndexRecord @widgetId = ' +
			this.widgetObject.WidgetId +
			', @userId = ' +
			Global.User.currentUser.Id +
			', @chartTypeId = ' +
			event.id +
			', @widgetTabIndex = ' +
			i;
		if (existingRecord !== undefined) {
			statement = statement + ', @id = ' + existingRecord.Id;
		}
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.widgetTabsChartConfigurations = data;
			this.utilityService.showToastMessageShared({
				type: 'success',
				message: 'Chart type changed to ' + event.properName,
			});
			//   this.toastr.success("Chart type changed to " + event.properName);
		});
	}

	showDialogModalPopupGrid(tab) {
		const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '100%' : '60%',
			height: Global.isMobile ? '90%' : '80%',
			data: {
				graphId: uuidv4(),
				groupable: tab.groupable,
				groupColumns: tab.groupable === true ? tab.groupColumns : [],
				popupTitle: 'Raw Data: ' + tab.textLabel,
				WidgetName: 'Kendo-Grid',
				tableStructure: tab.tableStructure,
				tableData: tab.tableData,
				canCreateDashboardWidgetFromPopup: false,
			},
			maxWidth: '100vw',
			maxHeight: '100vh',
		});
	}


	showChartAsPopup(tab) {
		var popupData;
		if (tab.textLabel === 'Top  Faults' || tab.textLabel === 'Top  Faults by Asset') {
			popupData = {
				categoryField: 'value',
				field: 'alarmLength',
				popupTitle: 'Raw Chart: ' + tab.textLabel,
				chartTitle: tab.textLabel,
				WidgetName: 'Kendo-Chart',
				chartData: tab.chartData,
				canCreateDashboardWidgetFromPopup: false,
				graphType: tab.graphType?.name,
				legendVisible: tab.legendVisible,
			}
		}
		else if (tab.textLabel === 'Top  Faults Historical') {
			popupData = {
				categoryField: 'DateOfObservationDateObject',
				field: 'DateOfObservation',
				popupTitle: 'Raw Chart: ' + tab.textLabel,
				titleXAxis: 'Total Fault Instances',
				chartTitle: tab.textLabel,
				WidgetName: 'Kendo-Chart',
				chartData: tab.chartData,
				canCreateDashboardWidgetFromPopup: false,
				graphType: tab.graphType?.name,
				legendVisible: tab.legendVisible,
				aggregate: true,
				canIterate: true,
			}
		}

		const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '90%' : '90%',
			height: Global.isMobile ? '90%' : '90%',
			data: popupData,
			maxWidth: '100vw',
			maxHeight: '100vh',
		});
	}

	retrieveData() {
		this.timeScopeObject =
			this.dashboardService.determineProperTimeScopeForWidget({
				widgetObject: this.widgetObject,
				UTCConfiguration: false,
			});
		if (this.timeScopeObject === undefined) {
			this.displayNoTimeScopeMessage = true;
			this.isLoading = false;
		} else {
			this.displayNoTimeScopeMessage = false;
			if (
				this.widgetTabs[0].tabHasBeenLoaded === true ||
				this.widgetTabs[1].tabHasBeenLoaded === true ||
				this.widgetTabs[2].tabHasBeenLoaded === true ||
				this.widgetTabs[3].tabHasBeenLoaded === true
			) {
				this.widgetTabs[0].isLoading =
					this.widgetTabs[1].isLoading =
					this.widgetTabs[2].isLoading =
				this.widgetTabs[3].isLoading =
					true;


				// let startDateMS = moment()
				// 	.utc()
				// 	.add(-this.timeScopeObject.Days, 'days')
				// 	.valueOf();
				// let endDateMS = moment()
				// 	.utc()
				// 	.valueOf();


				let detailStatement =
					'API.TacticalDashboard_GS_Alarms_Detail_FilterByAsset ' +
					" @startDateTimeMS=" +
					this.timeScopeObject.queryStartDate +
					" , @endDateTimeMS=" +
					this.timeScopeObject.queryEndDate +
					" , @accessToken='" +
					Global.User.currentUser.ODataAccessToken +
					"'";
				if (!_.isNil(this.widgetObject.SiteList)) {
					detailStatement =
						detailStatement +
						", @siteIdList = '" +
						this.widgetObject.SiteList +
						"'";
				}
				if (
					!_.isNil(this.widgetObject.WidgetAssetTypeId) &&
					this.widgetObject.WidgetAssetTypeId !== 0
				) {
					detailStatement =
						detailStatement +
						', @assetTypeId = ' +
						this.widgetObject.WidgetAssetTypeId;
				}
				if (
					!_.isNil(this.widgetObject.WidgetAssetId) &&
					this.widgetObject.WidgetAssetId !== 0
				) {
					detailStatement =
						detailStatement +
						', @assetId = ' +
						this.widgetObject.WidgetAssetId;
				}
				this.dataService
					.SQLActionAsPromise(detailStatement)
					.then((data: any) => {

						if (data.length === 0) {
							this.widgetTabs[1].tableData = data;
							this.widgetTabs[1].chartData = data;
							this.widgetTabs[2].tableData = data;
							this.widgetTabs[2].chartData = data;
							this.widgetTabs[3].tableData = data;
							this.widgetTabs[3].chartData = data;

							this.widgetTabs[0].isLoading =
								this.widgetTabs[1].isLoading =
								this.widgetTabs[2].isLoading =
							this.widgetTabs[3].isLoading =
								false;
						} else {
							data.forEach((record) => {
								record.DateOfObservationDateObject = new Date(
									record.DateOfObservation
								);
								record.ProperAssetName =
									record.SiteName +
									' ' +
									record.GateName +
									' ' +
									record.AssetName;
								record.DurationInMinutes = (
									record.DurationInSeconds / 60
								).toFixed(2);
								record.count = 1;
							});

							let tempChartData = groupBy(data, [
								{ field: 'AlarmName' },
							]) as any[];
							tempChartData.forEach((group) => {
								group.alarmLength = group.items.length;
							});
							Global.User.DebugMode && console.log(tempChartData);

							let dateAggregatedData = groupBy(data, [
								{ field: 'DateOfObservation' },
							]) as any[];
							dateAggregatedData.sort(
								(a, b) => b.items.length - a.items.length
							);

							let sortbyDateDesc = groupBy(data, [
								{ field: 'DateOfObservation' },
							]) as any[];
							sortbyDateDesc.forEach((group) => {
								group.alarmLength = group.items.length;
							});
							sortbyDateDesc.sort(
								(a, b) => new Date(b.value).getTime() - new Date(a.value).getTime()
							);

							this.createSparkLineChart(sortbyDateDesc);//Sparkline Data


							tempChartData.forEach((aggregation) => {
								aggregation.items.forEach((record) => {
									record.JavascriptDate = new Date(
										record.DateOfObservation
									);
									record.DateInMilleseconds =
										record.JavascriptDate.getTime();
								});
								aggregation.items = aggregation.items.sort(
									(a, b) => {
										return (
											a.DateInMilleseconds -
											b.DateInMilleseconds
										);
									}
								);
							});

							let assetAggregated = groupBy(data, [
								{ field: 'ProperAssetName' },
							]) as any[];
							assetAggregated.forEach((group) => {
								group.alarmLength = group.items.length;
							});
							Global.User.DebugMode && console.log(assetAggregated);
							let assetDesc = assetAggregated.sort(
								(a, b) => b.items.length - a.items.length
							);
							assetDesc.length =
								assetDesc.length > 5 ? 5 : assetDesc.length;
							this.widgetTabs[3].chartData = assetDesc;
							this.widgetTabs[3].chartDataIsReady = true;
							this.widgetTabs[3].tableData = data;

							let desc = tempChartData.sort(
								(a, b) => b.items.length - a.items.length
							);
							let tempArray = [];
							desc.forEach((aggregation) => {
								aggregation.items.forEach((record) => {
									record.timesAlarmOccurred =
										aggregation.alarmLength;
									tempArray.push(record);
								});
							});
							desc.length =
								tempChartData.length > 5
									? 5
									: tempChartData.length;

							this.countOfTop5Alarms = 0;
							tempChartData.forEach((alarm) => {
								this.countOfTop5Alarms += alarm.alarmLength;
							});

							this.totalCountAlarms = data.length;

							this.percentTop5Alarms = 100 * this.countOfTop5Alarms / this.totalCountAlarms;

							let progress = Math.round(100 *this.countOfTop5Alarms / this.totalCountAlarms);
							this.progressBackground = `conic-gradient(#008fff ${progress}%, #f2f2f4 ${progress}%)`;


							this.ConditionalColorSummaryTiles();


							this.summaryArray[0] = this.totalCountAlarms;// Total  Faults Count
							this.summaryArray[1] = tempChartData[0].value //Most Triggered Alarm Name
							this.summaryArray[2] = dateAggregatedData[0].value//Day with Most  Faults
							this.summaryArray[3] = dateAggregatedData[0].items.length //Day with Most  Faults Count
							this.summaryArray[4] = assetAggregated[0].value //Asset that had Max  Faults

							Global.User.DebugMode && console.log(this.summaryArray);

							this.widgetTabs[1].chartData = desc;
							this.widgetTabs[1].chartDataIsReady = true;
							this.widgetTabs[1].tableData = tempArray;

							this.widgetTabs[2].chartData = tempChartData;
							this.widgetTabs[2].chartDataIsReady = true;
							this.widgetTabs[2].tableData = data;
							Global.User.DebugMode && console.log(data);
							this.widgetTabs[0].isLoading =
								this.widgetTabs[1].isLoading =
								this.widgetTabs[2].isLoading =
							this.widgetTabs[3].isLoading =
									false;
						}


					});
			}

			this.lastUpdatedDate = new Date();

			this.isLoading = false;
		}
	}

	private ConditionalColorSummaryTiles() {
		this.percentTextColor[1] = "lightseagreen";

		var percentOfTop5Alarms = this.countOfTop5Alarms / this.totalCountAlarms;
		var percentOtherAlarms = 1 - percentOfTop5Alarms;
		Global.User.DebugMode && console.log("percentTop5AlarmsData:", this.percentTop5AlarmsData);


		if (this.ConditionalShadingRecord.length > 0) {
			this.ConditionalShadingRecord.forEach((r) => {
				if(r.SummaryTileIndex == 1) {
					switch (r.Operator) {
						case 'Is greater than' :
							if(percentOfTop5Alarms > r.Value/100) {
								this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
							}
							break;
						case 'Is less than' :
							if(percentOfTop5Alarms < r.Value/100) {
								this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
							}
							break;
						case 'Is equal to' :
							var percentOfTop5AlarmsRounded = Math.round(percentOfTop5Alarms * 100)/100;
							if(percentOfTop5AlarmsRounded == r.Value/100) {
								this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
							}
							break;
						case 'Is not equal to' :
							var percentOfTop5AlarmsRounded = Math.round(percentOfTop5Alarms * 100)/100;
							if(percentOfTop5AlarmsRounded != r.Value/100) {
								this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
							}
							break;
					}
				}
			});
		}


		var newData = [
			{
				kind: "top 5",
				share: percentOfTop5Alarms,
				color: this.percentTextColor[1],
			},
			{
				kind: "other",
				share: percentOtherAlarms,
				color: "grey",
			},
		];

		this.percentTop5AlarmsData = newData;
	}

	private buildNavigationOptionsArray() {
		var service = this;

		var navigationWidth = Global.isMobile ? "95px" : "105px";

		service.options = [
			{
				id: 1,
				width: navigationWidth,
				name: "Navigation",
				children: [
					{
						id: 10,
						width: navigationWidth,
						name: "Summary",
						action: () => {service.navigateToTab(0, "Summary"); this.navigationOpened=false;}
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Top  Faults",
						action: () => { service.navigateToTab(1, "Top  Faults"); this.navigationOpened = false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Historical",
						action: () => { service.navigateToTab(2, "Top  Faults Historical"); this.navigationOpened = false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "By Asset",
						action: () => { service.navigateToTab(3, "Top  Faults by Asset"); this.navigationOpened = false; }
					},


					// {
					// 	id: 11,
					// 	width: navigationWidth,
					// 	name: "Raw Data",
					// 	children: [
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Top  Faults",
					// 			action: () => {service.showDialogModalPopupGrid(service.widgetTabs[1]); this.navigationOpened=false;}
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Historical",
					// 			action: () => {service.showDialogModalPopupGrid(service.widgetTabs[2]); this.navigationOpened=false;}
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "By Asset",
					// 			action: () => {service.showDialogModalPopupGrid(service.widgetTabs[3]); this.navigationOpened=false;}
					// 		},

					// 	],


					// }

				],
				root: true,
				opened: false
			},
			{
				id: 2,
				width: navigationWidth,
				name: "Settings",
				children: [
					{
						id: 10,
						width: navigationWidth,
						name: "Site",
						action: () => {service.widgetObject.editWidgetSettings(service.widgetObject); this.navigationOpened=false;}
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Edit Name",
						action: () => {service.widgetObject.editWidgetName(service.widgetObject); this.navigationOpened=false;}
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Reload",
						action: () => {service.dashboardService._reloadedWidget.next(service.widgetObject); this.navigationOpened=false;}
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Delete",
						action: () => {service.widgetObject.deleteFunction(); this.navigationOpened=false;}
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Timescope",
						action: () => {service.widgetObject.editTimeScopeSettings(service.widgetObject); this.navigationOpened=false;}
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Interval",
						action: () => {service.widgetObject.editIntervalSettings(service.widgetObject); this.navigationOpened=false;}
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Legend",
						action: () => {service.toggleLegendHidden(service.tab, service.selectedTabIndex); this.navigationOpened=false;}
					},

					{
						id: 23,		// id has to match id in toggle method below
						width: navigationWidth,
						name: service.widgetObject.RememberSelectedTabIndex == 0 ? "Remember Nav" : "Forget Nav",
						action: () => { service.widgetObject.invertRememberingOfSelectedTabIndex(service.widgetObject); this.toggleRememberingOfSelectedTabIndexName(); this.navigationOpened=false;}
					},

				],
				root: true,
				opened: false
			}
		];

		let optionsDesktop = [
			{
				id: 22,		// id has to match id in toggle method below
				width: navigationWidth,
				name: service.widgetObject.dragEnabled ? "Disable Drag" : "Allow Drag",
				action: () => { service.widgetObject.invertDraggableSetting(service.widgetObject); this.toggleDragName(); this.navigationOpened=false;}
			},
			{
				id: 21,		// id has to match id in toggle method below
				width: navigationWidth,
				name: service.widgetObject.resizeEnabled ? "Disable Resize" : "Allow Resize",
				action: () => { service.widgetObject.invertResizeSetting(service.widgetObject); this.toggleResizeName(); this.navigationOpened=false;}
			},
		];

		if(Global.isMobile == false) {
			service.options[1].children = service.options[1].children.concat(optionsDesktop);
		}

		service.optionsSettingsOnly = service.options.where((item:any) => { return item.id == 2 }).toArray(); //- Settings tab only when no Site selected.

		console.log("options built", service.options);

	}

	public editWidgetSettings() {
		this.widgetObject.editWidgetSettings(this.widgetObject)
	}

	public refreshWidget() {
		this.dashboardService._reloadedWidget.next(this.widgetObject);
	}

	public changeWidgetInterval() {
		this.widgetObject.editIntervalSettings(this.widgetObject);
	}

	public changeWidgetTimescope() {
		this.widgetObject.editTimeScopeSettings(this.widgetObject);
	}

	private toggleRememberingOfSelectedTabIndexName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item:any) => { return item.id == 2 }).firstOrDefault();
		var rememberTabSetting = settings.children.where((item:any) => { return item.id == 23 }).firstOrDefault();

		rememberTabSetting.name = this.widgetObject.RememberSelectedTabIndex == 0 ? "Remember Nav" : "Forget Nav";

	}

	private toggleDragName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item:any) => { return item.id == 2 }).firstOrDefault();
		var dragSetting = settings.children.where((item:any) => { return item.id == 22 }).firstOrDefault();

		dragSetting.name = this.widgetObject.dragEnabled ? "Disable Drag" : "Enable Drag";

	}

	private toggleResizeName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item:any) => { return item.id == 2 }).firstOrDefault();
		var resizeSetting = settings.children.where((item:any) => { return item.id == 21 }).firstOrDefault();

		resizeSetting.name = this.widgetObject.resizeEnabled ? "Disable Resize" : "Allow Resize";

	}

	public checkNavigation(opened: any) {
		Global.User.DebugMode && console.log("checkNavigation opened = %O", opened);
		this.navigationOpened = opened;
		this.isLoading = false;
	}

	public checkSelectedItem(selected: any) {
		Global.User.DebugMode && console.log("checkSelectedItem invoked. selected = %O", selected);
	}

	public openCloseNavigation() {
		Global.User.DebugMode && console.log("openCloseNavigation invoked. navigationOpened current status: = " + this.navigationOpened);
		this.navigationOpened = !this.navigationOpened; //--whatever it was set to, set it to the opposite.
		Global.User.DebugMode && console.log("openCloseNavigation invoked. navigationOpened changed status: = " + this.navigationOpened);
	}

	createSparkLineChart(chartData: any) {
		this.sparklineData = [
			{
				field: 'y',
				width: 0.75,
				color: 'limegreen',
				data: chartData.map((a) => ({
					y: a.alarmLength,
					alarmDate: a.value,
				})),
			},
		];
	}

	toggleLegendHidden(tab, index) {
		tab.legendVisible = !tab.legendVisible;
		let foundSetting = this.WidgetTabSettings.find((widgetTabSetting) => {
			return widgetTabSetting.TabIndex === index;
		});

		let statement =
			'API.EditWidgetTabSettings @WidgetId = ' +
			this.widgetObject.WidgetId +
			', @TabIndex = ' +
			index +
			', @IsHidden = ' +
			!tab.legendVisible;
		if (!_.isNil(foundSetting)) {
			statement = statement + ', @Id = ' + foundSetting.Id;
		}
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.WidgetTabSettings = data;
			this.WidgetTabSettings.forEach((tabSetting) => {
				this.widgetTabs[tabSetting.TabIndex].legendVisible =
					!tabSetting.IsLegendHidden;
			});
		});
	}

	animationRedraw() {
		// if (this.selectedMatTabLabel === "Map") {
		// 	this.loadCorrectTabData(true)
		// }
	}

	navigateToTab(index, textLabel)  {
		this.tab = this.widgetTabs[index];
		if (this.tab.graphType != undefined && this.tab.graphType.name == undefined)
			this.tab.graphType = Global.tacticalDashboardGraphTypes.find((graphType) => graphType.name == this.tab.graphType);


		clearTimeout(this.timeoutToExpire);
		this.selectedMatTabLabel = textLabel;
		this.selectedTabIndex = index;
		if (this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded === false) {
			this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded = true;
			this.destroyIntervals();
			this.startIntervals();
		}
		this.timeoutToExpire = setTimeout(() => {
			this.widgetObject.SelectedTabIndex = this.selectedTabIndex;
			if (this.widgetObject.RememberSelectedTabIndex) {
				let returnedWidgetAsArray =
					this.dataService.updateTacticalDashboardWidgetRecord(
						this.widgetObject
					);
			}

		}, 5000);
	}



	onResized(event) {
		// this.tagDataPageSize = Math.floor(((event.newRect.height - 120) / this.tagDataRowHeight) * 3);
		// this.eventDataPageSize = Math.floor(((event.newRect.height - 120) / this.eventDataRowHeight) * 3);
		this.parentContainerSize = event.newRect.height;
		if (this.childOverviewGauge !== undefined) {
			this.childOverviewGauge.reFlow();
		}

		// Global.User.DebugMode && console.log("gse-summary: pageSize = " + this.tagDataPageSize);
		// this.newHeight = event.newRect.height;
		// this.oldHeight = event.oldHeight;
		// this.newWidth = event.newRect.width;
		// this.oldWidth = event.oldWidth;
	}

	public toggleSeries(item: any): void {
		let i = item.series.index;

		this.series._results[i].toggleVisibility();
	}

	public toggleAllSeries(e) {
		if (e.text === 'Toggle All Series') {
			this.series._results.forEach((series, index) => {
				this.series._results[index].toggleVisibility();
			});
		}
	}

	initializeTour(): void {
		this.tourService.initialize([
			{
                anchorId: this.widgetObject.WidgetId + '-1',
                content: 'Here you can click on the Site, Update Interval and Timescope areas to change the settings for this widget',
                title: 'Settings',
            },
            {
                anchorId:  this.widgetObject.WidgetId + '-2',
                content: 'Navigate to different tabs & Widget Settings',
                title: 'Navigation',
            },
            {
                anchorId:  this.widgetObject.WidgetId + '-3',
                content: 'Click on various tiles for more information',
                title: 'Summary Info',
            },
		]);

		this.tourService.start();
	}

	ngOnDestroy() {
		this.destroyIntervals();
	}

	public labelContent(e: any): string {
		return e.category + ': ' + e.value;
	}

	public labelContentShort(e: any): string {
		return e.value;
	}
}
