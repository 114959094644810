import { Component, AfterViewInit, Input, EventEmitter, ViewChild, ViewChildren, ElementRef } from "@angular/core";
import _ from "lodash";
import { DataService } from "../../services/data.service";
import { DashboardService } from "../../services/dashboard.service";
import { MatDialog } from "@angular/material/dialog";
import { groupBy, GroupResult } from "@progress/kendo-data-query";
import { Subscription } from 'rxjs';
import { TacticalDashboardOverviewGaugeComponent } from "../../components/tactical-dashboard-overview-gauge/tactical-dashboard-overview-gauge.component";
import { ToastrService } from "ngx-toastr";
import { Global } from '../../_constants/global.variables';
import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';
import { UtilityService } from '../../services/utility.service';
import { IGlobal } from "../../_models/global.model";
import { TourService } from 'ngx-ui-tour-md-menu';
import { TacticalWidgetSummaryTilePopupComponent } from '../../components/tactical-widget-summary-tile-popup/tactical-widget-summary-tile-popup.component';
import { v4 as uuidv4 } from 'uuid';

@Component({
	selector: 'lib-gse-equipment-not-used-last',
	templateUrl: './gse-equipment-not-used-last.component.html',
	styleUrls: ['./gse-equipment-not-used-last.component.scss'],
})
export class GseEquipmentNotUsedLastComponent implements AfterViewInit {
	@ViewChildren('seriesItems')
	public series: any;
	@ViewChildren('seriesItemsDriven')
	public seriesDriven: any;
	@ViewChildren('seriesItemsIdle')
	public seriesIdle: any;

	@ViewChild('chart')
	public chart: any;
	public graphTypes: string[] = ['area', 'bar', 'column', 'donut', 'horizontalWaterfall', 'line', 'verticalArea', 'verticalLine', 'waterfall'];


	@ViewChild('childOverviewGauge')
	childOverviewGauge: TacticalDashboardOverviewGaugeComponent;

	@Input() widgetObject: any;
	@Input() private dashboardTimeScopeChanged: EventEmitter<any>;
	@Input() private dashboardUpdateIntervalChanged: EventEmitter<any>;
	@Input() private widgetResizedEvent: EventEmitter<any>;
	public global: IGlobal = Global;

	fullDataCacheSubscription: any;
	colorChangedSubscription: any;
	dashboardUpdateIntervalSubscription: any;
	dashboardTimeScopeChangedSubscription: any;
	selectedMatTabLabel: string;
	updateInterval: any;
	displayNoTimeScopeMessage: boolean = false;
	displayNoUpdateIntervalMessage: boolean = false;
	secondsAgoInterval: NodeJS.Timeout;
	lastUpdatedDate: Date;
	displayTimeAgoMessage: any;
	updateControllerInterval: NodeJS.Timeout;
	parentContainerSize: any;
	widgetTabsChartConfigurations: any;
	theme: string;
	chartBackgroundColor: string = '#27304C';
	chartLabelColors: string = 'white';
	gridLineColor: string = 'white';
	axisItemColor: string = 'white';
	h1Number: number = undefined;
	h4Percent: number = undefined;
	totalAssetLength: any;
	percentCalculated: boolean = false;
	gaugeDisplayData: any;
	chartDataFinalIsReady: boolean = false;
	widgetTabConfigurationsFinishedMapping: boolean = false;
	widgetNavigateToFirstTabSubscription: Subscription;
	widgetToggleLegendHiddenSubscription: Subscription;
	summaryArray = [];
	summaryArray1 = [];
	summaryArray2 = [];
	summaryArray3 = [];
	summaryArray4 = [];
	summaryArray5 = [];
	sparklineData = [];
	fontSize1 = 'medium';
	fontSize2 = 'small';
	cardClass1 = 'card-tile-lg';
	cardClass2 = 'card-tile-md';
	cardWideClass2 = 'card-wide-tile-lg';
	gridNumberTitleClass = 'grid-number-title-lg';
	holeSize = 60;
	holeCenterTextClass = 'donut-center-text-lg';
	donutClass = 'donut-lg';
	headerGridTitle = 'grid-title-lg';
	cardTileCenterTitleClass = 'grid-card-title-center-sm';
	cardWideTileCenterTitleClass = 'grid-wide-card-title-center-sm';
	contentHeight:any;
	widgetResizedSubscription: any;
	timeScopeObject: any;
	ConditionalShadingRecord: any;
	percentTextColor: any = [];
	conditionalColorPercentAmount: any = [];
	public label = {
		visible: false,
	};
	public progressStyles: { [key: string]: any } = {
		background: 'limegreen',
	};

	barChartData: any[] = [
		{ Status: 10 },
		{ Status: 20 },
		{ Status: 200 },
		{ Status: 200 },
	];

	isLoading: boolean;
	public tab: any;
	public widgetTabs: any = [
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Summary',
			tooltip:
				'Summary showing within the current scope settings (Site) the total amount of assets that are currently not being used.',
			downloadableData: true,
			groupable: true,
			groupColumns: [{ field: 'OnDuringTimeScopeBoolean' }],
			tableStructure: [
				{
					field: 'LastEquipmentWasOnDateTime',
					title: 'Last Time Asset Was On',
					filter: 'date',
					_width: 300,
					// formatOption: 'longDate'
				},
				{
					field: 'OnDuringTimeScopeBoolean',
					title: 'On During Time Scope',
					_width: 100,
				},
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},

				{
					field: 'AssetName',
					title: 'Asset Name',
					_width: 100,
				},
			],
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Total Minutes Off For Day',
			tooltip:
				'Detailed line chart showing within the current scope settings (site, asset type) the total amount of minutes an asset was not used during the 24 hour period of the date shown. ',
			downloadableData: true,
			graphType: this.graphTypes[5],
			groupable: true,
			legendVisible: true,
			groupColumns: [{ field: 'AssetName' }],
			tableStructure: [
				{
					field: 'DateOfObservationDateObject',
					title: 'Date',
					filter: 'date',
					_width: 300,
					formatOption: 'shortDate',
				},
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},

				{
					field: 'AssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'AssetNotUsedMinutes',
					title: 'Asset Not Used Minutes',
					_width: 300,
				},
			],
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Assets Used/Not Used Each Day',
			tooltip:
				'Stacked area chart showing how many assets were used and not used over the timescope selected.  ',
			downloadableData: true,
			groupable: true,
			legendVisible: false,
			groupColumns: [
				{ field: 'DateOfObservationDateObject' },
				{ field: 'used' },
			],
			tableStructure: [
				{
					field: 'DateOfObservationDateObject',
					title: 'Date',
					filter: 'date',
					_width: 300,
					formatOption: 'shortDate',
				},
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},

				{
					field: 'AssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'used',
					title: 'Was Asset Used',
					_width: 100,
				},
				{
					field: 'UsageCount',
					title: 'Times Used',
					_width: 100,
				},
				{
					field: 'EquipmentUsedMinutes',
					title: 'Minutes Used',
					_width: 100,
				},
			],
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Assets Used Detail',
			graphType: this.graphTypes[2],
			tooltip:
				'Column chart showing the amount of times an asset was In Use total over the timescope selected.',
			downloadableData: true,
			groupable: true,
			legendVisible: false,
			groupColumns: [],
			tableStructure: [
				{
					field: 'DateOfObservationDateObject',
					title: 'Date',
					filter: 'date',
					_width: 300,
					formatOption: 'shortDate',
				},
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},

				{
					field: 'AssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'DurationInSeconds',
					title: 'Seconds In Use',
					_width: 100,
				},
				{
					field: 'DurationInMinutes',
					title: 'Minutes In Use',
					_width: 100,
				},
				{
					field: 'StartDate',
					title: 'Start Date',
					filter: 'date',
					_width: 300,
					// formatOption: 'shortDate'
				},
				{
					field: 'EndDate',
					title: 'End Date',
					filter: 'date',
					_width: 300,
					// formatOption: 'shortDate'
				},
			],
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Equipment Driven Each Day Duration',
			graphType: this.graphTypes[5],
			tooltip:
				'Line chart showing the amount of minutes an asset was driven total for each day of the selected timescope. ',
			downloadableData: true,
			groupable: true,
			legendVisible: true,
			groupColumns: [{ field: 'DateOfObservationDateObject' }],
			tableStructure: [
				{
					field: 'DateOfObservationDateObject',
					title: 'Date',
					filter: 'date',
					_width: 300,
					formatOption: 'shortDate',
				},
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},
				{
					field: 'AssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'EquipmentUsedMinutes',
					title: 'Drive Time',
					_width: 100,
				},
				{
					field: 'UsageCount',
					title: 'Times Driven',
					_width: 100,
				},
			],
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Equipment Driven Each Day Detail',
			graphType: this.graphTypes[2],
			tooltip:
				'Bar chart showing the amount of times an asset was driven total over the timescope selected.',
			downloadableData: true,
			groupable: true,
			legendVisible: false,
			groupColumns: [],
			tableStructure: [
				{
					field: 'DateOfObservationDateObject',
					title: 'Date',
					filter: 'date',
					_width: 300,
					formatOption: 'shortDate',
				},
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},

				{
					field: 'AssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'DurationInSeconds',
					title: 'Seconds Driven',
					_width: 100,
				},
				{
					field: 'DurationInMinutes',
					title: 'Minutes Driven',
					_width: 100,
				},
				{
					field: 'StartDate',
					title: 'Start Date',
					filter: 'date',
					_width: 300,
					// formatOption: 'shortDate'
				},
				{
					field: 'EndDate',
					title: 'End Date',
					filter: 'date',
					_width: 300,
					// formatOption: 'shortDate'
				},
			],
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Equipment Idle Each Day Duration',
			graphType: this.graphTypes[5],
			tooltip:
				'Line chart showing the amount of minutes an asset was in idle total for each day of the selected timescope. ',
			downloadableData: true,
			groupable: true,
			legendVisible: true,
			groupColumns: [{ field: 'DateOfObservationDateObject' }],
			tableStructure: [
				{
					field: 'DateOfObservationDateObject',
					title: 'Date',
					filter: 'date',
					_width: 300,
					formatOption: 'shortDate',
				},
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},
				{
					field: 'AssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'EquipmentIdleMinutes',
					title: 'Idle Time (Min)',
					_width: 100,
				},
				{
					field: 'UsageCount',
					title: 'Times Idle',
					_width: 100,
				},
			],
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Equipment Idle Each Day Detail',
			graphType: this.graphTypes[2],
			tooltip:
				'Bar chart showing the amount of times an asset was in idle total over the timescope selected.',
			downloadableData: true,
			groupable: true,
			legendVisible: false,
			groupColumns: [],
			tableStructure: [
				{
					field: 'DateOfObservationDateObject',
					title: 'Date',
					filter: 'date',
					_width: 300,
					formatOption: 'shortDate',
				},
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},

				{
					field: 'AssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'DurationInSeconds',
					title: 'Seconds Idle',
					_width: 100,
				},
				{
					field: 'DurationInMinutes',
					title: 'Minutes Idle',
					_width: 100,
				},
				{
					field: 'StartDate',
					title: 'Start Date',
					filter: 'date',
					_width: 300,
					// formatOption: 'shortDate'
				},
				{
					field: 'EndDate',
					title: 'End Date',
					filter: 'date',
					_width: 300,
					// formatOption: 'shortDate'
				},
			],
		},
	];

	public options: any = [];
	public optionsSettingsOnly: any = [];
	public navigationOpened: boolean = false;

	public percentAssetsInUse = [];

	noDataForSite: boolean = false;
	timeoutToExpire: NodeJS.Timeout;
	selectedTabIndex: number = 0;
	WidgetTabSettings: any = [];

	public percentAssetsNotUsed = [];
	Fleet: any;
	assetIdList: string;


	constructor(
		public dataService: DataService,
		private dashboardService: DashboardService,
		private dialog: MatDialog,
		public toastr: ToastrService,
		private utilityService: UtilityService,
		private elem: ElementRef,
		public tourService: TourService,
	) {}
	public labelContent(e: any): string {
		return e.category + ': ' + e.value;
	}

	ngOnInit() {
		this.tab = this.widgetTabs[0];
		this.updateInterval =
			this.dashboardService.determineProperTacticalDashboardUpdateIntervalForWidget(
				this.widgetObject
			);
		this.timeScopeObject =
		this.dashboardService.determineProperTimeScopeForWidget({
			widgetObject: this.widgetObject,
			UTCConfiguration: false,
		});
		this.buildNavigationOptionsArray();
	}

	ngAfterViewInit(): void {
		var bounds = this.elem.nativeElement.getBoundingClientRect();
		this.cardResize(bounds);

		if(Global.isMobile == true) {
			this.contentHeight = 'calc(100% - 20px)';
		}
		else {
			this.contentHeight = 'calc(100% - 120px)';
		}

		let statement =
			'API.GetWidgetTabSettings @WidgetId = ' +
			this.widgetObject.WidgetId;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.WidgetTabSettings = data;
			this.WidgetTabSettings.forEach((tabSetting) => {
				this.widgetTabs[tabSetting.TabIndex].legendVisible =
					!tabSetting.IsLegendHidden;
			});
			console.log(this);
		});

		this.ConditionalShadingRecord = this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
			return p.WidgetId == this.widgetObject.WidgetId;
		});

		if (this.ConditionalShadingRecord.length > 0) {
			this.ConditionalShadingRecord.sort((a, b) => parseFloat(a.Value) - parseFloat(b.Value));
		}

		if (this.widgetResizedEvent) {
			this.widgetResizedSubscription = this.widgetResizedEvent.subscribe((data) => {
				if (this.widgetObject.WidgetId == data.item.WidgetId) {
					this.cardResize(data);
					this.fontResize(data);
				}
			});
		}
		this.widgetNavigateToFirstTabSubscription = this.dashboardService._widgetNavigateToFirstTab.subscribe((widgetObject: any) => {
			if (widgetObject && parseInt(this.widgetObject.WidgetId) === parseInt(widgetObject.WidgetId)) {
				this.selectedTabIndex = 0
			}
		});

		this.widgetToggleLegendHiddenSubscription = this.dashboardService._widgetToggleLegendHidden.subscribe((widgetObject: any) => {
			if (widgetObject && parseInt(this.widgetObject.WidgetId) === parseInt(widgetObject.WidgetId)) {
				this.toggleLegendHidden(this.tab ,this.selectedTabIndex);
			}
		});

		this.isLoading = true;
		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			this.initialize();
		}

		this.colorChangedSubscription =
			this.dataService.colorChanged$.subscribe((data: any) => {
				if (data === 'light') {
					this.CreateTheme('light');
				} else {
					this.CreateTheme('dark');
				}
			});
		if (Global.Theme === 'light') {
			this.CreateTheme('light');
		} else {
			this.CreateTheme('dark');
		}

		if (this.dashboardUpdateIntervalChanged) {
			this.dashboardUpdateIntervalSubscription =
				this.dashboardUpdateIntervalChanged.subscribe((data) => {
					console.log(data);
					let foundWidgetWithSameWidgetId = data.find(
						(widgetThatWasChanged) => {
							return (
								widgetThatWasChanged.WidgetId ===
								this.widgetObject.WidgetId
							);
						}
					);

					if (!_.isNil(foundWidgetWithSameWidgetId)) {
						//if data (which is list of widgets that had the time zone changed as an array of widgets includes a widget with this widget id, we can assume this widget needs to be updated. needs to initialize with new selected time zone in mind. )
						this.initialize();
					}
				});
		}
		if (this.dashboardTimeScopeChanged) {
			this.dashboardTimeScopeChangedSubscription =
				this.dashboardTimeScopeChanged.subscribe((data) => {
					let foundWidgetWithSameWidgetId = data.find(
						(widgetThatWasChanged) => {
							return (
								widgetThatWasChanged.WidgetId ===
								this.widgetObject.WidgetId
							);
						}
					);

					if (!_.isNil(foundWidgetWithSameWidgetId)) {
						console.log('Widget Time Zone Changed');
						this.initialize();
					}
				});
		}
		// this.chartDataIsReady = true;
		this.tab = this.widgetTabs[0];
	}

	fontResize(data: any) {

		if (data.height < 400 || data.width < 400) {
			this.fontSize1 = 'small';
			this.fontSize2 = 'x-small';
		}

		else if ((data.height > 400 && data.height < 500) || (data.width > 400 && data.width < 600)) {
			this.fontSize1 = 'medium';
			this.fontSize2 = 'small';
		}

		else if ((data.height > 500 && data.height < 700) || (data.width > 600 && data.width < 800)) {
			this.fontSize1 = 'large';
			this.fontSize2 = 'medium';
		}
		else if (data.height > 700 || data.width > 800) {
			this.fontSize1 = 'x-large';
			this.fontSize2 = 'large';
	  }
  }

  cardResize(data: any) {

	if (data.height < 400 || data.width < 400) {
		this.cardClass2 = 'card-tile-sm';
		this.cardWideClass2 = 'card-wide-tile-sm';

		this.cardTileCenterTitleClass = 'grid-card-title-center-sm';
		this.cardWideTileCenterTitleClass = 'grid-wide-card-title-center-sm';

		this.holeSize = 50;
		this.holeCenterTextClass = 'donut-center-text-xs';
		this.donutClass = 'donut-sm';
	}

	else if ((data.height > 400 && data.height < 500) || (data.width > 400 && data.width < 600)) {
		this.cardClass2 = 'card-tile-md';
		this.cardWideClass2 = 'card-wide-tile-md';

		this.cardTileCenterTitleClass = 'grid-card-title-center-sm';
		this.cardWideTileCenterTitleClass = 'grid-wide-card-title-center-md';

		this.holeSize = 60;
		this.holeCenterTextClass = 'donut-center-text-sm';
		this.donutClass = 'donut-sm';
	}

	else if ((data.height > 500 && data.height < 700) || (data.width > 600 && data.width < 800)) {
		this.cardClass2 = 'card-tile-md';
		this.cardWideClass2 = 'card-wide-tile-md';

		this.cardTileCenterTitleClass = 'grid-card-title-center-md';
		this.cardWideTileCenterTitleClass = 'grid-wide-card-title-center-md';

		this.holeSize = 60;
		this.holeCenterTextClass = 'donut-center-text-sm';
		this.donutClass = 'donut-sm';
	}
	else if (data.height > 700 || data.width > 800) {
		this.cardClass2 = 'card-tile-lg';
		this.cardWideClass2 = 'card-wide-tile-lg';

		this.cardTileCenterTitleClass = 'grid-card-title-center-md';
		this.cardWideTileCenterTitleClass = 'grid-wide-card-title-center-md';

		this.holeSize = 60;
		this.holeCenterTextClass = 'donut-center-text-sm';
		this.donutClass = 'donut-sm';
	}

	if (data.width < 400) {
		this.headerGridTitle = 'grid-title-sm';
	}
	else if (data.width > 400 && data.width < 600) {
		this.headerGridTitle = 'grid-title-sm';
	}
	else if (data.width > 600 && data.width < 800) {
		this.headerGridTitle = 'grid-title-lg';
	}
	else if (data.width > 800) {
		this.headerGridTitle = 'grid-title-lg';
	}
}

	startIntervals() {
		this.updateInterval =
			this.dashboardService.determineProperTacticalDashboardUpdateIntervalForWidget(
				this.widgetObject
			);

		if (this.updateInterval === undefined) {
			this.isLoading = false;
			this.displayNoUpdateIntervalMessage = true;
			return;
		} else {
			this.isLoading = true;
			this.displayNoUpdateIntervalMessage = false;
		}
		let intervalUpdate = this.updateInterval.UpdateInterval * 60000;
		if (this.secondsAgoInterval !== undefined) {
			clearInterval(this.secondsAgoInterval);
			this.secondsAgoInterval = undefined;
		}
		this.retrieveData();

		this.secondsAgoInterval = setInterval(() => {
			if (this.lastUpdatedDate !== undefined) {
				this.displayTimeAgoMessage =
					this.dashboardService.calculateTimeAgo(
						this.lastUpdatedDate
					);
				this.widgetObject.displayTimeAgoMessage =
					this.displayTimeAgoMessage;
			}
		}, 1000);
		if (this.updateControllerInterval !== undefined) {
			clearInterval(this.updateControllerInterval);
			this.updateControllerInterval = undefined;
		}
		this.updateControllerInterval = setInterval(() => {
			this.retrieveData();
		}, intervalUpdate);
	}

	destroyIntervals() {
		if (this.updateControllerInterval) {
			clearInterval(this.updateControllerInterval);
			this.updateControllerInterval = undefined;
		}
		if (this.secondsAgoInterval) {
			clearInterval(this.secondsAgoInterval);
			this.secondsAgoInterval = undefined;
		}
	}

	initialize() {
		if (_.isNil(this.widgetObject.SiteList) && _.isNil(this.widgetObject.VocationalSettingsJSON)) {	// added fleet settings as an option
			this.isLoading = false;
			return;
		}

		if (!_.isNil(this.widgetObject.VocationalSettingsJSON)) {
			//this.widgetObject.WidgetAssetTypeId = 3;

			var VocationalSettings = JSON.parse(this.widgetObject.VocationalSettingsJSON);
			console.log("VocationalSettings ", VocationalSettings)

			let fleetId = VocationalSettings.id;
			this.widgetObject.WidgetAssetTypeId = VocationalSettings.child.id;

			let assetType = this.dataService.cache.assetTypes.find(
				(assetType) => {
					return assetType.Id == VocationalSettings.child.id;
				}
			);
			this.widgetObject.WidgetAssetTypeName = assetType?.Name;

			this.dataService.GetFleets().subscribe((data: any) => {
				Global.User.DebugMode && console.log(data);
				this.Fleet = data.find((f) => {
					return f.FleetId === fleetId;
				});
				Global.User.DebugMode && console.log(this.Fleet);
			});

			this.dataService.GetFleetAssets(fleetId).subscribe((data: any) => {
				let assetIdListArray = new Array();
				data.forEach(record => {
					assetIdListArray.push(record.AssetId);
				});
				this.assetIdList = assetIdListArray.toString();
			});
		}
		let statement =
			'API.TacticalDashboardsRetrieveAllRecordsForWidgetId @widgetId = ' +
			this.widgetObject.WidgetId +
			', @userId = ' +
			Global.User.currentUser.Id;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.widgetTabsChartConfigurations = data;
			this.widgetTabs.forEach((tab, index) => {
				if (this.widgetTabsChartConfigurations.length > 0) {
					let foundWidgetTabConfiguration =
						this.widgetTabsChartConfigurations.find(
							(configurationrecord) => {
								return (
									configurationrecord.WidgetTabIndex === index
								);
							}
						);
					if (foundWidgetTabConfiguration !== undefined) {
						tab.graphType = Global.tacticalDashboardGraphTypes.find(
							(graphType) =>
								graphType.id ==
								foundWidgetTabConfiguration.ChartTypeId
						);
					}
				}
			});
			this.widgetTabConfigurationsFinishedMapping = true;

			this.selectedTabIndex =
				this.widgetObject.SelectedTabIndex !== undefined
					? this.widgetObject.SelectedTabIndex
					: 0;
			this.selectedMatTabLabel =
				this.widgetTabs[
					this.widgetObject.SelectedTabIndex !== undefined
						? this.widgetObject.SelectedTabIndex
						: 0
				].textLabel;
			this.widgetTabs[
				this.widgetObject.SelectedTabIndex !== undefined
					? this.widgetObject.SelectedTabIndex
					: 0
			].tabHasBeenLoaded = true;
			this.tab = this.widgetTabs[this.selectedTabIndex];
			this.startIntervals();
		});
	}

	showDialogModalPopupGrid(tab) {
		const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '90%' : '60%',
			height: Global.isMobile ? '90%' : '80%',
			data: {
				graphId: uuidv4(),
				groupable: tab.groupable,
				groupColumns: tab.groupable === true ? tab.groupColumns : [],
				popupTitle: 'Raw Data: ' + tab.textLabel,
				WidgetName: 'Kendo-Grid',
				tableStructure: tab.tableStructure,
				tableData: tab.tableData,
				canCreateDashboardWidgetFromPopup: false,
			},
			maxWidth: '100vw',
			maxHeight: '100vh',
		});
	}

	retrieveData() {
		this.timeScopeObject =
		this.dashboardService.determineProperTimeScopeForWidget({
			widgetObject: this.widgetObject,
			UTCConfiguration: false,
		});
		if (this.timeScopeObject === undefined) {
			this.displayNoTimeScopeMessage = true;
			this.isLoading = false;
		} else {
			this.displayNoTimeScopeMessage = false;
			if (this.widgetTabs[0].tabHasBeenLoaded === true) {
				this.widgetTabs[0].isLoading = true;
				let statement =
					"API.TacticalDashboard_GSE_Equipment_NotUsed @accessToken= '" +
					Global.User.currentUser.ODataAccessToken +
					"'";
				if (_.isNil(this.assetIdList) && !_.isNil(this.widgetObject.SiteList)) {
					statement =
						statement +
						", @siteIdList = '" +
						this.widgetObject.SiteList +
						"'";
				}

				statement =
					statement +
					', @startDateTimeMS=' +
				this.timeScopeObject.queryStartDate +
					', @endDateTimeMS= ' +
					this.timeScopeObject.queryEndDate;

				if (
					!_.isNil(this.widgetObject.WidgetAssetTypeId) &&
					this.widgetObject.WidgetAssetTypeId !== 0
				) {
					statement =
						statement +
						', @assetTypeId = ' +
						this.widgetObject.WidgetAssetTypeId;
				}
				if (!_.isNil(this.assetIdList)) {
					statement = statement + ", @assetIdList = '" + this.assetIdList + "'";
				}

				this.dataService
					.SQLActionAsPromise(statement)
					.then((data: any) => {
						Global.User.DebugMode && console.log(data);

						if (data.length === 0) {
							this.summaryArray[0] = 0;
							this.totalAssetLength = 0;
							this.summaryArray[2] = 0.0;

							this.widgetTabs[0].tableData = data;

							this.ConditionalColorSummaryTiles();

							this.widgetTabs[0].isLoading = false;
						} else {
							let h1Number = 0;
							data.forEach((asset) => {
								asset.OnDuringTimeScopeBoolean =
									!!asset.OnDuringTimeScope;

								if (asset.OnDuringTimeScope !== 1) {
									h1Number = h1Number + 1;
								}
							});

							this.summaryArray[0] = h1Number; // Count of Equipment Not Used for the selected timescope
							this.totalAssetLength = data.length;

							if (this.summaryArray[0] === 0) {
								this.summaryArray[2] = 0.0 // Percent Docked
							} else {
								this.summaryArray[2] = parseFloat(((this.summaryArray[0] / this.totalAssetLength) * 100).toFixed(1)); //Percent Docked
							}

							this.widgetTabs[0].tableData = data;

							this.ConditionalColorSummaryTiles();

							this.percentCalculated = true;
							this.widgetTabs[0].isLoading = false;
						}
					});
			}

			if (
				this.widgetTabs[0].tabHasBeenLoaded === true ||
				this.widgetTabs[1].tabHasBeenLoaded === true
			) {
				this.widgetTabs[0].isLoading = this.widgetTabs[1].isLoading =
					true;
				let statement2 =
					'API.TacticalDashboard_Asset_NotUsed_Duration ' +
					'@startDateTimeMS=' +
					this.timeScopeObject.queryStartDate +
					', @endDateTimeMS= ' +
					this.timeScopeObject.queryEndDate +
					" , @accessToken='" +
					Global.User.currentUser.ODataAccessToken +
					"'";
				if (_.isNil(this.assetIdList) && !_.isNil(this.widgetObject.SiteList)) {
					statement2 =
						statement2 +
						", @siteIdList = '" +
						this.widgetObject.SiteList +
						"'";
				}
				if (
					!_.isNil(this.widgetObject.WidgetAssetTypeId) &&
					this.widgetObject.WidgetAssetTypeId !== 0
				) {
					statement2 =
						statement2 +
						', @assetTypeId = ' +
						this.widgetObject.WidgetAssetTypeId;
				}
				if (!_.isNil(this.assetIdList)) {
					statement2 = statement2 + ", @assetIdList = '" + this.assetIdList + "'";
				}

				this.dataService
					.SQLActionAsPromise(statement2)
					.then((data: any) => {
						if (data.length === 0) {
							this.widgetTabs[1].tableData = data;
							this.widgetTabs[1].chartData = data;
							this.widgetTabs[1].isLoading = false;
						} else {
							this.chartDataFinalIsReady = false;
							console.log(data);
							data.forEach((record) => {
								record.DateOfObservationDateObject = new Date(
									record.DateOfObservation
								);
							});
							this.widgetTabs[1].tableData = data;
							this.widgetTabs[1].chartData = groupBy(data, [
								{ field: 'AssetName' },
							]) as GroupResult[];

							

							this.widgetTabs[1].chartData.forEach(item => {
								
								item.items.forEach(element => {
									element.tooltip = element.AssetName + ": " + element.AssetNotUsedMinutes + " minutes";
								});
								
							});



							if (this.widgetTabs[1].chartData.length > 0) {
								this.widgetTabs[1].chartData.push({
									value: 'Toggle All Series',
								});
							}

							


							let sortByAssetNotUsedMinutes = data;
							sortByAssetNotUsedMinutes.sort(
								(a, b) =>
									b.AssetNotUsedMinutes -
									a.AssetNotUsedMinutes
							);
							if (sortByAssetNotUsedMinutes.length > 0) {
								this.summaryArray1[0] =
									sortByAssetNotUsedMinutes[
										sortByAssetNotUsedMinutes.length - 1
									].AssetNotUsedMinutes; //Least AssetNotUsedMinutes
								this.summaryArray1[1] =
									sortByAssetNotUsedMinutes[
										sortByAssetNotUsedMinutes.length - 1
									].DateOfObservation; //Least AssetNotUsedMinutes DateOfObservation
								this.summaryArray1[2] =
									sortByAssetNotUsedMinutes[
										sortByAssetNotUsedMinutes.length - 1
									].AssetName; //Least AssetNotUsedMinutes Asset Name
							}

							this.widgetTabs[1].chartDataIsReady = true;
							this.widgetTabs[0].isLoading =
								this.widgetTabs[1].isLoading = false;
						}
					});
			}

			if (
				this.widgetTabs[0].tabHasBeenLoaded === true ||
				this.widgetTabs[2].tabHasBeenLoaded === true
			) {
				this.widgetTabs[0].isLoading = this.widgetTabs[2].isLoading =
					true;
				let statement3 =
					'API.TacticalDashboard_Equipment_Used_EachDayCount_Duration ' +
					'@startDateTimeMS=' +
					this.timeScopeObject.queryStartDate +
					', @endDateTimeMS= ' +
					this.timeScopeObject.queryEndDate +
					" , @accessToken='" +
					Global.User.currentUser.ODataAccessToken +
					"'";
				if (_.isNil(this.assetIdList) && !_.isNil(this.widgetObject.SiteList)) {
					statement3 =
						statement3 +
						", @siteIdList = '" +
						this.widgetObject.SiteList +
						"'";
				}
				if (
					!_.isNil(this.widgetObject.WidgetAssetTypeId) &&
					this.widgetObject.WidgetAssetTypeId !== 0
				) {
					statement3 =
						statement3 +
						', @assetTypeId = ' +
						this.widgetObject.WidgetAssetTypeId;
				}
				if (!_.isNil(this.assetIdList)) {
					statement3 = statement3 + ", @assetIdList = '" + this.assetIdList + "'";
				}

				this.dataService
					.SQLActionAsPromise(statement3)
					.then((data: any) => {
						if (data.length === 0) {
							this.widgetTabs[2].tableData = data;
							this.widgetTabs[2].chartData = data;
							this.widgetTabs[2].isLoading = false;
						} else {
							this.chartDataFinalIsReady = false;
							console.log(data);
							data.forEach((record) => {
								record.count = 1;

								record.DateOfObservationDateObject = new Date(
									record.DateOfObservation
								);
							});
							this.widgetTabs[2].tableData = data;
							this.widgetTabs[2].chartData = [];
							this.widgetTabs[2].chartData.used = [];
							this.widgetTabs[2].chartData.notused = [];
							data.forEach((record) => {
								record.properDate = new Date(
									record.DateOfObservation
								);
								if (record.UsageCount !== 0) {
									record.used = true;
									this.widgetTabs[2].chartData.used.push(
										record
									);
								} else {
									record.used = false;
									this.widgetTabs[2].chartData.notused.push(
										record
									);
								}
							});

							let used = this.widgetTabs[2].chartData.used;
							let notused = this.widgetTabs[2].chartData.notused;
							let sparkLineData = groupBy(notused, [
								{ field: 'DateOfObservation' },
							]) as any[];
							sparkLineData.sort(
								(a, b) =>
									new Date(b.value).getTime() -
									new Date(a.value).getTime()
							);
							this.createSparkLineChart(sparkLineData); //Sparkline Data

							let sortByAssetOnCount = data;
							sortByAssetOnCount.sort(
								(a, b) => b.UsageCount - a.UsageCount
							);
							if (used.length > 0) {
								let groupByDateUsed = groupBy(used, [
									{ field: 'DateOfObservation' },
								]) as any[];
								groupByDateUsed.sort(
									(a, b) => b.items.length - a.items.length
								);
								this.summaryArray2[0] =
									groupByDateUsed[0].items.length; // Highest Assets Used
								this.summaryArray2[1] =
									groupByDateUsed[0].value; // Highest Assets Used Date
								this.summaryArray2[2] =
									sortByAssetOnCount[0].UsageCount; //Highest Times An Asset was turned On
								this.summaryArray2[3] =
									sortByAssetOnCount[0].DateOfObservation; //Date Highest Times An Asset was turned On
								this.summaryArray2[4] =
									sortByAssetOnCount[0].AssetName; //Name of Asset that was turned on Highest No. of times
							}

							if (notused.length > 0) {
								let groupByDateNotUsed = groupBy(notused, [
									{ field: 'DateOfObservation' },
								]) as any[];
								groupByDateNotUsed.sort(
									(a, b) => b.items.length - a.items.length
								);
								this.summaryArray3[0] =
									groupByDateNotUsed[0].items.length; // Highest Assets Not Used
								this.summaryArray3[1] =
									groupByDateNotUsed[0].value; // Highest Assets Not Used Date
							}

							this.widgetTabs[2].chartDataIsReady = true;
							this.widgetTabs[0].isLoading =
								this.widgetTabs[2].isLoading = false;
						}
					});
			}
			if (this.widgetTabs[3].tabHasBeenLoaded === true) {
				this.widgetTabs[3].isLoading = true;
				let statement4 =
					'API.TacticalDashboard_Asset_InUse_Detail ' +
					'@startDateTimeMS=' +
					this.timeScopeObject.queryStartDate +
					', @endDateTimeMS= ' +
					this.timeScopeObject.queryEndDate +
					" , @accessToken='" +
					Global.User.currentUser.ODataAccessToken +
					"'";
				if (_.isNil(this.assetIdList) && !_.isNil(this.widgetObject.SiteList)) {
					statement4 = statement4 +
						", @siteIdList = '" +
						this.widgetObject.SiteList +
						"'";
				}
				if (
					!_.isNil(this.widgetObject.WidgetAssetTypeId) &&
					this.widgetObject.WidgetAssetTypeId !== 0
				) {
					statement4 = statement4 +
						', @assetTypeId = ' +
						this.widgetObject.WidgetAssetTypeId;
				}

				if (!_.isNil(this.assetIdList)) {
					statement4 = statement4 + ", @assetIdList = '" + this.assetIdList + "'";
				}
				this.dataService
					.SQLActionAsPromise(statement4)
					.then((data: any) => {
						if (data.length === 0) {
							this.widgetTabs[3].tableData = data;
							this.widgetTabs[3].chartData = data;
							this.widgetTabs[3].isLoading = false;
						} else {
							data.forEach((record) => {
								record.ProperrecordName =
									record.SiteName +
									' ' +
									record.GateName +
									' ' +
									record.AssetName;

								record.DateOfObservationDateObject = new Date(
									record.DateOfObservation
								);

								record.DurationInMinutes = (
									record.DurationInSeconds / 60
								).toFixed(2);
								record.count = 1;
							});

							let assetAggregated = groupBy(data, [
								{ field: 'AssetName' },
							]) as any[];
							assetAggregated.forEach((group) => {
								group.inUseLength = group.items.length;
							});

							assetAggregated.forEach(element => {
								element.tooltip = element.value +": "+ element.inUseLength + " times";
							});

							this.widgetTabs[3].chartData = assetAggregated;
							this.widgetTabs[3].chartDataIsReady = true;
							this.widgetTabs[3].tableData = data;

							this.widgetTabs[3].isLoading = false;
						}
					});
			}

			if (
				this.widgetTabs[0].tabHasBeenLoaded === true ||
				this.widgetTabs[4].tabHasBeenLoaded === true
			) {
				this.widgetTabs[0].isLoading = this.widgetTabs[4].isLoading =
					true;
				let statement5 =
					'API.TacticalDashboard_Equipment_Driven_EachDayCount_Duration ' +
					'@startDateTimeMS=' +
					this.timeScopeObject.queryStartDate +
					', @endDateTimeMS= ' +
					this.timeScopeObject.queryEndDate +
					" , @accessToken='" +
					Global.User.currentUser.ODataAccessToken +
					"'";
				if (_.isNil(this.assetIdList) && !_.isNil(this.widgetObject.SiteList)) {
					statement5 = statement5 +
						", @siteIdList = '" +
						this.widgetObject.SiteList +
						"'";
				}
				if (
					!_.isNil(this.widgetObject.WidgetAssetTypeId) &&
					this.widgetObject.WidgetAssetTypeId !== 0
				) {
					statement5 = statement5 +
						', @assetTypeId = ' +
						this.widgetObject.WidgetAssetTypeId;
				}
				if (!_.isNil(this.assetIdList)) {
					statement5 = statement5 + ", @assetIdList = '" + this.assetIdList + "'";
				}

				this.dataService
					.SQLActionAsPromise(statement5)
					.then((data: any) => {
						if (data.length === 0) {
							this.widgetTabs[4].tableData = data;
							this.widgetTabs[4].chartData = data;
							this.widgetTabs[4].isLoading = false;
						} else {
							this.chartDataFinalIsReady = false;
							console.log(data);
							data.forEach((record) => {
								record.DateOfObservationDateObject = new Date(
									record.DateOfObservation
								);
								if (record.EquipmentUsedMinutes == null)
									record.EquipmentUsedMinutes = 0;
							});
							this.widgetTabs[4].tableData = data;

							this.widgetTabs[4].chartData = groupBy(data, [
								{ field: 'AssetName' },
							]) as GroupResult[];



							this.widgetTabs[4].chartData.forEach(item => {
								
								item.items.forEach(element => {
									element.tooltip = element.AssetName + ": " + element.EquipmentUsedMinutes + " minutes";
								});
								
							});


							if (this.widgetTabs[4].chartData.length > 0) {
								this.widgetTabs[4].chartData.push({
									value: 'Toggle All Series',
								});
							}


							
							let sortByEquipmentUsedMinutes = data;
							sortByEquipmentUsedMinutes.sort(
								(a, b) =>
									b.EquipmentUsedMinutes -
									a.EquipmentUsedMinutes
							);
							if (sortByEquipmentUsedMinutes.length > 0) {
								this.summaryArray4[0] =
									sortByEquipmentUsedMinutes[0].EquipmentUsedMinutes; //Highest Equipmet Used Mins
								this.summaryArray4[1] =
									sortByEquipmentUsedMinutes[0].DateOfObservation; //Highest Equipmet Used DateOfObservation
								this.summaryArray4[2] =
									sortByEquipmentUsedMinutes[0].AssetName; //Highest Equipmet Used Asset Name
								this.summaryArray4[3] =
									sortByEquipmentUsedMinutes[0].UsageCount; // Highest Equipmet Used Count
							}
							this.widgetTabs[4].chartDataIsReady = true;
							this.widgetTabs[0].isLoading =
								this.widgetTabs[4].isLoading = false;
						}
					});
			}
			if (this.widgetTabs[5].tabHasBeenLoaded === true) {
				this.widgetTabs[5].isLoading = true;
				let statement6 =
					'API.TacticalDashboard_Asset_Driven_Detail ' +
					'@startDateTimeMS=' +
					this.timeScopeObject.queryStartDate +
					', @endDateTimeMS= ' +
					this.timeScopeObject.queryEndDate +
					" , @accessToken='" +
					Global.User.currentUser.ODataAccessToken +
					"'";
				if (_.isNil(this.assetIdList) && !_.isNil(this.widgetObject.SiteList)) {
					statement6 = statement6 +
						", @siteIdList = '" +
						this.widgetObject.SiteList +
						"'";
				}
				if (
					!_.isNil(this.widgetObject.WidgetAssetTypeId) &&
					this.widgetObject.WidgetAssetTypeId !== 0
				) {
					statement6 = statement6 +
						', @assetTypeId = ' +
						this.widgetObject.WidgetAssetTypeId;
				}
				if (!_.isNil(this.assetIdList)) {
					statement6 = statement6 + ", @assetIdList = '" + this.assetIdList + "'";
				}

				this.dataService
					.SQLActionAsPromise(statement6)
					.then((data: any) => {
						if (data.length === 0) {
							this.widgetTabs[5].tableData = data;
							this.widgetTabs[5].chartData = data;
							this.widgetTabs[5].isLoading = false;
						} else {
							data.forEach((record) => {
								record.DateOfObservationDateObject = new Date(
									record.DateOfObservation
								);

								record.DurationInMinutes = (
									record.DurationInSeconds / 60
								).toFixed(2);
								record.count = 1;
							});

							let assetAggregated = groupBy(data, [
								{ field: 'AssetName' },
							]) as any[];
							assetAggregated.forEach((group) => {
								group.driveLength = group.items.length;
							});
							let subStatement =
								'API.TacticalDashboard_Asset_NotDriven  ' +
								'@startDateTimeMS=' +
								this.timeScopeObject.queryStartDate +
								', @endDateTimeMS= ' +
								this.timeScopeObject.queryEndDate +
								" , @accessToken='" +
								Global.User.currentUser.ODataAccessToken +
								"'";
							if (_.isNil(this.assetIdList) && !_.isNil(this.widgetObject.SiteList)) {
								subStatement =
									subStatement +
									", @siteIdList = '" +
									this.widgetObject.SiteList +
									"'";
							}
							if (
								!_.isNil(this.widgetObject.WidgetAssetTypeId) &&
								this.widgetObject.WidgetAssetTypeId !== 0
							) {
								subStatement =
									subStatement +
									', @assetTypeId = ' +
									this.widgetObject.WidgetAssetTypeId;
							}
							if (!_.isNil(this.assetIdList)) {
								subStatement = subStatement + ", @assetIdList = '" + this.assetIdList + "'";
							}
							this.dataService
								.SQLActionAsPromise(subStatement)
								.then((data2: any) => {
									console.log(data2);
									console.log(assetAggregated);

									if (data2.length > 0) {
										data2.forEach((asset) => {
											let artificialObject = {
												driveLength: 0,
												field: 'AssetName',
												items: [],
												value: asset.AssetName,
											};
											assetAggregated.push(
												artificialObject
											);
										});
									}

									assetAggregated.forEach(element => {
										element.tooltip = element.value +": "+ element.driveLength + " times";
									});

									this.widgetTabs[5].chartData =
										assetAggregated;
									this.widgetTabs[5].chartDataIsReady = true;
									this.widgetTabs[5].tableData = data;

									this.widgetTabs[5].isLoading = false;
								});
						}
					});
			}
			if (
				this.widgetTabs[0].tabHasBeenLoaded === true ||
				this.widgetTabs[6].tabHasBeenLoaded === true
			) {
				this.widgetTabs[0].isLoading = this.widgetTabs[6].isLoading =
					true;
				let statement7 =
					'API.TacticalDashboard_Equipment_Idle_EachDayCount_Duration ' +
					'@startDateTimeMS=' +
					this.timeScopeObject.queryStartDate +
					', @endDateTimeMS= ' +
					this.timeScopeObject.queryEndDate +
					" , @accessToken='" +
					Global.User.currentUser.ODataAccessToken +
					"'";
				if (_.isNil(this.assetIdList) && !_.isNil(this.widgetObject.SiteList)) {
					statement7 = statement7 +
						", @siteIdList = '" +
						this.widgetObject.SiteList +
						"'";
				}
				if (
					!_.isNil(this.widgetObject.WidgetAssetTypeId) &&
					this.widgetObject.WidgetAssetTypeId !== 0
				) {
					statement7 = statement7 +
						', @assetTypeId = ' +
						this.widgetObject.WidgetAssetTypeId;
				}
				if (!_.isNil(this.assetIdList)) {
					statement7 = statement7 + ", @assetIdList = '" + this.assetIdList + "'";
				}

				this.dataService
					.SQLActionAsPromise(statement7)
					.then((data: any) => {
						if (data.length === 0) {
							this.widgetTabs[6].tableData = data;
							this.widgetTabs[6].chartData = data;
							this.widgetTabs[6].isLoading = false;
						} else {
							// AssetId:137916
							// AssetName:'B95020002'
							// DateOfObservation:'04/14/2022'
							// EquipmentIdleMinutes:491
							// IdleCount:12
							// SiteName:'CVG'
							this.chartDataFinalIsReady = false;
							console.log(data);
							data.forEach((record) => {
								record.DateOfObservationDateObject = new Date(
									record.DateOfObservation
								);
								if (record.EquipmentIdleMinutes == null)
									record.EquipmentIdleMinutes = 0;
							});
							this.widgetTabs[6].tableData = data;

							this.widgetTabs[6].chartData = groupBy(data, [
								{ field: 'AssetName' },
							]) as GroupResult[];
							if (this.widgetTabs[6].chartData.length > 0) {
								this.widgetTabs[6].chartData.push({
									value: 'Toggle All Series',
								});
							}

							this.widgetTabs[6].chartData.forEach(element => {
								element.tooltip = element.AssetName + ": " + element.EquipmentIdleMinutes + " minutes";
							});

							let sortByEquipmentIdleMinutes = data;
							sortByEquipmentIdleMinutes.sort(
								(a, b) =>
									b.EquipmentIdleMinutes -
									a.EquipmentIdleMinutes
							);
							if (
								sortByEquipmentIdleMinutes.length > 0 &&
								sortByEquipmentIdleMinutes[0]
									.EquipmentIdleMinutes > 0
							) {
								this.summaryArray5[0] =
									sortByEquipmentIdleMinutes[0].EquipmentIdleMinutes; //Highest Equipmet Used Mins
								this.summaryArray5[1] =
									sortByEquipmentIdleMinutes[0].DateOfObservation; //Highest Equipmet Used DateOfObservation
								this.summaryArray5[2] =
									sortByEquipmentIdleMinutes[0].AssetName; //Highest Equipmet Used Asset Name
								this.summaryArray5[3] =
									sortByEquipmentIdleMinutes[0].UsageCount; // Highest Equipmet Used Count
							}
							this.widgetTabs[6].chartDataIsReady = true;
							this.widgetTabs[0].isLoading =
								this.widgetTabs[6].isLoading = false;
						}
					});
			}
			if (this.widgetTabs[7].tabHasBeenLoaded === true) {
				this.widgetTabs[7].isLoading = true;
				let statement8 =
					'API.TacticalDashboard_Asset_Idle_Detail ' +
					'@startDateTimeMS=' +
					this.timeScopeObject.queryStartDate +
					', @endDateTimeMS= ' +
					this.timeScopeObject.queryEndDate +
					" , @accessToken='" +
					Global.User.currentUser.ODataAccessToken +
					"'";
				if (_.isNil(this.assetIdList) && !_.isNil(this.widgetObject.SiteList)) {

					statement8 = statement8 +
						", @siteIdList = '" +
						this.widgetObject.SiteList +
						"'";
				}
				if (
					!_.isNil(this.widgetObject.WidgetAssetTypeId) &&
					this.widgetObject.WidgetAssetTypeId !== 0
				) {
					statement8 = statement8 +
						', @assetTypeId = ' +
						this.widgetObject.WidgetAssetTypeId;
				}
				if (!_.isNil(this.assetIdList)) {
					statement8 = statement8 + ", @assetIdList = '" + this.assetIdList + "'";
				}

				this.dataService
					.SQLActionAsPromise(statement8)
					.then((data: any) => {
						if (data.length === 0) {
							this.widgetTabs[7].tableData = data;
							this.widgetTabs[7].chartData = data;
							this.widgetTabs[7].isLoading = false;
						} else {
							// AssetId:137893
							// AssetName:'B95019018'
							// DateOfObservation:Fri Apr 15 2022 19:00:00 GMT-0500 (Central Daylight Time)
							// DateOfObservationMS:1650067200000
							// DurationInSeconds:31
							// EndDate:Fri Apr 15 2022 21:32:04 GMT-0500 (Central Daylight Time)
							// EndDate(UTC):Sat Apr 16 2022 02:32:04 GMT-0500 (Central Daylight Time)
							// EndDate(UTC)MS:1650094324123
							// EndDateMS:1650076324123
							// GateName:'CVG GSE'
							// SiteName:'CVG'
							// SiteUTCTimeOffset:-5
							// StartDate:Fri Apr 15 2022 21:31:33 GMT-0500 (Central Daylight Time)
							// StartDate(UTC):Sat Apr 16 2022 02:31:33 GMT-0500 (Central Daylight Time)
							// StartDate(UTC)MS:1650094293997
							// StartDateMS:1650076293997
							// TagId:11454663
							// TagName:'Idle'

							data.forEach((record) => {
								record.DateOfObservationDateObject = new Date(
									record.DateOfObservation
								);

								record.DurationInMinutes = (
									record.DurationInSeconds / 60
								).toFixed(2);
								record.count = 1;
							});

							let assetAggregated = groupBy(data, [
								{ field: 'AssetName' },
							]) as any[];
							assetAggregated.forEach((group) => {
								group.idleLength = group.items.length;
							});

							assetAggregated.forEach(element => {
								element.tooltip = element.value + ": " + element.idleLength + " times";
							});

							this.widgetTabs[7].chartData = assetAggregated;
							this.widgetTabs[7].chartDataIsReady = true;
							this.widgetTabs[7].tableData = data;

							this.widgetTabs[7].isLoading = false;
						}
					});
			}

			this.lastUpdatedDate = new Date();

			this.isLoading = false;

			var bounds = this.elem.nativeElement.getBoundingClientRect();
			this.cardResize(bounds);
		}
	}


	createSparkLineChart(chartData: any) {
		this.sparklineData = [
			{
				field: 'y',
				width: 0.75,
				color: 'limegreen',
				data: chartData.map((a) => ({
					y: a.items.length,
					dateOfObs: a.value,
				})),
			},
		];
	}

	toggleLegendHidden(tab, index) {
		tab.legendVisible = !tab.legendVisible;
		let foundSetting = this.WidgetTabSettings.find((widgetTabSetting) => {
			return widgetTabSetting.TabIndex === index;
		});

		let statement =
			'API.EditWidgetTabSettings @WidgetId = ' +
			this.widgetObject.WidgetId +
			', @TabIndex = ' +
			index +
			', @IsHidden = ' +
			!tab.legendVisible;
		if (!_.isNil(foundSetting)) {
			statement = statement + ', @Id = ' + foundSetting.Id;
		}
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.WidgetTabSettings = data;
			this.WidgetTabSettings.forEach((tabSetting) => {
				this.widgetTabs[tabSetting.TabIndex].legendVisible =
					!tabSetting.IsLegendHidden;
			});
		});
	}

	openConditionalShadingSettingsPopup(tileNumber) {
		let tileConditionalShadingRecord =
			this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
				return p.WidgetId == this.widgetObject.WidgetId && p.SummaryTileIndex == tileNumber;
			});
		if (tileConditionalShadingRecord.length == 0) {
			tileConditionalShadingRecord.WidgetId = this.widgetObject.WidgetId;
			tileConditionalShadingRecord.SummaryTileIndex = tileNumber;
		}

		var DialogRef = this.dialog.open(TacticalWidgetSummaryTilePopupComponent, {
			width: '50%',
			height: '40%',
			data: tileConditionalShadingRecord,
		});


		DialogRef.afterClosed().subscribe((result) => {
			let tileConditionalShadingRecordLatest =
				this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
					return p.WidgetId == this.widgetObject.WidgetId;
				});
			this.ConditionalShadingRecord = [];
			tileConditionalShadingRecordLatest.forEach((r) => {
				this.ConditionalShadingRecord.push(r);
			});

			this.ConditionalShadingRecord.sort((a, b) => parseFloat(a.Value) - parseFloat(b.Value));
			this.ConditionalColorSummaryTiles();

		});

	}


	animationRedraw() {
		// if (this.selectedMatTabLabel === "Map") {
		// 	this.loadCorrectTabData(true)
		// }
	}

	public toggleLegendChange(event: any) {
		this.toggleLegendHidden(this.tab, this.selectedTabIndex);
	}

	changeGraphType(event) {
		let i = this.selectedTabIndex;
		this.widgetTabs[i].graphType = event;
		let existingRecord = this.widgetTabsChartConfigurations.find(
			(record) => {
				return record.WidgetTabIndex === i;
			}
		);

		let statement =
			'API.TacticalDashboardAddOrUpdateWidgetChartIndexRecord @widgetId = ' +
			this.widgetObject.WidgetId +
			', @userId = ' +
			Global.User.currentUser.Id +
			', @chartTypeId = ' +
			event.id +
			', @widgetTabIndex = ' +
			i;
		if (existingRecord !== undefined) {
			statement = statement + ', @id = ' + existingRecord.Id;
		}
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.widgetTabsChartConfigurations = data;
			this.utilityService.showToastMessageShared({
				type: 'success',
				message: 'Chart type changed to ' + event.properName,
			});
			//   this.toastr.success("Chart type changed to " + event.properName);
		});
	}

	showChartAsPopup(tab) {
		var popupData;

		if (tab.textLabel === 'Assets Used Detail' || tab.textLabel === 'Equipment Driven Each Day Detail' || tab.textLabel === 'Equipment Idle Each Day Detail') {
			var detailField;
			if (tab.textLabel === 'Assets Used Detail') {
				detailField = 'inUseLength';
				titleXAxis = 'In Use Count';
			}
			if (tab.textLabel === 'Equipment Driven Each Day Detail') {
				detailField = 'driveLength';
				titleXAxis = 'Drive Count';
			}
			if (tab.textLabel === 'Equipment Idle Each Day Detail') {
				detailField = 'idleLength';
				titleXAxis = 'Idle Count';
			}

			popupData = {
				categoryField: 'value',
				field: detailField,
				popupTitle: 'Raw Chart: ' + tab.textLabel,
				titleXAxis: titleXAxis,
				chartTitle: tab.textLabel,
				WidgetName: 'Kendo-Chart',
				chartData: tab.chartData,
				canCreateDashboardWidgetFromPopup: false,
				graphType: tab.graphType?.name,
				legendVisible: tab.legendVisible,
				useTooltipTemplate: true,
			}
		}
		else if (tab.textLabel === 'Total Minutes Off For Day' || tab.textLabel === 'Equipment Driven Each Day Duration' || tab.textLabel === 'Equipment Idle Each Day Duration') {
			var field;
			var titleXAxis;
			if (tab.textLabel === 'Total Minutes Off For Day') {
				field = 'AssetNotUsedMinutes';
				titleXAxis = 'Minutes Not Used 24 Hours';
			}
			if (tab.textLabel === 'Equipment Driven Each Day Duration') {
				field = 'EquipmentUsedMinutes';
				titleXAxis = 'Duration Driven (minutes)';
			}
			if (tab.textLabel === 'Equipment Idle Each Day Duration') {
				field = 'EquipmentIdleMinutes';
				titleXAxis = 'Duration Idle (minutes)';
			}

			popupData = {
				categoryField: 'DateOfObservationDateObject',
				field: field,
				popupTitle: 'Raw Chart: ' + tab.textLabel,
				titleXAxis: titleXAxis,
				chartTitle: tab.textLabel,
				WidgetName: 'Kendo-Chart',
				chartData: tab.chartData,
				canCreateDashboardWidgetFromPopup: false,
				graphType: tab.graphType?.name,
				legendVisible: tab.legendVisible,
				canIterate: true,
				useTooltipTemplate: true,
			}
		}

		const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '90%' : '90%',
			height: Global.isMobile ? '90%' : '90%',
			data: popupData,
			maxWidth: '100vw',
			maxHeight: '100vh',
		});
	}

	navigateToTab(index, textLabel) {
		this.tab = this.widgetTabs[index];
		if (this.tab.graphType != undefined && this.tab.graphType.name == undefined)
			this.tab.graphType = Global.tacticalDashboardGraphTypes.find((graphType) => graphType.name == this.tab.graphType);

		clearTimeout(this.timeoutToExpire);
		this.selectedMatTabLabel = textLabel;
		this.selectedTabIndex = index;
		if (this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded === false) {
			this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded = true;
			this.destroyIntervals();
			this.startIntervals();
		}
		this.timeoutToExpire = setTimeout(() => {
			this.widgetObject.SelectedTabIndex = this.selectedTabIndex;
			if (this.widgetObject.RememberSelectedTabIndex) {
				let returnedWidgetAsArray =
					this.dataService.updateTacticalDashboardWidgetRecord(
						this.widgetObject
					);
			}

		}, 5000);
	}
	tabHasChanged(event) {
		clearTimeout(this.timeoutToExpire);
		this.selectedMatTabLabel = event.tab.textLabel;
		this.selectedTabIndex = event.index;
		if (this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded === false) {
			this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded = true;
			this.destroyIntervals();
			this.startIntervals();
		}
		this.timeoutToExpire = setTimeout(() => {
			this.widgetObject.SelectedTabIndex = this.selectedTabIndex;
			if (this.widgetObject.RememberSelectedTabIndex) {
				let returnedWidgetAsArray =
					this.dataService.updateTacticalDashboardWidgetRecord(
						this.widgetObject
					);
			}

		}, 5000);
	}

	onResized(event) {
		// this.tagDataPageSize = Math.floor(((event.newRect.height - 120) / this.tagDataRowHeight) * 3);
		// this.eventDataPageSize = Math.floor(((event.newRect.height - 120) / this.eventDataRowHeight) * 3);
		this.parentContainerSize = event.newRect.height;
		if (this.childOverviewGauge !== undefined) {
			this.childOverviewGauge.reFlow();
		}

		// Global.User.DebugMode && console.log("gse-summary: pageSize = " + this.tagDataPageSize);
		// this.newHeight = event.newRect.height;
		// this.oldHeight = event.oldHeight;
		// this.newWidth = event.newRect.width;
		// this.oldWidth = event.oldWidth;
	}

	ngOnDestroy() {
		this.destroyIntervals();
	}



	CreateTheme(theme: string) {
		if (theme === 'light') {
			this.theme = 'light';
			this.chartBackgroundColor = 'white';
			this.chartLabelColors = 'grey';
			this.gridLineColor = 'grey';
			this.axisItemColor = 'grey';
		} else {
			this.theme = 'dark';
			this.chartBackgroundColor = '#27304C';
			this.chartLabelColors = 'white';
			this.gridLineColor = 'white';
			this.axisItemColor = 'white';
		}
	}

	public toggleSeries(item: any, type): void {
		let i = item.series.index;
		if (type === 'driven') {
			this.seriesDriven._results[i].toggleVisibility();
		} else if (type === 'used') {
			this.series._results[i].toggleVisibility();
		} else if (type === 'idle') {
			this.seriesIdle._results[i].toggleVisibility();
		}
	}

	public toggleAllSeries(e, type) {
		if (e.text === 'Toggle All Series') {
			if (type === 'driven') {
				this.seriesDriven._results.forEach((series, index) => {
					this.seriesDriven._results[index].toggleVisibility();
				});
			} else if (type === 'used') {
				this.series._results.forEach((series, index) => {
					this.series._results[index].toggleVisibility();
				});
			} else if (type === 'idle') {
				this.seriesIdle._results.forEach((series, index) => {
					this.seriesIdle._results[index].toggleVisibility();
				});
			}
		}
	}


	private ConditionalColorSummaryTiles() {
		this.percentTextColor[1] = "lightseagreen";

		this.conditionalColorPercentAmount[1] = this.summaryArray[2] / 100;


		if (this.ConditionalShadingRecord.length > 0) {
			this.ConditionalShadingRecord.forEach((r) => {

				switch (r.Operator) {
					case 'Is greater than':
						if (this.conditionalColorPercentAmount[r.SummaryTileIndex] > r.Value) {
							this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
						}
						break;
					case 'Is less than':
						if (this.conditionalColorPercentAmount[r.SummaryTileIndex] < r.Value) {
							this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
						}
						break;
					case 'Is equal to':
						var conditionalColorPercentAmountRounded = Math.round(this.conditionalColorPercentAmount[r.SummaryTileIndex]);
						if (conditionalColorPercentAmountRounded == r.Value) {
							this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
						}
						break;
					case 'Is not equal to':
						var conditionalColorPercentAmountRounded = Math.round(this.conditionalColorPercentAmount[r.SummaryTileIndex]);
						if (conditionalColorPercentAmountRounded != r.Value) {
							this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
						}
						break;
				}
			});
		}

		this.percentAssetsNotUsed = [
			{
				kind: "assets in alarm",
				share: this.conditionalColorPercentAmount[1],
				color: this.percentTextColor[1],
			},
			{
				kind: "other",
				share: 1 - this.conditionalColorPercentAmount[1],
				color: "grey",
			},
		];

	}

	public labelContentShort(e: any): string {
		return e.value;
	}

	private buildNavigationOptionsArray() {
		var service = this;

		var navigationWidth = Global.isMobile ? "115px" : "155px";

		service.options = [
			{
				id: 1,
				width: navigationWidth,
				name: "Navigation",
				children: [
					{
						id: 10,
						width: navigationWidth,
						name: "Summary",
						action: () => { service.navigateToTab(0, "Summary");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Total Minutes Off For Day",
						action: () => { service.navigateToTab(1, "Total Minutes Off For Day");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Assets Used/Not Used",
						action: () => { service.navigateToTab(2, "Assets Used/Not Used Each Day");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Assets Used Detail",
						action: () => { service.navigateToTab(3, "Assets Used Detail");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Asset Driven Duration",
						action: () => { service.navigateToTab(4, "Equipment Driven Each Day Duration");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Asset Driven Detail",
						action: () => { service.navigateToTab(5, "Equipment Driven Each Day Detail");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Asset Idle Duration",
						action: () => { service.navigateToTab(6, "Equipment Idle Each Day Duration");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Asset Idle Detail",
						action: () => { service.navigateToTab(7, "Equipment Idle Each Day Detail");  this.navigationOpened=false; }
					},


					// {
					// 	id: 11,
					// 	width: navigationWidth,
					// 	name: "Raw Data",
					// 	children: [
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Total Minutes Off For Day",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[1]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Assets Used/Not Used Each Day",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[2]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Assets Used Detail",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[3]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Asset Driven Duration",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[4]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Asset Driven Detail",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[5]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Asset Idle Duration",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[6]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Asset Idle Detail",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[7]);  this.navigationOpened=false; }
					// 		},

					// 	],


					// }

				],
				root: true,
				opened: false
			},
			{
				id: 2,
				width: navigationWidth,
				name: "Settings",
				children: [
					{
						id: 10,
						width: navigationWidth,
						name: "Site",
						action: () => { service.widgetObject.editWidgetSettings(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Edit Name",
						action: () => { service.widgetObject.editWidgetName(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Reload",
						action: () => { service.dashboardService._reloadedWidget.next(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Delete",
						action: () => { service.widgetObject.deleteFunction();  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Timescope",
						action: () => { service.widgetObject.editTimeScopeSettings(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Interval",
						action: () => { service.widgetObject.editIntervalSettings(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Legend",
						action: () => { service.toggleLegendHidden(service.tab, service.selectedTabIndex);  this.navigationOpened=false; }
					},

					{
						id: 23,		// id has to match id in toggle method below
						width: navigationWidth,
						name: service.widgetObject.RememberSelectedTabIndex == 0 ? "Remember Nav" : "Forget Nav",
						action: () => { service.widgetObject.invertRememberingOfSelectedTabIndex(service.widgetObject); this.toggleRememberingOfSelectedTabIndexName(); this.navigationOpened=false; }
					},

				],
				root: true,
				opened: false
			}
		];

		let optionsDesktop = [
			{
				id: 22,		// id has to match id in toggle method below
				width: navigationWidth,
				name: service.widgetObject.dragEnabled ? "Disable Drag" : "Allow Drag",
				action: () => { service.widgetObject.invertDraggableSetting(service.widgetObject); this.toggleDragName(); this.navigationOpened=false;}
			},
			{
				id: 21,		// id has to match id in toggle method below
				width: navigationWidth,
				name: service.widgetObject.resizeEnabled ? "Disable Resize" : "Allow Resize",
				action: () => { service.widgetObject.invertResizeSetting(service.widgetObject); this.toggleResizeName(); this.navigationOpened=false;}
			},
		];

		if(Global.isMobile == false) {
			service.options[1].children = service.options[1].children.concat(optionsDesktop);
		}

		service.optionsSettingsOnly = service.options.where((item: any) => { return item.id == 2 }).toArray(); //- Settings tab only when no Site selected.

		console.log("options built", service.options);

	}


	public editWidgetSettings() {
		this.widgetObject.editWidgetSettings(this.widgetObject)
	}

	public refreshWidget() {
		this.dashboardService._reloadedWidget.next(this.widgetObject);
	}

	public changeWidgetInterval() {
		this.widgetObject.editIntervalSettings(this.widgetObject);
	}

	public changeWidgetTimescope() {
		this.widgetObject.editTimeScopeSettings(this.widgetObject);
	}


	private toggleRememberingOfSelectedTabIndexName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item: any) => { return item.id == 2 }).firstOrDefault();
		var rememberTabSetting = settings.children.where((item: any) => { return item.id == 23 }).firstOrDefault();

		rememberTabSetting.name = this.widgetObject.RememberSelectedTabIndex == 0 ? "Remember Nav" : "Forget Nav";

	}

	private toggleDragName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item: any) => { return item.id == 2 }).firstOrDefault();
		var dragSetting = settings.children.where((item: any) => { return item.id == 22 }).firstOrDefault();

		dragSetting.name = this.widgetObject.dragEnabled ? "Disable Drag" : "Enable Drag";

	}

	public checkSelectedItem(selected: any) {
		Global.User.DebugMode && console.log("checkSelectedItem invoked. selected = %O", selected);
	}

	private toggleResizeName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item: any) => { return item.id == 2 }).firstOrDefault();
		var resizeSetting = settings.children.where((item: any) => { return item.id == 21 }).firstOrDefault();

		resizeSetting.name = this.widgetObject.resizeEnabled ? "Disable Resize" : "Allow Resize";

	}

	public checkNavigation(opened: any) {
		Global.User.DebugMode && console.log("checkNavigation opened = %O", opened);
		this.navigationOpened = opened;
		this.isLoading = false;
	}

	public openCloseNavigation() {
		Global.User.DebugMode && console.log("openCloseNavigation invoked. navigationOpened current status: = " + this.navigationOpened);
		this.navigationOpened = !this.navigationOpened; //--whatever it was set to, set it to the opposite.
		Global.User.DebugMode && console.log("openCloseNavigation invoked. navigationOpened changed status: = " + this.navigationOpened);
	}

	initializeTour(): void {
		this.tourService.initialize([
			{
				anchorId: this.widgetObject.WidgetId + '-1',
				content: 'Here you can click on the Site, Update Interval and Timescope areas to change the settings for this widget',
				title: 'Settings',
			},
			{
				anchorId: this.widgetObject.WidgetId + '-2',
				content: 'Navigate to different tabs & Widget Settings',
				title: 'Navigation',
			},
			{
				anchorId: this.widgetObject.WidgetId + '-3',
				content: 'Click on various tiles for more information',
				title: 'Summary Info',
			},
		]);

		this.tourService.start();
	}


}
