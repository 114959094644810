import { Component, AfterViewInit, Input, EventEmitter, ViewChild, ElementRef, ViewChildren, OnInit } from "@angular/core";
import _ from "lodash";



import { MatDialog } from '@angular/material/dialog';

import { groupBy, GroupResult } from '@progress/kendo-data-query';

import { TacticalDashboardOverviewGaugeComponent } from '../../components/tactical-dashboard-overview-gauge/tactical-dashboard-overview-gauge.component';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/data.service';
import { DashboardService } from '../../services/dashboard.service';
import { Global } from '../../_constants/global.variables';
import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';
import { IGlobal } from "../../_models/global.model";
import { Subscription } from "rxjs/internal/Subscription";
import { UtilityService } from '../../services/utility.service';
import {IStepOption, TourAnchorMatMenuDirective, TourMatMenuModule, TourService} from 'ngx-ui-tour-md-menu';
import { v4 as uuidv4 } from 'uuid';


@Component({
  selector: 'lib-equipment-cycle-count-summary',
  templateUrl: './equipment-cycle-count-summary.component.html',
  styleUrls: ['./equipment-cycle-count-summary.component.scss']
})
export class EquipmentCycleCountSummaryComponent implements AfterViewInit, OnInit {

  public graphTypes: string[] = ['area', 'bar', 'column', 'donut', 'horizontalWaterfall', 'line', 'verticalArea', 'verticalLine', 'waterfall'];

  public graphShow: boolean = true;

  @ViewChildren('assetCycleCountHistorical')
  public assetCycleCountHistorical: any;

  @ViewChildren('assetRunTimeHistoical')
  public assetRunTimeHistoical: any;

  @Input() private dashboardTimeScopeChanged: EventEmitter<any>;
  @ViewChild("childOverviewGauge") childOverviewGauge: TacticalDashboardOverviewGaugeComponent;
  public chartDataIsReady: boolean = false;
  public isCollapsed = false;
  public global: IGlobal = Global;

  @Input() widgetObject: any;
  h1TitlePercent: number;
  componentSubtitle: String = '';
  percentCalculated: boolean = false;
  h1Number: number = undefined;
  h4Percent: number = undefined;
  updateControllerInterval: NodeJS.Timeout;
  totalAssetLength: any;
  lastUpdatedDate: Date;
  displayTimeAgoMessage: any;
  @Input() private dashboardUpdateIntervalChanged: EventEmitter<any>;
  @Input() private widgetResizedEvent: EventEmitter<any>;
  widgetResizedSubscription: any;
  widgetToggleLegendHiddenSubscription: Subscription;
  dashboardUpdateIntervalSubscription: any;
  secondsAgoInterval: NodeJS.Timeout;
  fullDataCacheSubscription: any;
  dialogModalData: any;
  chartData: any;
  noDataForSite: boolean = false;
  chartBackgroundColor: string = "#27304C"
  chartLabelColors: string = "white"
  gridLineColor: string = "white"
  axisItemColor: string = "white"
  colorChangedSubscription: any;
  theme: string;
  contentHeight: any;
  barChartData: any[] = [{ Status: 10 }, { Status: 20 }, { Status: 200 }, { Status: 200 }]
  public userDefinedAlarmObservationIds: any = [56442, 56443, 56444, 56445, 56446, 56447, 56448, 56449, 56450, 56451, 56452, 56453, 56454, 56455, 56456, 56457, 56458, 56459, 56460, 56461]
  displayNoUpdateIntervalMessage: boolean;
  updateInterval: any;
  isLoading: boolean;

  // AssetId: 87962
  // AssetName: "PBB"
  // CycleCount: 29
  // DateInMilleseconds: 1646892000000
  // DateOfObservation: "03/10/2022"
  // GateId: 372
  // GateName: "B32"
  // GateNameFull: "JFK B32"
  // JavascriptDate: Thu Mar 10 2022 00:00:00 GMT-0600 (Central Standard Time) {}
  // OffsetFromToday: 7
  // ProperAssetName: "JFK B32 PBB"
  // RunTimeMinutes: 705
  // SiteName: "JFK"

	summaryArray = [];
	summaryArray1 = [];

	fontSize1 = 'medium';
	fontSize2 = 'small';
	// cardClass1 = 'card-tile-lg';
	// cardClass2 = 'card-tile-md';
	// cardWideClass2 = 'card-wide-tile-lg';
	cardTileCenterTitleClass = 'grid-card-title-center-sm';
	cardWideTileCenterTitleClass = 'grid-wide-card-title-center-sm';
	headerGridTitle = 'grid-title-lg';

	public timeScopeObject: any;
	public tab: any;
	public options: any = [];
	public optionsSettingsOnly: any = [];
	public navigationOpened: any;

  public widgetTabs: any = [
	{
		isLoading: false,
		tabHasBeenLoaded: false,
		textLabel: 'Summary',
		rendered: true,
		tooltip:
			'Summary of assets cycle count and run time minutes within the selected scope.Click on the info to navigate to corresponding tab for details.',
	},
    {
      isLoading: false,
      tabHasBeenLoaded: false,
      textLabel: "Asset Cycle Count Today",
      legendVisible: true,
		graphType: this.graphTypes[2],
      downloadableData: true,
      chartDataIsReady: false,
      tooltip: "Stacked column chart showing the count of cycles for today grouped by Asset Type.",
      groupable: true,
      groupColumns: [{ field: 'GateName' }],
      tableStructure: [
		  {
			  field: "DateOfObservationDateObject",
			  title: "Date",
			  filter: 'date',
			  _width: 200,
			  formatOption: 'shortDate'
		  },
        {
          field: "SiteName",
          title: "Site",
          _width: 100
        },
        {
          field: "GateName",
          title: "Gate Name",
			_width: 200
        },
        {
          field: "ProperAssetName",
          title: "Asset Name",
          _width: 300,
        },
        {
          field: "CycleCount",
          title: "Cycle Count",
			_width: 200
        },
        {
          field: "RunTimeMinutes",
          title: "Run Time Minutes",
			  _width: 200
		  },

      ],

    },
    {
      isLoading: false,
      tabHasBeenLoaded: false,
      textLabel: "Asset Cycle Count Historical",
		graphType: this.graphTypes[5],
      legendVisible: true,
      tooltip: "Line chart showing the cycle count for each asset over a historical trend.",
      downloadableData: true,
      chartDataIsReady: false,
      groupable: true,
      groupColumns: [{ field: 'ProperAssetName' }],
      tableStructure: [
		  {
			  field: "DateOfObservationDateObject",
			  title: "Date",
			  filter: 'date',
			  _width: 200,
			  formatOption: 'shortDate'
		  },
        {
          field: "SiteName",
          title: "Site",
          _width: 100
        },
        {
          field: "GateName",
          title: "Gate Name",
			_width: 200
        },
        {
          field: "ProperAssetName",
          title: "Asset Name",
          _width: 300,
        },
        {
			field: "CycleCount",
			title: "Cycle Count",
			_width: 200
        },
		  {
			  field: "RunTimeMinutes",
			  title: "Run Time Minutes",
			  _width: 200
		  },

      ],
    },
    {
      isLoading: false,
      tabHasBeenLoaded: false,
		graphType: this.graphTypes[5],
      textLabel: "Asset Type Cycle Count Historical",
      tooltip: "Line chart showing the cycle count for each asset type over a historical trend.",
      downloadableData: true,
      chartDataIsReady: false,
      groupable: true,
      groupColumns: [{ field: 'AssetName' }, { field: 'DateOfObservation' }],
      legendVisible: true,
      tableStructure: [
		  {
			  field: "DateOfObservationDateObject",
			  title: "Date",
			  filter: 'date',
			  _width: 200,
			  formatOption: 'shortDate'
		  },
        {
          field: "SiteName",
          title: "Site",
          _width: 100
        },
        {
          field: "GateName",
          title: "Gate Name",
			_width: 200
        },
        {
          field: "ProperAssetName",
          title: "Asset Name",
          _width: 300,
        },
        {
			field: "CycleCount",
			title: "Cycle Count",
			_width: 200
        },
        {
			field: "RunTimeMinutes",
			title: "Run Time Minutes",
			_width: 200
        },

      ],
    },
    {
      isLoading: false,
      tabHasBeenLoaded: false,
      textLabel: "Asset Run Time Minutes Today",
		graphType: this.graphTypes[2],
      tooltip: "Stacked column chart showing the run time minutes for today grouped by Asset Type.",
      downloadableData: true,
      chartDataIsReady: false,
      groupable: true,
      groupColumns: [{ field: 'GateName' }],
      legendVisible: true,
      tableStructure: [
		  {
			  field: "DateOfObservationDateObject",
			  title: "Date",
			  filter: 'date',
			  _width: 200,
			  formatOption: 'shortDate'
		  },
        {
          field: "SiteName",
          title: "Site",
          _width: 100
        },
        {
          field: "GateName",
          title: "Gate Name",
			_width: 200
        },
        {
          field: "ProperAssetName",
          title: "Asset Name",
          _width: 300,
        },
        {
			field: "CycleCount",
			title: "Cycle Count",
			_width: 200
        },
        {
			field: "RunTimeMinutes",
			title: "Run Time Minutes",
			_width: 200
        },

      ],
    },
    {
      isLoading: false,
      tabHasBeenLoaded: false,
      textLabel: "Asset Run Time Historical",
		graphType: this.graphTypes[5],
      tooltip: "Line chart showing the run time in minutes for each asset over a historical trend.",
      downloadableData: true,
      chartDataIsReady: false,
      groupable: true,
      groupColumns: [{ field: 'ProperAssetName' }],
      legendVisible: true,
      tableStructure: [
		  {
			  field: "DateOfObservationDateObject",
			  title: "Date",
			  filter: 'date',
			  _width: 200,
			  formatOption: 'shortDate'
		  },
        {
          field: "SiteName",
          title: "Site",
          _width: 100
        },
        {
          field: "GateName",
          title: "Gate Name",
			_width: 200
        },
        {
          field: "ProperAssetName",
          title: "Asset Name",
          _width: 300,
        },
        {
			field: "CycleCount",
			title: "Cycle Count",
			_width: 200
        },
        {
			field: "RunTimeMinutes",
			title: "Run Time Minutes",
			_width: 200
        },

      ],
    },
    {
      isLoading: false,
      tabHasBeenLoaded: false,
      textLabel: "Asset Type Run Time Historical",
		graphType: this.graphTypes[5],
      tooltip: "Line chart showing the run time in minutes for each asset type over a historical trend.",
      downloadableData: true,
      chartDataIsReady: false,
      groupable: true,
      groupColumns: [{ field: 'AssetName' }, { field: 'DateOfObservation' }],
      legendVisible: true,
      tableStructure: [
		  {
			  field: "DateOfObservationDateObject",
			  title: "Date",
			  filter: 'date',
			  _width: 200,
			  formatOption: 'shortDate'
		  },
        {
          field: "SiteName",
          title: "Site",
          _width: 100
        },
        {
          field: "GateName",
          title: "Gate Name",
			_width: 200
        },
        {
          field: "ProperAssetName",
          title: "Asset Name",
          _width: 300,
        },
        {
			field: "CycleCount",
			title: "Cycle Count",
			_width: 200
        },
        {
			field: "RunTimeMinutes",
			title: "Run Time Minutes",
			_width: 200
        },

      ],
    },

  ];



  // AlarmCount:0
  // AlarmDurationSeconds:null
  // AssetId:88195
  // AssetName:'GPU'
  // DateOfObservation:'02/23/2022'
  // GateName:'A6L2'
  // SiteName:'JFK'
  gaugeDisplayData: any;
  parentContainerSize: any;
  selectedMatTabLabel: string;
  displayNoTimeScopeMessage: boolean = false;
  dashboardTimeScopeChangedSubscription: any;
  timeoutToExpire: NodeJS.Timeout;
  selectedTabIndex: number = 0;
  widgetTabsChartConfigurations: any;
  widgetTabConfigurationsFinishedMapping: boolean = false;
  WidgetTabSettings: any = []


	constructor(public dataService: DataService, private utilityService: UtilityService, private dashboardService: DashboardService, private dialog: MatDialog, private elem: ElementRef, public tourService: TourService,) { }

  CreateTheme(theme: string) {
    if (theme === 'light') {
      this.theme = 'light';
      this.chartBackgroundColor = "white"
      this.chartLabelColors = "grey"
      this.gridLineColor = "grey"
      this.axisItemColor = "grey"
    } else {
      this.theme = 'dark';
      this.chartBackgroundColor = "#27304C"
      this.chartLabelColors = "white"
      this.gridLineColor = "white"
      this.axisItemColor = "white"
    }
  }

  ngOnInit() {
	this.tab = this.widgetTabs[0];
	this.updateInterval =
		this.dashboardService.determineProperTacticalDashboardUpdateIntervalForWidget(
			this.widgetObject
		);
	this.timeScopeObject =
		this.dashboardService.determineProperTimeScopeForWidget({
			widgetObject: this.widgetObject,
			UTCConfiguration: false,
		});
	this.buildNavigationOptionsArray();
}

  ngAfterViewInit() {

	var bounds = this.elem.nativeElement.getBoundingClientRect();
	this.cardResize(bounds);

	if(Global.isMobile == true) {
		this.contentHeight = 'calc(100% - 20px)';
	}
	else {
		this.contentHeight = 'calc(100% - 120px)';
	}

    let statement = "API.GetWidgetTabSettings @WidgetId = " + this.widgetObject.WidgetId;
    this.dataService.SQLActionAsPromise(statement).then((data: any) => {
      this.WidgetTabSettings = data;
      this.WidgetTabSettings.forEach(tabSetting => {
        this.widgetTabs[tabSetting.TabIndex].legendVisible = !tabSetting.IsLegendHidden;
      })
      console.log(this);

    })
    this.isLoading = true;
    if (!Global.FullDataCacheExists) {
      this.fullDataCacheSubscription = this.dataService.fullDataCacheExists$.subscribe((data: any) => {
        if (data === true) {
          this.initialize();
          this.fullDataCacheSubscription.unsubscribe();
        }
      });
    } else {

      this.initialize();
    }

    this.colorChangedSubscription = this.dataService.colorChanged$.subscribe((data: any) => {
      if (data === "light") {
        this.CreateTheme('light');
      } else {
        this.CreateTheme('dark');
      }
    });
    if (Global.Theme === "light") {
      this.CreateTheme('light');

    } else {
      this.CreateTheme('dark');

    }

	if (this.widgetResizedEvent) {
		this.widgetResizedSubscription = this.widgetResizedEvent.subscribe((data) => {
			if (this.widgetObject.WidgetId == data.item.WidgetId) {
				this.cardResize(data);
				this.fontResize(data);
			}
		});
	}

	this.widgetToggleLegendHiddenSubscription = this.dashboardService._widgetToggleLegendHidden.subscribe((widgetObject: any) => {
		if (widgetObject && parseInt(this.widgetObject.WidgetId) === parseInt(widgetObject.WidgetId)) {
			this.toggleLegendHidden(this.tab ,this.selectedTabIndex);
		}
	});

    if (this.dashboardUpdateIntervalChanged) {
      this.dashboardUpdateIntervalSubscription = this.dashboardUpdateIntervalChanged.subscribe((data) => {
        console.log(data);
        let foundWidgetWithSameWidgetId = data.find(widgetThatWasChanged => {
          return widgetThatWasChanged.WidgetId === this.widgetObject.WidgetId
        })

        if (!_.isNil(foundWidgetWithSameWidgetId)) {
          //if data (which is list of widgets that had the time zone changed as an array of widgets includes a widget with this widget id, we can assume this widget needs to be updated. needs to initialize with new selected time zone in mind. )
          this.initialize();
        }

      });
    }
    if (this.dashboardTimeScopeChanged) {
      this.dashboardTimeScopeChangedSubscription = this.dashboardTimeScopeChanged.subscribe((data) => {
        let foundWidgetWithSameWidgetId = data.find(widgetThatWasChanged => {
          return widgetThatWasChanged.WidgetId === this.widgetObject.WidgetId
        })

        if (!_.isNil(foundWidgetWithSameWidgetId)) {
          console.log("Widget Time Zone Changed")
          this.initialize();

        }
      });
    }
    // this.chartDataIsReady = true;
	this.tab = this.widgetTabs[0];
  }



  fontResize(data: any) {

	if (data.height < 400 || data.width < 400) {
		this.fontSize1 = 'small';
		this.fontSize2 = 'x-small';
	}

	else if ((data.height > 400 && data.height < 500) || (data.width > 400 && data.width < 600)) {
		this.fontSize1 = 'medium';
		this.fontSize2 = 'small';
	}

	else if ((data.height > 500 && data.height < 700) || (data.width > 600 && data.width < 800)) {
		this.fontSize1 = 'large';
		this.fontSize2 = 'medium';
	}
	else if (data.height > 700 || data.width > 800) {
		this.fontSize1 = 'x-large';
		this.fontSize2 = 'large';
  }
}



cardResize(data: any) {

	if (data.height < 360 || data.width < 400) {
		// this.cardClass2 = 'card-tile-xs';
		// this.cardWideClass2 = 'card-wide-tile-sm';

		this.cardTileCenterTitleClass = 'grid-card-title-center-md';
		this.cardWideTileCenterTitleClass = 'grid-wide-card-title-center-md';

	}

	else if ((data.height > 360 && data.height < 500) || (data.width > 400 && data.width < 600)) {
		// this.cardClass2 = 'card-tile-sm';
		// this.cardWideClass2 = 'card-wide-tile-md';

		this.cardTileCenterTitleClass = 'grid-card-title-center-md';
		this.cardWideTileCenterTitleClass = 'grid-wide-card-title-center-md';

	}

	else if ((data.height > 500 && data.height < 700) || (data.width > 600 && data.width < 800)) {
		// this.cardClass2 = 'card-tile-sm';
		// this.cardWideClass2 = 'card-wide-tile-md';

		this.cardTileCenterTitleClass = 'grid-card-title-center-md';
		this.cardWideTileCenterTitleClass = 'grid-wide-card-title-center-md';

	}
	else if (data.height > 700 || data.width > 800) {
		// this.cardClass2 = 'card-tile-md';
		// this.cardWideClass2 = 'card-wide-tile-lg';

		this.cardTileCenterTitleClass = 'grid-card-title-center-md';
		this.cardWideTileCenterTitleClass = 'grid-wide-card-title-center-md';

	}

	if (data.width < 400) {
		this.headerGridTitle = 'grid-title-sm';
	}
	else if (data.width > 400 && data.width < 600) {
		this.headerGridTitle = 'grid-title-sm';
	}
	else if (data.width > 600 && data.width < 800) {
		this.headerGridTitle = 'grid-title-lg';
	}
	else if (data.width > 800) {
		this.headerGridTitle = 'grid-title-lg';
	}

}


  startIntervals() {
    this.updateInterval = this.dashboardService.determineProperTacticalDashboardUpdateIntervalForWidget(this.widgetObject);

    if (this.updateInterval === undefined) {
      this.isLoading = false;
      this.displayNoUpdateIntervalMessage = true;
      return;
    } else {
      this.isLoading = true;
      this.displayNoUpdateIntervalMessage = false;
    }
    let intervalUpdate = this.updateInterval.UpdateInterval * 60000;
    if (this.secondsAgoInterval !== undefined) {
      clearInterval(this.secondsAgoInterval);
      this.secondsAgoInterval = undefined;
    }
    this.retrieveData();

    this.secondsAgoInterval = setInterval(() => {
      if (this.lastUpdatedDate !== undefined) {
        this.displayTimeAgoMessage = this.dashboardService.calculateTimeAgo(this.lastUpdatedDate);
        this.widgetObject.displayTimeAgoMessage = this.displayTimeAgoMessage;
      }
    }, 1000);
    if (this.updateControllerInterval !== undefined) {
      clearInterval(this.updateControllerInterval);
      this.updateControllerInterval = undefined;
    }
    this.updateControllerInterval = setInterval(() => {
      this.retrieveData();
    }, intervalUpdate);
  }

  destroyIntervals() {
    if (this.updateControllerInterval) {
      clearInterval(this.updateControllerInterval);
      this.updateControllerInterval = undefined;
    }
    if (this.secondsAgoInterval) {
      clearInterval(this.secondsAgoInterval);
      this.secondsAgoInterval = undefined;
    }
  }


  initialize() {
    if (_.isNil(this.widgetObject.SiteList)) {
      this.isLoading = false;
      return;
    }
    let statement = "API.TacticalDashboardsRetrieveAllRecordsForWidgetId @widgetId = " + this.widgetObject.WidgetId + ", @userId = " + Global.User.currentUser.Id;
    this.dataService.SQLActionAsPromise(statement).then((data: any) => {
      this.widgetTabsChartConfigurations = data;
      this.widgetTabs.forEach((tab, index) => {
        if (this.widgetTabsChartConfigurations.length > 0) {
          let foundWidgetTabConfiguration = this.widgetTabsChartConfigurations.find((configurationrecord) => {
            return configurationrecord.WidgetTabIndex === index;
          });
          if (foundWidgetTabConfiguration !== undefined) {
            tab.graphType = Global.tacticalDashboardGraphTypes.find((graphType) => graphType.id == foundWidgetTabConfiguration.ChartTypeId);
          }
        }
      });
      this.widgetTabConfigurationsFinishedMapping = true;

      this.selectedTabIndex = this.widgetObject.SelectedTabIndex !== undefined ? this.widgetObject.SelectedTabIndex : 0;
      this.selectedMatTabLabel = this.widgetTabs[this.widgetObject.SelectedTabIndex !== undefined ? this.widgetObject.SelectedTabIndex : 0].textLabel;
      this.widgetTabs[this.widgetObject.SelectedTabIndex !== undefined ? this.widgetObject.SelectedTabIndex : 0].tabHasBeenLoaded = true;
	  this.tab = this.widgetTabs[this.selectedTabIndex];

      this.startIntervals()

	})
  }

  showDialogModalPopupGrid(tab) {
    const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
		width: Global.isMobile ? '100%' : '60%',
		height: Global.isMobile ? '90%' : '80%',
		data: {
			graphId: uuidv4(),
			groupable: tab.groupable,
			groupColumns: tab.groupable === true ? tab.groupColumns : [],
			popupTitle: 'Raw Data: ' + tab.textLabel,
			WidgetName: 'Kendo-Grid',
			tableStructure: tab.tableStructure,
			tableData: tab.tableData,
			canCreateDashboardWidgetFromPopup: false,
		},
		maxWidth: '100vw',
		maxHeight: '100vh',
	});
	}

	changeGraphType(event) {
		let i = this.selectedTabIndex;
		this.widgetTabs[i].graphType = event;
		let existingRecord = this.widgetTabsChartConfigurations.find(
			(record) => {
				return record.WidgetTabIndex === i;
			}
		);

		let statement =
			'API.TacticalDashboardAddOrUpdateWidgetChartIndexRecord @widgetId = ' +
			this.widgetObject.WidgetId +
			', @userId = ' +
			Global.User.currentUser.Id +
			', @chartTypeId = ' +
			event.id +
			', @widgetTabIndex = ' +
			i;
		if (existingRecord !== undefined) {
			statement = statement + ', @id = ' + existingRecord.Id;
		}
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.widgetTabsChartConfigurations = data;
			this.utilityService.showToastMessageShared({
				type: 'success',
				message: 'Chart type changed to ' + event.properName,
			});
			//   this.toastr.success("Chart type changed to " + event.properName);
		});
	}

	showChartAsPopup(tab) {
		var popupData;
		var field;
		var categoryField;
		var XAxisTitle;

		if (tab.textLabel === 'Asset Cycle Count Today') {
			field = 'CycleCount';
			categoryField = 'GateNameFull';
			XAxisTitle = 'Count';
		}
		else if (tab.textLabel === 'Asset Run Time Minutes Today') {
			field = 'RunTimeMinutes';
			categoryField = 'GateNameFull';
			XAxisTitle = 'Run Time(Mins)';
		}
		else if (tab.textLabel === 'Asset Cycle Count Historical') {
			field = 'CycleCount';
			categoryField = 'DateOfObservationDateObject';
			XAxisTitle = 'Cycle Count For Day';
		}
		else if (tab.textLabel === 'Asset Run Time Historical') {
			field = 'RunTimeMinutes';
			categoryField = 'DateOfObservationDateObject';
			XAxisTitle = 'Run Time Minutes For Day';
		}
		else if (tab.textLabel === 'Asset Type Cycle Count Historical') {
			field = 'CycleCount';
			categoryField = 'DateOfObservationDateObject';
			XAxisTitle = 'Cycle Count For Day';
		}
		else if (tab.textLabel === 'Asset Type Run Time Historical') {
			field = 'RunTimeMinutes';
			categoryField = 'DateOfObservationDateObject';
			XAxisTitle = 'Run Time Minutes For Day';
		}

		popupData = {
			categoryField: categoryField,
			field: field,
			popupTitle: 'Raw Chart: ' + tab.textLabel,
			titleXAxis: XAxisTitle,
			chartTitle: tab.textLabel,
			WidgetName: 'Kendo-Chart',
			chartData: tab.chartData,
			canCreateDashboardWidgetFromPopup: false,
			graphType: tab.graphType?.name,
			legendVisible: tab.legendVisible,
			canIterate: true,
		}

		const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '90%' : '90%',
			height: Global.isMobile ? '90%' : '90%',
			data: popupData,
			maxWidth: '100vw',
			maxHeight: '100vh',
		});
	}

	public toggleLegendChange(event: any) {
		this.toggleLegendHidden(this.tab, this.selectedTabIndex);
	}


  retrieveData() {
    this.timeScopeObject = this.dashboardService.determineProperTimeScopeForWidget({
		widgetObject: this.widgetObject,
		UTCConfiguration: false,
	});
    if (this.timeScopeObject === undefined) {
      this.displayNoTimeScopeMessage = true;
      this.isLoading = false;
    } else {
      this.displayNoTimeScopeMessage = false;
      if (this.widgetTabs[0].tabHasBeenLoaded === true || this.widgetTabs[1].tabHasBeenLoaded === true || this.widgetTabs[2].tabHasBeenLoaded === true || this.widgetTabs[3].tabHasBeenLoaded === true || this.widgetTabs[4].tabHasBeenLoaded === true || this.widgetTabs[5].tabHasBeenLoaded === true || this.widgetTabs[6].tabHasBeenLoaded === true) {
        this.widgetTabs[0].isLoading = this.widgetTabs[1].isLoading = this.widgetTabs[2].isLoading = this.widgetTabs[3].isLoading = this.widgetTabs[4].isLoading = this.widgetTabs[5].isLoading = this.widgetTabs[6].isLoading = true;

        let statement = "API.TacticalDashboard_GS_Asset_RunTimeAndCycleCount @accessToken= '" + Global.User.currentUser.ODataAccessToken + "'";
        if (!_.isNil(this.widgetObject.SiteList)) {
          statement = statement + ", @siteIdList = '" + this.widgetObject.SiteList + "'";
        }
        if (!_.isNil(this.widgetObject.WidgetAssetTypeId) && this.widgetObject.WidgetAssetTypeId !== 0) {
          statement = statement + ", @assetTypeId = " + this.widgetObject.WidgetAssetTypeId;
        }
        if (!_.isNil(this.timeScopeObject)) {
          statement =
				statement +
				', @startDateTimeMS=' +
				this.timeScopeObject.queryStartDate +
				', @endDateTimeMS= ' +
				this.timeScopeObject.queryEndDate;
        }
        this.dataService.SQLActionAsPromise(statement).then((data: any) => {
          console.log(data);
          if (data.length === 0) {
            this.widgetTabs[1].tableData = data;
            this.widgetTabs[2].tableData = data;
            this.widgetTabs[3].tableData = data;
            this.widgetTabs[4].tableData = data;
            this.widgetTabs[5].tableData = data;
            this.widgetTabs[6].tableData = data;
            this.widgetTabs[1].chartData = data;
            this.widgetTabs[2].chartData = data;
            this.widgetTabs[3].chartData = data;
            this.widgetTabs[4].chartData = data;
            this.widgetTabs[5].chartData = data;
            this.widgetTabs[6].chartData = data;
            this.widgetTabs[0].isLoading = this.widgetTabs[1].isLoading = this.widgetTabs[2].isLoading = this.widgetTabs[3].isLoading = this.widgetTabs[4].isLoading = this.widgetTabs[5].isLoading  = this.widgetTabs[6].isLoading = false;
          } else {
            data.forEach(record => {
				record.DateOfObservationDateObject = new Date(record.DateOfObservation);
				record.DateInMilleseconds = record.DateOfObservationDateObject.getTime();

              record.ProperAssetName = record.SiteName + ' ' + record.GateName + ' ' + record.AssetName;
              record.GateNameFull = record.SiteName + ' ' + record.GateName;
            })

            this.widgetTabs[1].tableData = this.widgetTabs[2].tableData = this.widgetTabs[3].tableData = this.widgetTabs[4].tableData = this.widgetTabs[5].tableData = this.widgetTabs[6].tableData = data

			let sortByCycleCount = data.slice();
			sortByCycleCount.sort(
				(a, b) =>
					b.CycleCount -
					a.CycleCount
			);

			let sortByRunTimeMinutes = data.slice();
			sortByRunTimeMinutes.sort(
				(a, b) =>
					b.RunTimeMinutes -
					a.RunTimeMinutes
			);

			this.summaryArray[0] = sortByCycleCount[0].CycleCount; //Highest Cycle Count
			this.summaryArray[1] = sortByCycleCount[0].ProperAssetName; //Highest Cycle Count
			this.summaryArray1[0] = sortByRunTimeMinutes[0].RunTimeMinutes; //Highest Run time minutes
			this.summaryArray1[1] = sortByRunTimeMinutes[0].ProperAssetName; //Highest Run time minutes


			let groupByDayandSortByCycleCount = groupBy(data, [
				{ field: 'DateOfObservation' },
			]) as any[];

			groupByDayandSortByCycleCount.forEach(day => {
				var totalCycleCountForDay = day.items.reduce((accum,item) => accum + item.CycleCount, 0)
				//console.log("totalCycleCountForDay=", totalCycleCountForDay);
				day.totalCycleCountForDay = totalCycleCountForDay;
			})

			groupByDayandSortByCycleCount.sort(
				(a, b) =>
					b.totalCycleCountForDay -
					a.totalCycleCountForDay
			);

			this.summaryArray[2] = groupByDayandSortByCycleCount[0].totalCycleCountForDay; //Highest Cycle Count
			this.summaryArray[3] = groupByDayandSortByCycleCount[0].value; //Highest Cycle Count

			let groupByDayandSortByRuntimeMinutes = groupBy(data, [
				{ field: 'DateOfObservation' },
			]) as any[];

			groupByDayandSortByRuntimeMinutes.forEach(day => {
				var totalRunTimeMinutesForDay = day.items.reduce((accum,item) => accum + item.RunTimeMinutes ? item.RunTimeMinutes : 0, 0)
				//console.log("totalCycleCountForDay=", totalCycleCountForDay);
				day.totalRunTimeMinutesForDay = totalRunTimeMinutesForDay;
			})

			groupByDayandSortByRuntimeMinutes.sort(
				(a, b) =>
					b.totalRunTimeMinutesForDay -
					a.totalRunTimeMinutesForDay
			);

			this.summaryArray1[2] = groupByDayandSortByRuntimeMinutes[0].totalRunTimeMinutesForDay; //Highest Run time minutes
			this.summaryArray1[3] = groupByDayandSortByRuntimeMinutes[0].value; //Highest Run time minutes


            let gateGrouped = groupBy(data, [{ field: "DateInMilleseconds" }]) as any[];
            let today: any = gateGrouped.pop()
            let todayDataArray = []
            todayDataArray.push(today);
            let todayDataRaw = todayDataArray[0].items;


            let todayDataGroupedByAssetType = groupBy(todayDataRaw, [{ field: "AssetName" }]) as any[];
            this.widgetTabs[1].tableData = this.widgetTabs[4].tableData = todayDataRaw;
            this.widgetTabs[1].chartData = todayDataGroupedByAssetType;
            this.widgetTabs[1].chartDataIsReady = true;



            this.widgetTabs[2].chartData = groupBy(data, [{ field: "ProperAssetName" }]) as any[];
            if (this.widgetTabs[2].chartData.length > 0) {
              this.widgetTabs[2].chartData.push({ value: "Toggle All Series" })
            }
            this.widgetTabs[2].chartDataIsReady = true;


            this.widgetTabs[3].chartData = groupBy(data, [{ field: "AssetName" }]) as any[];
            this.widgetTabs[3].chartDataIsReady = true;



            this.widgetTabs[4].chartData = todayDataGroupedByAssetType;
            this.widgetTabs[4].chartDataIsReady = true;

            this.widgetTabs[5].chartData = groupBy(data, [{ field: "ProperAssetName" }]) as any[];
            if (this.widgetTabs[5].chartData.length > 0) {
              this.widgetTabs[5].chartData.push({ value: "Toggle All Series" })
            }
            this.widgetTabs[5].chartDataIsReady = true;

            this.widgetTabs[6].chartData = groupBy(data, [{ field: "AssetName" }]) as any[];
            this.widgetTabs[6].chartDataIsReady = true;



            this.widgetTabs[0].isLoading = this.widgetTabs[1].isLoading = this.widgetTabs[2].isLoading = this.widgetTabs[3].isLoading = this.widgetTabs[4].isLoading = this.widgetTabs[5].isLoading = this.widgetTabs[6].isLoading = false;
          }


        })
      }








      this.lastUpdatedDate = new Date();

      this.isLoading = false;

      // this.chartDataIsReady = true;


      // })
    }




  }



	private buildNavigationOptionsArray() {
		var service = this;

		var navigationWidth = Global.isMobile ? "150px" : "175px";

		service.options = [
			{
				id: 1,
				width: navigationWidth,
				name: "Navigation",
				children: [
					{
						id: 10,
						width: navigationWidth,
						name: "Summary",
						action: () => { service.navigateToTab(0, "Summary");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Cycle Count Today",
						action: () => { service.navigateToTab(1, "Asset Cycle Count Today");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Cycle Count History",
						action: () => { service.navigateToTab(2, "Asset Cycle Count Historical");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Cycle Count/Type History",
						action: () => { service.navigateToTab(3, "Asset Type Cycle Count Historical");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Run Time Today",
						action: () => { service.navigateToTab(4, "Asset Run Time Minutes Today");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Run Time History",
						action: () => { service.navigateToTab(5, "Asset Run Time Historical");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Run Time/Type History",
						action: () => { service.navigateToTab(6, "Asset Type Run Time Historical");  this.navigationOpened=false; }
					},

					// {
					// 	id: 11,
					// 	width: navigationWidth,
					// 	name: "Raw Data",
					// 	children: [
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Cycle Count Today",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[1]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Cycle Count History",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[2]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Cycle Count Per Type History",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[3]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Run Time Today",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[4]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Run Time History",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[5]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Run Time Per Type History",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[6]);  this.navigationOpened=false; }
					// 		},

					// 	],


					// }

				],
				root: true,
				opened: false
			},
			{
				id: 2,
				width: navigationWidth,
				name: "Settings",
				children: [
					{
						id: 10,
						width: navigationWidth,
						name: "Site",
						action: () => { service.widgetObject.editWidgetSettings(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Edit Name",
						action: () => { service.widgetObject.editWidgetName(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Reload",
						action: () => { service.dashboardService._reloadedWidget.next(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Delete",
						action: () => { service.widgetObject.deleteFunction();  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Timescope",
						action: () => { service.widgetObject.editTimeScopeSettings(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Interval",
						action: () => { service.widgetObject.editIntervalSettings(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Legend",
						action: () => { service.toggleLegendHidden(service.tab, service.selectedTabIndex);  this.navigationOpened=false; }
					},

					{
						id: 23,		// id has to match id in toggle method below
						width: navigationWidth,
						name: service.widgetObject.RememberSelectedTabIndex == 0 ? "Remember Nav" : "Forget Nav",
						action: () => { service.widgetObject.invertRememberingOfSelectedTabIndex(service.widgetObject); this.toggleRememberingOfSelectedTabIndexName(); this.navigationOpened=false; }
					},

				],
				root: true,
				opened: false
			}
		];

		let optionsDesktop = [
			{
				id: 22,		// id has to match id in toggle method below
				width: navigationWidth,
				name: service.widgetObject.dragEnabled ? "Disable Drag" : "Allow Drag",
				action: () => { service.widgetObject.invertDraggableSetting(service.widgetObject); this.toggleDragName(); this.navigationOpened=false;}
			},
			{
				id: 21,		// id has to match id in toggle method below
				width: navigationWidth,
				name: service.widgetObject.resizeEnabled ? "Disable Resize" : "Allow Resize",
				action: () => { service.widgetObject.invertResizeSetting(service.widgetObject); this.toggleResizeName(); this.navigationOpened=false;}
			},
		];

		if(Global.isMobile == false) {
			service.options[1].children = service.options[1].children.concat(optionsDesktop);
		}

		service.optionsSettingsOnly = service.options.where((item:any) => { return item.id == 2 }).toArray(); //- Settings tab only when no Site selected.

		console.log("options built", service.options);

	}


	public editWidgetSettings() {
		this.widgetObject.editWidgetSettings(this.widgetObject)
	}

	public refreshWidget() {
		this.dashboardService._reloadedWidget.next(this.widgetObject);
	}

	public changeWidgetInterval() {
		this.widgetObject.editIntervalSettings(this.widgetObject);
	}

	public changeWidgetTimescope() {
		this.widgetObject.editTimeScopeSettings(this.widgetObject);
	}


	private toggleRememberingOfSelectedTabIndexName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item:any) => { return item.id == 2 }).firstOrDefault();
		var rememberTabSetting = settings.children.where((item:any) => { return item.id == 23 }).firstOrDefault();

		rememberTabSetting.name = this.widgetObject.RememberSelectedTabIndex == 0 ? "Remember Nav" : "Forget Nav";

	}

	private toggleDragName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item:any) => { return item.id == 2 }).firstOrDefault();
		var dragSetting = settings.children.where((item:any) => { return item.id == 22 }).firstOrDefault();

		dragSetting.name = this.widgetObject.dragEnabled ? "Disable Drag" : "Enable Drag";

	}

	private toggleResizeName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item:any) => { return item.id == 2 }).firstOrDefault();
		var resizeSetting = settings.children.where((item:any) => { return item.id == 21 }).firstOrDefault();

		resizeSetting.name = this.widgetObject.resizeEnabled ? "Disable Resize" : "Allow Resize";

	}

	public checkNavigation(opened: any) {
		Global.User.DebugMode && console.log("checkNavigation opened = %O", opened);
		this.navigationOpened = opened;
		this.isLoading = false;
	}

	public checkSelectedItem(selected: any) {
		Global.User.DebugMode && console.log("checkSelectedItem invoked. selected = %O", selected);
	}

	public openCloseNavigation() {
		Global.User.DebugMode && console.log("openCloseNavigation invoked. navigationOpened current status: = " + this.navigationOpened);
		this.navigationOpened = !this.navigationOpened; //--whatever it was set to, set it to the opposite.
		Global.User.DebugMode && console.log("openCloseNavigation invoked. navigationOpened changed status: = " + this.navigationOpened);
	}



  toggleLegendHidden(tab, index) {
    tab.legendVisible = !tab.legendVisible;
    let foundSetting = this.WidgetTabSettings.find(widgetTabSetting => {
      return widgetTabSetting.TabIndex === index;
    })

    let statement = "API.EditWidgetTabSettings @WidgetId = " + this.widgetObject.WidgetId + ", @TabIndex = " + index + ", @IsHidden = " + !tab.legendVisible;
    if (!_.isNil(foundSetting)) {
      statement = statement + ", @Id = " + foundSetting.Id;
    }
    this.dataService.SQLActionAsPromise(statement).then((data: any) => {
      this.WidgetTabSettings = data;
      this.WidgetTabSettings.forEach(tabSetting => {
        this.widgetTabs[tabSetting.TabIndex].legendVisible = !tabSetting.IsLegendHidden;
      })
    })

  }

  animationRedraw() {
    // if (this.selectedMatTabLabel === "Map") {
    // 	this.loadCorrectTabData(true)

    // }
  }

  navigateToTab(index, textLabel)  {
	this.tab = this.widgetTabs[index];
	  if (this.tab.graphType != undefined && this.tab.graphType.name == undefined)
		  this.tab.graphType = Global.tacticalDashboardGraphTypes.find((graphType) => graphType.name == this.tab.graphType);


	clearTimeout(this.timeoutToExpire);
	this.selectedMatTabLabel = textLabel;
	this.selectedTabIndex = index;
	if (this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded === false) {
		this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded = true;
		this.destroyIntervals();
		this.startIntervals();
	}
	this.timeoutToExpire = setTimeout(() => {
		this.widgetObject.SelectedTabIndex = this.selectedTabIndex;
		if (this.widgetObject.RememberSelectedTabIndex) {
			let returnedWidgetAsArray =
				this.dataService.updateTacticalDashboardWidgetRecord(
					this.widgetObject
				);
		}

	}, 5000);
}

  tabHasChanged(event) {

    clearTimeout(this.timeoutToExpire);
    this.selectedMatTabLabel = event.tab.textLabel;
    this.selectedTabIndex = event.index;
    if (this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded === false) {
      this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded = true;
      this.destroyIntervals();
      this.startIntervals();
    }
    this.timeoutToExpire = setTimeout(() => {
		this.widgetObject.SelectedTabIndex = this.selectedTabIndex;
		if (this.widgetObject.RememberSelectedTabIndex) {
			let returnedWidgetAsArray =
				this.dataService.updateTacticalDashboardWidgetRecord(
					this.widgetObject
				);
		}
	}, 5000);
  }

  onResized(event) {
    // this.tagDataPageSize = Math.floor(((event.newRect.height - 120) / this.tagDataRowHeight) * 3);
    // this.eventDataPageSize = Math.floor(((event.newRect.height - 120) / this.eventDataRowHeight) * 3);
    this.parentContainerSize = event.newRect.height;
    if (this.childOverviewGauge !== undefined) {
      this.childOverviewGauge.reFlow()

    }

    // Global.User.DebugMode && console.log("gse-summary: pageSize = " + this.tagDataPageSize);
    // this.newHeight = event.newRect.height;
    // this.oldHeight = event.oldHeight;
    // this.newWidth = event.newRect.width;
    // this.oldWidth = event.oldWidth;
  }

  initializeTour(): void {
	this.tourService.initialize([
		{
			anchorId: this.widgetObject.WidgetId + '-1',
			content: 'Here you can click on the Site, Update Interval and Timescope areas to change the settings for this widget',
			title: 'Settings',
		},
		{
			anchorId:  this.widgetObject.WidgetId + '-2',
			content: 'Navigate to different tabs & Widget Settings',
			title: 'Navigation',
		},
		{
			anchorId:  this.widgetObject.WidgetId + '-3',
			content: 'Click on various tiles for more information',
			title: 'Summary Info',
		},
	]);

	this.tourService.start();
}

  ngOnDestroy() {
    this.destroyIntervals();

  }

  public labelContent(e: any): string {
    return e.category + ': ' + e.value;
  }

  public toggleSeries(item: any, type): void {

    let i = item.series.index;
    if (type === 'assetCycleCountHistorical') {
      this.assetCycleCountHistorical._results[i].toggleVisibility();
    } else if (type === 'assetRunTimeHistoical') {
      this.assetRunTimeHistoical._results[i].toggleVisibility();
    }


  }

  public toggleAllSeries(e, type) {
    if (e.text === "Toggle All Series") {
      if (type === 'assetCycleCountHistorical') {
        this.assetCycleCountHistorical._results.forEach((series, index) => {
          this.assetCycleCountHistorical._results[index].toggleVisibility();
        })
      } else if (type === 'assetRunTimeHistoical') {
        this.assetRunTimeHistoical._results.forEach((series, index) => {
          this.assetRunTimeHistoical._results[index].toggleVisibility();
        })
      }

    }
  }


}
