<lib-loading-spinner
	*ngIf="isDataLoading"
	[loadingMessage]="'Loading Data...'"
	[vehicle]="'plane'"
></lib-loading-spinner>
<div *ngIf="!isDataLoading" class="parent-table-container card card-tasks">

	<lib-kendo-grid-parent
		[gridData]="gridData"
		[gridSettings]="gridSettings"
		[widgetObject]="widgetObject"
		[configuration]="configuration"
		(callSave)="saveState()"
		[style.height.%]="100"
		filterable="menu"
		[sortable]="true"
		[reorderable]="true"
		[resizable]="true"
		[gridContextMenuItems]="gridContextMenuItems"
		(onRightClickSelectEmitter)="onRightClickSelect($event)"
		*ngIf="gridData !== undefined"

		#tagDataGrid>
	</lib-kendo-grid-parent>

</div>
