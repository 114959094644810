import { Component, AfterViewInit, Input, EventEmitter, ViewChild, ElementRef, ViewChildren } from '@angular/core';
import _ from "lodash";
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { TacticalDashboardOverviewGaugeComponent } from '../../components/tactical-dashboard-overview-gauge/tactical-dashboard-overview-gauge.component';
import { Global } from '../../_constants/global.variables';
import { DataService } from '../../services/data.service';
import { DashboardService } from '../../services/dashboard.service';
import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';
import { IGlobal } from '../../_models/global.model';
import { UtilityService } from "../../services/utility.service";
import { TacticalWidgetSummaryTilePopupComponent } from '../../components/tactical-widget-summary-tile-popup/tactical-widget-summary-tile-popup.component';
import { TourService } from 'ngx-ui-tour-md-menu';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'lib-gse-out-of-service-or-maintenance-mode',
  templateUrl: './gse-out-of-service-or-maintenance-mode.component.html',
  styleUrls: ['./gse-out-of-service-or-maintenance-mode.component.scss']
})
export class GseOutOfServiceOrMaintenanceModeComponent implements AfterViewInit {
  @ViewChildren('seriesItems')
  public series: any;

  @ViewChild('chart')
  public chart: any;
  public graphTypes: string[] = ['area', 'bar', 'column', 'donut', 'horizontalWaterfall', 'line', 'verticalArea', 'verticalLine', 'waterfall'];

  public graphType = this.graphTypes[0];
  public graphShow: boolean = true;

  @Input() private dashboardTimeScopeChanged: EventEmitter<any>;
	@Input() private widgetResizedEvent: EventEmitter<any>;
  @ViewChild("childOverviewGauge") childOverviewGauge: TacticalDashboardOverviewGaugeComponent;
  public chartDataIsReady: boolean = false;
  public isCollapsed = false;
  public global: IGlobal = Global;

  @Input() widgetObject: any;
  h1TitlePercent: number;
  componentSubtitle: String = '';
	percentCalculated: boolean = false;
	updateControllerInterval: NodeJS.Timeout;
  lastUpdatedDate: Date;
  displayTimeAgoMessage: any;
  @Input() private dashboardUpdateIntervalChanged: EventEmitter<any>;
  dashboardUpdateIntervalSubscription: any;
  secondsAgoInterval: NodeJS.Timeout;
  fullDataCacheSubscription: any;
  dialogModalData: any;
  chartData: any;
  noDataForSite: boolean = false;
  chartBackgroundColor: string = "#27304C"
  chartLabelColors: string = "white"
  gridLineColor: string = "white"
  axisItemColor: string = "white"
  colorChangedSubscription: any;
	fontSize1 = 'medium';
	fontSize2 = 'small';
  theme: string;
	summaryArray = [];
	cardClass1 = 'card-tile-md';
	cardClass2 = 'card-tile-md';
	cardWideClass2 = 'card-wide-tile-md';
	gridNumberTitleClass = 'grid-number-title-md';
	holeSize = 30;
	holeCenterTextClass = 'donut-center-text-md';
	donutClass = 'donut-md';
	headerGridTitle = 'grid-title-lg';
	cardTileCenterDonutTitleClass = 'grid-card-title-center-md';
	cardTileCenterTitleClass = 'grid-card-title-center-sm';
	contentHeight: any;
	widgetResizedSubscription: any;
	public label = {
		visible: false,
	};
	public progressStyles: { [key: string]: any } = {
		background: 'limegreen',
	};

  barChartData: any[] = [{ Status: 10 }, { Status: 20 }, { Status: 200 }, { Status: 200 }]
  public userDefinedAlarmObservationIds: any = [56442, 56443, 56444, 56445, 56446, 56447, 56448, 56449, 56450, 56451, 56452, 56453, 56454, 56455, 56456, 56457, 56458, 56459, 56460, 56461]
  displayNoUpdateIntervalMessage: boolean;
  updateInterval: any;
  isLoading: boolean;
  widgetNavigateToFirstTabSubscription: Subscription;
  widgetToggleLegendHiddenSubscription: Subscription;
  public tab: any;
  public widgetTabs: any = [
    {
      isLoading: false,
      tabHasBeenLoaded: false,
		  textLabel: "Summary",
      rendered: true,
      downloadableData: true,
		  tooltip: "Summary of assets that are currently out of service or in maintenance mode.Click on the info to navigate to corresponding tab for details.",

    },
    {
      isLoading: false,
      tabHasBeenLoaded: false,
      textLabel: "Current Unavailable Assets",
      tooltip: "Donut chart showing within the current scope settings (site, asset type) the amount of unavailable assets (assets that are in maintenance mode or out of service) out of the total amount of assets within the selected scope.",
      downloadableData: true,
      chartDataIsReady: false,
      groupable: true,
		graphType: this.graphTypes[3],
      legendVisible: true,
      groupColumns: [{ field: 'IsOutOfService' }, { field: 'IsInMaintenanceMode' }],
      tableStructure: [

        {
          field: "SiteName",
          title: "Site",
          _width: 100
        },
        {
          field: "AssetName",
          title: "Asset Name",
          _width: 300
        },
        {
          field: "IsOutOfService",
          title: "Out of Service",
          _width: 300,
        },
        {
          field: "IsInMaintenanceMode",
          title: "In Maintenance Mode",
          _width: 300
        },
        {
          field: "MaintenanceModeReason",
          title: "Maintenance Mode Reason",
          _width: 300,
        },
        {
          field: "OutOfServiceReason",
          title: "Out Of Service Reason",
          _width: 300,
        },
        {
          field: "LastModifiedUser",
          title: "Last Modified User",
          _width: 300
        }

      ],
    },
    {
      isLoading: false,
      tabHasBeenLoaded: false,
		graphType: this.graphTypes[5],
      textLabel: "Total Minutes in Out of Service For Day",
      tooltip: "Detailed line chart showing within the current scope settings (site, asset type) the total amount of minutes an asset was in out of service or maintenance mode for the 24 hour period of the date shown. ",
      downloadableData: true,
      groupable: true,
      legendVisible: true,
      groupColumns: [{ field: 'AssetName' }, { field: 'JBTStandardObservationName' }],
      tableStructure: [
        {
          field: "DateOfObservationDateObject",
          title: "Date",
          filter: 'date',
          _width: 300,
          formatOption: 'shortDate'
        },
        {
          field: "SiteName",
          title: "Site",
          _width: 100
        },
        {
          field: "AssetName",
          title: "Asset Name",
          _width: 300
        },
        {
          field: "JBTStandardObservationName",
          title: "Tag Name",
          _width: 300
        },
        {
			field: "AssetOutOfServiceMaintenanceModeCount",
          title: "Count",
          _width: 300
        },
        {
			field: "AssetOutOfServiceMaintenanceModeDurationMinutes",
          title: "Duration",
          _width: 300
        },
      ]
    },

  ];


  // AssetId:137892
  // AssetName:'B95019024'
  // AssetOutOfServiceMaintenanceModeCount:0
  // AssetOutOfServiceMaintenanceModeDurationMinutes:0
  // DateOfObservation:'02/24/2022'
  // DateOfObservationDateObject:Thu Feb 24 2022 00:00:00 GMT-0600 (Central Standard Time)
  // JBTStandardObservationName:'Is in Maintenance Mode'
  // SiteName:'CVG'
  parentContainerSize: any;
  selectedMatTabLabel: string;
  displayNoTimeScopeMessage: boolean = false;
  dashboardTimeScopeChangedSubscription: any;
  timeoutToExpire: NodeJS.Timeout;
  selectedTabIndex: number = 0;
  widgetTabsChartConfigurations: any;
  widgetTabConfigurationsFinishedMapping: boolean = false;
  WidgetTabSettings: any = [];
	public timeScopeObject: any;
	public progressBackground: any;

	public options: any = [];
	public optionsSettingsOnly: any = [];
	public navigationOpened: boolean = false;

	public percentMaintenanceModeData = [];
	public percentOutOfServiceModeData = [];

	ConditionalShadingRecord: any;
	percentTextColor: any = [];
	conditionalColorPercentAmount: any = [];
	anchorId1: string;
	anchorId2: string;
	anchorId3: string;
	Fleet: any;
	assetIdList: string;

	constructor(public dataService: DataService, private utilityService: UtilityService, private dashboardService: DashboardService, private dialog: MatDialog, private elem: ElementRef, public tourService: TourService,) { }

  CreateTheme(theme: string) {
    if (theme === 'light') {
      this.theme = 'light';
      this.chartBackgroundColor = "white"
      this.chartLabelColors = "grey"
      this.gridLineColor = "grey"
      this.axisItemColor = "grey"
    } else {
      this.theme = 'dark';
      this.chartBackgroundColor = "#27304C"
      this.chartLabelColors = "white"
      this.gridLineColor = "white"
      this.axisItemColor = "white"
    }
  }

	initializeTour(): void {
		this.tourService.initialize([
			{
			anchorId: this.widgetObject.WidgetId + '-1',
			content: 'Here you can click on the Site, Update Interval and Timescope areas to change the settings for this widget',
			title: 'Settings',
		},
		{
			anchorId: this.widgetObject.WidgetId + '-2',
			content: 'Navigate to different tabs & Widget Settings',
			title: 'Navigation',
		},
		{
			anchorId: this.widgetObject.WidgetId + '-3',
			content: 'Click on various tiles for more information',
			title: 'Summary Info',
		},
	]);

	  this.tourService.start();
  }

	ngOnInit() {
	  this.tab = this.widgetTabs[0];
	  this.updateInterval =
		  this.dashboardService.determineProperTacticalDashboardUpdateIntervalForWidget(
			  this.widgetObject
		  );
	  this.timeScopeObject =
	  this.dashboardService.determineProperTimeScopeForWidget({
		widgetObject: this.widgetObject,
		UTCConfiguration: false,
	});
	  this.buildNavigationOptionsArray();
  }

  ngAfterViewInit() {

	var bounds = this.elem.nativeElement.getBoundingClientRect();
	this.cardResize(bounds);

	if(Global.isMobile == true) {
		this.contentHeight = 'calc(100% - 20px)';
	}
	else {
		this.contentHeight = 'calc(100% - 40px)';
	}

    let statement = "API.GetWidgetTabSettings @WidgetId = " + this.widgetObject.WidgetId;
    this.dataService.SQLActionAsPromise(statement).then((data: any) => {
      this.WidgetTabSettings = data;
      this.WidgetTabSettings.forEach(tabSetting => {
        this.widgetTabs[tabSetting.TabIndex].legendVisible = !tabSetting.IsLegendHidden;
      })
      console.log(this);

    })

	this.ConditionalShadingRecord = this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
		return p.WidgetId == this.widgetObject.WidgetId;
	});

	if (this.ConditionalShadingRecord.length > 0) {
		this.ConditionalShadingRecord.sort((a, b) => parseFloat(a.Value) - parseFloat(b.Value));
	}

	  if (this.widgetResizedEvent) {
		  this.widgetResizedSubscription = this.widgetResizedEvent.subscribe((data) => {
			  if (this.widgetObject.WidgetId == data.item.WidgetId) {
				  this.cardResize(data);
				  this.fontResize(data);
			  }
		  });
	  }
	  this.widgetNavigateToFirstTabSubscription = this.dashboardService._widgetNavigateToFirstTab.subscribe((widgetObject: any) => {
		  if (widgetObject && parseInt(this.widgetObject.WidgetId) === parseInt(widgetObject.WidgetId)) {
			  this.selectedTabIndex = 0
		  }
	  });

	  this.widgetToggleLegendHiddenSubscription = this.dashboardService._widgetToggleLegendHidden.subscribe((widgetObject: any) => {
		if (widgetObject && parseInt(this.widgetObject.WidgetId) === parseInt(widgetObject.WidgetId)) {
			this.toggleLegendHidden(this.tab ,this.selectedTabIndex);
		}
	});

    this.selectedTabIndex = this.widgetObject.SelectedTabIndex !== undefined ? this.widgetObject.SelectedTabIndex : 0;


    this.selectedMatTabLabel = this.widgetTabs[this.widgetObject.SelectedTabIndex !== undefined ? this.widgetObject.SelectedTabIndex : 0].textLabel;
    this.isLoading = true;
    if (!Global.FullDataCacheExists) {
      this.fullDataCacheSubscription = this.dataService.fullDataCacheExists$.subscribe((data: any) => {
        if (data === true) {
          this.initialize();
          this.fullDataCacheSubscription.unsubscribe();
        }
      });
    } else {

      this.initialize();
    }

    this.colorChangedSubscription = this.dataService.colorChanged$.subscribe((data: any) => {
      if (data === "light") {
        this.CreateTheme('light');
      } else {
        this.CreateTheme('dark');
      }
    });
    if (Global.Theme === "light") {
      this.CreateTheme('light');

    } else {
      this.CreateTheme('dark');

    }

    if (this.dashboardUpdateIntervalChanged) {
      this.dashboardUpdateIntervalSubscription = this.dashboardUpdateIntervalChanged.subscribe((data) => {
        console.log(data);
        let foundWidgetWithSameWidgetId = data.find(widgetThatWasChanged => {
          return widgetThatWasChanged.WidgetId === this.widgetObject.WidgetId
        })

        if (!_.isNil(foundWidgetWithSameWidgetId)) {
          //if data (which is list of widgets that had the time zone changed as an array of widgets includes a widget with this widget id, we can assume this widget needs to be updated. needs to initialize with new selected time zone in mind. )
          this.initialize();
        }

      });
    }
    if (this.dashboardTimeScopeChanged) {
      this.dashboardTimeScopeChangedSubscription = this.dashboardTimeScopeChanged.subscribe((data) => {
        let foundWidgetWithSameWidgetId = data.find(widgetThatWasChanged => {
          return widgetThatWasChanged.WidgetId === this.widgetObject.WidgetId
        })

        if (!_.isNil(foundWidgetWithSameWidgetId)) {
          console.log("Widget Time Zone Changed")
          this.initialize();

        }
      });
    }
    // this.chartDataIsReady = true;
	  this.tab = this.widgetTabs[0];
  }

  public graphTypeChange(event: any) {
    this.graphType = this.graphTypes[event];

    // force re-render (necessary due to *ngFor)
    this.graphShow = !this.graphShow;
    setTimeout(() => this.graphShow = !this.graphShow, 10);
  }

  fontResize(data: any) {

	if (data.height < 400 || data.width < 400) {
		this.fontSize1 = 'small';
		this.fontSize2 = 'x-small';
	}

	else if ((data.height > 400 && data.height < 500) || (data.width > 400 && data.width < 600)) {
		this.fontSize1 = 'medium';
		this.fontSize2 = 'small';
	}

	else if ((data.height > 500 && data.height < 700) || (data.width > 600 && data.width < 800)) {
		this.fontSize1 = 'large';
		this.fontSize2 = 'medium';
	}
	else if (data.height > 700 || data.width > 800) {
		this.fontSize1 = 'x-large';
		this.fontSize2 = 'large';
  }
}


	cardResize(data: any) {

		if (data.height < 440 || data.width < 400) {
			this.cardClass1 = 'card-tile-md';
			this.cardClass2 = 'card-tile-xs';
			this.cardWideClass2 = 'card-wide-tile-sm';

			this.gridNumberTitleClass = 'grid-number-title-md';

			this.holeSize = 55;
			this.holeCenterTextClass = 'donut-center-text-xs';
			this.donutClass = 'donut-sm';

			this.cardTileCenterTitleClass = 'grid-card-title-center-sm';
			this.cardTileCenterDonutTitleClass = 'grid-card-title-center-md';

		}

		else if ((data.height > 440 && data.height < 500) || (data.width > 400 && data.width < 600)) {
			this.cardClass1 = 'card-tile-md';
			this.cardClass2 = 'card-tile-sm';
			this.cardWideClass2 = 'card-wide-tile-md';

			this.gridNumberTitleClass = 'grid-number-title-md';

			this.holeSize = 60;
			this.holeCenterTextClass = 'donut-center-text-sm';
			this.donutClass = 'donut-sm';

			this.cardTileCenterTitleClass = 'grid-card-title-center-md';
			this.cardTileCenterDonutTitleClass = 'grid-card-title-center-md';

		}

		else if ((data.height > 500 && data.height < 700) || (data.width > 600 && data.width < 800)) {
			this.cardClass1 = 'card-tile-md';
			this.cardClass2 = 'card-tile-sm';
			this.cardWideClass2 = 'card-wide-tile-md';

			this.gridNumberTitleClass = 'grid-number-title-md';

			this.holeSize = 60;
			this.holeCenterTextClass = 'donut-center-text-sm';
			this.donutClass = 'donut-sm';

			this.cardTileCenterTitleClass = 'grid-card-title-center-md';
			this.cardTileCenterDonutTitleClass = 'grid-card-title-center-md';

		}
		else if (data.height > 700 || data.width > 800) {
			this.cardClass1 = 'card-tile-lg';
			this.cardClass2 = 'card-tile-md';
			this.cardWideClass2 = 'card-wide-tile-lg';

			this.gridNumberTitleClass = 'grid-number-title-lg';

			this.holeSize = 60;
			this.holeCenterTextClass = 'donut-center-text-sm';
			this.donutClass = 'donut-sm';

			this.cardTileCenterTitleClass = 'grid-card-title-center-lg';
			this.cardTileCenterDonutTitleClass = 'grid-card-title-center-md';

		}

		if (data.width < 400) {
			this.headerGridTitle = 'grid-title-sm';
		}
		else if (data.width > 400 && data.width < 600) {
			this.headerGridTitle = 'grid-title-sm';
		}
		else if (data.width > 600 && data.width < 800) {
			this.headerGridTitle = 'grid-title-lg';
		}
		else if (data.width > 800) {
			this.headerGridTitle = 'grid-title-lg';
		}

	}

  startIntervals() {
    this.updateInterval = this.dashboardService.determineProperTacticalDashboardUpdateIntervalForWidget(this.widgetObject);

    if (this.updateInterval === undefined) {
      this.isLoading = false;
      this.displayNoUpdateIntervalMessage = true;
      return;
    } else {
      this.isLoading = true;
      this.displayNoUpdateIntervalMessage = false;
    }
    let intervalUpdate = this.updateInterval.UpdateInterval * 60000;
    if (this.secondsAgoInterval !== undefined) {
      clearInterval(this.secondsAgoInterval);
      this.secondsAgoInterval = undefined;
    }
    this.retrieveData();

    this.secondsAgoInterval = setInterval(() => {
      if (this.lastUpdatedDate !== undefined) {
        this.displayTimeAgoMessage = this.dashboardService.calculateTimeAgo(this.lastUpdatedDate);
        this.widgetObject.displayTimeAgoMessage = this.displayTimeAgoMessage;
      }
    }, 1000);
    if (this.updateControllerInterval !== undefined) {
      clearInterval(this.updateControllerInterval);
      this.updateControllerInterval = undefined;
    }
    this.updateControllerInterval = setInterval(() => {
      this.retrieveData();
    }, intervalUpdate);
  }

  destroyIntervals() {
    if (this.updateControllerInterval) {
      clearInterval(this.updateControllerInterval);
      this.updateControllerInterval = undefined;
    }
    if (this.secondsAgoInterval) {
      clearInterval(this.secondsAgoInterval);
      this.secondsAgoInterval = undefined;
    }
  }



  initialize() {
	  if (_.isNil(this.widgetObject.SiteList) && _.isNil(this.widgetObject.VocationalSettingsJSON)) {	// added fleet settings as an option
		  this.isLoading = false;
		  return;
	  }

	  // Fleet
	  //
	  if (!_.isNil(this.widgetObject.VocationalSettingsJSON)) {
		  //this.widgetObject.WidgetAssetTypeId = 3;

		  var VocationalSettings = JSON.parse(this.widgetObject.VocationalSettingsJSON);
		  console.log("VocationalSettings ", VocationalSettings)

		  let fleetId = VocationalSettings.id;
		  this.widgetObject.WidgetAssetTypeId = VocationalSettings.child.id;

		  let assetType = this.dataService.cache.assetTypes.find(
			  (assetType) => {
				  return assetType.Id == VocationalSettings.child.id;
			  }
		  );
		  this.widgetObject.WidgetAssetTypeName = assetType?.Name;

		  this.dataService.GetFleets().subscribe((data: any) => {
			  console.log(data);
			  this.Fleet = data.find((f) => {
				  return f.FleetId === fleetId;
			  }
			  );
			  console.log(this.Fleet);
		  });

		  this.dataService.GetFleetAssets(fleetId).subscribe((data: any) => {
			  let assetIdListArray = new Array();
			  data.forEach(record => {
				  assetIdListArray.push(record.AssetId);
			  });
			  this.assetIdList = assetIdListArray.toString();
		  });
	  }
    let statement = "API.TacticalDashboardsRetrieveAllRecordsForWidgetId @widgetId = " + this.widgetObject.WidgetId + ", @userId = " + Global.User.currentUser.Id;
    this.dataService.SQLActionAsPromise(statement).then((data: any) => {
      this.widgetTabsChartConfigurations = data;
      this.widgetTabs.forEach((tab, index) => {
        if (this.widgetTabsChartConfigurations.length > 0) {
          let foundWidgetTabConfiguration = this.widgetTabsChartConfigurations.find((configurationrecord) => {
            return configurationrecord.WidgetTabIndex === index;
          });
          if (foundWidgetTabConfiguration !== undefined) {
            tab.graphType = Global.tacticalDashboardGraphTypes.find((graphType) => graphType.id == foundWidgetTabConfiguration.ChartTypeId);
          }
        }
      });
      this.widgetTabConfigurationsFinishedMapping = true;

      this.selectedTabIndex = this.widgetObject.SelectedTabIndex !== undefined ? this.widgetObject.SelectedTabIndex : 0;
      this.selectedMatTabLabel = this.widgetTabs[this.widgetObject.SelectedTabIndex !== undefined ? this.widgetObject.SelectedTabIndex : 0].textLabel;
      this.widgetTabs[this.widgetObject.SelectedTabIndex !== undefined ? this.widgetObject.SelectedTabIndex : 0].tabHasBeenLoaded = true;
		this.tab = this.widgetTabs[this.selectedTabIndex];
		this.startIntervals();

    })


  }


  openConditionalShadingSettingsPopup(tileNumber) {
	let tileConditionalShadingRecord =
		this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
			return p.WidgetId == this.widgetObject.WidgetId && p.SummaryTileIndex == tileNumber;
		});
	if(tileConditionalShadingRecord.length == 0) {
		tileConditionalShadingRecord.WidgetId = this.widgetObject.WidgetId;
		tileConditionalShadingRecord.SummaryTileIndex = tileNumber;
	}

	var DialogRef = this.dialog.open(TacticalWidgetSummaryTilePopupComponent, {
		width: '50%',
		height: '40%',
		data: tileConditionalShadingRecord,
	});


	DialogRef.afterClosed().subscribe((result) => {
		let tileConditionalShadingRecordLatest =
			this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
				return p.WidgetId == this.widgetObject.WidgetId;
			});
		this.ConditionalShadingRecord = [];
		tileConditionalShadingRecordLatest.forEach((r) => {
			this.ConditionalShadingRecord.push(r);
		});

		this.ConditionalShadingRecord.sort((a, b) => parseFloat(a.Value) - parseFloat(b.Value));
		this.ConditionalColorSummaryTiles();

	});

}
	private ConditionalColorSummaryTiles() {
		this.percentTextColor[1] = this.percentTextColor[2] = "lightseagreen";

		this.conditionalColorPercentAmount[1] = this.summaryArray[5] * 100;
		this.conditionalColorPercentAmount[2] = this.summaryArray[4] * 100;

		if (this.ConditionalShadingRecord.length > 0) {
			this.ConditionalShadingRecord.forEach((r) => {

				switch (r.Operator) {
					case 'Is greater than' :
						if(this.conditionalColorPercentAmount[r.SummaryTileIndex] > r.Value) {
							this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
						}
						break;
					case 'Is less than' :
						if(this.conditionalColorPercentAmount[r.SummaryTileIndex] < r.Value) {
							this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
						}
						break;
					case 'Is equal to' :
						var conditionalColorPercentAmountRounded = Math.round(this.conditionalColorPercentAmount[r.SummaryTileIndex]);
						if(conditionalColorPercentAmountRounded == r.Value) {
							this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
						}
						break;
					case 'Is not equal to' :
						var conditionalColorPercentAmountRounded = Math.round(this.conditionalColorPercentAmount[r.SummaryTileIndex]);
						if(conditionalColorPercentAmountRounded != r.Value) {
							this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
						}
						break;
				}
			});
		}

		this.percentMaintenanceModeData = [
			{
				kind: "maintenance mode",
				share: this.conditionalColorPercentAmount[1],
				color: this.percentTextColor[1],
			},
			{
				kind: "other",
				share: 100 - this.conditionalColorPercentAmount[1],
				color: "grey",
			},
		];

		this.percentOutOfServiceModeData = [
			{
				kind: "out of service mode",
				share: this.conditionalColorPercentAmount[2],
				color: this.percentTextColor[2],
			},
			{
				kind: "other",
				share: 100 - this.conditionalColorPercentAmount[2],
				color: "grey",
			},
		];

	}


  showDialogModalPopupGrid(tab) {
    const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
		width: Global.isMobile ? '100%' : '60%',
		height: Global.isMobile ? '90%' : '80%',
		data: {
			graphId: uuidv4(),
			groupable: tab.groupable,
			groupColumns: tab.groupable === true ? tab.groupColumns : [],
			popupTitle: 'Raw Data: ' + tab.textLabel,
			WidgetName: 'Kendo-Grid',
			tableStructure: tab.tableStructure,
			tableData: tab.tableData,
			canCreateDashboardWidgetFromPopup: false,
		},
		maxWidth: '100vw',
		maxHeight: '100vh',
	});

  }

	showChartAsPopup(tab) {
		var popupData;
		if (tab.textLabel == 'Current Unavailable Assets') {
			popupData = {
				categoryField: 'dataType',
				field: 'total',
				popupTitle: 'Raw Chart: ' + tab.textLabel,
				chartTitle: tab.textLabel,
				WidgetName: 'Kendo-Chart',
				chartData: tab.chartData,
				canCreateDashboardWidgetFromPopup: false,
				graphType: tab.graphType?.name,
				legendVisible: tab.legendVisible,
			}
		}
		else if (tab.textLabel == 'Total Minutes in Out of Service For Day') {
			popupData = {
				categoryField: 'value',
				field: 'UnavailableMinutes',
				popupTitle: 'Raw Chart: ' + tab.textLabel,
				titleXAxis: 'Minutes Unavailable',
				chartTitle: tab.textLabel,
				WidgetName: 'Kendo-Chart',
				chartData: tab.chartData,
				canCreateDashboardWidgetFromPopup: false,
				graphType: tab.graphType?.name,
				legendVisible: tab.legendVisible,
				canIterate: true,
			}
		}

		const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '90%' : '90%',
			height: Global.isMobile ? '90%' : '90%',
			data: popupData,
			maxWidth: '100vw',
			maxHeight: '100vh',
		});
	}

	changeGraphType(event) {
		let i = this.selectedTabIndex;
		this.widgetTabs[i].graphType = event;
		let existingRecord = this.widgetTabsChartConfigurations.find(
			(record) => {
				return record.WidgetTabIndex === i;
			}
		);

		let statement =
			'API.TacticalDashboardAddOrUpdateWidgetChartIndexRecord @widgetId = ' +
			this.widgetObject.WidgetId +
			', @userId = ' +
			Global.User.currentUser.Id +
			', @chartTypeId = ' +
			event.id +
			', @widgetTabIndex = ' +
			i;
		if (existingRecord !== undefined) {
			statement = statement + ', @id = ' + existingRecord.Id;
		}
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.widgetTabsChartConfigurations = data;
			this.utilityService.showToastMessageShared({
				type: 'success',
				message: 'Chart type changed to ' + event.properName,
			});
			//   this.toastr.success("Chart type changed to " + event.properName);
		});
	}

	public toggleLegendChange(event: any) {
		this.toggleLegendHidden(this.tab, this.selectedTabIndex);
	}

  retrieveData() {
	  this.timeScopeObject = this.dashboardService.determineProperTimeScopeForWidget({
		widgetObject: this.widgetObject,
		UTCConfiguration: false,
	});
	  if (this.timeScopeObject === undefined) {
      this.displayNoTimeScopeMessage = true;
      this.isLoading = false;
    } else {
      this.displayNoTimeScopeMessage = false;
      if (this.widgetTabs[0].tabHasBeenLoaded === true || this.widgetTabs[1].tabHasBeenLoaded === true) {
        this.widgetTabs[0].isLoading = this.widgetTabs[1].isLoading = true;

        let statement = "API.TacticalDashboard_AssetStatus_OutOfServiceOrMaintenanceMode_Current @accessToken= '" + Global.User.currentUser.ODataAccessToken + "'";
		  if (_.isNil(this.assetIdList) && !_.isNil(this.widgetObject.SiteList)) {
          statement = statement + ", @siteIdList = '" + this.widgetObject.SiteList + "'";
        }
        if (!_.isNil(this.widgetObject.WidgetAssetTypeId) && this.widgetObject.WidgetAssetTypeId !== 0) {
          statement = statement + ", @assetTypeId = " + this.widgetObject.WidgetAssetTypeId;
        }
		  if (!_.isNil(this.assetIdList)) {
			  statement = statement + ", @assetIdList = '" + this.assetIdList + "'";
		  }
        this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			if (data.length === 0) {
            this.widgetTabs[1].tableData = data;
            this.widgetTabs[0].isLoading = this.widgetTabs[1].isLoading = false;

          } else {
            this.widgetTabs[1].chartData = [{
              dataType: 'In Maintenance Mode',
              total: 0,
              tooltip: []
            }, {
              dataType: "Out of Service",
              total: 0,
              tooltip: []
            }, {
              dataType: 'Is Available',
              total: 0,
              tooltip: []
            }]
            let inMaintenanceMode = []
            let outOfService = []
            let isAvailable = []

            data.forEach(asset => {
              if (asset.IsInMaintenanceMode === false && asset.IsOutOfService === false) {
                asset.NotAvailable = 0;
                isAvailable.push(asset);


              }
              else {
                asset.NotAvailable = 1;
                if (asset.IsInMaintenanceMode === true) {
                  inMaintenanceMode.push(asset);
                } else if (asset.IsOutOfService === true) {
                  outOfService.push(asset);
                }
              }
            })
            inMaintenanceMode.forEach((asset, index) => {
              this.widgetTabs[1].chartData[0].total = this.widgetTabs[1].chartData[0].total + 1;
              this.widgetTabs[1].chartData[0].tooltip.push(asset.AssetName)

            })

            outOfService.forEach((asset, index) => {
              this.widgetTabs[1].chartData[1].total = this.widgetTabs[1].chartData[1].total + 1;
              this.widgetTabs[1].chartData[1].tooltip.push(asset.AssetName)

            })

            isAvailable.forEach((asset, index) => {
              this.widgetTabs[1].chartData[2].total = this.widgetTabs[1].chartData[2].total + 1;

				if(this.widgetTabs[1].chartData[2].total < 6) {
					this.widgetTabs[1].chartData[2].tooltip.push( asset.AssetName );
				}
				else if(this.widgetTabs[1].chartData[2].total == 6) {
					this.widgetTabs[1].chartData[2].tooltip.push("...");
				}

            })

			  this.summaryArray[0] = data.length;// Total Asset Count
			  this.summaryArray[1] = outOfService.length; // No. of Assets in Out of Service
			  this.summaryArray[2] = inMaintenanceMode.length// No. of Assets in Maintenance Mode
			  this.summaryArray[3] = isAvailable.length //No. of Assets Available
			  if (outOfService.length === 0) {
				  this.summaryArray[4] = 0.0 //Out Of Service Percent
			  } else {

				  this.summaryArray[4] = (outOfService.length /
					  data.length)  //Out Of Service Percent
			  }
			  if (inMaintenanceMode.length === 0) {
				  this.summaryArray[5] = 0.0//Maintenance Mode Percent
			  } else {
				  this.summaryArray[5] = (inMaintenanceMode.length /
					  data.length) //Maintenance Mode Percent
			  }
			  if (isAvailable.length === 0) {
				  this.summaryArray[6] = 0.0 //Available Percent
			  } else {
				  let availablePercent = parseFloat(
					  (
						  (isAvailable.length /
							  data.length) *
						  100
					  ).toFixed(1)
				  );
				  this.summaryArray[6] = availablePercent //Available Percent
			  }

			  this.ConditionalColorSummaryTiles();



            this.widgetTabs[0].tableData = data;
            this.widgetTabs[1].tableData = data;
            this.widgetTabs[1].chartDataIsReady = true;


            // this.h1Number = data.filter(asset => {
            //   return asset.IsInMaintenanceMode === true || asset.IsOutOfService === true;
            // }).length
            // this.totalAssetLength = data.length;
            // if (this.h1Number === 0) {
            //   this.h4Percent = 0;
            // } else {
            //   this.h4Percent = parseFloat(((this.h1Number / this.totalAssetLength) * 100).toFixed(1));

            // }
            // this.gaugeDisplayData = {
            //   h1Number: this.h1Number,
            //   h4Percent: this.h4Percent,
            //   totalAssetLength: this.totalAssetLength,
            //   parentContainerSize: this.parentContainerSize
            // }

            this.percentCalculated = true;
            this.widgetTabs[0].isLoading = this.widgetTabs[1].isLoading = false;
          }




        })
      }
      if (this.widgetTabs[2].tabHasBeenLoaded === true) {
        this.widgetTabs[2].isLoading = true;
        let secondStatement =
			'API.TacticalDashboard_Asset_OutOfServiceOrMaintenanceMode_Duration ' +
			'@startDateTimeMS=' +
			this.timeScopeObject.queryStartDate +
			', @endDateTimeMS= ' +
			this.timeScopeObject.queryEndDate +
			" , @accessToken='" +
			Global.User.currentUser.ODataAccessToken +
			"'";
		  if (_.isNil(this.assetIdList) && !_.isNil(this.widgetObject.SiteList)) {
			  secondStatement = secondStatement + ", @siteIdList = '" + this.widgetObject.SiteList + "'";
        }
        if (!_.isNil(this.widgetObject.WidgetAssetTypeId) && this.widgetObject.WidgetAssetTypeId !== 0) {
          secondStatement = secondStatement + ", @assetTypeId = " + this.widgetObject.WidgetAssetTypeId;
        }
		  if (!_.isNil(this.assetIdList)) {
			  secondStatement = secondStatement + ", @assetIdList = '" + this.assetIdList + "'";
		  }
        this.dataService.SQLActionAsPromise(secondStatement).then((data: any) => {
          if (data.length === 0) {
            this.widgetTabs[2].tableData = data;
            this.widgetTabs[2].chartData = data;
            this.widgetTabs[2].isLoading = false;
          } else {
            console.log(data);
            data.forEach(record => {
              	record.DateOfObservationDateObject = new Date(record.DateOfObservation)
				record.AssetName = this.dataService.cache.assetsObject[record.AssetId].Name;
            })
            //                     AssetId: 138540
            // AssetName: "FL20002"
            // AssetOutOfServiceMaintenanceModeCount: 0
            // AssetOutOfServiceMaintenanceModeDurationMinutes: 0
            // DateOfObservation: "02/16/2022"
            // DateOfObservationDateObject: Wed Feb 16 2022 00:00:00 GMT-0600 (Central Standard Time) {}
            // JBTStandardObservationName: "Is in Maintenance Mode"
            // SiteName: "ANC"
            this.widgetTabs[2].tableData = data;

            this.widgetTabs[2].chartData = groupBy(data, [{ field: "AssetName" }, { field: "DateOfObservation" }]) as any[];
            if (this.widgetTabs[2].chartData.length > 0) {
              this.widgetTabs[2].chartData.forEach(asset => {

                asset.items.forEach(assetDateAggregate => {

                  assetDateAggregate.items.forEach(dateRecordForOutOfServiceOrMaintenanceMode => {
                    if (dateRecordForOutOfServiceOrMaintenanceMode.JBTStandardObservationName === "Is in Maintenance Mode") {
						assetDateAggregate.InMaintenanceModeCount = dateRecordForOutOfServiceOrMaintenanceMode['AssetOutOfServiceMaintenanceModeCount'];
						assetDateAggregate.InMaintenanceModeMinutes = dateRecordForOutOfServiceOrMaintenanceMode['AssetOutOfServiceMaintenanceModeDurationMinutes']
                    } else if (dateRecordForOutOfServiceOrMaintenanceMode.JBTStandardObservationName === 'Is Out of Service') {
						assetDateAggregate.InOutOfServiceCount = dateRecordForOutOfServiceOrMaintenanceMode['AssetOutOfServiceMaintenanceModeCount'];
						assetDateAggregate.InOutOfServiceMinutes = dateRecordForOutOfServiceOrMaintenanceMode['AssetOutOfServiceMaintenanceModeDurationMinutes']
                    }

                    assetDateAggregate.UnavailableCount = assetDateAggregate.InMaintenanceModeCount + assetDateAggregate.InOutOfServiceCount;
                    assetDateAggregate.UnavailableMinutes = assetDateAggregate.InMaintenanceModeMinutes + assetDateAggregate.InOutOfServiceMinutes;
                    if (assetDateAggregate.UnavailableCount > 0 || assetDateAggregate.UnavailableMinutes > 0) {
                      console.log(assetDateAggregate);
                    }
                    assetDateAggregate.AssetName = asset.value;

                    // asset.unavailableCount = asset.unavailableCount + dateRecordForOutOfServiceOrMaintenanceMode['AssetOutOfServiceMaintenanceModeCount'];
                    // asset.unavailableMinutes = asset.unavailableMinutes + dateRecordForOutOfServiceOrMaintenanceMode['AssetOutOfServiceMaintenanceModeDurationMinutes'];
                  })
                })
              })
            }
            // this.widgetTabs[2].chartData = groupBy(data, [{ field: "AssetName" }, { field: "DateOfObservation" }]) as any[];
            if (this.widgetTabs[2].chartData.length > 0) {
              this.widgetTabs[2].chartData.push({ value: "Toggle All Series" })
            }
            console.log('OutOfService Data')
            console.log(this.widgetTabs[2].chartData);
            this.widgetTabs[2].chartDataIsReady = true;
            this.widgetTabs[2].isLoading = false;

          }


        })

      }





      this.lastUpdatedDate = new Date();

      this.isLoading = false;

	  var bounds = this.elem.nativeElement.getBoundingClientRect();
	  this.cardResize(bounds);

      // this.chartDataIsReady = true;


      // })
    }

	}

	private buildNavigationOptionsArray() {
		var service = this;

		var navigationWidth = Global.isMobile ? "115px" : "155px";

		service.options = [
			{
				id: 1,
				width: navigationWidth,
				name: "Navigation",
				children: [
					{
						id: 10,
						width: navigationWidth,
						name: "Summary",
						action: () => { service.navigateToTab(0, "Summary"); this.navigationOpened=false;}
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Current Unavl. Assets",
						action: () => { service.navigateToTab(1, "Current UnAvailable Assets"); this.navigationOpened=false;}
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Mins Out Of Service / Day",
						action: () => { service.navigateToTab(2, "Total Minutes in Out of Service For Day"); this.navigationOpened=false;}
					},

					// 	{
					// 		id: 11,
					// 		width: navigationWidth,
					// 		name: "Raw Data",
					// 		children: [
					// 			{
					// 				id: 12,
					// 				width: navigationWidth,
					// 				name: "Current UnAvailable Assets",
					// 				action: () => { service.showDialogModalPopupGrid(service.widgetTabs[1]); this.navigationOpened=false;}
					// 			},
					// 			{
					// 				id: 12,
					// 				width: navigationWidth,
					// 				name: "Mins Out Of Service / Day",
					// 				action: () => { service.showDialogModalPopupGrid(service.widgetTabs[2]); this.navigationOpened=false;}
					// 			},

					// 	],


					// }

				],
				root: true,
				opened: false
			},
			{
				id: 2,
				width: navigationWidth,
				name: "Settings",
				children: [
					{
						id: 10,
						width: navigationWidth,
						name: "Site",
						action: () => { service.widgetObject.editWidgetSettings(service.widgetObject); this.navigationOpened=false;}
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Edit Name",
						action: () => { service.widgetObject.editWidgetName(service.widgetObject); this.navigationOpened=false;}
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Reload",
						action: () => { service.dashboardService._reloadedWidget.next(service.widgetObject); this.navigationOpened=false;}
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Delete",
						action: () => { service.widgetObject.deleteFunction(); this.navigationOpened=false;}
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Timescope",
						action: () => { service.widgetObject.editTimeScopeSettings(service.widgetObject); this.navigationOpened=false;}
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Interval",
						action: () => { service.widgetObject.editIntervalSettings(service.widgetObject); this.navigationOpened=false;}
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Legend",
						action: () => { service.toggleLegendHidden(service.tab, service.selectedTabIndex); this.navigationOpened=false;}
					},

					{
						id: 23,		// id has to match id in toggle method below
						width: navigationWidth,
						name: service.widgetObject.RememberSelectedTabIndex == 0 ? "Remember Nav" : "Forget Nav",
						action: () => { service.widgetObject.invertRememberingOfSelectedTabIndex(service.widgetObject); this.toggleRememberingOfSelectedTabIndexName(); this.navigationOpened=false;}
					},

				],
				root: true,
				opened: false
			}
		];

		let optionsDesktop = [
			{
				id: 22,		// id has to match id in toggle method below
				width: navigationWidth,
				name: service.widgetObject.dragEnabled ? "Disable Drag" : "Allow Drag",
				action: () => { service.widgetObject.invertDraggableSetting(service.widgetObject); this.toggleDragName(); this.navigationOpened=false;}
			},
			{
				id: 21,		// id has to match id in toggle method below
				width: navigationWidth,
				name: service.widgetObject.resizeEnabled ? "Disable Resize" : "Allow Resize",
				action: () => { service.widgetObject.invertResizeSetting(service.widgetObject); this.toggleResizeName(); this.navigationOpened=false;}
			},
		];

		if(Global.isMobile == false) {
			service.options[1].children = service.options[1].children.concat(optionsDesktop);
		}

		service.optionsSettingsOnly = service.options.where((item: any) => { return item.id == 2 }).toArray(); //- Settings tab only when no Site selected.

		console.log("options built", service.options);

	}


	public editWidgetSettings() {
		this.widgetObject.editWidgetSettings(this.widgetObject)
	}

	public refreshWidget() {
		this.dashboardService._reloadedWidget.next(this.widgetObject);
	}

	public changeWidgetInterval() {
		this.widgetObject.editIntervalSettings(this.widgetObject);
	}

	public changeWidgetTimescope() {
		this.widgetObject.editTimeScopeSettings(this.widgetObject);
	}


	private toggleRememberingOfSelectedTabIndexName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item: any) => { return item.id == 2 }).firstOrDefault();
		var rememberTabSetting = settings.children.where((item: any) => { return item.id == 23 }).firstOrDefault();

		rememberTabSetting.name = this.widgetObject.RememberSelectedTabIndex == 0 ? "Remember Nav" : "Forget Nav";

	}

	private toggleDragName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item: any) => { return item.id == 2 }).firstOrDefault();
		var dragSetting = settings.children.where((item: any) => { return item.id == 22 }).firstOrDefault();

		dragSetting.name = this.widgetObject.dragEnabled ? "Disable Drag" : "Enable Drag";

	}

	private toggleResizeName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item: any) => { return item.id == 2 }).firstOrDefault();
		var resizeSetting = settings.children.where((item: any) => { return item.id == 21 }).firstOrDefault();

		resizeSetting.name = this.widgetObject.resizeEnabled ? "Disable Resize" : "Allow Resize";

	}

	public checkNavigation(opened: any) {
		Global.User.DebugMode && console.log("checkNavigation opened = %O", opened);
		this.navigationOpened = opened;
		this.isLoading = false;
	}

	public checkSelectedItem(selected: any) {
		Global.User.DebugMode && console.log("checkSelectedItem invoked. selected = %O", selected);
	}

	public openCloseNavigation() {
		Global.User.DebugMode && console.log("openCloseNavigation invoked. navigationOpened current status: = " + this.navigationOpened);
		this.navigationOpened = !this.navigationOpened; //--whatever it was set to, set it to the opposite.
		Global.User.DebugMode && console.log("openCloseNavigation invoked. navigationOpened changed status: = " + this.navigationOpened);
	}
  toggleLegendHidden(tab, index) {
    tab.legendVisible = !tab.legendVisible;
    let foundSetting = this.WidgetTabSettings.find(widgetTabSetting => {
      return widgetTabSetting.TabIndex === index;
    })

    let statement = "API.EditWidgetTabSettings @WidgetId = " + this.widgetObject.WidgetId + ", @TabIndex = " + index + ", @IsHidden = " + !tab.legendVisible;
    if (!_.isNil(foundSetting)) {
      statement = statement + ", @Id = " + foundSetting.Id;
    }
    this.dataService.SQLActionAsPromise(statement).then((data: any) => {
      this.WidgetTabSettings = data;
      this.WidgetTabSettings.forEach(tabSetting => {
        this.widgetTabs[tabSetting.TabIndex].legendVisible = !tabSetting.IsLegendHidden;
      })
    })

  }

  animationRedraw() {
    // if (this.selectedMatTabLabel === "Map") {
    // 	this.loadCorrectTabData(true)

    // }
  }



  onResized(event) {
    // this.tagDataPageSize = Math.floor(((event.newRect.height - 120) / this.tagDataRowHeight) * 3);
    // this.eventDataPageSize = Math.floor(((event.newRect.height - 120) / this.eventDataRowHeight) * 3);
    this.parentContainerSize = event.newRect.height;
    if (this.childOverviewGauge !== undefined) {
      this.childOverviewGauge.reFlow()

    }

    // Global.User.DebugMode && console.log("gse-summary: pageSize = " + this.tagDataPageSize);
    // this.newHeight = event.newRect.height;
    // this.oldHeight = event.oldHeight;
    // this.newWidth = event.newRect.width;
    // this.oldWidth = event.oldWidth;
  }
	navigateToTab(index, textLabel) {
		this.tab = this.widgetTabs[index];
		if (this.tab.graphType != undefined && this.tab.graphType.name == undefined)
			this.tab.graphType = Global.tacticalDashboardGraphTypes.find((graphType) => graphType.name == this.tab.graphType);

		clearTimeout(this.timeoutToExpire);
		this.selectedMatTabLabel = textLabel;
		this.selectedTabIndex = index;
		if (this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded === false) {
			this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded = true;
			this.destroyIntervals();
			this.startIntervals();
		}
		this.timeoutToExpire = setTimeout(() => {
			this.widgetObject.SelectedTabIndex = this.selectedTabIndex;
			if (this.widgetObject.RememberSelectedTabIndex) {
				let returnedWidgetAsArray =
					this.dataService.updateTacticalDashboardWidgetRecord(
						this.widgetObject
					);
			}

	}, 5000);
  }

  ngOnDestroy() {
    this.destroyIntervals();

  }

  public labelContent(e: any): string {
    return e.category + ': ' + e.value;
  }

	public labelContentShort(e: any): string {
		return e.value;
	}

  public toggleSeries(item: any): void {

    let i = item.series.index;

    this.series._results[i].toggleVisibility();



  }

  public toggleAllSeries(e) {
    if (e.text === "Toggle All Series") {
      this.series._results.forEach((series, index) => {
        this.series._results[index].toggleVisibility();
      })
    }
  }


}
