<div
	class="parent-table-container"
	id="parentTableContainerPopup"
	(resized)="onResized($event)"
	[hidden]="isDataLoading"
	*ngIf="dataObject.WidgetName === 'Kendo-Grid'"
>
<lib-kendo-grid-parent
		[gridPurpose]="dataObject?.description == 'Notes list' ? 'logbook' : undefined"

		[gridData]="dataObject.tableData"
		[gridSettings]="gridSettings"
		[groupable]="dataObject.groupable"
		[style.height.%]="100"
		filterable="menu"
		[sortable]="true"
		[reorderable]="true"
		[resizable]="true"
		*ngIf="dataObject?.tableData"

		#tagDataGrid

	>
	</lib-kendo-grid-parent>

</div>
<div
	class="parent-table-container"
	id="parentTableContainerPopup"
	(resized)="onResized($event)"
	[hidden]="isDataLoading"
	*ngIf="dataObject.WidgetName === 'Kendo-Chart'"
>

<mat-slide-toggle style="margin: 10px" [checked]="dataObject.legendVisible" (change)="toggleLegendChange()">Legend</mat-slide-toggle>
	<kendo-chart [style.height.%]="90" [transitions]="false" *ngIf="dataObject.chartData?.length > 0">
		<kendo-chart-value-axis>
			<kendo-chart-value-axis-item [title]="{ text: dataObject.titleXAxis }" [color]="axisItemColor">
			</kendo-chart-value-axis-item>
		</kendo-chart-value-axis>
		<kendo-chart-area [background]="chartBackgroundColor">
		</kendo-chart-area>
		<kendo-chart-series *ngIf="dataObject.canIterate && dataObject.aggregate && (dataObject.stackedSeriesValues == undefined || dataObject.stackedSeriesValues.length == 0)">
			<kendo-chart-series-item
				*ngFor="let item of dataObject.chartData"
				[type]=" dataObject.graphType"
				[data]="item.items"
				[field]="dataObject.field"
				[name]="item.value"
				[categoryField]="dataObject.categoryField"
				aggregate="count"
			>
				<kendo-chart-series-item-tooltip  *ngIf="dataObject.useTooltipTemplate == true">
					<ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem">
						<div>
						<span>
							<strong>{{dataItem.tooltip}}</strong>
						</span>
						</div>
					</ng-template>
				</kendo-chart-series-item-tooltip>
				<kendo-chart-series-item-labels *ngIf="dataObject.graphType == 'donut'" position="outsideEnd" color="#000" [content]="labelContent">
				</kendo-chart-series-item-labels>
			</kendo-chart-series-item>
		</kendo-chart-series>
		<kendo-chart-series *ngIf="dataObject.canIterate && !dataObject.aggregate && (dataObject.stackedSeriesValues == undefined || dataObject.stackedSeriesValues.length == 0)">
			<kendo-chart-series-item
				*ngFor="let item of dataObject.chartData"
				[type]=" dataObject.graphType"
				[data]="item.items"
				[field]="dataObject.field"
				[name]="item.value"
				[categoryField]="dataObject.categoryField"
				[stack]="dataObject.stack"
			>
				<kendo-chart-series-item-tooltip  *ngIf="dataObject.useTooltipTemplate == true">
					<ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem">
						<div>
						<span>
							<strong>{{dataItem.tooltip}}</strong>
						</span>
						</div>
					</ng-template>
				</kendo-chart-series-item-tooltip>
				<kendo-chart-series-item-labels *ngIf="dataObject.graphType == 'donut'" position="outsideEnd" color="#000" [content]="labelContent">
				</kendo-chart-series-item-labels>
			</kendo-chart-series-item>
		</kendo-chart-series>



		<kendo-chart-series *ngIf="dataObject.stackedSeriesValues?.length > 0">
			<kendo-chart-series-item
				*ngFor="let stackedSeriesItem of dataObject.stackedSeriesValues; let i = index"
				[type]="dataObject.graphType"
				[data]="dataObject.chartData"
				[field]="stackedSeriesItem.field"
				[name]="stackedSeriesItem.name"
				[categoryField]="dataObject.categoryField"
				[stack]="dataObject.stack"
				[color]="stackedSeriesItem.color"
			>
				<kendo-chart-series-item-tooltip>
					<ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem">
						<div>
						<span>
							<strong>{{dataItem.tooltip[i]}}</strong>
						</span>
						</div>
					</ng-template>
				</kendo-chart-series-item-tooltip>
				<kendo-chart-series-item-labels *ngIf="dataObject.graphType == 'donut'" position="outsideEnd" color="#000" [content]="labelContent">
				</kendo-chart-series-item-labels>
			</kendo-chart-series-item>
		</kendo-chart-series>



		<kendo-chart-series *ngIf="!dataObject.canIterate && (dataObject.stackedSeriesValues == undefined || dataObject.stackedSeriesValues.length == 0)">
			<kendo-chart-series-item *ngIf="dataObject.overviewOperator != undefined"
				[type]=" dataObject.graphType"
				[data]="dataObject.chartData"
				[field]="dataObject.field"
				[color]="pointColor"
				[categoryField]="dataObject.categoryField"
			>
				<kendo-chart-series-item-tooltip  *ngIf="dataObject.useTooltipTemplate == true">
					<ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem">
						<div>
						<span>
							<strong>{{dataItem.tooltip}}</strong>
						</span>
						</div>
					</ng-template>
				</kendo-chart-series-item-tooltip>
				<kendo-chart-series-item-labels *ngIf="dataObject.graphType == 'donut'" position="outsideEnd" color="#000" [content]="labelContent">
				</kendo-chart-series-item-labels>
			</kendo-chart-series-item>
			<kendo-chart-series-item *ngIf="dataObject.overviewOperator === undefined"
				[type]=" dataObject.graphType"
				[data]="dataObject.chartData"
				[field]="dataObject.field"
				[categoryField]="dataObject.categoryField"
			>
				<kendo-chart-series-item-tooltip  *ngIf="dataObject.useTooltipTemplate == true">
					<ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem">
						<div>
						<span>
							<strong>{{dataItem.tooltip}}</strong>
						</span>
						</div>
					</ng-template>
				</kendo-chart-series-item-tooltip>
				<kendo-chart-series-item-labels *ngIf="dataObject.graphType == 'donut'" position="outsideEnd" color="#000" [content]="labelContent">
				</kendo-chart-series-item-labels>
			</kendo-chart-series-item>
		</kendo-chart-series>
		<kendo-chart-title [color]="axisItemColor" [text]="dataObject.chartTitle">
		</kendo-chart-title>
		<kendo-chart-tooltip format="{0}">
		</kendo-chart-tooltip>
		<kendo-chart-category-axis>
			<kendo-chart-category-axis-item
				[majorGridLines]="{ visible: true, color: gridLineColor }"
				[color]="axisItemColor"
				[labels]="{ format: 'd', rotation: 'auto' }"
				[color]="axisItemColor"
				baseUnit="fit"
			>
			</kendo-chart-category-axis-item>
		</kendo-chart-category-axis>
		<kendo-chart-legend [visible]="dataObject.legendVisible" [labels]="{ color: axisItemColor }"></kendo-chart-legend>
	</kendo-chart>
</div>
